import React, {Component} from 'react';
import * as auth from '../../helpers/authenticationHelper';
import {i18n} from '../../data';
import {ChildContainer} from '../../containers';
import {Link} from 'react-router-dom';
import * as api from '../../api';

export class VerifyPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isEmailConfirmationSent: false
    };
    this.onClick = this.onClick.bind(this)
    this.closePopup = this.closePopup.bind(this)
  }

  closePopup(e) {
    if (e.target === e.currentTarget) {
      window.location.href = `/cabinet/child/${auth.getCurrentChild()}`
    }
  }

  render() {

    const formComponent = (
      <div className="verify-password__wrapper">
        <h2 className="verify-password__title">{i18n.verifyTitle}</h2>
        <p className="verify-password__text">{i18n.verifyText}</p>
        <h3 className="verify-password__sub-title">{`${i18n.verifyEmail}: ${auth.getUserEmail()}`}</h3>
        <button
          type="button"
          onClick={this.onClick}
          className="btn verify-password__btn"
        >
          {i18n.verifyButton}
        </button>
      </div>
    );

    const requestResultComponent = (
      <div className="verify-password__wrapper verify-password__wrapper_type_result">
        <i className="verify-password__icon_result"/>
        <h2 className="verify-password__title">Мы отправили вам письмо</h2>
        <p className="verify-password__text">Просто откройте наше письмо и&nbsp;нажмите кнопку «Подтвердить аккаунт»</p>
      </div>
    );

    return (
      <div className="verify-password">
        <ChildContainer/>
        <section className="popup verify-password__popup" onClick={(e) => this.closePopup(e)}>
          <div className="verify-password__inner">
            <Link
              to={`/cabinet/child/${auth.getCurrentChild()}`}
              type="button"
              className="popup__close">
            </Link>
            {this.state.isEmailConfirmationSent ? requestResultComponent : formComponent}
          </div>
        </section>
      </div>
    );
  }

  onClick(event) {
    event.preventDefault();

    api.postEmailConfirmation(auth.getUserEmail())
      .then(response => {
        if (response.message) {
          this.setState({
            isEmailConfirmationSent: true
          })
        }
      });
  }
}
