import React from 'react';
import PropTypes from 'prop-types';
import * as auth from '../../helpers/authenticationHelper';


export const Lessons = (props) => {
  const {lessonsList, buyLesson} = props;

  let lessonElements;
  if (lessonsList?.length) {
    lessonElements = lessonsList.map((item) => {
      const checkPayment = ['on', 'soon_finished'].includes(((auth.getProfile() || {}).subscription?.status) || {recurrent_status: 2});
      let questPaid = item.is_bought;

    /*  for (let i = 0; i < payments.length; i++) {
        if (payments[i].quest && payments[i].quest.id === item.id)
          if (payments[i].status === 'completed') {
            questPaid = true;
          }
      }*/
      return (
        <div className={'quest__name__wrapper'} onClick={() => {
          if (item.category === 'free') {
            window.location.replace(`/quests/${item.id}`);
          }
          if (item.category === 'subscription') {
            if (checkPayment) {
              window.location.replace(`/quests/${item.id}`);

            } else {
              window.location.replace('/cabinet/select_tariff');
            }
          }
          if (item.category === 'paid') {

            if (questPaid) {
              window.location.replace(`/quests/${item.id}`);
            } else {
            buyLesson(true,item)
            //  setCurrentQuest(item);
            //  setShowPopup(true);

            }
          }
        }} style={{position: 'absolute', top: `${item.icon_position_y}px`, left: `${item.icon_position_x}px`}}><img
          alt={'Иконка маршрута'} className={'map__questelement'} src={item.icon}/>
        <div className={'quest__name'}>{item.name}</div></div>
      )
    })
  }

  return (
    <>

      <nav className="lessons">
        <ul className="lessons__list">
          <div>
            {lessonElements}
          </div>
        </ul>
      </nav>
    </>
  );
};

Lessons.propTypes = {
  lessonsList: PropTypes.array,
  showPopUp: PropTypes.func
};
