import React from 'react';
import PropTypes from 'prop-types';

const Button = (props) => {
  const {
    children,
    color,
    size,
    mod,
    customTag,
    staticContext,
    ...rest
  } = props;

  let Tag = customTag;
  let attributes = {};

  if (!customTag) {
    Tag = 'button';
    attributes.type = 'button';
  }

  let buttonStyle = `${mod || ''} new-button new-button_color_${color}`;
  buttonStyle += size ? ` new-button_size_${size}` : ' new-button_size_m';

  return (
    <Tag
      className={buttonStyle}
      {...attributes}
      {...rest}
    >{children}</Tag>
  );
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired
}

export default Button;
