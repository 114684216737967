import {redirect} from './index';
import {config} from '../data';
import isEmpty from 'lodash/isEmpty'; // https://lodash.com/docs/4.17.4#isEmpty
import * as api from '../api';
import Cookies from 'universal-cookie';

import decode from 'jwt-decode';

/**
 * Получает время жизни токена
 *
 * @param token
 * @returns {Date}
 */
export function getTokenExpirationDate(token) {
  //  Токен должен быть строкой
  if (typeof token !== 'string') return null;

  // Токен должен содержать разделяющую точку, см. https://jwt.io/
  if (token.split('.').length < 2) return null;

  const decoded = decode(token);

  if (!decoded.exp) {
    return null;
  }

  const date = new Date(0); // The 0 here is the key, which sets the date to the epoch

  date.setUTCSeconds(decoded.exp);
  return date;
}

/**
 * Проверяет если у токена не кончилось время жизни
 * @param token
 * @returns {boolean}
 */
export function isTokenExpired(token) {
  const date = getTokenExpirationDate(token);
  const offsetSeconds = 0;

  if (date === null) {
    return true;
  }

  return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
}

/**
 * @typedef {Object} Profile
 *
 * @property {Number} id
 * @property {Boolean} is_confirmed
 * @property {String} email
 * @property {Number} [vk_id]
 * @property {String} [currentChildName]
 * @property {Number} [currentChildId]
 * @property {String} [currentChildAvatar]
 * @property {Array<{id, name, category_ids}>} [children]
 *
 */

/**
 *
 * @param {Object} response
 * @param {Object} response.jwt
 */
export async function handleAuthResponse(response) {
  let jwt = response.jwt;
  let currentChild = getCurrentChild();

  if (isTokenExpired(jwt)) {
    redirect('/?auth=login');
    return;
  }

  setToken(jwt);

  const profile = await api.getCurrentUserProfile();
  setProfile(profile);

  if (currentChild) {
    redirect(`/cabinet/child/${currentChild}`);
  } else {
    // если детей > 1
    if (getChildren().length > 1) {
      redirect(`/cabinet/${config.routes.child_selector}`);

      // если только 1 ребёнок
    } else {
      let childId = getChildren()[0].id;
      let childName = getChildren()[0].name;
      let childAvatar = getChildren()[0].avatar;

      setCurrentChild(childId, childName, childAvatar);
      redirect('/');
    }
    window.localStorage.setItem('auth', true);
  }

  try {
    api.sendClientId(profile.id, window.ga.getAll()[0].get('clientId'));
  } catch (error) {
    // console.log(`Error with send user Id: ${error}`)
  }
}

export async function handleNewAuthResponse(response) {
  let jwt = response.jwt;

  if (isTokenExpired(jwt)) {
    redirect('/?auth=login');
    return;
  }

  setToken(jwt);

  const profile = await api.getCurrentUserProfile();
  setProfile(profile);

  let currentChild = getCurrentChild();
  const children = getChildren();

  if (currentChild) {
    redirect('/lessons');
  } else if (children.length === 1) {
    const { id, name, avatar } = children[0];
    setCurrentChild(id, name, avatar);
    redirect('/?popup=successReg');
    window.localStorage.setItem('auth', 'true');
  }


}

/**
 * Устанавливаем параметры при переходе
 * @returns {Promise<string>}
 */
export async function handleSwitchFromSubdomain() {
  const cookies = new Cookies();
  const token = cookies.get('auth_token');
  const profile = localStorage.getItem('profile');

  if (token && !profile) {
    const response = await api.getCurrentUserProfile();

    if (response) {
      setProfile(response);
      localStorage.setItem('auth', 'true');
      window.location.href = '/';
    }
  }

  if (token && localStorage.getItem('auth_token') !== token) {
    localStorage.setItem('auth_token', token); // Игры берут токен отсюда
  }
}

export function setToken(token) {
  // Строка 'undefined'
  if (!token || token === 'undefined') return;

  let domain = 'chevostik.ru';

  if (process.env.NODE_ENV === 'development') {
    domain = 'localhost';
  }

  const cookies = new Cookies();
  cookies.set('auth_token', token, { domain, path: '/', maxAge: 86400 * 30, secure: true });
  localStorage.setItem('auth_token', token); // Игры берут токен отсюда
}

export function getToken() {
  const cookies = new Cookies();
  return cookies.get('auth_token');
}

export function logout() {
  localStorage.removeItem('profile');
  localStorage.removeItem('visit_counter');

  const cookies = new Cookies();

  let domain = 'chevostik.ru';

  if (process.env.NODE_ENV === 'development') {
    domain = 'localhost';
  }

  cookies.remove('auth_token', { domain, path: '/' });
  redirect('/?auth=login');
}

/**
 * Сохраняет в cookies id профиля
 *
 * @param profile
 */
export function setCookies(profile) {
  if (!profile || profile === 'undefined') return;
  const cookies = new Cookies();
  let date = new Date();
  date.setDate(date.getDate() + 60);

  cookies.set('chevostik_uid', profile.id, {expires: date});
}

/**
 * Сохраняет в локальное хранилище профиль пользователя
 *
 * @param profile - объект для сохранения
 * @param update - для режима обновления не отправлять статистику
 */
export function setProfile(profile, update) {
  if (!profile || profile === 'undefined') return;
  setCookies(profile);
 // window.gtag({event: 'authentication', userId: profile.id});
  localStorage.setItem('profile', JSON.stringify(profile));

  /* global ga */
 /* if (!update) {
    ga('set', 'userId', profile.id);
    ga('send', 'event', 'authentication', 'user-id available');
  }*/
}

export function getProfile() {
  const profile = localStorage.getItem('profile');
  if (!profile || profile === 'undefined') return;
  setCookies(JSON.parse(profile));

  return JSON.parse(profile);
}

/**
 *
 * @return {Promise.<Profile>}
 */
export function renewProfile() {
  return api.postNewToken()
    .then(async response => {
      const jwt = response.jwt;

      setToken(jwt);

      const profile = await api.getCurrentUserProfile();
      setProfile(profile);

      return profile;
    })
    .catch(e => console.error(e));
}

export function fetchProfile() {
  return Promise.resolve(getProfile());
}

export function bindVk(id) {
  let profile = getProfile();
  profile.vk_id = id;
  setProfile(profile);
}

export function unBindVk() {
  let profile = getProfile();
  delete profile.vk_id;
  setProfile(profile);
}

export function bindFb(id) {
  let profile = getProfile();
  profile.fb_id = id;
  setProfile(profile);
}

export function unBindFb() {
  let profile = getProfile();
  delete profile.fb_id;
  setProfile(profile);
}

export function getCurrentChild() {
  let profile = localStorage.getItem('profile');
  if (!profile || profile === 'undefined') return;

  return JSON.parse(profile).currentChildId;
}

export function getCurrentName() {
  let profile = localStorage.getItem('profile');
  if (!profile || profile === 'undefined') return;

  return JSON.parse(profile).currentChildName;
}

export function getCurrentAvatar() {
  let profile = localStorage.getItem('profile');
  if (!profile || profile === 'undefined') return;

  return JSON.parse(profile).currentChildAvatar;
}

export function isSubscriptionActive() {
  let profile = localStorage.getItem('profile');
  if (!profile || profile === 'undefined') return;
  return JSON.parse(profile).subscription;
}

export function getUserEmail() {
  let profile = JSON.parse(localStorage.getItem('profile'));
  return profile && profile.email;
}

/**
 *
 * @param {number} id
 * @param {string} [name]
 * @param {string} [avatar]
 *
 * @returns {Profile|undefined} profile
 */
export function setCurrentChild(id, name, avatar) {
  let profile = localStorage.getItem('profile');

  if (!profile || profile === 'undefined') return;

  profile = JSON.parse(profile);

  if (!name) {
    let currentChild = profile.children.find(child => child.id === id);
    name = currentChild && currentChild.name;
  }

  profile.currentChildId = id;
  profile.currentChildName = name;
  profile.currentChildAvatar = isEmpty(avatar) ? '1' : avatar;

  // меняю имя в массиве children
  profile.children.forEach(element => {
    if (element.id === id) {
      element.name = profile.currentChildName;
      element.avatar = profile.currentChildAvatar;
    }
  });

  // проверяю добавлен ли новый ребёнок
  const isNewChild = (children, newChildId) => {
    const isChildId = child => child.id === newChildId;

    return children.filter(isChildId).length === 0;
  };

  // если такого id нет, добавляю нового ребёнка в массив children
  if (isNewChild(profile.children, id)) {
    profile.children.push({
      id: id,
      name: name,
      avatar: avatar
    });
  }

  localStorage.setItem('profile', JSON.stringify(profile));

  return profile;
}

/**
 * @returns {Array|undefined}
 */
export function getChildren() {
  let profile = localStorage.getItem('profile');
  if (!profile || profile === 'undefined') return;

  return JSON.parse(profile).children;
}

/**
 * Проверяет, залогинен ли пользователь
 *
 * @returns {boolean}
 */
export function isLoggedIn() {
  const token = getToken();

  return !!token && !isTokenExpired(token);
}
