import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from '../../data';

export const UserEmail = (props) => {
  return (
    <label className="input-txt">
      <input
        className="input-txt__field"
        placeholder={props.placeholder}
        name={props.name}
        maxLength={35}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onChange}
        required={props.isRequired}
        type="email"
      />
      {
        props.message && (<p className="input-txt__message input-txt__message_state_error"
          dangerouslySetInnerHTML={{__html: props.message}}
        />)
      }
    </label>
  );
}

UserEmail.defaultProps = {
  isRequired: true,
  name: 'email',
  placeholder: i18n.email
};

UserEmail.propTypes = {
  isRequired: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string
};
