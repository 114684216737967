/**
 * Пробел каждые три символа
 *
 * @param {string} str
 *
 * @returns {string}
 */
export const splitThousands = (str) => {
  return (str + '').replace(/(\d)(?=(\d{3})+$)/g, '$1 ')
}
