import React from 'react'

export default ({className , id}) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74 74" fill="none" className={className}>
<circle cx="37" cy="37" r="37" fill="#FFFBF8"/>
<g filter={id}>
<circle cx="37" cy="37" r="30" fill="#E34E0E"/>
</g>
<path d="M20 33C20 30.7909 21.7909 29 24 29H50C52.2091 29 54 30.7909 54 33V47C54 49.2091 52.2091 51 50 51H24C21.7909 51 20 49.2091 20 47V33Z" fill="#FFFBF8"/>
<path fillRule="evenodd" clipRule="evenodd" d="M37 36C40.866 36 44 32.866 44 29C44 25.134 40.866 22 37 22C33.134 22 30 25.134 30 29C30 32.866 33.134 36 37 36ZM37 40C43.0751 40 48 35.0751 48 29C48 22.9249 43.0751 18 37 18C30.9249 18 26 22.9249 26 29C26 35.0751 30.9249 40 37 40Z" fill="#FFFBF8"/>
<path d="M35 39C35 37.8954 35.8954 37 37 37C38.1046 37 39 37.8954 39 39V43C39 44.1046 38.1046 45 37 45C35.8954 45 35 44.1046 35 43V39Z" fill="#DE4C0E"/>
<defs>
<filter id={id} x="7" y="7" width="60" height="60" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feFlood floodOpacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="5"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow"/>
</filter>
</defs>
</svg>