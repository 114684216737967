import { ajaxV2 } from '../../helpers';
import React from 'react';
import { render } from 'react-dom';

import {
  ErrorPage
} from '../../containers';

/**
 * @typedef {Object} Quest
 */

/**
 * @param {number} childId
 * @param {number} id
 * @returns {Promise.<Quest>}
 */
export const getQuest = (childId, id) => {
  return ajaxV2
    .get(`/quests/${id}?child_id=${childId}`)
    .then(response => response.data)
    .catch(err => {
      window.devMode && console.log('getQuest error: ', err)

      if (err.response.status === 404) {
        render(
          <ErrorPage status={404} />,
          document.getElementById('root')
        );
      }
    })
};
