import React from 'react'

export default ({className , id}) => <svg xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 74 74" fill="none" className={className}>
<circle cx="37" cy="37" r="37" fill="white"/>
<g filter={id}>
<circle cx="37" cy="37" r="29" fill="#91CA5A"/>
</g>
<g filter={id + 1}>
<path d="M54.6253 25.3274C52.8252 23.5468 49.9121 23.5468 48.1447 25.3274L33.2524 40.0576L25.8553 32.7087C24.0551 30.9281 21.1421 30.9281 19.3747 32.7087C17.5745 34.4892 17.5745 37.3705 19.3747 39.1511L30.0448 49.7051C31.845 51.4857 34.758 51.4857 36.5254 49.7051L54.6253 31.7698C56.4255 29.9892 56.4255 27.108 54.6253 25.3274Z" fill="white"/>
</g>
<defs>
<filter id={id} x="8" y="8" width="58" height="58" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="3.5"/>
<feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.29 0"/>
<feBlend mode="normal" in2="shape" result="effect1_innerShadow"/>
</filter>
<filter id={id + 1} x="13.0246" y="18.9919" width="47.9509" height="37.0486" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
<feOffset/>
<feGaussianBlur stdDeviation="2.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
</filter>
</defs>
</svg>