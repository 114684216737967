/**
 * Форматирует день или месяц
 *
 * Пример: 
 * 
 * 1) formatDayAndMonth('1') // 01
 * 2) formatDayAndMonth('05') // 05
 * 3) formatDayAndMonth('0005') // 05
 *
 * @param string
 * @returns string
 */
export const formatDayAndMonth = (string) => ('0' + string).slice(-2);
