import React, { Component } from 'react';
import c from 'classnames';

export class AudioFactPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
      styleAnimation: {},
    };

    this.toggleAudioPlay = this.toggleAudioPlay.bind(this);
  }

  componentDidUpdate (prevProps) {
    const { duration } = this.props;
    const { styleAnimation } = this.state;

    if ((prevProps.duration !== duration) && duration) {
      const animationName = styleAnimation && styleAnimation.animation && styleAnimation.animation.includes('dash')
        ? 'hack'
        : 'dash'
      return this.setState({
        styleAnimation: {
          animation: `${animationName} ${duration}s linear forwards`,
          strokeDasharray: 503,
          strokeDashoffset: 503,
        }
      }, )
    }
  }

  toggleAudioPlay() {
    const { audio } = this.props;

    if (audio.paused) {
      audio.play()
      return this.setState({
        styleAnimation: {
          ...this.state.styleAnimation,
          animationPlayState: 'running ',
        }
      })
    }

    this.setState({styleAnimation: {
      ...this.state.styleAnimation,
      animationPlayState: 'paused',
    }})
    audio.pause()
  }

  render() {
    const { audio, duration } = this.props;
    const { styleAnimation } = this.state;

    return (
      <div className="audio-fact-player">
        <svg
          viewBox="0 0 200 200"
          className="audio-fact-player__progress"
        >
          <circle
            r="80"
            cx="100"
            cy="100"
            fill="none"
            strokeWidth="25"
            stroke="#FFF"
            strokeOpacity=".8"
          />
          {duration && <circle
            r="80"
            cx="100"
            cy="100"
            fill="none"
            strokeWidth="15"
            stroke="#96BB24"
            strokeLinecap="round"
            style={styleAnimation}
          />}
        </svg>
        <button
          className={c(
            'audio-fact-player__controll',
            {'audio-fact-player__controll--pause': !audio.paused},
            {'audio-fact-player__controll--play': audio.paused},
          )}
          onClick={this.toggleAudioPlay}
        />
      </div>
    )
  }
}



