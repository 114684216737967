import React from 'react';
import cn from 'classnames';
import style from './game.module.sass';
import * as images from './images';

export const Game = () => {
  return (
    <div className={cn(style.game)}>
      <picture className={style.image}>
        <source media="(max-width: 849px)" srcSet={`${images.cheMobile} 1x, ${images.cheMobileRetina} 2x`} />
        <img src={images.che} srcSet={`${images.cheRetina} 2x`} alt="" />
      </picture>
      <div className={style.title}>Это сокращенная версия</div>
      <div className={style.descr}>
        Используйте приложение, играйте в логические игры, следите за развитием навыков вашего ребенка.
      </div>
      <a href="https://chevostik.ru/#universal" className={style.button}>Скачать приложение</a>
    </div>
  );
};
