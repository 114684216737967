import React, {Component} from 'react';
import {connect} from 'react-redux';
import {setPreloader} from '../../redux/actions';
import {withRouter} from 'react-router';
import * as auth from '../../helpers/authenticationHelper';
import * as api from '../../api';
import {
  CabinetBanner,
  CabinetStats,
  CabinetChildrenSelector,
  CabinetResults,
  CabinetProgress,
  CabinetLessons,
  CabinetQuestsFilter,
  CabinetQuests
} from '../../components';
import {ChildProfile} from './child-profile';
import {AddChildContainer} from '../cabinet';
import {config} from '../../data';

class ChildContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      children: null,
      child: null,
      stats: null,
      lessons: null,
      questsFilter: 'all',
      time: null,
      statsText: null,
      contentLoaded: false,
      imageLoaded: false
      //preLoaderType: 'game'
    }
  }

  componentDidMount() {
    this.props.setPreloader('game');

    let currentChildId = auth.getCurrentChild();

    if (currentChildId) {
      Promise.all([
        api.getChildren(),
        api.getChild(currentChildId),
        api.getStats(currentChildId),
        api.getLessons(currentChildId)
      ])
        .then(data => {
          this.setState({
            children: data[0],
            child: data[1],
            stats: data[2],
            lessons: data[3],
            time: {
              data: [
                {label: 'уроки', start: 0, end: 33, color: '115, 157, 56'},
                {label: 'тесты', start: 33, end: 66, color: '74, 231, 252'},
                {label: 'игры', start: 66, end: 100, color: '221, 159, 65'}
              ],
              total: 10
            },
            statsText: {
              result: 'Проявляет явную склонность к инженерии и программированию',
              recommendation: 'Рекомендуем больше внимания уделить гуманитарным наукам'
            },
            contentLoaded: true,
            imageLoaded: true
          }, () => {
            if (!(/profile$/.test(this.props.match.path) || this.props.match.path.indexOf(config.routes.add_new_child) > -1)) {
              setTimeout(() => {
                this.props.setPreloader('');
              }, 500);
            }
          })
        });
    } else {
      this.setState({
        contentLoaded: true,
        imageLoaded: true
      }, () => {
        if (!(/profile$/.test(this.props.match.path) || this.props.match.path.indexOf(config.routes.add_new_child) > -1)) {
          setTimeout(() => {
            this.props.setPreloader('');
          }, 500);
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let currentChildId = auth.getCurrentChild();

    if (this.state.contentLoaded === true && this.state.child !== null && this.state.child.id !== currentChildId) {
      this.props.setPreloader('quest__preloader');
      this.setState({
        contentLoaded: false
      }, () => {
        Promise.all([
          api.getChild(currentChildId),
          api.getStats(currentChildId),
          api.getLessons(currentChildId)
        ])
          .then(data => {
            this.setState({
              child: data[0],
              stats: data[1],
              lessons: data[2],
              contentLoaded: true
            }, () => {
              if (!(/profile$/.test(this.props.match.path) || this.props.match.path.indexOf(config.routes.add_new_child) > -1)) {
                setTimeout(() => {
                  this.props.setPreloader('');
                }, 500);
              }
            });
          });
      });
    }
  }

  render() {
    const subscription = auth.getProfile() && auth.getProfile().subscription;
    let childStatsFiltered = [];

    if (this.state.stats !== null && this.state.stats.categories) {
      childStatsFiltered = this.state.stats.categories.filter(object => object.successfully_completed_tests_count > 0);
    }

    return (
      <main className={`cabinet__main ${this.state.contentLoaded ? '' : 'cabinet__main_preloader'}`}>
        <CabinetChildrenSelector
          children={this.state.children}
          add={this.props.match.path.indexOf(config.routes.add_new_child) > -1}
        />
        <div className="child">
          {this.props.match.path.indexOf(config.routes.add_new_child) > -1 ?
            <AddChildContainer/>
            : /profile$/.test(this.props.match.path) ?
              <ChildProfile/>
              : <>
                {
                  (subscription && subscription.status !== 'off') &&
                  <div className="child__section child__section_banner">
                    <CabinetBanner/>
                  </div>
                }
                {
                  // TODO: Пока не будет апи показываем блок только если нет статистики
                  (childStatsFiltered.length === 0) &&
                  <section className="child__section child__section_stats">
                    <CabinetStats
                      child={this.state.child}
                      stats={this.state.stats}
                      statsText={this.state.statsText}
                    />
                  </section>
                }
                <section className="child__section">
                  <h2 className="child__title">Интересы</h2>
                  <div className="child__interests">
                    {/*<div className="child__col">*/}
                    {/*  <CabinetGraph*/}
                    {/*    child={this.state.child}*/}
                    {/*    time={null}*/}
                    {/*  />*/}
                    {/*</div>*/}
                    <div className="child__col">
                      <CabinetResults
                        child={this.state.child}
                        stats={this.state.stats}
                      />
                    </div>
                  </div>
                </section>
                <section className="child__section">
                  <h2 className="child__title">Пройдено уроков:</h2>
                  <CabinetProgress
                    child={this.state.child}
                    lessons={this.state.lessons}
                  />
                </section>
                <section className="child__section">
                  <h2 className="child__title">Последние уроки:</h2>
                  <CabinetLessons
                    child={this.state.child}
                    stats={this.state.stats}
                  />
                </section>
                <section className="child__section">
                  <h2 className="child__title child__title_quests">
                    Прогресс маршрутов:
                    <div className="child__quests-filter">
                      <CabinetQuestsFilter filterQuests={this.filterQuests}/>
                    </div>
                  </h2>
                  <CabinetQuests
                    child={this.state.child}
                    stats={this.state.stats}
                    filter={this.state.questsFilter}
                  />
                </section>
              </>
          }
        </div>

        {/*<PreLoader*/}
        {/*  image_loaded={this.state.imageLoaded}*/}
        {/*  pre_loader_type={this.state.preLoaderType}*/}
        {/*/>*/}
      </main>
    );
  }

  filterQuests = (event) => {
    this.setState({
      questsFilter: event.target.value
    });
  }
}

const mapStateToProps = (state) => {
  return {
    preloaderType: state.common.preloaderType
  }
};

const mapDispatchToProps = {
  setPreloader
};

const componentWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(ChildContainer))

export {componentWithRouter as ChildContainer}
