import React from 'react';
import PropTypes from 'prop-types';

export const TestRadio = (props) => {

  const {current, is_disabled, index, is_animation_playing, question: {options}} = props;

  let answers = options.map((option, index) => {
    return (
      <label className={'infant-garb__button test-radio ' + (index ? '__red' : '__blue')} key={index}>
        <input
          type='checkbox'
          className='test-radio__input'
          name='test-radio'
          value={option.value}
          onChange={props.onAnswerSelected}
          disabled={is_disabled}
        />
        <span className="test-radio__text">{index ? 'Нет' : 'Да'}</span>
      </label>
    )
  });

  return (
    <fieldset className={`test-radio-group test-radio-group_state_${current === index ? 'current' : 'hide'} test-radio-group_playing_animation_${is_animation_playing}`}>
      {answers}
    </fieldset>
  );
};

TestRadio.propTypes = {
  onAnswerSelected: PropTypes.func.isRequired
};
