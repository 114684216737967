import { ajaxV2 } from '../../helpers';

/**
 * @typedef {Array.<Object>} Lessons
 */

/**
 * @returns {Promise.<Lessons>}
 */
export const getLessons = (childId) => {

  // меняю урл в зависимости от токена
  let url = childId ? `/quests?child_id=${childId}` : '/quests';

  return ajaxV2.get(url)
    .then(response => response.data)
    .catch(error => console.error('getLessons error: ', error))
};
