import React from 'react';
import PropTypes from 'prop-types';

export const Error = (props) => {
  return (
    <div className={`error ${props.type ? 'error_type_' + props.type : ''}`}>
      { props.message }
    </div>
  );
}

Error.defaultProps = {};

Error.propTypes = {
  message: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  type: PropTypes.oneOf(['inline']),
};
