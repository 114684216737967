import React from 'react';
import PropTypes from 'prop-types';

import {
  CabinetQuestSection
} from '../../components';

export const CabinetQuests = ({ child, stats, filter }) => {
  let isNotQuests = true;
console.log(stats);
  return (
    <div className="cabinet-quests">
      {
        (child !== null && stats !== null) &&
        stats.quests.map((item, index) => {
          const finishedLessons = item.lessons.filter(lesson => (lesson.is_test_completed_successfully === true));
          const finishedLessonsCnt = finishedLessons.length;
          const lessonsCnt = item.lessons.length;

          if (
            (filter === 'finished' && finishedLessonsCnt === lessonsCnt) ||
            (filter === 'not_finished' && finishedLessonsCnt >= 1 && finishedLessonsCnt < lessonsCnt) ||
            (filter === 'not_started' && finishedLessonsCnt === 0) ||
            (filter === 'all' && lessonsCnt > 0)
          ) {

            isNotQuests = false;

            return (
              <div key={index} className="cabinet-quests__item">
                <CabinetQuestSection
                  quest={item}
                  finishedLessonsCnt={finishedLessonsCnt}
                  lessonsCnt={lessonsCnt}
                  child={child}
                />
              </div>
            );
          }

          return false;
        })
      }
      {
        isNotQuests &&
        <p className="cabinet-quests__text">Нет маршрутов</p>
      }
    </div>
  );
};

CabinetQuests.propTypes = {
  child: PropTypes.object,
  stats: PropTypes.object,
  filter: PropTypes.string
};
