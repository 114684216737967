import React, { Component } from 'react';
import Counter from './counter';

export default class CounterWrapper extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.state = { timer: props.timer };
  }

  onChange(timer) {
    this.setState({ timer });
    if ('function' === typeof this.props.onChange) {
      this.props.onChange(timer);
    }
  }

  render() {
    return (
      <div className="counter-wrapper">
        <Counter minutes={this.props.minutes} timer={this.props.timer} onChange={this.onChange} />
      </div>
    );
  }
}
