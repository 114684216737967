import React from 'react';
import PropTypes from 'prop-types';

export const AuthHeader = (props) => {
  return (
    <h2 className="auth-header">
      {props.text}
    </h2>
  );
};

AuthHeader.propTypes = {
  text: PropTypes.string
};
