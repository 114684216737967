import { ajax } from '../../helpers';

// @see https://chevostik.ru/apipie/1.0/authentications/sign_in.html

/**
 * @typedef {Object} UserAuthRequestData
 * @property {String} email
 * @property {String} password
 * @property {String} [password_confirmation]
 * */

/**
 * @typedef {Object} UserAuthResponseData
 * @property {Number} id
 * @property {String} email
 * @property {Boolean} is_confirmed
 * @property {Object} subscription
 * @property {Array<UserChild>} children
 */

/**
 * @typedef {Object} UserChild
 * @property {Number} id
 * @property {String} name
 */

/**
 * @typedef {Object} Response
 * @property {String} api_auth_token
 * @property {UserAuthResponseData} user
 *
 */

/**
 * @param {String} type – sign_up, sing_in
 * @param {UserAuthRequestData} user
 * @returns {Promise.<UserAuthResponseData>}
 */
export const postUserSession = (type, user) => {
  return ajax
    .post(`/auth/${type}`, user)
    .then(response => response.data)
};
