import React, {Component} from 'react';
import {emailSimpleRegExp} from '../../helpers';
import {connect} from 'react-redux';
import {closeAuthPopup, openAuthPopup} from '../../redux/actions';
import {i18n} from '../../data';
import * as auth from '../../helpers/authenticationHelper';
import * as api from '../../api';
import {
  UserPassword,
  AuthText,
  Error,
  UserEmail
} from '../../components';

class LoginContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      name: '',
      password: '',
      errorMessage: ''
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  openResetPopup = () => {
    this.props.closeAuthPopup('login');
    this.props.openAuthPopup('password-reset');
  }

  render() {
    return (
      <form className="sign-up" onSubmit={this.onSubmit}>
        <h2 className="sign-up__title">Войдите в аккаунт</h2>

        <AuthText text=""/>
        {this.state.errorMessage && <Error message={this.state.errorMessage}/>}
        <UserEmail value={this.state.email} onChange={this.onChange}/>

        <UserPassword value={this.state.password} onChange={this.onChange}/>

        <button
          type="submit"
          className="auth-form__submit btn btn_full btn_size_l login_enter"
          disabled={
            !emailSimpleRegExp.test(this.state.email) ||
            this.state.password.length < 6
          }
        >
          {i18n.enter}
        </button>

        {/*<div className="auth-container__social">
          <h4 className="auth-social-title"> Или войдите через соцсети</h4>
          <div className="auth-social-buttons">
            <AuthSocialButtonVk
              redirect_uri={config.social_auth.vk.login_redirect_uri}
            />
            <AuthSocialButtonFb
              redirect_uri={config.social_auth.fb.login_redirect_uri}
            />
          </div>
        </div>*/}

        <span className="reset-password-text"
              onClick={() => {
                //  to={`/auth/password-reset${window.location.search}`}
                this.openResetPopup();
              }}
        >Забыли пароль?</span>
      </form>
    );
  }

  onChange(e) {
    this.setState({
      errorMessage: '',
      [e.target.name]: e.target.value
    });
  }

  onSubmit(e) {
    this.setState({errorMessage: ''});
    e.preventDefault();
    api
      .postUserSession('sign_in', {
        email: this.state.email,
        password: this.state.password
      })
      .then(response => {
        this.props.closeAuthPopup('login');
        this.setState({errorMessage: ''});
        auth.handleAuthResponse(response);
        window.location.reload();
      })
      .catch(err => {
        if (err.response.status === 404) {
          this.setState({errorMessage: i18n.notValidData});
        }
      });

  }
}

export default connect(null, {
  closeAuthPopup,
  openAuthPopup
})(LoginContainer);
