import React from 'react';
import { Alert, alertPosition } from '../../../components';
import { Game, withBanner } from '../../banners';

const GameAlert = () => {
  return (
    <Alert position={alertPosition.center} open={true} fixed>
      <Game />
    </Alert>
  );
};

export const GameAlertBanner = withBanner(GameAlert);
