import React from 'react';
import PropTypes from 'prop-types';
import Button from '../button/button';

export const SupportBtn = ({ showPopUp }) => {
  return (
    <button
      type="button"
      className="cabinet-menu__link"
      data-section="feedback"
      onClick={showPopUp}
    >
      <Button mod="cabinet-menu__button" color="yellow" customTag="span">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.94518 30.3393H24.5551C25.7866 30.3393 26.5143 29.7795 26.5143 28.8419C26.5143 26.0011 22.9598 22.0828 17.2501 22.0828C11.5545 22.0828 8 26.0011 8 28.8419C8 29.7795 8.7277 30.3393 9.94518 30.3393ZM17.2501 20.1236C19.6012 20.1236 21.6723 18.0105 21.6723 15.2536C21.6723 12.5107 19.6012 10.5236 17.2501 10.5236C14.9131 10.5236 12.842 12.5667 12.842 15.2816C12.842 18.0105 14.9131 20.1236 17.2501 20.1236Z" fill="white"/>
          <path d="M28.0116 17.6326C28.6414 17.6326 29.0192 17.2408 29.0192 16.751C29.0192 16.723 29.0192 16.695 29.0192 16.667C29.0192 16.0793 29.3971 15.7014 30.1248 15.2116C31.1743 14.5399 32 13.8962 32 12.5107C32 10.5796 30.2787 9.59998 28.3755 9.59998C26.4443 9.59998 25.1708 10.4676 24.835 11.4332C24.765 11.6291 24.723 11.8251 24.723 12.021C24.723 12.5667 25.1708 12.8606 25.5767 12.8606C25.9965 12.8606 26.2624 12.6787 26.5003 12.3848L26.7382 12.1049C27.2 11.5172 27.7038 11.2793 28.3055 11.2793C29.2152 11.2793 29.8169 11.811 29.8169 12.6087C29.8169 13.3504 29.3411 13.6863 28.4035 14.33C27.6618 14.8618 27.0181 15.4495 27.0181 16.5691C27.0181 16.5831 27.0181 16.625 27.0181 16.639C27.0181 17.2968 27.3679 17.6326 28.0116 17.6326ZM27.9837 20.9912C28.6694 20.9912 29.2711 20.4455 29.2711 19.7597C29.2711 19.06 28.6834 18.5282 27.9837 18.5282C27.284 18.5282 26.6962 19.074 26.6962 19.7597C26.6962 20.4315 27.298 20.9912 27.9837 20.9912Z" fill="white"/>
        </svg>
      </Button>
      Поддержка
    </button>
  );
};

SupportBtn.propTypes = {
  showPopUp: PropTypes.func
};
