import React, {Component} from 'react';
import {withRouter} from 'react-router';
import * as auth from '../../helpers/authenticationHelper';
import {redirect} from '../../helpers';
import history from '../../history';
import Banner from '../subscription-banner/banner';
import '../subscription/subscription-banners.styl';
import {bannerType, SpringAlertBanner} from '../banners';

class PromoContainer extends Component {
  state = {
    banner: null,
    unListen: null,
    newUser: false,
    bigBannerShow: false
  };

  isLessons = (pathname) => {
    const split = pathname.split('/').filter(item => item !== '');
    return split.length === 1 && split[0] === 'lessons';
  }

  isLesson = (pathname) => {
    const split = pathname.split('/').filter(item => item !== '');
    return split.length > 1 && split[0] === 'lessons';
  }

  updateVisitCounter = (loc) => {
    const {pathname, search} = loc;

    if (search) {
      return null;
    }

    const subscription = auth.isSubscriptionActive();
    const day24 = 1000 * 60 * 60 * 24;
    const now = (new Date()).getTime();
    let visitCounter = localStorage.getItem('visit_counter');
    let banner = null;

    if (visitCounter) {
      visitCounter = JSON.parse(visitCounter);
    } else {
      visitCounter = {lastLessAWeekWarn: now - day24}
    }

    if (subscription?.status === 'on'
      && (pathname === '/'
        || this.isLessons(pathname)
        || this.isLesson(pathname))
    ) {
      if (!visitCounter.hasOwnProperty('lastLessAWeekWarn')) {
        visitCounter.lastLessAWeekWarn = now - day24 - 1;
      }

      if ((new Date(subscription.due_date).getTime() - now) <= day24 * 7
        && (now - (visitCounter.lastLessAWeekWarn || 0)) >= day24
      ) {
        visitCounter.lastLessAWeekWarn = now;

        banner = (
          <Banner
            section="less-a-week"
            title="Подписка заканчивается!"
            text="Продли подписку, чтобы оставаться в гуще событий!"
            bannerClass="__less_a_week"
            onAction={() => {
              this.setState({banner: null}, () => {
                redirect('/cabinet/select_tariff');
              });
            }}
            onClose={() => {
              this.setState({banner: null});
            }}
          />
        );
      }
    }

    localStorage.setItem('visit_counter', JSON.stringify(visitCounter));
    this.setState({banner});
  }

  loadPromo() {
    this.updateVisitCounter(window.location);

    this.setState({
      unListen: history.listen((loc) => {
        this.updateVisitCounter(loc);
      })
    });
  }

  getNewUserStatus() {
    const {search} = window.location;
    const {newUser} = this.state;
    const registerSuccess = (search === '?popup=successReg');

    if (registerSuccess && !newUser) {
      this.setState({newUser: registerSuccess});
    }
  }

  componentDidMount() {
    this.loadPromo();
    this.getNewUserStatus();
  }

  componentDidUpdate() {
    this.getNewUserStatus();
  }

  componentWillUnmount() {
    if (typeof this.state.unListen === 'function') {
      this.state.unListen();
    }
  }

  afterPhysicsBannerUpdatedShowed(showed) {
    if (showed > 1) {
      localStorage.removeItem('newUser');
      this.setState({newUser: false});
    }
  }

  afterBigBannerUpdatedShowed(showed, repeat) {
    if (showed !== 1 && showed % repeat === 0) {
      this.setState({bigBannerShow: true});
    } else {
      this.setState({bigBannerShow: false});
    }
  }

  render() {
    const {banner, newUser, bigBannerShow} = this.state;
    const {pathname, search} = window.location;
    const subscription = auth.isSubscriptionActive();

    return (
      <>
        {
          // Баннер об окончании подписки
          banner
          && <div className="subscription-banners">{banner}</div>
        }

        {
          // Большой баннер
          (!newUser && ['soon_finished', 'off'].includes(subscription?.status))
          && <SpringAlertBanner
            name="winter-big"
            expression={((pathname === '/' && !search) || this.isLessons(pathname) || this.isLesson(pathname))}
            repeat={5}
            type={bannerType.big}
            afterUpdatedShowed={(showed, repeat) => this.afterBigBannerUpdatedShowed(showed, repeat)}
          />
        }

        {
          // Маленький баннер если не показывается большой
          (!newUser && !bigBannerShow && ['soon_finished', 'off'].includes(subscription?.status))
          && <SpringAlertBanner
            name="winter-small"
            expression={((pathname === '/' && !search) || this.isLessons(pathname) || this.isLesson(pathname))}
            repeat={1}
            type={bannerType.big}
          />
        }
      </>
    );
  }
}

export default withRouter(PromoContainer)
