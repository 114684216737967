import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { checkImagesLoad } from '../../helpers';
import { Icon } from '../../components';
import { config } from '../../data';

export class DialogGames extends Component {

  constructor(props) {
    super(props);

    this.state = {
      images_state: 'not_loaded'
    };
  }

  componentDidMount() {
    let url = this.props.preview;

    checkImagesLoad(url).then(result => {
      this.setState({
        images_state: 'loaded'
      })
    });
  }

  render() {
    const {
      status,
      preview,
      url
    } = this.props;

    return (
      <div className={`dialog-games dialog-games_status_${status}`}>
        <div className={`dialog-games__image dialog-games__image_state_${this.state.images_state}`} style={{backgroundImage: `url(${preview})`}}></div>
        <div className="dialog-games__description">
          <div className="dialog-games__description-inner">
            <Icon name={`#games--${config[`games_${status}`].icon}`} width="72px" height="72px"/>
            <h2 className="dialog-games__title">{config[`games_${status}`].title}</h2>

            {config[`games_${status}`].text ? <p className="dialog-games__text">{config[`games_${status}`].text}</p> : null }

            <Link
              className="btn btn_size_l"
              to={url}
            >{config[`games_${status}`].btn}</Link>
          </div>
        </div>
      </div>
    );
  }
}

DialogGames.propTypes = {
  status: PropTypes.string,
  preview: PropTypes.string,
  url: PropTypes.string
};
