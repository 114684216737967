/**
 * @param el
 * @param selector
 * @returns {*}
 * 
 * Example: closest('.current-element', '.closest-selector-you-need')
 */
export function closest(el, selector) {
  const matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;
  
  while (el) {
    if (matchesSelector.call(el, selector)) {
      return el;
    } else {
      el = el.parentElement;
    }
  }
  return null;
}
