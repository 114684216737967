import { ajax } from '../../helpers';

/**
 * @param {string} password
 * @param {string} password_confirmation
 */
export const patchPassword = (password, password_confirmation) => {
  return ajax
    .patch('/users/update_password', {
      password: password,
      password_confirmation: password_confirmation
    })
    .then(response => response)
    .catch(error => console.log('patchPassword error: ', error));
};
