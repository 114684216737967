import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { bannerType } from '../const';
import style from './winter.module.sass';
import * as images from './images';

export const Winter = ({ type, handleButtonClick }) => {
  return (
    <div className={cn(style.winter, style[type])}>

      {
        (type === bannerType.big)
        && <picture className={style.image}>
          <source media="(max-width: 849px)" srcSet={`${images.cheBigMobile} 1x, ${images.cheBigMobileRetina} 2x`} />
          <img src={images.cheBig} srcSet={`${images.cheBigRetina} 2x`} alt="" />
        </picture>
      }

      {
        (type === bannerType.small)
        && <picture className={style.image}>
          <source media="(max-width: 849px)" srcSet={`${images.cheSmallMobile} 1x, ${images.cheSmallMobileRetina} 2x`} />
          <img src={images.cheSmall} srcSet={`${images.cheSmallRetina} 2x`} alt="" />
        </picture>
      }

      {
        (type === bannerType.routes)
        && <picture className={style.image}>
          <source media="(max-width: 849px)" srcSet={`${images.cheRoutesMobile} 1x, ${images.cheRoutesMobileRetina} 2x`} />
          <img src={images.cheRoutes} srcSet={`${images.cheRoutesRetina} 2x`} alt="" />
        </picture>
      }

      <div className={style.title}>
        Зимняя <br />
        <span className={style.titleOffset}>сказка</span>
      </div>
      <div className={style.sale}>Скидка 65%</div>
      <div className={style.descr}>
        Успей оформить<br />
        годовую подписку
      </div>
      <Link
        to="/cabinet/select_tariff/"
        className={style.button}
        onClick={handleButtonClick}
        type="button"
      >
        Подписаться
      </Link>
    </div>
  );
};

Winter.propTypes = {
  type: PropTypes.string.isRequired,
  handleButtonClick: PropTypes.func
};
