import React from 'react';
import PropTypes from 'prop-types';
import { config } from '../../data';
import { Link } from 'react-router-dom';

export const CabinetDemoMessage = ({ message }) => {
  return (
    <div className="cabinet-demo-message">
      <p>Ознакомительная подписка заканчивается через {message.match(/[\d]/g)} дня.</p>
      <p>
        Рекомендуем <Link
          to={`${config.routes.cabinet}/${config.routes.select_tariff}`}
          className="cabinet-demo-message__link"
          onlyActiveOnIndex={false}
        >
          продлить подписку
        </Link>, чтобы сохранить доступ ко всем приключениям.</p>
    </div>
  );
};

CabinetDemoMessage.propTypes = {
  message: PropTypes.string.isRequired
};
