/**
 * @param {number} день месяца
 * @returns {string} возвращает "месяц" в нужном склонении */
export const getMonthName = (number) => {
  let mounthName = 'месяц';
  switch (number) {
    case 3:
      mounthName = 'месяца'
      break;
    case 6:
      mounthName = 'месяцев'
      break;
    case 12:
      mounthName = 'месяцев'
      break;
    default:
      break;
  };

  return `${number} ${mounthName}`;
};
  