import React, {Component} from 'react';
import {withRouter} from 'react-router'
import queryString from 'query-string';
import Isvg from 'react-inlinesvg';
import * as api from '../api';
import * as auth from '../helpers/authenticationHelper';
import {redirect, removeDataLoadAtFirst} from '../helpers';
import PromoContainer from './promo-container/promo-container';
import {getStats} from "../api";

class App extends Component {
  constructor(props) {
    super(props);

    const {location: {search}} = props;
    const query = queryString.parse(search);

    this.state = {
      is_popup_closed: true,
      book: {
        type: '',
        preview: '',
        url: '',
        section: 'book'
      },
      backToMobile: query.backToMobile || null
    };
  }


  componentDidMount() {
    auth.handleSwitchFromSubdomain();

    if (document.location.pathname.includes('sberbank')) document.location.href = '/sb'

    let isUtm = window.location.search;
    let utmString = '';
    let utmcatcher = utm => {
      var reg = /utm_([^&]+)/gi;
      let utmArray = utm.match(reg);
      if (utmArray && utmArray.length > 0) {
        utmArray.map(item => {
          return (utmString += item + '&');
        });
        window.sessionStorage.setItem('utmString', utmString);
      }
    };
    utmcatcher(isUtm);

    let lessonsPage =
      this.props.location.pathname === '/' ||
      this.props.location.pathname === '/lessons';

    if (auth.isLoggedIn()) {
      api.postNewToken().then(async response => {
        let jwt = response.jwt;

        if (auth.isTokenExpired(jwt)) {
          return redirect('/?auth=login');
        }

        const profile = await api.getCurrentUserProfile();
        const cachedProfile = auth.getProfile();

        // TODO: обновлять быстрее на сервере привязку профиля соц сети
        if (cachedProfile?.vk_id) {
          profile.vk_id = cachedProfile.vk_id;
        }
        if (cachedProfile.fb_id) {
          profile.fb_id = cachedProfile.fb_id;
        }

        // Сохранять выбранного ребенка
        // TODO: внедрить Redux
        profile.currentChildAvatar = cachedProfile.currentChildAvatar;
        profile.currentChildId = cachedProfile.currentChildId;
        profile.currentChildName = cachedProfile.currentChildName;

        auth.setToken(jwt);
        auth.setProfile(profile);
        const date1 = new Date();
        const date2 = new Date(profile.subscription.due_date);
        const oneDay = 1000 * 60 * 60 * 24;
        const diffInTime = date2.getTime() - date1.getTime();
        const diffInDays = Math.round(diffInTime / oneDay);

        const metrikaCompleted =  window.localStorage.getItem('metrikacompleted');
        const metrikaIsCompleted =  window.localStorage.getItem('metrikaiscompleted');
        const lessonsMetrika =  window.localStorage.getItem('lessonsMetrika');
        const lessonsMetrikaCount =  window.localStorage.getItem('lessonsMetrikaCount');
        const lessonsPayMetrika =  window.localStorage.getItem('lessonsPayMetrika');
        const lessonsPayMetrikaCount =  window.localStorage.getItem('lessonsPayMetrikaCount');

if(profile.currentChildId){
  getStats(profile.currentChildId).then((e)=>{
    let finished = 0

    for(let i=0; i < e.lessons.length; i++){
      if(e.lessons[i].progress.is_finished === true){
        finished++;
      }
    }
    if(!lessonsMetrika){
      window.localStorage.setItem('lessonsMetrikaCount',finished);
      if(finished>2){
        window.localStorage.setItem('lessonsMetrika',true)
        window.ym(92335881,'reachGoal','activation')
      }
    }
    if(!lessonsPayMetrika && profile.subscription.status === 'on'){
      window.localStorage.setItem('lessonsMetrikaCount',finished-lessonsMetrikaCount);
      if(lessonsPayMetrikaCount>3){
        window.localStorage.setItem('lessonsPayMetrika',true)
        window.ym(92335881,'reachGoal','activuser');
      }
    }
  })
  if(diffInDays < 7 && !metrikaIsCompleted){
    window.localStorage.setItem('metrikaiscompleted', true);
    window.ym(92335881,'reachGoal','iscompleted')
  }
  if(diffInDays < 1 && !metrikaCompleted){
    window.localStorage.setItem('metrikacompleted', true);
    window.ym(92335881,'reachGoal','completed')
  }
  if(diffInDays > 7 ){
    window.localStorage.setItem('metrikaiscompleted', false);
    window.localStorage.setItem('metrikacompleted', false);
  }
}

        try {
          api.sendClientId(profile.id, window.ga.getAll()[0].get('clientId'))
        } catch (error) {
          // console.log(`Error with send user Id: ${error}`)
        }
      });
    } else {
      // данные загрузились когда пользователь первый раз зашёл в приложение
      lessonsPage && localStorage.setItem('dataAtFirstLoad', 'true');

      if (!(window.location.search.includes('reset_password_token=') || window.location.search.includes('auth='))) {
       // redirect('/?popup=firstEnter');
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {backToMobile} = this.state;
    // HACK: принудительно возвращаем пользователей на мобильную версию
    if (this.props.location.pathname !== prevProps.location.pathname && backToMobile) this.backToMobile()

    removeDataLoadAtFirst();
  }

  backToMobile = () => {
    const {backToMobile} = this.state;
    const {pathname} = this.props.location;
    if (pathname.includes('lessons/') || pathname.includes('games/')) return
    window.location.replace(backToMobile)
  }

  render() {
    if (auth.isLoggedIn() && !auth.getProfile()) {
      return null;
    }

    return (
      <div className="app">
        <Isvg src={process.env.PUBLIC_URL + '/img/_icons/social-links/sprite.svg'} uniquifyIDs={false}/>

        {this.props.children}

        <div className="app__icon"/>

        {auth.isLoggedIn() ? <PromoContainer/> : null}
      </div>
    );
  }
}

const componentWithRouter = withRouter(App)
export {componentWithRouter as App}
