import React, {Component} from 'react';
import {redirect} from '../../helpers'
import * as auth from '../../helpers/authenticationHelper';
import {ChildSelector, CloudAnimation} from '../../components';
import {setPreloader} from '../../redux/actions';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';

class ChildSelectorContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      children: [],
      cloudAnimate: false
    };

    this.selectChild = this.selectChild.bind(this);
  }

  componentDidMount() {
    this.props.setPreloader('');
    this.fetchProfile()
      .then(profile => {
        if (profile) {
          this.setState({
            children: profile.children
          })
        } else {
          this.logout();
        }
      });
  }

  render() {
    return (
      <div className={`child-selector-page ${this.state.cloudAnimate ? 'child-selector-page_move' : ''}`}>
        <CloudAnimation
          animate={this.state.cloudAnimate}
        />
        <button
          className="child-selector-page__btn link link_color_blue"
          type="button"
          onClick={this.logout}
        >
          Выйти
        </button>

        <ChildSelector
          children={this.state.children}
          onSelect={this.selectChild}
        />
      </div>
    );
  }

  selectChild(id, name, avatar) {
    let audio = new Audio('/audio/clouds.mp3');

    auth.setCurrentChild(id, name, avatar);
    audio.play();
    redirect('/lessons');
  }

  fetchProfile() {
    return Promise.resolve(auth.getProfile());
  }

  logout() {
    auth.logout();
  }
}

const mapDispatchToProps = {
  setPreloader
};

const componentWithRouter = withRouter(connect(null, mapDispatchToProps)(ChildSelectorContainer))

export {componentWithRouter as ChildSelectorContainer};
