import { ajaxV2 } from '../../helpers';

/**
 * @typedef {Array.<Object>} Payments
 */

/**
 * @returns {Promise.<Payments>}
 */
export const getProducts = (category = '') => {
  return ajaxV2
    .get('/products/' + (category ? '?category=' + category : ''))
    .then(response => response.data.sort((a,b) => a.price - b.price))
    .catch(error => console.log('getPayments error: ', error));
};
