import React, {Component} from 'react';

export class BlackFridaySale extends Component {
  render() {
    return (
      <div className="black-friday-sale">
        <h2 className="black-friday-sale__title">Черная пятница</h2>
        <div className="black-friday-sale__image-wrap">
          <img src='/img/promo/black-friday.png'
               srcSet='/img/promo/black-friday@2x.png 2x'
               alt=""
               className="black-friday-sale__image"/>
        </div>
        <div className="black-friday-sale__sale">Скидка 75%</div>
        <div className="black-friday-sale__description">Оформи только сегодня годовую подписку</div>
        <div className="black-friday-sale__actions">
          <button onClick={this.props.goToLink} type="button" className="subscribe-btn black-friday-sale__button">
            <span className="subscribe-btn__inner">Подписаться</span>
          </button>
        </div>
      </div>
    )
  }
}
