import React, { Component } from 'react';

export default class GameRepeatBtn extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="repeat-level">
        <div className="repeat-level__level-icon">
          ВРЕМЯ ВЫШЛО
        </div>
        <div className="repeat-level__btn orange" onClick={() => this.props.showNextFrame(this.props.gameName, null, true)}>
          <div className="repeat-level__game-repeat-icon">Начать сначала</div>
        </div>
        <div className="repeat-level__btn orange" onClick={() => {}}>
          <div className="repeat-level__game-over-icon">Завершить игру</div>
        </div>
        <div className="repeat-level__btn green" onClick={() => {}}>
          <div className="repeat-level__game-continue-icon">Продолжить 99</div>
        </div>
      </div>
    );
  }
}
