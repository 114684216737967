import React from 'react';
import PropTypes from 'prop-types';

export const CabinetStats = ({ child, stats, statsText }) => {
  let childStatsFiltered = [];

  if (stats !== null && stats.categories) {
    childStatsFiltered = stats.categories.filter(object => object.successfully_completed_tests_count > 0);
  }

  let text = (
    <div className="cabinet-stats__text">
      <p>Здесь будет отображаться статистика на основе интересов вашего ребенка</p>
    </div>
  );

  if (child !== null && statsText !== null && childStatsFiltered.length > 0) {
    text = (
      <div className="cabinet-stats__text">
        <p>{statsText.result}</p>
        <p>{statsText.recommendation}</p>
      </div>
    );
  }

  return (
    <div className="cabinet-stats">
      {text}
    </div>
  );
}

CabinetStats.propTypes = {
  child: PropTypes.object,
  statsText: PropTypes.object
};
