import React from 'react';
import PropTypes from 'prop-types';
import { closest, formatDayAndMonth } from '../../helpers';

const MAX_NUMBER_OF_VISIBLE_ROW = 4;

export const BillingsTable = (props) => {
  const {payments} = props;
  let isBtnShow = false;
  let filteredPayment = [];

  payments.forEach(item => {
    if (item.status !== 'processing') {
      filteredPayment.push(item)
    }
  });

  window.devMode && console.log('filteredPayment', filteredPayment);

  let row = filteredPayment.filter(r => r.hasOwnProperty('tariff')).reverse().map((item, index) => {
    index >=MAX_NUMBER_OF_VISIBLE_ROW ? isBtnShow = true : isBtnShow = false;

    let fullDate = new Date(item.created_at*1000);
    let date = `${fullDate.getDate()}.${formatDayAndMonth(fullDate.getMonth()+1)}.${fullDate.getFullYear()}`;

    let isHide = item.amount === item.original_amount ? 'hide' : 'show';

    return (
      <tr className={`billings-table__row billings-table__row_state_${index >= MAX_NUMBER_OF_VISIBLE_ROW ? 'hide' : 'show'}`} key={index}>
        <td>
          {date}
        </td>
        <td>
          <span className={`discount discount_${isHide}`}>
            {item.original_amount}
            <span className="ruble">Р</span>
          </span>
          <span className="nowrap">
            {item.amount}
            <span className="ruble">Р</span>
          </span>
        </td>
        <td>
          {item.tariff.name}
        </td>
        <td>
          {item.type_description ? item.type_description : ''}
        </td>
        <td>{item.is_recurrent ? 'Автопродление' : ''}</td>
      </tr>
    )
  });

  return (
    <div className="billings-table">
      <table className="billings-table__inner">
        <thead>
        <tr>
          <td>
            Дата оплаты
          </td>
          <td>
            Сумма
          </td>
          <td>
            Тариф
          </td>
        </tr>
        </thead>
        <tbody>
        {row}
        </tbody>
      </table>

      {
        isBtnShow ? <button
          onClick={(event) => showAllPayments(event)}
          className="billings-table__btn btn"
        >
          Показать все
        </button> : null
      }

    </div>
  );
};

function showAllPayments(event) {
  closest(event.currentTarget, '.billings-table').classList.add('billings-table_state_show_all')
}

BillingsTable.propTypes = {
  payments: PropTypes.array
};
