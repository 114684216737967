import React, { Component } from 'react';
import PropTypes from 'prop-types';
import enhanceWithClickOutside from 'react-click-outside';
import { PersonFact } from '../../components';

class PersonsFacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timerId: null,
    };
  }

  setClearFacts = () => {
    clearTimeout(this.state.timerId);
    const timerId = setTimeout(() => {
      this.setState({
        timerId: null,
      });
    }, 20000);
    this.setState({timerId});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedPerson !== this.props.selectedPerson || !this.state.timerId) this.setClearFacts();
  }

  componentWillUnmount() {
    clearTimeout(this.state.timerId);
  }

  //закрыть факт при нажатии извне
  handleClickOutside() {
    this.props.stopShowFact()
  }

  render() {
    const {
      facts,
      selectedPerson,
      factPositionX,
      factPositionY
    } = this.props;
    const {timerId} = this.state

    const personFacts = facts.filter(person => person.dom_id === selectedPerson)[0];
    if (!personFacts || !timerId) return null;
    return (
      <div className="persons__facts">
        <PersonFact
          person={personFacts}
          factPositionX={factPositionX}
          factPositionY={factPositionY}
        />
      </div>
    )
  }
}

PersonFact.propTypes = {
  facts: PropTypes.array,
  factPositionX: PropTypes.string,
  factPositionY: PropTypes.string,
  selectedPerson: PropTypes.string,
};

const _PersonsFacts = enhanceWithClickOutside(PersonsFacts)

export {_PersonsFacts as PersonsFacts}
