import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import * as auth from '../../helpers/authenticationHelper';
import history from '../../history';

import {
  Infant,
  CoinShop,
  ScoreTable
} from '../../components';

import {
  PopupSuccessReg
} from '../../containers';
import PopupWelcome from '../popup-welcome/popup-welcome';
import Button from '../../components/button/button';

let popupNames = '';


const useGetParameter = () => {
  const {search} = useLocation();
  const query = new URLSearchParams(search);
  return query.get('popup');
};

export const togglePopup = (popup, show) => {
  let searchParam = new URLSearchParams(window.location.search.slice(1));
  let allPopups = searchParam.has('popup') ? searchParam.get('popup').split(',') : [];
  let startLen = allPopups.length;
  let popupIndex = allPopups.indexOf(popup);

  if (show) {
    if (popupIndex === -1) {
      allPopups.push(popup);
    }
  } else {
    if (popupIndex > -1) {
      allPopups.splice(popupIndex, 1);
    }
  }

  if (allPopups.length) {
    searchParam[searchParam.has('popup') ? 'set' : 'append']('popup', allPopups.join(','))
  } else {
    searchParam.delete('popup')
  }

  if (allPopups.length !== startLen) {
    history.push(window.location.pathname + (searchParam ? '?' + searchParam.toString() : ''));
  }

  localStorage.setItem('popup', allPopups.join(','));

  return null;
};

export const PopupManager = () => {
  const popupsList = {
    'scoreTable': ScoreTable,
    'infantCabinet': Infant,
    'firstEnter': PopupWelcome,
    'successReg': PopupSuccessReg,
    'coinShop': CoinShop
  };

  useEffect(() => {
    window.openCoinShop = () => {
      togglePopup('coinShop', true)
    }
  })


  popupNames = useGetParameter();

  if (popupNames) {
    const user = auth.getProfile();
    let popupCounter = 0;
    let popups = popupNames.split(',');

    let popupComponents = popups.map((p, pi) => {
      const Component = popupsList[p];

      if (!Component) {
        return null;
      }

      let showCloseBtn = true; // popupWithCloseBtn.indexOf(p) > -1;
      popupCounter++;

      return (
        <div key={pi} className="overlay" data-popup={p} onClick={(e) => {
          //if (p === 'successReg') {
          //  let block = e.target;
          //  if (e.target.className !== 'overlay') {
          //    block = e.target.closest('.overlay');
          //  }
          //
          //  block.classList.add('animation__end');
          //
          //  setTimeout(() => {
          //    togglePopup(p, false);
          //  }, 1500);
          //}

          if (!showCloseBtn && e.target.className === 'overlay') {
            e.stopPropagation();
            togglePopup(p, false);
          }
        }}>
          <div className={'popup-wrapper'}>
            <Component noClickOutside={showCloseBtn} user={user} togglePopup={() => togglePopup(p, false)}/>
          </div>
          {
            showCloseBtn
              ? (
                <Button
                  mod="map-nav__home"
                  onClick={() => {
                    togglePopup(p, false)
                  }}
                  color="orange">
                  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M30.0008 17.4026L20.4358 10.5359C20.1611 10.3547 19.8315 10.3547 19.5751 10.5359L10.0101 17.4026C9.27757 17.8859 9.58889 19.1142 10.4496 19.1142C10.9074 19.1142 11.292 19.517 11.292 20.0405V27.8737C11.292 28.3772 11.6582 28.8 12.1344 28.8H16.2479C16.6691 28.8 17.0171 28.4174 17.0171 27.9543V23.3832C17.0171 22.4569 17.6946 21.7119 18.537 21.7119H21.5105C22.3529 21.7119 23.0304 22.4569 23.0304 23.3832V27.914C23.0304 28.3772 23.3784 28.7597 23.7996 28.7597H27.9131C28.3709 28.7597 28.7555 28.357 28.7555 27.8335V20.0204C28.7555 19.517 29.1218 19.0941 29.5979 19.0941C30.4037 19.0941 30.715 17.8657 30.0008 17.4026Z"
                      fill="white"/>
                  </svg>
                </Button>
              ) : null
          }
        </div>
      )
    });

    return (
      popupCounter ? <div
        className={'popup-manager' + (popups.length ? ' has-popup' : '') + (' active-popup_' + popups[popups.length - 1])}>{popupComponents}</div> : null
    )
  } else {
    return null;
  }
}
