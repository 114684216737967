import React from 'react';
import * as auth from '../../helpers/authenticationHelper';
import Button from '../button/button';

export const LogoutBtn = () => {
  return (
    <button
      className="cabinet-menu__link"
      type="button"
      onClick={auth.logout}>
      <Button mod="cabinet-menu__button" color="white" customTag="span">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.2946 30.9972C19.9587 32.3659 21.9643 31.5257 21.2461 30.0486L19.0371 25.5901C18.8745 25.2513 18.6441 24.8854 18.4409 24.6008L17.0451 22.6223L17.1399 22.3377C17.5194 21.2265 17.6549 20.5489 17.7362 19.4512L17.953 16.3343C18.0614 14.8572 17.1941 13.7188 15.6628 13.7188C14.5109 13.7188 13.752 14.3016 12.695 15.318L11.0552 16.9441C10.4996 17.4726 10.337 17.9199 10.2828 18.611L10.0795 21.1587C10.0253 21.8092 10.3912 22.2564 11.001 22.2835C11.5973 22.3242 11.9767 21.9718 12.0309 21.2807L12.2749 18.489L13.0609 17.7844C13.3454 17.5269 13.6978 17.703 13.6842 17.9741L13.5081 20.3321C13.4132 21.5382 13.6978 22.1073 14.5109 23.1373L16.7063 25.8747C16.9231 26.1457 16.9502 26.2542 17.0451 26.4303L19.2946 30.9972ZM16.8689 12.7567C18.1834 12.7567 19.2269 11.6997 19.2269 10.3987C19.2269 9.09774 18.1834 8.04071 16.8689 8.04071C15.5679 8.04071 14.5109 9.09774 14.5109 10.3987C14.5109 11.6997 15.5679 12.7567 16.8689 12.7567ZM11.4211 31.3225L14.66 27.4738C14.9852 27.0943 15.0258 26.9995 15.1614 26.6065L15.3782 25.9289L13.63 23.72L13.1151 26.1186L9.91687 29.9131C8.9005 31.1327 10.5809 32.3388 11.4211 31.3225ZM23.0485 17.9199H20.5685L19.0371 16.2259L18.8474 18.8549L19.4302 19.4241C19.7825 19.7764 20.0806 19.8848 20.7311 19.8848H23.0485C23.7125 19.8848 24.1461 19.5054 24.1461 18.9091C24.1461 18.3129 23.6989 17.9199 23.0485 17.9199Z" fill="black"/>
          <path d="M28.8644 31.0243C28.8644 31.5663 29.3116 32 29.8537 32C30.3822 32 30.8294 31.5663 30.8294 31.0243V8.98926C30.8294 8.4472 30.3822 8 29.8537 8C29.3116 8 28.8644 8.4472 28.8644 8.98926V31.0243Z" fill="black"/>
        </svg>
      </Button>
      Выйти
    </button>
  );
};
