import {FastAverageColor} from 'fast-average-color';

/**
 * Средний цвет изображения
 * @param {String} imgUrl URL изображения
 * @returns {Promise<string>}
 */
export async function getImageAverageColor(imgUrl) {
  const fac = new FastAverageColor();
  try {
    const color = await fac.getColorAsync(imgUrl);
    return color.hex;
  } catch(e) {
    console.log(e);
  }
}
