import { ajax } from '../../helpers';
import React from 'react';
import { render } from 'react-dom';

import {
  ErrorPage
} from '../../containers';

/**
 * @typedef {Array.<Object>} PaymentsUrl
 */

/**
 * @returns {Promise.<PaymentsUrl>}
 */
export const postPayments = (tariff) => {

  return ajax
    .post('/payments', tariff)
    .then(response => response.data)
    .catch(err => {
      if (err.response.status === 404) {
        render(
          <ErrorPage status={404} />,
          document.getElementById('root')
        );
      }
    })
};
