import React from 'react';
import PropTypes from 'prop-types';
import Isvg from 'react-inlinesvg'

import {
  Icon
} from '../../components';

import { config } from '../../data';

export const SocialLinks = (props) => {

  const {isInstagramIconGradient} = props

  return(
    <nav className="social-links">
      <Isvg
        src={process.env.PUBLIC_URL + '/img/_icons/social-links/sprite.svg'}
        uniquifyIDs={false}
      >
      </Isvg>

      <a href={config.social_links.vk} target="_blank" rel="noopener noreferrer" className="social-links__item social-links__item_type_vk">
        <Icon name='#social-links--vk_icon' width='22px' height='20px'/>
      </a>
      <a href={config.social_links.fb} target="_blank" rel="noopener noreferrer" className="social-links__item social-links__item_type_fb">
        <Icon name='#social-links--fb_icon' width='12px' height='20px'/>
      </a>

      {isInstagramIconGradient ?
        <a href={config.social_links.instagram} target="_blank" rel="noopener noreferrer" className="social-links__item social-links__item_type_insta">
          <Icon name='#social-links--instagram_icon_gradient' width='18px' height='20px'/>
        </a>
        :
        <a href={config.social_links.instagram} target="_blank" rel="noopener noreferrer" className="social-links__item social-links__item_type_insta">
          <Icon name='#social-links--instagram_icon' width='18px' height='20px'/>
        </a>
      }

    </nav>
  );
};

SocialLinks.propTypes = {
  isInstagramIconGradient: PropTypes.bool
};
