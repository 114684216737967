import { ajaxV2 } from '../../helpers/index';

/**
 * @param {String} code
 *  * @param {String} redirect_uri
 * @returns {Promise}
 */
export const getRatings = () => {
  return ajaxV2
    .get('/ratings')
    .then(response => response.data.map(e => ({
      avatar: e.avatar,
      name: e.name,
      level: e.level,
      points: e.points,
    })))
    .catch(error => error);
};
