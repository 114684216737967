import React from 'react';
import PropTypes from 'prop-types';
import { config } from '../../data';

export const CabinetQuestsFilter = ({ filterQuests }) => {
  return (
    <div className="cabinet-quests-filter">
      {
        config.cabinet.routes_filter.map((item, index) => {
          return (
            <label key={index} className="cabinet-quests-filter__item">
              <input
                type="radio"
                name="quests-filter"
                className="cabinet-quests-filter__input"
                value={item.tag}
                defaultChecked={index === 0}
                onChange={filterQuests}
              />
              <span className="cabinet-quests-filter__title">
                {item.name}
              </span>
            </label>
          )
        })
      }
    </div>
  );
};

CabinetQuestsFilter.propTypes = {
  filterQuests: PropTypes.func
};
