import React, {Component} from 'react';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'

import {Drop, Drag, DragPreview} from '../dnd';
import {isTablet} from '../../helpers';
import {config} from '../../data';
import CounterWrapper from '../counter/counter-wrapper';

const tabletOffsetY = isTablet() ? 117 : 0;
const tabletSizeRatio = isTablet() ? 0.8 : 1;
const postLoaderTimeOut = 800;

const offsetXFigure = 184;
const offsetYTopFigure = 80;
const offsetYBottomFigure = 80;
const offsetYTopFigureTablet = 100;
const offsetXFigureTablet = 80;

const offsetXTablet = isTablet() ? 194 : 1;
const offsetYBG = 50;

const BASE_CANVAS = {
  width: 1920,
  height: 1080,
};

const getScale = () => {
  const scaleX = window.innerWidth / BASE_CANVAS.width;
  const scaleY = window.innerHeight / BASE_CANVAS.height;

  return Math.min(scaleX, scaleY);
};

export class PickUpSilhouette extends Component {
  constructor(props) {
    super(props);

    this.state = {
      figures: null,
      figures_outline: null,
      background: null,
      counter: 0,
      timer: null,
      width: window.innerWidth,
      height: window.innerHeight,
      draggingFigureName: null,
      scaleBothValue: getScale(),
    };

    this.onFigureDragEnd = this.onFigureDragEnd.bind(this);
    this.onDragging = this.onDragging.bind(this);
    this.resizeDisplay = this.resizeDisplay.bind(this);
  }

  resizeDisplay() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      scaleBothValue: getScale(),
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeDisplay);

    // Костыль для исправление размера трех игр
    const idGame = +(document.location.pathname.split('/games/')[1]);
    const resizeCount = ([59,77].includes(idGame) && !isTablet())
      ? 0.8
      : 1;

    let figures = this.props.figures;
    let figuresOutline = this.props.figuresOutline;
    let background = this.props.background;

    let loadedFigures = [];
    let loadedFiguresOutline = [];
    let loadedBackground = {};

    /**
     * Фоновое изображение
     */
    if (background) {
      let backgroundWidth = background.width * tabletSizeRatio * resizeCount;
      let backgroundHeight = background.height * tabletSizeRatio * resizeCount;

      let backgroundX = config.base_game_container.width / 2 - backgroundWidth/2;
      let backgroundY = config.base_game_container.height / 2 - backgroundHeight/2 - offsetYBG;

      let backgroundXTablet = config.base_game_container.width / 2 - backgroundWidth/2 - offsetXTablet;
      let backgroundYTablet = config.base_game_container.height / 2 - backgroundHeight/2 - tabletOffsetY;

      loadedBackground = {
        link: background.link,
        width: backgroundWidth,
        height: backgroundHeight,
        x: isTablet() ? backgroundXTablet : backgroundX,
        y: isTablet() ? backgroundYTablet : backgroundY,
      };
    }

    /**
     * Фигуры
     */
    const maxFigureAtColumn = Math.ceil(figures.length/2);
    const maxHeightForFigure = Math.ceil(config.base_game_container.height / maxFigureAtColumn);
    const maxWidthForFigure = Math.ceil(config.base_game_container.width / figures.length);

    figures.forEach((item, index) => {

      let x = index < maxFigureAtColumn ? offsetXFigure : config.base_game_container.width-offsetXFigure-item.width;
      let y = index < maxFigureAtColumn ? index * maxHeightForFigure : (index - maxFigureAtColumn) * maxHeightForFigure;

      let fWidth = item.width * tabletSizeRatio * resizeCount;
      let fHeight = item.height * tabletSizeRatio * resizeCount;

      if (index === 0 || index === maxFigureAtColumn) {
        y = offsetYTopFigure
      }

      if (index === maxFigureAtColumn-1 || index === figures.length-1) {
        y = index < maxFigureAtColumn ? index * maxHeightForFigure - offsetYBottomFigure : (index - maxFigureAtColumn) * maxHeightForFigure - offsetYBottomFigure
      }

      if (isTablet()){
        x = maxWidthForFigure * index;
        y = config.base_game_container.height + offsetYTopFigureTablet - fHeight;

        if (index === 0) {
          x = offsetXFigureTablet
        }

        if (index === (figures.length-1)) {
          const offsetXTablet = isTablet() ? fWidth/2 + 80 : 0
          x = config.base_game_container.width-offsetXFigureTablet - fWidth - offsetXTablet
        }
      }

      // Костыль для игры 77
      if (idGame === 77 && index === 1 && resizeCount < 1) y += 100;
      if (idGame === 77 && index === 4 && resizeCount < 1) x += 50;

      loadedFigures.push({
        link: item.link,
        width: fWidth,
        height: fHeight,
        x: x * tabletSizeRatio,
        y: y * tabletSizeRatio,
        figure_name: index,
        isDraggable: true
      });
    });

    /**
     * Фигуры с контуром
     */
    figuresOutline.forEach((item, index) => {
      let fWidth = item.width * tabletSizeRatio * resizeCount;
      let fHeight = item.height * tabletSizeRatio * resizeCount;

      // Костыли для 77 и 59 игры
      const resizeCountoffsetX = resizeCount < 1 ? 190 : 0;
      const resizeCountoffsetY = resizeCount < 1
        ? (idGame === 77 ? 100 : 52)
        : 0;

      const offsetY = isTablet() ? 30 : 1;

      loadedFiguresOutline.push({
        link: item.link,
        width: fWidth,
        height: fHeight,
        x: item.options.x * tabletSizeRatio * resizeCount + resizeCountoffsetX,
        y: item.options.y * tabletSizeRatio * resizeCount + resizeCountoffsetY + offsetY,
        figure_name: index,
      });
    });

    this.setState({
      background: loadedBackground,
      figures: loadedFigures,
      figures_outline: loadedFiguresOutline,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeDisplay)
  }

  showPostLoader(instance, gameName) {
    setTimeout(() => {
      instance.props.showPostLoader(gameName)
    }, postLoaderTimeOut);
  }

  onDragging(fragmentName) {
    let draggingFigureName = null;
    this.state.figures.map(item => {
      if (fragmentName === item.figure_name) {
        draggingFigureName = item.figure_name;
      }
    });
    if (this.state.draggingFigureName !== draggingFigureName) {
      this.setState({
        draggingFigureName
      });
    }
  }

  onFigureDragEnd() {
    this.setState({
      counter: this.state.counter + 1
    }, () => {
      if (this.state.counter === this.state.figures.length) {
        this.showPostLoader(this, 'pick-up-silhouette');
      }
    });
  }

  onChangeTimer(instance, timer, gameName) {
    if (0 === timer.days && 0 === timer.hours && 0 === timer.minutes && 0 === timer.seconds) {
      instance.showPostLoader(instance, gameName);
    }
  }

  render() {
    let background = null;
    let figures = null;
    let figuresOutline = null;

    let cssWrap = {
      backgroundImage: `url(${config.base_url}${this.props.image})`
    };
    const { width, height, scaleBothValue } = this.state;
    const options = { enableMouseEvents: true };


    if (this.state.background && this.state.background.link) {
      background = <div style={{
        position: 'absolute',
        backgroundImage: `url(${config.base_url}${this.state.background.link})`,
        backgroundSize: '100% 100%',
        width: this.state.background.width * scaleBothValue,
        height: this.state.background.height * scaleBothValue,
        left: this.state.background.x * scaleBothValue,
        top: this.state.background.y * scaleBothValue,
      }} />;
    }

    if (this.state.figures) {
      figures = this.state.figures.map((item, index) =>
        <Drag key={index}
              name={'figure-' + item.figure_name}
              src={item.link}
              height={item.height * scaleBothValue}
              width={item.width * scaleBothValue}
              left={item.x * scaleBothValue}
              top={item.y * scaleBothValue}
              draggable={item.isDraggable}
              scaleBothValue={scaleBothValue}
              onDragEnd={this.onFigureDragEnd.bind(this, item.figure_name)}
              onDragging={this.onDragging.bind(this, item.figure_name)} />
      );
    }
    if (this.state.figures_outline) {
      figuresOutline = this.state.figures_outline.map((item, index) =>
        <Drop key={index}
              name={'figure-' + item.figure_name}
              src={item.link}
              height={item.height * scaleBothValue}
              width={item.width * scaleBothValue}
              left={item.x * scaleBothValue}
              top={item.y * scaleBothValue}
              zIndex={this.state.draggingFigureName === item.figure_name ? 9930 : 9900}  />
      );
    }

    return (
      <>
        <div className="pick-up-silhouette game-page__wrap" style={cssWrap}>
          <h1 className='game-page__name'>
            <CounterWrapper minutes={this.props.counterDown} onChange={(timer) => this.onChangeTimer(this, timer, 'pick-up-silhouette')} />
          </h1>

          <div className="canvas-layer"
               style={{ position: 'relative', zIndex: 1, maxWidth: '100%', overflow: 'hidden', width: width + 'px', height: height + 'px' }}>

            <div className="canvas" style={{width: (BASE_CANVAS.width * scaleBothValue) + 'px', height: (BASE_CANVAS.height * scaleBothValue) + 'px'}}>

              <DndProvider backend={ isTablet() ? TouchBackend : HTML5Backend } options={isTablet() ? options : {}} >
                {background}
                {figuresOutline}
                {figures}
                { isTablet() ? <DragPreview />  : ''}
              </DndProvider>

            </div>
          </div>

        </div>
      </>
    );
  }
}
