import React from 'react';
import PropTypes from 'prop-types';

export const AuthButton = (props) => {
  return (
    <button
      className="auth-form__submit btn btn_full btn_size_l reset-password_button"
      onClick={props.onClick}
      disabled={props.isDisabled}
    >
      {props.text}
    </button>
  );
};

AuthButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool
};
