import { ajaxV2 } from '../../helpers';
import * as auth from '../../helpers/authenticationHelper';

export const setPersonStatus = (idPerson, status) => {
  return ajaxV2
    .post(`/persons/${idPerson}/save_progress`, {
      status,
      child_id: auth.getCurrentChild()
    })
    .catch(err => {
      window.devMode && console.log('setPersonStatus error: ', err)
    })
};
