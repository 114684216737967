import React, {Component} from 'react';
import {setPreloader} from '../../redux/actions';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
  checkImagesLoad,
  isPhone
} from '../../helpers';

import {Link} from 'react-router-dom';

class ErrorPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pre_loader_type: 'game',
      images_loaded: false,
      not_found_status: 404,
      server_error_status: 500
    };
  }

  componentDidMount() {
    this.props.setPreloader('game');

    let images = [
      '/img/error-section/error_animation_fon.svg',
      '/img/error-section/error_animation_sprite.svg'
    ];

    checkImagesLoad(images)
      .then((images) => {
        this.setState({
          background: {src: images[0].src},
          person: {src: images[1].src},
          image_loaded: true
        }, () => {
          this.props.setPreloader('');
        })
      });
  }

  render() {
    let images = null;
    let title = null;
    let text = null;
    let isRouteStatus = this.props.route && this.props.route.status;

    if (isRouteStatus || this.props.status === this.state.not_found_status) {
      title = '404!';
      text = 'Упс, такой страницы не\u00A0существует...';
    }

    if (this.props.status === this.state.server_error_status) {
      title = '500!';
      text = 'Упс, что-то пошло не так...';
    }

    if (this.state.background && this.state.person) {
      images = <div
        className="error-page__img"
        style={{backgroundImage: `url(${this.state.background.src})`}}
      >
        <div
          className="error-page__img-person"
          style={{backgroundImage: `url(${this.state.person.src})`}}
        />
      </div>
    }

    return (
      <div
        className={`error-page error-page_state_${isPhone() ? 'phone' : 'not_phone'}`}
      >
        {/*<PreLoader*/}
        {/*  image_loaded={this.state.image_loaded}*/}
        {/*  pre_loader_type={this.state.pre_loader_type}*/}
        {/*/>*/}
        <div className="error-page__wrapper">
          {images}
          <div className="error-page__overview">
            <h1 className="error-page__title">{title}</h1>
            <p className="error-page__text">{text}</p>
            <p className="error-page__text-additional">
              Начните с
              <Link to="/" className="link"> главной страницы</Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

ErrorPage.propTypes = {
  status: PropTypes.number
};

const mapDispatchToProps = {
  setPreloader
};

const componentWithRouter = withRouter(connect(null, mapDispatchToProps)(ErrorPage))

export {componentWithRouter as ErrorPage};
