import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {config} from '../../data';
import {getImageAverageColor} from '../../helpers';

export class Thing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageURL: '../img_static/small-loader.svg',
      loaded: false,
      img: new Image(),
      backgroundColor: ''
    };
  }

  static propTypes = {
    addThing: PropTypes.func
  };

  imgLoader(url) {
    this.setState({imageURL: url, loaded: true})
  }

  setBackgroundColor(imgUrl) {
    getImageAverageColor(imgUrl)
      .then(color => {
        this.setState({ backgroundColor: color });
      });
  }

  componentDidMount() {
    const {item} = this.props;
    const {img} = this.state;
    const imgUrl = `${config.base_url}${item.image}`;
    this.setBackgroundColor(imgUrl);

    img.addEventListener('load', this.imgLoader.bind(this, imgUrl));
    img.src = imgUrl;
  }

  componentWillUnmount() {
    const {img} = this.state;
    img.removeEventListener('load', this.imgLoader);
  }

  render() {
    const {item} = this.props;

    let thingUnitStyle = 'thing-unit';

    if (item.newly) {
      thingUnitStyle += ' thing-unit_type_newly';
    }

    return (
      <div
        onClick={() => this.props.addThing(item.id)}
        className={thingUnitStyle}
        style={{backgroundColor: this.state.backgroundColor}}>
        {item.count ? <div className="thing-unit__btn thing-unit__btn_type_count">{item.count}</div> : null}

        {item.count ?
          null
          :
          <div className="thing-unit__hover">
            <div className="infant-popup__price">х {item.price}</div>
            <div className="infant-garb__button">
              <span>Купить</span></div>
          </div>
        }

        <div className="thing-unit__btn thing-unit__btn_type_add">+</div>

        <img className="thing-unit__image" src={this.state.imageURL} alt=""/>
        <div className="thing-unit__name">{item.title}</div>
        <div className="thing-unit__info">{item.description}</div>
      </div>
    )
  }
}
