import React, {Component} from 'react';
import * as auth from '../../helpers/authenticationHelper';
import {counterEffect} from '../../helpers';
import * as api from '../../api';
import {InfantPopup} from '../../components';
import {togglePopup} from '../popup-manager';

class ExternalShop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      children: [],
      popupOpened: false,
      alert: false,
      popup: null
    };
  }

  buyWithCoins = (item, cb) => {
    const user = auth.getProfile();
    let childId = auth.getCurrentChild();
    let child = user && user.hasOwnProperty('children') ? user.children.find(f => f.id === childId) : {};

    window.devMode && console.log('buyWithCoins', item, user, child);

    if (child.balance < (item.price * item.count)) {
      this.setState({
        alert: 'Недостаточно монет <br /> для совершения покупки!'
      });
      this.setState({popupOpened: true});
    } else {
      api.paymentsV2({product_id: item.id, child_id: childId, quantity: item.count})
        .then(data => {

          if (data) {
            let coins = document.querySelector('.map-nav .map-nav__shop-count');
            counterEffect(coins, parseInt(coins.innerHTML), data.balance, 13, 1000);
          }

          cb(data);
        })
        .catch((err) => {
          window.devMode && console.log('error', err);
        });
    }
  }

  buyCallback = (item) => {
    window.devMode && console.log('buyCallback', item);

    this.setState({popupOpened: false, popup: null});

    if (item && item.count) {
      this.buyWithCoins(item, (data) => {
        this.props.buyResult({result: 'buyWithCoins', data: data});
      });
    } else {
      this.setState({
        popup: null
      });

      this.props.buyResult({result: 'nothing'});
    }

    setTimeout(() => {
      window.devMode && console.log(this.state);
    }, 10);
  }

  shopCallback = (item) => {
    window.devMode && console.log('shopCallback', item);

    this.setState({
      popupOpened: false,
      alert: false,
      popup: null
    });

    if (item) {
      togglePopup('coinShop', true);
      this.props.buyResult({result: 'coinShop'});
    } else {
      this.props.buyResult({result: 'nothing'});
    }
  }

  render() {
    let {buyItem} = this.props;
    let {alert} = this.state;

    return buyItem ?
      alert ?
        <div className={'infant-achievements__popup'}>
          <InfantPopup btnTitle={'В магазин'} item={{alert: alert}} hidePopUp={(e) => {
            this.shopCallback(e)
          }}/>
        </div>
        : <div className={'infant-achievements__popup'}>
          <InfantPopup quantity={false} btnTitle={'Купить'} item={buyItem} hidePopUp={(e) => {
            this.buyCallback(e)
          }}/>
        </div>
      : null
  }
}

export default ExternalShop
