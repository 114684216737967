import { ajax } from '../../helpers/index';

/**
 * @param {String} code
 *  * @param {String} redirect_uri
 * @returns {Promise}
 */
export const cancelSubscription = () => {
  return ajax
    .post('/users/cancel_recurrent_payment')
    .then(response => response.data)
    .catch(error => error);
};
