import {ajaxV2} from '../../helpers';
import React from 'react';
import {render} from 'react-dom';

import {
  ErrorPage
} from '../../containers';

/**
 * @typedef {Array.<Object>} PaymentsUrl
 */

/**
 * @returns {Promise.<PaymentsUrl>}
 */
export const paymentsQuest = (data) => {
  window.devMode && console.log('paymentsV2', data);
  return ajaxV2
    .post('/payments/quest', data)
    .then(response => response.data)
    .catch(err => {
      if (err.response.status === 404) {
        render(
          <ErrorPage status={404}/>,
          document.getElementById('root')
        );
      }
    })
};
