import React from 'react';
import PropTypes from 'prop-types';
import { Stage, Layer, Arc, Shape } from 'react-konva';

export const CabinetGraph = ({ child, time }) => {
  const width = 380;
  const height = 380;
  const lineWidth = 40;
  const radius = (width - lineWidth) / 2;

  let data = [{ label: '', start: 50, end: 100, color: '255, 255, 255' }];
  let total = 0;

  if (child !== null && time !== null) {
    data = time.data
    total = time.total;
  }

  return (
    <div className="cabinet-graph">
      {
        (total > 0) &&
        data.map((item, index) => {
          return <div key={index} className="cabinet-graph__label">
            {item.label}<br/>
            {item.end - item.start}%
          </div>
        })
      }
      <div className="cabinet-graph__inner">
        <div className="cabinet-graph__total">
          <div className="cabinet-graph__title">Общее время на сайте:</div>
          <div className="cabinet-graph__value">{total} часов</div>
        </div>
        <Stage width={width} height={height}>
          <Layer>
            {
              (total === 0) &&
              <Arc
                x={width / 2}
                y={height / 2}
                outerRadius={radius}
                innerRadius={radius}
                angle={360}
                stroke="rgba(255, 255, 255, 0.2)"
                strokeWidth={lineWidth}
              />
            }
            <Shape
              width={width}
              height={height}
              sceneFunc={function (context) {
                createShape(data, radius, lineWidth, context, this); // eslint-disable-line no-use-before-define
              }}
            />
          </Layer>
        </Stage>
      </div>
    </div>
  );
};

/**
 * Генерируем градиентную заливку
 * @param context Контекст Canvas
 * @param color Цвет заливки
 * @param x Координата x центра окружности
 * @param y Координата y центра окружности
 * @param radius Радиус окружности
 * @param startAngle Начальный угол
 * @param endAngle Конечный угол
 * @returns {CanvasGradient}
 */
const createGradient = (context, color, x, y, radius, startAngle, endAngle) => {
  const gradient = context.createLinearGradient(
    x + Math.cos(startAngle) * radius,
    y + Math.sin(startAngle) * radius,
    x + Math.cos(endAngle) * radius,
    y + Math.sin(endAngle) * radius
  );

  gradient.addColorStop(0, 'transparent');
  gradient.addColorStop(0.1, `rgba(${color}, 0.05)`);
  gradient.addColorStop(0.4, `rgba(${color}, 0.3)`);
  gradient.addColorStop(1, `rgba(${color})`);

  return gradient;
};

/**
 * Рисуем график
 * @param data Массив данных
 * @param radius Радиус окружности
 * @param lineWidth Ширина линии
 * @param context Контекст Canvas
 * @param shape Shape
 */
const createShape = (data, radius, lineWidth, context, shape) => {
  const x = shape.getAttr('width') / 2;
  const y = shape.getAttr('height') / 2;

  data.forEach((item) => {
    const startAngle = ((item.start * 360 * Math.PI) / 18000) - Math.PI / 2;
    const endAngle = ((item.end * 360 * Math.PI) / 18000) - Math.PI / 2;

    context.beginPath();
    context.arc(x, y, radius, startAngle, endAngle, false);
    context.strokeStyle = createGradient(context, item.color, x, y, radius, startAngle, endAngle);
    context.lineWidth = lineWidth;
    context.lineCap = 'round';
    context.stroke();
    context.closePath();
  });
  context.fillStrokeShape(shape);
};

CabinetGraph.propTypes = {
  child: PropTypes.object,
  time: PropTypes.object
};
