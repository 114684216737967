import React from 'react';
import PropTypes from 'prop-types';
import { i18n } from '../../data';
import {
  Error
} from '../../components';


export const UserPassword = (props) => {
  return (
    <label className="input-txt">
      <input
        className={`input-txt__field ${props.message ? 'input-txt__field_state_error' : ''}`}
        type="password"
        placeholder={props.placeholder}
        name={props.name}
        value={props.value}
        maxLength={35}
        min={props.minLength}
        onChange={props.onChange}
        autoFocus={props.autoFocus}
      />
      {props.message && <Error message={props.message} type="inline" />}
    </label>
  );
}


UserPassword.defaultProps = {
  isRequired: false,
  name: 'password',
  placeholder: i18n.password,
  minLength: 6
};

UserPassword.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onChange: PropTypes.func,
  minLength: PropTypes.number
};
