import React from 'react';
import { config } from '../../data'
import { Link } from 'react-router-dom';
import Button from '../button/button';

export const LibraryLink = () => {
  return (
    <Link
      to={config.routes.library}
      className="cabinet-menu__link"
    >
      <Button mod="cabinet-menu__button" color="navy-blue" customTag="span">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.2 27.9331C11.2 30.1238 12.2633 31.2 14.4156 31.2H25.0363C27.1886 31.2 28.2647 30.1238 28.2647 27.9331V12.8669C28.2647 10.6889 27.1886 9.59998 25.0363 9.59998H14.4156C12.2633 9.59998 11.2 10.6889 11.2 12.8669V27.9331ZM13.0448 27.9075V12.8925C13.0448 11.9701 13.5444 11.4448 14.5181 11.4448H24.9466C25.9074 11.4448 26.4071 11.9701 26.4071 12.8925V27.9075C26.4071 28.8299 25.9074 29.3551 24.9466 29.3551H14.5181C13.5444 29.3551 13.0448 28.8299 13.0448 27.9075ZM16.017 19.1829H23.4604C23.8576 19.1829 24.1523 18.8626 24.1523 18.4654C24.1523 18.0939 23.8576 17.7865 23.4604 17.7865H16.017C15.6071 17.7865 15.3124 18.0939 15.3124 18.4654C15.3124 18.8626 15.6071 19.1829 16.017 19.1829ZM16.017 15.6854H23.4604C23.8576 15.6854 24.1523 15.3779 24.1523 14.9808C24.1523 14.5964 23.8576 14.3018 23.4604 14.3018H16.017C15.6071 14.3018 15.3124 14.5964 15.3124 14.9808C15.3124 15.3779 15.6071 15.6854 16.017 15.6854ZM16.017 22.6676H19.5017C19.8989 22.6676 20.1936 22.3729 20.1936 21.9886C20.1936 21.5914 19.8989 21.284 19.5017 21.284H16.017C15.6071 21.284 15.3124 21.5914 15.3124 21.9886C15.3124 22.3729 15.6071 22.6676 16.017 22.6676Z" fill="white"/>
        </svg>
      </Button>
      Оглавление
    </Link>
  );
};
