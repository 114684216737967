import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import c from 'classnames';
let clickable = true;
export function Quests({loading, quests}) {

  return (
    <div className="quests custom_scrollbar">
      {loading && <div className="quests__loading"/>}
      {window.innerWidth > 900 ? ( <div className="quests__inner">
        {quests}
      </div>) : (<div>
        <Slider
          dots
          slidesToShow={1}
          slidesToScroll={1}
          className={c('main-slider')}
          dotsClass="main-slider__dots"
          afterChange={() => {
            clickable = true;
          }}
          beforeChange={(oldIndex, newIndex) => {
            sessionStorage.setItem('currentSlide', newIndex);
            clickable = false;
          }}
          initialSlide={
            sessionStorage.getItem('currentSlide')
              ? +sessionStorage.getItem('currentSlide')
              : 0
          }
        >
          {quests}
        </Slider>
      </div>)}

    </div>
  )
}

Quests.propTypes = {
  location: PropTypes.object
};

