import React from 'react';
import { Winter, withBanner } from '../../banners';

const WinterBanner = ({ type }) => {
  return (
    <Winter type={type} />
  );
};

export const WinterRoutesBanner = withBanner(WinterBanner);
