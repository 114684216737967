import React, {Component} from 'react';
import {config} from '../../data';

export class Achievka extends Component {
  state = {
    imageURL: '../img_static/small-loader.svg',
    loaded: false,
    img: new Image()
  }

  componentDidUpdate(prevProps) {

  }

  componentDidMount() {
    const {item} = this.props;
    const {img} = this.state;
    const imgUrl = `${config.base_url}${item.image}`;

    img.addEventListener('load', () => {
      this.setNewImageUrl(imgUrl);
    });
    img.src = imgUrl;
  }

  setNewImageUrl(imgUrl) {
    this.setState({imageURL: imgUrl})
  }

  render() {
    const {item, index} = this.props;

    return (
      <div className={'swiper-slide'}>
        <div data-index={index} className={'achievka__unit'}>
          <div className={'achievka__img' + (item.newly && this.state.loaded ? ' __newly' : '')}>
            <img src={this.state.imageURL} alt=""/>
          </div>
          <div className="achievka__name">{item.title}</div>
        </div>
      </div>
    )
  }
}
