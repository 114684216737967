import {UPDATE_COINS, UPDATE_POINTS} from '../actionTypes';

const initialState = {
  childPoints: 0,
  childCoins: 0
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_COINS: {
      return {
        ...state,
        childCoins: action.childCoins
      };
    }

    case UPDATE_POINTS: {
      return {
        ...state,
        childPoints: action.childPoints
      };
    }

    default:
  }
  return state;
}
