import React from 'react';
import { Autumn, withBanner } from '../../banners';

const SpringBanner = ({ type }) => {
  return (
    <Autumn type={type} />
  );
};

export const AutumnRoutesBanner = withBanner(SpringBanner);
