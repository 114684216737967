import React, {Component} from 'react';
import {checkImagesLoad} from '../../helpers';
import {config} from '../../data';
import * as api from '../../api';
import {
  Lessons,
  MainNav,
  Map,
  Quest,
  //PreLoader,
  LinkAbout
} from '../../components';
import * as auth from '../../helpers/authenticationHelper';

export class LessonsForTours extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lessons: [],
      image_loaded: false
    };
  }

  componentDidMount() {
    this.showLessons()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname.indexOf('/cabinet/child/') === 0 || prevProps.location.pathname.indexOf('/lessons') === 0) {
      this.showLessons();
    }
  }

  showLessons() {
    let currentChildId = auth.getCurrentChild();

    Promise.all([
      api.getLessons(currentChildId),
      checkImagesLoad(config.map_lessons_img)
    ]).then(result => {
      let images = [];

      result[0].forEach(item => {
        if (item.map && item.map.icon) {
          images.push(`${config.base_url}${item.map.icon}`);
        }
      });
      this.setState({
        lessons: result[0],
        image_loaded: true
      });
    });
  }

  render() {
    const {highlightLessons} = this.props;
    const {lessons} = this.state;

    return (
      <main className="lessons-container">
        {/*<PreLoader*/}
        {/*  image_loaded={this.state.image_loaded}*/}
        {/*  pre_loader_type="default"*/}
        {/*/>*/}
        <LinkAbout/>
        <MainNav
          navList={config.lessons_page_main_nav}
          mobileMenu={true}
          tooltip={this.state.tooltipPrize}
          tooltipCB={this.tooltipPrizeRemove}
          numberTour={this.props.numberTour}
        />
        <Map
          itsToursPage={true}
        >
          <Lessons
            lessonsList={lessons}
            showPopUp={this.showPopUp}
          />
        </Map>

        {highlightLessons && <Quest
          lessonsList={highlightLessons}
          showPopUp={this.showPopUp}
          fakeParams={true}
          numberTour={2}
        />}

      </main>
    );
  }
}
