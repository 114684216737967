/**
 * Плавный скролл
 *
 * @returns {number}
 */

export function easeInOutQuart(time, from, distance, duration) {
  if ((time /= duration / 2) < 1) {
    return distance / 2 * Math.pow(time, 4) + from;
  }

  return -distance / 2 * ((time -= 2) * Math.pow(time, 3) - 2) + from;
}

export function smoothScrollTo(target, startY = 0, endY = 0, startX = 0, endX = 0, duration = 500) {
  let distanceY = endY - startY;
  let distanceX = endX - startX;
  let startTime = new Date().getTime();

  if (!target) {
    return;
  }

  let timer = window.setInterval(() => {
    let time = new Date().getTime() - startTime;
    let newY = easeInOutQuart(time, startY, distanceY, duration) || 0;
    let newX = easeInOutQuart(time, startX, distanceX, duration) || 0;

    if (time >= duration) {
      window.clearInterval(timer);
    }

    target.scrollTo(newX, newY);
  }, 1000 / 60);
}
