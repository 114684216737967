import React, {Component} from 'react';

export class NewYearBanner extends Component {
  render() {
    return (
      <div className="new-year-banner">
        <h2 className="new-year-banner__title">Зимние чудеса</h2>
        <div className="new-year-banner__image-wrap">
          <img src="/img/promo/winter.png"
               srcSet="/img/promo/winter@2x.png 2x"
               alt=""
               className="new-year-banner__image"/>
        </div>
        <div className="new-year-banner__sale">скидка 75%</div>
        <div className="new-year-banner__description">Успей оформить подписку</div>
        <div className="new-year-banner__actions">
          <button onClick={this.props.onAction} type="button" className="subscribe-btn new-year-banner__button">
            <span className="subscribe-btn__inner subscribe-btn__inner_color_green">Подписаться</span>
          </button>
        </div>
      </div>
    );
  }
}
