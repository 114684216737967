import React from 'react';
import {Lottery, withBanner} from '../../banners';

const LotteryBanner = ({ type }) => {
  return (
    <Lottery type={type} />
  );
};

export const LotteryRoutesBanner = withBanner(LotteryBanner);
