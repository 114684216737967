import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {whichAnimationEvent} from '../../helpers';

export class Hero extends Component {
  state = {
    imageURL: this.props.noPreloader ? '' : '/img_static/small-loader.svg',
    img: new Image(),
    imgLoaded: false,
    animationEnd: false
  };

  heroRef = React.createRef();
  wrapperRef = React.createRef();

  imgLoader(url) {
    const {animationStart} = this.props;

    setTimeout(() => {
      this.setState({imageURL: url, imgLoaded: true});
      if (typeof animationStart === 'function') {
        animationStart();
      }
    }, 10);
  }

  componentDidMount() {
    const {name, animationEnd} = this.props;
    const {img} = this.state;
    const url = '/img/hero/' + (name) + '.png';

    let animationEvent = whichAnimationEvent();

    if (this.heroRef && this.heroRef.current) {
      this.heroRef.current.addEventListener(animationEvent, () => {
        if (this.state.imgLoaded) {
          this.setState({animationEnd: true});
          this.heroRef.current.classList.add('end');

          if (typeof animationEnd === 'function') {
            animationEnd();
          }

          //this.wrapperRef.current.parentNode.classList.add('animation__end');
          //
          //setTimeout(() => {
          //  togglePopup('successReg', false);
          //}, 1500);
        }
      });
    }

    img.addEventListener('load', this.imgLoader.bind(this, url));
    img.src = url;
  }

  componentWillUnmount() {
    const {img} = this.state;
    img.removeEventListener('load', this.imgLoader);
  }

  render() {
    const {
      cssClass,
      animate,
      height,
      width,
      name,
      text
    } = this.props;

    const {
      imageURL,
      animationEnd,
      imgLoaded
    } = this.state;

    let svg = <svg preserveAspectRatio="xMidYMid meet" width={'100%'} height={'100%'}
                   viewBox={'0 0 ' + width + ' ' + height}>
      <foreignObject width="100%" height="100%">
        <div ref={this.heroRef}
             style={{width: width + 'px', height: height + 'px', backgroundImage: `url(${imageURL})`}}
             className={('animation animation_' + name)}/>
      </foreignObject>
    </svg>

    return (
      <div ref={this.wrapperRef}
           className={'hero__wrapper ' + cssClass + (animate ? ' animate' + (animationEnd ? ' end' : (imgLoaded ? ' start' : '')) : '')}>
        <div className={'hero'} style={{width: width + 'px', paddingBottom: (100 * height / width) + '%'}}>
          {animate ? svg :
            <img width={width || null} height={height || null} src={animate ? '/img/hero/pixel.png' : imageURL}
                 alt=""/>}
          {imgLoaded && this.props.children}
        </div>
        {text && imgLoaded ? <div className="hero__text">
          <span dangerouslySetInnerHTML={{__html: text}}/>
        </div> : null}
      </div>
    )
  }
}

Hero.propTypes = {
  animationStart: PropTypes.func,
  animationEnd: PropTypes.func,
  name: PropTypes.string,
  cssClass: PropTypes.string,
  noPreloader: PropTypes.bool,
  animate: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  text: PropTypes.string
};
