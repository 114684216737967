import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { bannerType } from '../const';
import style from './lottery.module.sass';
import * as images from './images';

export const Lottery = ({ type, handleButtonClick }) => {
  return (
    <div className={cn(style.lottery, style[type])}>
      <div className={style.title}>розыгрыш</div>

      {
        (type === bannerType.big)
        && <>
          <picture className={style.prizes}>
            <source media="(max-width: 849px)" srcSet={`${images.prizesMobileBig} 1x, ${images.prizesMobileBigRetina} 2x`} />
            <img src={images.prizesBig} srcSet={`${images.prizesBigRetina} 2x`} alt="" />
          </picture>
          <img src={images.che} srcSet={`${images.cheRetina} 2x`} alt="" className={style.image} />
        </>
      }

      {
        (type === bannerType.small)
        && <picture className={style.image}>
          <source media="(max-width: 849px)" srcSet={`${images.prizesMobileSmall} 1x, ${images.prizesMobileSmallRetina} 2x`} />
          <img src={images.prizesSmall} srcSet={`${images.prizesSmallRetina} 2x`} alt="" />
        </picture>
      }

      {
        (type === bannerType.routes)
        && <picture className={style.image}>
          <source media="(max-width: 849px)" srcSet={`${images.prizesMobileRoutes} 1x, ${images.prizesMobileRoutesRetina} 2x`} />
          <img src={images.prizesRoutes} srcSet={`${images.prizesRoutesRetina} 2x`} alt="" />
        </picture>
      }

      <div className={style.sale}>75% скидка подписки</div>
      <div className={style.descr}>Успей купить подписку по супер цене, а мы подарим подарки</div>
      <Link
        to="/cabinet/select_tariff/"
        className={style.button}
        onClick={handleButtonClick}
        type="button"
      >
        { type === bannerType.big ? 'Оформить подписку' : 'Подписаться' }
      </Link>
      <a href={'/terms.docx'} className={style.terms}>Правила участия</a>
    </div>
  );
};

Lottery.propTypes = {
  type: PropTypes.string.isRequired,
  handleButtonClick: PropTypes.func
};
