import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { config } from '../../data';

import {
  CabinetQuestLessons,
  CabinetQuestPath
} from '../index';

export class CabinetQuestSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      path: null
    };

    this.lessonsWrap = null;
  }

  onResizeWindow = () => {
    this.setState({
      path: {
        width: this.lessonsWrap.offsetWidth,
        height: this.lessonsWrap.offsetHeight,
        coords: this.generatePathCoords()
      }
    });
  };

  componentDidMount() {
    this.setState({
      path: {
        width: this.lessonsWrap.offsetWidth,
        height: this.lessonsWrap.offsetHeight,
        coords: this.generatePathCoords()
      }
    });

    window.addEventListener('resize', this.onResizeWindow);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResizeWindow)
  }

  render() {
    const { quest, finishedLessonsCnt, lessonsCnt } = this.props;

    return (
      <section className="cabinet-quest-section">
        <h3 className="cabinet-quest-section__title">{quest.name} ({finishedLessonsCnt}/{lessonsCnt})</h3>
        <div className="cabinet-quest-section__inner">
          <CabinetQuestLessons
            lessonsWrapRef={element => this.lessonsWrap = element}
            lessons={quest.lessons}
            quest={quest}
          />
          {
            (this.state.path !== null && window.innerWidth > 900) &&
            <CabinetQuestPath path={this.state.path}/>
          }
        </div>
      </section>
    );
  }

  /**
   * Генерируем массив координат для построения линии,
   * соединяющей иконки уроков
   */
  generatePathCoords() {
    const items = this.lessonsWrap.children;
    const coords = [];
    let prevCoords = {};
    let index = 1;
    let point = 1;
    let offsetTop = 45;

    if (document.documentElement.clientWidth < config.cabinet.breakpoint_large) {
      offsetTop = 30; // У экранов с меньшим разрешением размер иконки урока меньше
    }

    for (const item of items) {
      const row = Math.ceil(index / config.cabinet.quests_lessons_in_row);
      const prevRow = Math.ceil((index - 1) / config.cabinet.quests_lessons_in_row) || 1;
      let x = item.offsetLeft - this.lessonsWrap.offsetLeft + item.offsetWidth / 2;
      let y = item.offsetTop - this.lessonsWrap.offsetTop + offsetTop;

      if (index === 1 ||
          index % config.cabinet.quests_lessons_in_row === 0 ||
          row > prevRow ||
          index === items.length) {

        // point:
        // 1------------------2
        //                    |
        //                    |
        // 4------------------3
        // |
        // |
        // 1...

        if (index === items.length && (index % config.cabinet.quests_lessons_in_row) === 1) {
          coords.push({ x: prevCoords.x, y });
        } else if (index > 1 && index < items.length && (point === 1 || point === 4)) {
          x -= item.offsetWidth / 2;
        } else if (index < items.length && (point === 2 || point === 3)) {
          x += item.offsetWidth / 2;
        }

        coords.push({ x, y });

        prevCoords = { x, y };
        point = (point === 4) ? 1 : ++point;
      }

      ++index;
    }

    return coords;
  }
}

CabinetQuestSection.propTypes = {
  finishedLessonsCnt: PropTypes.number.isRequired,
  lessonsCnt: PropTypes.number.isRequired,
  quest: PropTypes.object.isRequired
};
