import React from 'react';
import PropTypes from 'prop-types';
import { config } from '../../data';
import { Link } from 'react-router-dom';

export const CabinetLessons = ({ child, stats }) => {
  let lessonsShown = [];

  if (child !== null && stats !== null) {
    // eslint-disable-next-line no-use-before-define
    lessonsShown = limitLessons(
      stats.lessons,
      5,
      (lessonA, lessonB) => lessonA.listened_at - lessonB.listened_at
    );
  }

  return (
    <div className="cabinet-lessons">
      {
        (lessonsShown.length > 0) ?
        lessonsShown.map((item, index) => {
          const date = new Date(item.listened_at * 1000);

          return <Link
            key={index}
            to={`/lessons/${item.id}`}
            title={item.name}
            className="cabinet-lessons__item"
          >
            <div className="cabinet-lessons__img-wrap">
              <img
                className="cabinet-lessons__img"
                alt=""
                role="presentation"
                src={`${config.base_url}${item.map.icon}`}/>
            </div>
            <div className="cabinet-lessons__name">{item.name}</div>
            <div className="cabinet-lessons__date">{date.getDate()}/{(date.getMonth() + 1)}</div>
          </Link>
        })
        :
        <div className="cabinet-lessons__text">
          Не пройдено ни одного урока
        </div>
      }
    </div>
  );
};

/**
 * Сортируем и отбираем нужное количество уроков
 * @param arr Массив с уроками
 * @param limit Необходимое количество уроков
 * @param cb Функция сортировки
 * @returns {*}
 */
const limitLessons = (arr, limit, cb) => {
  const newArr = arr.concat();
  newArr.sort(cb);
  return newArr.reverse().slice(0, limit);
};

CabinetLessons.propTypes = {
  child: PropTypes.object,
  stats: PropTypes.object
};
