import React, { Component } from 'react';
import PropTypes from 'prop-types';


export class LibraryTitle extends Component {

  render() {

    return (
      <nav className="Library-title">
        Для вашего удобства уроки «Приключений Чевостика» собраны по темам. Вы всегда можете предложить вашему ребёнку изучить то, что ему больше всего интересно:
      </nav>
    )
  }
}

LibraryTitle.propTypes = {
  gamesList: PropTypes.array
};
