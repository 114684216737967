import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getMonthName} from '../../helpers';
import isEqual from 'lodash/isEqual';

export class Tariff extends Component {
  state = {
    canProlog: this.props.tariff.is_recurrent,
    isProlong: this.props.tariff.is_recurrent
  }

  componentDidUpdate(prevProps) {
    const {tariff} = this.props;
    const oldPromo = prevProps.tariff.promo;
    const newPromo = tariff.promo;

    if (!isEqual(oldPromo, newPromo)) {
      const canProlog = tariff.promo ? tariff.promo.is_recurrent : tariff.is_recurrent;

      this.setState({
        canProlog,
        isProlong: canProlog ? this.state.isProlong : false
      })
    }
  }

  render() {

    const {
      tariff: {
        id,
        months_number,
        description,
        price,
        old_price,
        saving,
        promo
      },
      selectTariff
    } = this.props;
    const {isProlong, canProlog} = this.state;
    const isSale = saving > 0 || promo;

    return (
      <li key={id} className={'tariff'}>
        <div style={{border:`${months_number === 12 && ('4px solid #0166FE')}`}} className={'tariff__container'}>


          <div className="tariff__month">{getMonthName(months_number)}</div>

          {!isSale && <div className="tariff__price">{price} р.</div>}


          {isSale && <div className="tariff__new-price">{promo ? promo.price : price} р.</div>}
          {isSale && <div className="tariff__old-price-wrapper">
            <span className="tariff__old-price">{promo ? promo.old_price : old_price || ''}</span>
          </div>}
          {description && (
            <ul className="tariff__features">
              {(description.split('\r\n')).map((e, i) => (<li className="tariff__features-item" key={i}>{e}</li>))}
            </ul>
          )}

          <input
            id={`prolong-${id}`}
            type="checkbox"
            checked={canProlog ? isProlong : false}
            onChange={e => this.setState({isProlong: e.target.checked})}
            className="tariff__input"
            disabled={!canProlog}
          />


          <button
            type="button"
            className="tariff__button"
            onClick={() => {
              window.ym(92335881,'reachGoal','paymentstart');
              selectTariff(this.props.tariff, this.state.isProlong)
            }}
            data-id={id}
          >
            Выбрать
          </button>
        </div>
      </li>
    )
  }
}

Tariff.propTypes = {
  tariff: PropTypes.object.isRequired,
  selectTariff: PropTypes.func.isRequired
};
