import React from 'react';
import { Spring, withBanner } from '../../banners';

const SpringBanner = ({ type }) => {
  return (
    <Spring type={type} />
  );
};

export const SpringRoutesBanner = withBanner(SpringBanner);
