import React, { Component } from 'react';
// import { checkImagesLoad } from '../../helpers';

export class CloudAnimation extends Component {

  constructor(props) {
    super(props);

    this.state = {
    };

  }

  render() {
    return (
       <div className={`clouds ${this.props.animate ? 'clouds_move ' : ''} ${this.props.remove ? 'clouds_down' : ''} ${this.props.transparent ? 'clouds_transparent' : ''}` }>
         <div className="sea_overlay"/>
         <div className="map_scalable"/>
         <div className="overlay_map"/>
         <div className="cloud cloud_1"/>
         <div className="cloud cloud_2"/>
         <div className="cloud cloud_3"/>
         <div className="cloud cloud_4"/>
         <div className="cloud cloud_5"/>
         <div className="cloud cloud_6"/>
         <div className="cloud cloud_7"/>
         <div className="cloud cloud_8"/>
         <div className="cloud cloud_9"/>
         <div className='sprite_plane'/>
       </div>
    );
  }
}
