import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';

const PreLoader = (props) => {
  const {image_loaded, pre_loader_type, className} = props;

  return (
    <div
      className={cn(
        'pre-loader',
        `pre-loader_state_${image_loaded === true ? 'hide' : 'show'}`,
        `pre-loader_type_${pre_loader_type}`,
        className
      )}
    />
  );
};

PreLoader.propTypes = {
  image_loaded: PropTypes.bool,
  pre_loader_type: PropTypes.string
};

const mapStateToProps = function (state) {
  return {
    pre_loader_type: state.common.preloaderType,
    image_loaded: !state.common.preloaderType
  }
}

export default connect(mapStateToProps)(PreLoader);
