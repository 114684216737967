import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper'; // https://github.com/kolodny/immutability-helper

import { scaleGame, setDelay } from '../../helpers';
import { config } from '../../data';

const ANIMATE_TIME = 1300;

export class FindHiddenItems extends Component {

  constructor(props) {
    super(props);

    // значение в % для центрирования игры
    const defaultCenterAxisValue = -50;

    this.state = {
      counter: 0,
      /* CSS  */
      transform: {
        scale: 1,
        translate: {
          x: defaultCenterAxisValue,
          y: defaultCenterAxisValue
        }
      },
    };

    this.defaultCenterAxisValue = defaultCenterAxisValue;
    this.scaleGame = this.scaleGame.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.scaleGame);
    this.scaleGame();
    let answers = [];

    this.props.items.forEach(item => {
      answers.push({
        is_found: false
      })
    });

    this.setState({ answers });
  }

  render() {
    let cssTransform = this.state.transform;
    let items = null;
    let panelItem = null;
    let backgroundImage = this.props.background.src;
    let cssTransformStyle = {
      transform: `
        scale(${cssTransform.scale})
        translate(${cssTransform.translate.x}%, ${cssTransform.translate.y}%)
      `
    };
    let css = this.props.css ? this.props.css : null;

    if (this.state.answers && this.props.items) {
      panelItem = this.state.answers.map((item, index) => {
        return (
          <div
            key={index}
            style={{backgroundImage: `url(${this.props.items[index].src})`}}
            className={`find-hidden-items__panel-item find-hidden-items__panel-item_state_${item.is_found ? 'is_found' : 'not_found'}`}
          >

          </div>
        )
      });
    }

    if (this.props.items) {
      items = this.props.items.map((item, index) => {
        return (
          <div
            key={index}
            className={`find-hidden-items__item find-hidden-items__item_${index+1}`}
            id={index}
            onClick={this.onClick}
            style={{backgroundImage: `url(${item.src})`, width: item.width, height: item.height}}
          >
          </div>
        )
      });
    }

    return (
      <section className="find-hidden-items">
        <h1 className='game-page__name'>Найди все предметы на картинке</h1>
        <div
          className="find-hidden-items__inner"
          style={cssTransformStyle}
        >
          <div
            className="find-hidden-items__layout"
            style={{backgroundImage: `url(${backgroundImage})`}}
          >
            <style>{css}</style>
            {items}
          </div>
        </div>


        <div className="find-hidden-items__panel">
          {panelItem}
        </div>
      </section>
    );
  }

  onClick(event) {
    event.currentTarget.classList.add('find-hidden-items__item_found');
    let id = event.currentTarget.getAttribute('id');

    let newAnswer = update(this.state.answers, {
      [id]: {
        is_found: {$set : true}
      }
    });

    this.setState({
      counter: this.state.counter + 1,
      answers: newAnswer
    });

    if (this.state.counter >= this.state.answers.length-1){
      setDelay(ANIMATE_TIME).then(() => {
        this.props.showPostLoader(`${config.game_types.find_hidden_hero}`)
      });
    }
  }

  scaleGame() {
    let scaleValue = scaleGame();

    // маштабировать игру под размер экрана
    this.setState((prevState, props) => {
      prevState.transform.scale = scaleValue;
      return prevState;
    });
  }
}

FindHiddenItems.propTypes = {
  showPostLoader: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  background: PropTypes.string.isRequired,
  css: PropTypes.string.isRequired
};
