import MobileDetect from 'mobile-detect';

/**
 * Определяет все тач устройства устройства
 */
export const isTouch = () => {
  let md = new MobileDetect(window.navigator.userAgent);
  
  return md.mobile() ? true : false
};
