import {ajax, ajaxV2} from '../../helpers';
import React from 'react';
import {render} from 'react-dom';

import {
  ErrorPage
} from '../../containers';

const isV2 = async (childId, id) => {
  return await ajaxV2
    .get(`/games/${id}?child_id=${childId}`)
    .then(response => {
      return response.data.game_datas && 0 < response.data.game_datas.length && response.data.game_levels && 0 < response.data.game_levels.length;
    })
}

/**
 * @typedef {Object} Game
 */

/**
 * @param {number} childId
 * @param {number} id
 * @returns {Promise.<Game>}
 */
export const getGame = async (childId, id) => {
  const v2 = await isV2(childId, id);
  const _ajax = v2 ? ajaxV2 : ajax;

  return _ajax
    .get(`/games/${id}?child_id=${childId}`)
    .then(response => {
      if (v2) {
        response.data.type = response.data.type + '-v2';
      }
      return response.data;
    })
    .catch(err => {
      if (err.response.status === 404) {
        render(
          <ErrorPage status={404}/>,
          document.getElementById('root')
        );
      }
    })
};
