import React, {Component} from 'react';
import queryString from 'query-string';
import * as api from '../../api';
import {UserEmail, AuthButton} from '../../components';
import {connect} from 'react-redux';
import {closeAuthPopup, openAuthPopup} from '../../redux/actions';

class AuthPasswordReset extends Component {
  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search)

    this.state = {
      email: query.email || '',
      isResetLinkSent: false
    };

    this.showForm = this.showForm.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  render() {
    const formComponent = (
      <form className="form" onSubmit={this.onSubmit}>
        <div className="passwort-reset__container">
          <UserEmail
            placeholder="Введите почту для восстановления пароля"
            name="email"
            value={this.state.email}
            onChange={this.onChange}
          />
        </div>
        <AuthButton text="Войти"/>
      </form>
    );

    const requestResultComponent = (
      <div className="result">
        <div className="result__icon"/>
        <p className="result__repeat-text">Проверьте почту!</p>
        <p className="result__repeat-link" onClick={this.showForm}>
          Запросить еще раз
        </p>
      </div>
    );

    return (
      <div className="sign-up">
        <div className="top">
          <h2 className="sign-up__title">Восстановление пароля</h2>
        </div>
        {this.state.isResetLinkSent ? requestResultComponent : formComponent}
      </div>
    );
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onSubmit(e) {
    e.preventDefault();

    api.passwordReset(this.state.email).then(result => {
      this.setState({
        isResetLinkSent: true
      });
    });
  }

  showForm() {
    this.setState({
      isResetLinkSent: false
    });
  }
}

export default connect(null, {
  closeAuthPopup,
  openAuthPopup
})(AuthPasswordReset);
