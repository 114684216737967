import React, { Component } from 'react';
import { isTablet } from '../../helpers';
import { config } from '../../data';
import { Drag, Drop, DragPreview } from '../dnd';
import CounterWrapper from '../counter/counter-wrapper';
import {HTML5Backend} from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend'
import {DndProvider} from 'react-dnd';
import GameNextLevelBtn from '../game-next-level-btn/game-next-level-btn'
import GameRepeatBtn from '../game-repeat-btn/game-repeat-btn'

const animationTimeOut = 800;
const postLoaderTimeOut = animationTimeOut * 2;

const BASE_CANVAS = {
  width: 1920,
  height: 1080,
};

const getScale = () => {
  const scaleX = window.innerWidth / BASE_CANVAS.width;
  const scaleY = window.innerHeight / BASE_CANVAS.height;

  return Math.min(scaleX, scaleY);
};

export class CollectItem extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loadedPuzzlesOutline: null,
      counter: 0,
      timer: null,
      gameTimerOver: false,
      width: window.innerWidth,
      height: window.innerHeight,
      draggingFigureName: null,
      scaleBothValue: getScale(),
    };

    this.onFigureDragEnd = this.onFigureDragEnd.bind(this);
    this.onDragging = this.onDragging.bind(this);
    this.resizeDisplay = this.resizeDisplay.bind(this);
  }

  resizeDisplay() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      scaleBothValue: getScale(),
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeDisplay)
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeDisplay)

    let { fragments, fragmentsOutline } = this.props;
    let loadedFragments = [];
    let loadedFragmentsOutline = [];
    const tabletRatio = isTablet() ? 0.8 : 1

    // костыль для 14 игры
    const idGame = +(document.location.pathname.split('/games/')[1]);

    /**
     * Фрагменты
     */

    fragments.forEach((item, index) => {
      let fWidth = item.width * tabletRatio;
      let fHeight = item.height * tabletRatio;

      const maxWidthForFigure = Math.ceil(config.base_game_container.width / fragments.length);

      const offsetX = 100;
      const offsetY = isTablet() ? 50 : 170;
      const offsetXTablet = isTablet() ? fWidth/2 + 20 : 0

      let x = maxWidthForFigure * index;
      let y = config.base_game_container.height - offsetY - fHeight

      if (index === 0) {
        x = offsetX
      }

      if (index === (fragments.length-1)) {
        x = config.base_game_container.width - offsetX - fWidth - offsetXTablet
      }

      // костыль для 14 игры
      if (idGame === 14 && index === 0 && !isTablet()) {
        y = 100;
        x = 250;
      };
      if (idGame === 14 && index === 1 && !isTablet()) {
        y = 400;
        x = 300;
      };
      if (idGame === 14 && index === 2 && !isTablet()) {
        y = 600;
        x = 300;
      };
      if (idGame === 14 && index === 3 && !isTablet()) {
        y = 750;
        x = 300;
      };
      if (idGame === 14 && index === 4 && !isTablet()) {
        y = 100;
        x = 1500;
      };
      if (idGame === 14 && index === 5 && !isTablet()) {
        y = 400;
        x = 1100;
      };

      loadedFragments.push({
        link: item.link,
        width: fWidth,
        height: fHeight,
        x: x * tabletRatio,
        y: y * tabletRatio,
        fragment_name: index,
        isDraggable: true
      });
    });

    /**
     * Фрагменты с контуром
     */

    fragmentsOutline.forEach((item, index) => {
      let fWidth = item.width * tabletRatio;
      let fHeight = item.height * tabletRatio;
      const offsetY = isTablet() ? 100: 0;
      const offsetX = isTablet() ? 40: 0;

      loadedFragmentsOutline.push({
        link: item.link,
        width: fWidth,
        height: fHeight,
        x: item.options.x * tabletRatio - offsetX,
        y: item.options.y * tabletRatio + offsetY,
        fragment_name: index,
        isDraggable: true
      });
    });

    this.setState({
      fragmentsOutline: loadedFragmentsOutline,
      fragments: loadedFragments,
    });
  }

  showNextFrame(instance, gameName, timer = null, timeout = false) {
    setTimeout(() => {
      instance.props.showNextFrame(gameName, timer, timeout)
    }, postLoaderTimeOut);
  }

  onDragging(fragmentName) {
    let draggingFigureName = null;
    this.state.fragments.forEach(item => {
      if (fragmentName === item.fragment_name) {
        draggingFigureName = item.fragment_name;
      }
    });
    if (this.state.draggingFigureName !== draggingFigureName) {
      this.setState({
        draggingFigureName
      });
    }
  }

  onFigureDragEnd(fragmentName) {
    let fragments = this.state.fragments.map(item => {
      if (fragmentName === item.fragment_name) {
        item.isDragged = true;
      }
      return item;
    });

    this.setState({
      counter: this.state.counter + 1,
      fragments
    }, () => {
      if (this.state.counter === this.state.fragments.length && !this.props.isShowNextLevelButton) {
        this.showNextFrame(this, 'collect-item', this.state.timer);
      }
    });
  }

  onChangeTimer(instance, timer, gameName) {
    if (0 === timer.days && 0 === timer.hours && 0 === timer.minutes && 0 === timer.seconds) {
      instance.setState({
        gameTimerOver: true
      })
      // instance.showNextFrame(instance, gameName, true);
    } else {
      instance.setState({
        timer
      })
    }
  }

  render() {
    let fragments = null;
    let fragmentsOutline = null;
    let cssWrap = {
      backgroundImage: `url(${config.base_url}${this.props.image})`
    };
    const { width, height, scaleBothValue } = this.state;
    const options = { enableMouseEvents: true };

    if (this.state.fragments){
      fragments = this.state.fragments.map((item, index) => {
        return <Drag key={index}
                     name={'fragment-' + item.fragment_name}
                     src={item.link}
                     height={item.height * scaleBothValue}
                     width={item.width * scaleBothValue}
                     left={item.x * scaleBothValue}
                     top={item.y * scaleBothValue}
                     draggable={item.isDraggable}
                     scaleBothValue={scaleBothValue}
                     onDragEnd={this.onFigureDragEnd.bind(this, item.fragment_name)}
                     onDragging={this.onDragging.bind(this, item.fragment_name)} />;
      })
    }

    if (this.state.fragmentsOutline){
      fragmentsOutline = this.state.fragmentsOutline.map((item, index) => {
        return <Drop key={index}
                     name={'fragment-' + item.fragment_name}
                     src={item.link}
                     height={item.height * scaleBothValue}
                     width={item.width * scaleBothValue}
                     left={item.x * scaleBothValue}
                     top={item.y * scaleBothValue}
                     zIndex={this.state.draggingFigureName === item.fragment_name ? 9930 : 9900} />;
      })
    }

    return (
      <div className="collect-item game-page__wrap" style={cssWrap}>
        <h1 className='game-page__name'>
          <CounterWrapper timer={this.props.timer} minutes={this.props.counterDown} onChange={(timer) => this.onChangeTimer(this, timer, 'collect-item')} />
        </h1>

        <div className="canvas-layer-v2"
             style={{ position: 'relative', zIndex: 1, maxWidth: '100%', overflow: 'hidden', width: width + 'px', height: height + 'px' }}>

          <div className="canvas-v2" style={{width: (BASE_CANVAS.width * scaleBothValue) + 'px', height: (BASE_CANVAS.height * scaleBothValue) + 'px'}}>
            {
              this.state.gameTimerOver
                ? (
                  <GameRepeatBtn gameName="collect-item" showNextFrame={this.props.showNextFrame} />
                )
                : (
                  this.state.fragments && this.state.counter === this.state.fragments.length && this.props.isShowNextLevelButton
                    ? (
                      <GameNextLevelBtn gameName={'collect-item'} timer={this.state.timer} showNextFrame={this.props.showNextFrame} />
                    )
                    : (
                      <DndProvider backend={ isTablet() ? TouchBackend : HTML5Backend } options={isTablet() ? options : {}} >
                        {fragmentsOutline}
                        {fragments}
                        { isTablet() ? <DragPreview />  : ''}
                      </DndProvider>
                    )
                )
            }
          </div>
        </div>



      </div>
    );
  }
}
