import React, { Component } from 'react';

const TIMER_DEFAULT = {
  minutes: 10,
  seconds: 0
}

export default class Counter extends Component {
  constructor(props) {
    super(props);

    this.timer = {
      days: null,
      hours: null,
      minutes: null,
      seconds: null
    };

    const minutes = props.timer
      ? (props.timer.minutes ?? TIMER_DEFAULT.minutes)
      : (props.minutes ?? TIMER_DEFAULT.minutes);
    const seconds = props.timer
      ? (props.timer.seconds ?? TIMER_DEFAULT.seconds)
      : (props.seconds ?? TIMER_DEFAULT.seconds);
    this.targetDate = new Date().getTime() + 1000 * 60 * minutes + 1000 * seconds; // установить дату обратного отсчета

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    this.tick()
    this.timerID = setInterval(
      () => this.tick(),
      this.props.timeout ?? 1000
    );
  }

  onChange(timer) {
    this.props.onChange(timer);
  }

  tick() {
    let currentDate = new Date().getTime();
    let diff = this.targetDate - currentDate;

    if (0 <= diff) {
      this.timer.days = Math.floor(diff / (1000 * 60 * 60 * 24));
      this.timer.hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      this.timer.minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      this.timer.seconds = Math.floor((diff % (1000 * 60)) / 1000);

      this.onChange(this.timer);
    } else {
      clearInterval(this.timerID);
    }
  }

  render() {
    return (
      <div className="counter">
        {
          ('0' + this.timer.minutes).substr(-2, 2) + ':' +
          ('0' + this.timer.seconds).substr(-2, 2)
        }
      </div>
    );
  }
}
