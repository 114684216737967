import React, { Component } from "react";
import PropTypes from "prop-types";
import { config } from "../../data";
import { checkImagesLoad, scale } from "../../helpers";
import { setPreloader } from "../../redux/actions";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import MobileLesson from "../../containers/lesson/mobileLesson";

let checkDesk = (function () {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    return true;
  } else {
    return false;
  }
})();

class LessonLoader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      background_size: "large",
      transform: {
        scale: 1,
        translate: {
          x: config.default_center_axis_value,
          y: config.default_center_axis_value,
        },
      },
    };

    this.scaleLessonLoader = this.scaleLessonLoader.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    window.devMode && console.log("componentWillReceiveProps", nextProps);
    if (!nextProps.lesson.preview_image) return;
    if (this.state.background_size === "large") return;

    checkImagesLoad(config.base_url + nextProps.lesson.preview_image.large)
      .then(() => {
        this.setState(
          {
            background_size: "large",
          },
          () => {
            this.props.setPreloader("");
          }
        );
      })
      .catch((e) => {
        window.devMode && console.log("image not loaded", e);
      });
  }

  componentDidMount() {
    window.devMode &&
      console.log("componentDidMount", this.props.lesson.preview_image);

    checkImagesLoad([
      this.props.lesson.preview_image &&
        config.base_url +
          this.props.lesson.preview_image[this.state.background_size],
    ]).then(() => {
      this.props.setPreloader("");
    });

    window.addEventListener("resize", this.scaleLessonLoader);
    this.scaleLessonLoader();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.scaleLessonLoader);
  }

  render() {
    let props = this.props;
    let controlText = props.is_loading ? "Загрузка" : "Слушать";
    let background_url =
      props.lesson.preview_image &&
      config.base_url + props.lesson.preview_image[this.state.background_size];

    let cssTransform = this.state.transform;
    let cssStyle = {
      transform: `
        scale(${cssTransform.scale})
        translate(${cssTransform.translate.x}%, ${cssTransform.translate.y}%)
      `,
      backgroundImage: `url(${background_url})`,
    };
    //Превью лоадер изменится для всего UI
    return checkDesk ? (
      <MobileLesson exit={this.props.onHome} question={this.props.onQuestion} />
    ) : (
      <div className="lesson-loader">
        <div
          className={`lesson-loader__background lesson-loader__background_${this.state.background_size}`}
          style={cssStyle}
        />

        <div className="lesson-loader__control" onClick={props.onClick}>
          <div
            className={`lesson-loader__control-icon lesson-loader__control-icon_loading_${
              props.is_loading ? "on" : "off"
            }`}
          >
            icon insert here
          </div>
          <div className="lesson-loader__control-text">{controlText}</div>
        </div>
        <h1 className="lesson-loader__title">{props.lesson.name}</h1>
      </div>
    );
  }

  /**
   * Маштабирует прелоадер урока под размер экрана
   */
  scaleLessonLoader() {
    let scaleValue = scale();

    this.setState((prevState) => {
      prevState.transform.scale = scaleValue;
      return prevState;
    });
  }
}

LessonLoader.propTypes = {
  is_loading: PropTypes.bool,
  lesson: PropTypes.shape({
    title: PropTypes.string,
    preview_image: PropTypes.shape({
      small: PropTypes.string,
      large: PropTypes.string,
    }),
  }),
  onClick: PropTypes.func,
};

const mapDispatchToProps = {
  setPreloader,
};

const componentWithRouter = withRouter(
  connect(null, mapDispatchToProps)(LessonLoader)
);

export { componentWithRouter as LessonLoader };
