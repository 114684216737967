import axios from 'axios';
import * as auth from './authenticationHelper';
import { config } from '../data';
import { page500, redirect } from '../helpers';

const oneMinute = 60000;
let environment = process.env.NODE_ENV;

// Для тестирования запросов к продакшен — раскоментировать.
environment = 'production';

// @see create-react-app/README.md#adding-custom-environment-variables
// run npm start => 'development',
// run npm test => 'test',
// npm run build => 'production'.

const BaseURL = {
  production: `${config.base_url}/api/v1`,
  productionV2: `${config.base_url}/api/v2`,
  development: '//localhost:5000/api'
};

export const ajax = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: oneMinute,

 // Внимание: transformRequest должен возвращать строку!
  transformRequest: [
    data => {
      // :TODO: убрать лог
      // console.log('ajax request data', data);
      return JSON.stringify(data);
    }
  ],

  validateStatus: function(status) {
    // Бекенд возвращает ошибку 404 если нет данных
    // нужно делать reject такого ответа
    // и обрабатывать эту ошибку для каждого запроса отдельно
    if (status === 404) {
      return false;
    }

    if (status >= 500 && status < 600) {
      page500();
      return true;
    }

    // если пользователь не авторизован
    if (status === 401 && !window.location.search.includes('reset_password_token=')) {
      auth.logout();
      redirect('/?auth=login');
      return true;
    }

    return status >= 200 && status < 300; // default
  }
});

export const ajaxV2 = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: oneMinute,

  // Внимание: transformRequest должен возвращать строку!
  transformRequest: [
    data => {
      // :TODO: убрать лог
      // console.log('ajax request data', data);
      return JSON.stringify(data);
    }
  ],

  validateStatus: function(status) {
    // Бекенд возвращает ошибку 404 если нет данных
    // нужно делать reject такого ответа
    // и обрабатывать эту ошибку для каждого запроса отдельно
    if (status === 404) {
      return false;
    }

    if (status >= 500 && status < 600) {
      page500();
      return true;
    }

    // если пользователь не авторизован
    if (status === 401 && !window.location.search.includes('reset_password_token=')) {
      auth.logout();
      redirect('/?auth=login');
      return true;
    }

    return status >= 200 && status < 300; // default
  }
});

// Конфигурация запроса
ajax.interceptors.request.use(
  config => {
    // Запросы к реальному бекенду
    let url = config.url;

    //Добавление токена авторизации
    if (auth.getToken()) {
      config.headers.Authorization = `Bearer ${auth.getToken()}`;
    }

    if (localStorage.getItem('ga_client_id')) config.headers['ga_client_id'] = localStorage.getItem('ga_client_id');

    url = `${BaseURL[environment]}${url}`;

    config.url = url;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Конфигурация запроса
ajaxV2.interceptors.request.use(
  config => {
    // Запросы к реальному бекенду
    let url = config.url;

    //Добавление токена авторизации
    if (auth.getToken()) {
      config.headers.Authorization = `Bearer ${auth.getToken()}`;
    }

    if (localStorage.getItem('ga_client_id')) config.headers['ga_client_id'] = localStorage.getItem('ga_client_id');

    url = `${BaseURL.productionV2}${url}`;

    config.url = url;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);


// HACK: не можем получить clientID, пока не загрузится ga
let countGetClient = 10;

function getClientId () {
  try {
    const clientID = window.ga.getAll()[0].get('clientId')
    localStorage.setItem('ga_client_id', clientID)
  } catch (error) {
    --countGetClient
    if (--countGetClient > 0 ) setTimeout(getClientId, 1000)
  }
}

setTimeout(getClientId, 1000);
