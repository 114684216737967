import React, {memo, useCallback, useMemo} from 'react';
import {bool, func, number, string, object} from 'prop-types';
import {config} from '../../data';
import cn from 'classnames';

export const Person = memo((props) => {
  const {
    person,
    number,
    cssSprite,
    status,
    animation,
    onClick,
  } = props;

  const style = useMemo(() => ({
    backgroundImage: `url(${config.base_url}${person.animation ? person.animation : cssSprite})`
  }), [person, cssSprite]);

  const handlerOnClick = useCallback((person) => {
    if (onClick) {
      onClick(person);
    }
  }, [onClick]);

  return (
    <>
      {!person.dom_id.match('number') && (
        <div
          id={person.dom_id}
          className={cn(
            'person',
            {
              'person_animate': person.animation,
              'person_colorless': status === 2,
              'person_animate_on': animation,
              'person_animate_off': !animation,
            })}
          style={style}
          onClick={() => handlerOnClick(person)}
        />
      )}

      {person.fact && (
        <div  onClick={() => handlerOnClick(person)} className={'element'}>
          {number + 1}
        </div>
      )}


    </>

  )
});

Person.propTypes = {
  person: object.isRequired,
  status: number,
  animation: bool,
  cssSprite: string,
  onClick: func,
};

/*
<>
  {person.fact && (<div className={'element'} onClick={() => handlerOnClick(person)} id={person.dom_id}>{number+1}</div>)}
</>*/
