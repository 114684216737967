/**
 * Тексты интерфейса
 *
 * @type {Object}
 */
export const i18n = {
  signUpTitle: 'Зарегистрируйся и получи 3 дня бесплатного полного доступа ко всем урокам',
  loginTitle: 'Начните приключения \u00A0с Чевостиком',
  hello: 'Привет',
  enterPassword: 'введите пароль',
  youAreAlreadyRegistered: 'Вы уже зарегистированы на сайте',
  enterPassportForLogin: 'Введите пароль, чтобы войти',
  welcome: 'Добро пожаловать!',
  email: 'Электронная почта',
  password: 'Пароль',
  next: 'Вперед',
  enter: 'Войти',
  register: 'Зарегистрироваться',
  enter_or_register: 'Войти или зарегистрироваться',
  addChild: 'Добавить ребенка',
  startAdventureWithChevostik: 'Начните приключения с\u00A0Чевостиком',
  yourChildName: 'Имя вашего ребёнка',
  signupAndGetFreeLesson:
    'Зарегистрируйтесь и получите пять бесплатных аудиоуроков с\u00A0тестам и играми',
  notValidData: 'Неверный логин или пароль',
  validationRequired: 'Необходимо заполнить',
  validationRequiredChild: 'Необходимо ввести имя минимум одного ребёнка',
  validationEmailError: 'Это не похоже на корректный адрес почты…',
  validationPassLength: 'Придумайте пароль не менее 6 символов',
  validationPassEqual: 'Вы неправильно повторили пароль',
  verifyTitle: 'Пожалуйста, подтвердите ваш аккаунт',
  verifyText:
    'Подтверждение аккаунта по\u00A0эл. почте необходимо, чтобы мы могли надежно сохранить и\u00A0защитить информацию о\u00A0вашей подписке',
  verifyEmail: 'Ваша эл. почта',
  verifyButton: 'Получить письмо для подтверждения',
  paymentCompletedTitle: 'Оплата прошла успешно!',
  paymentBtnTitle: 'Вперед к приключениям',
  paymentProcessingTitle: 'Платёж обрабатывается',
  paymentProcessingText:
    'Ваша подписка автоматически активируется, как только завершится обработка платежа',
  paymentProcessingBtnTitle: 'Хорошо',
  paymentFailedTitle: 'Произошла ошибка оплаты',
  paymentFailedText: 'Сумма не списана и не будет списана позже',
  paymentFailedBtnTitle: 'Перейти в кабинет',
  not_available_at_home: 'Недоступно на\xa0телефоне',
  games: 'Игры',
  loading: 'Загружаю',
  lesson_quest_text: 'Пройди по тайному маршруту, чтобы открыть этот урок',
  popup_not_registered:
    'Зарегистрируйтесь и\u00A0получите доступ к\u00A0пяти бесплатным историям',
  popup_lesson_not_paid:
    'Оформите подписку, чтобы получить доступ к\u00A0этой и\u00A0другим историям',
  prize_less: 'Собери полный времяскок, чтобы получить секретные знания',
  prize_full: 'Времяскок собран! Секретные знания доступны на карте',
  prize_tooltip_title_less: 'Открыта новая деталь времяскока',
  prize_tooltip_title_full: 'Новый времяскок собран',
  prize_tooltip_text_less:
    'Получай призы, чтобы собрать все детали и открыть секретные факты',
  prize_tooltip_text_full: 'На карте открылись новые факты',
  prize_open_secrets: 'Открыть секреты',
  prize_tooltip_title_empty: 'Это времяскок!',
  prize_tooltip_text_empty:
    'Получи 9 призов, чтобы открыть времяскок и узнать секретные факты',
  today: 'Сегодня',
  feedbackTitle: 'Напишите нам',
  yourName: 'Как вас зовут?',
  yourEmail: 'Email для ответа',
  feedBackTheme: 'О чем ваше письмо?',
  feedBackMessage: 'Сообщение',
  feedBackSentTitle: 'Спасибо за отзыв :)',
  feedBackSentText: 'Мы стараемся стать лучше',
  feedBackSentBtn: 'Отправить',
  feedBackOptions: {
    0: 'Выберите тему',
    1: 'Нужна помощь',
    2: 'Отзыв',
    3: 'Партнерство',
    4: 'Другое'
  }
};
