import React, { Component } from 'react';


export class DialogApprovement extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    let DialogApprove =
        <div className="inner_message">
            <h2 className="dialog-payment__title">Пароль успешно изменен</h2>
            <button className="btn btn_size_l btn_type_emphasis" onClick={this.props.hidePopUp}>Продолжить</button>
        </div>;

    return (
      DialogApprove
    )
  }
}
