import React from 'react';
import PropTypes from 'prop-types';
import { GameButton, GameLink } from '../../components';
import * as auth from '../../helpers/authenticationHelper';
import checkGameAccess from '../../helpers/checkGameAccess';

export const Games = (props) => {
  let gamesItem = null;
  const user = auth.getProfile() || {};
  const { showPopUp, gamesList } = props;

  if (gamesList && gamesList.length > 0) {
    gamesItem = gamesList
      .sort((a, b) => {
        if (a.isOpen && !b.isOpen) return -1;
        if (!a.isOpen && b.isOpen) return 1;
        if (a.isOpen && b.isOpen && a.isCompleted && !b.isCompleted) return 1;
        if (a.isOpen && b.isOpen && !a.isCompleted && b.isCompleted) return -1;
        return 1;
      })
      .map((game, index) => {
        // не обрабатывать игры не привязанные к уроку или маршруту
        if (!game.lesson && !game.quest && !game.share_bonus) return false;
        const previewImage = (game.lesson && game.lesson.preview_image.large) || game.without_lesson_image;
        const hasAccess = user && checkGameAccess(game);
        return (
          <li
            key={index}
            className={'games__item ' + (hasAccess ? 'games__item_type_open' : 'games__item_type_closed') }
            data-tags={`games ${game.type}`}
          >
            {hasAccess
              ? <GameLink
                  url={`/games/${game.id}`}
                  name={game.name}
                  index={index}
                  state={game.is_completed}
                  previewImage={previewImage}
                />
              : <GameButton
                  showPopUp={showPopUp}
                  name={game.name}
                  index={index}
                  game={game}
                />
            }
          </li>
        )
      });
  }

  return (
    <main className="games">
      <ul className="games__list js-game-list" data-filter="all">
        {gamesItem}
      </ul>
    </main>
  );
};

Games.propTypes = {
  gamesList: PropTypes.array.isRequired,
  showPopUp: PropTypes.func.isRequired
};
