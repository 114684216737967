import {Component} from 'react';
import queryString from 'query-string';
import {config} from '../../data'
import {
  makeAbsoluteUrl,
  redirect
} from '../../helpers'
import * as api from '../../api';
import * as auth from '../../helpers/authenticationHelper';

const getLocationQueryFromProps = props => queryString.parse(props.location.search);

const getCodeFromProps = props => {
  const query = getLocationQueryFromProps(props);
  return query && query.code;
}

export class UserSettingsAuthBindVk extends Component {
  render() {
    const code = getCodeFromProps(this.props);

    if (code) {
      this.bindAuth(code);
    }

    return null;
  }

  bindAuth(code) {
    const redirect_uri = makeAbsoluteUrl(config.social_auth.vk.bind_redirect_uri);

    api.bindVk(code, redirect_uri)
      .then(result => {
        result = result || true; // TODO:  @endenwer: Возвращать по API id профиля
        auth.bindVk(result);
        redirect(`/cabinet/${config.routes.settings}`);
      });
  }
}

export class UserSettingsAuthBindFb extends Component {
  render() {
    const code = getCodeFromProps(this.props);

    if (code) {
      this.bindAuth(code);
    }

    return null;
  }

  bindAuth(code) {
    const redirect_uri = makeAbsoluteUrl(config.social_auth.fb.bind_redirect_uri);

    api.bindFb(code, redirect_uri)
      .then(result => {
        result = result || true; // TODO:  @endenwer: Возвращать по API id профиля
        auth.bindFb(result);
        redirect(`/cabinet/${config.routes.settings}`);
      });
  }
}

export class UserSettingsAuthBindOk extends Component {
  render() {
    const code = getCodeFromProps(this.props);

    if (code) {
      this.bindAuth(code);
    }
    return null;
  }

  bindAuth(code) {
    const redirect_uri = makeAbsoluteUrl(config.social_auth.ok.bind_redirect_uri);

    api.bindOk(code, redirect_uri)
      .then(result => {
        result = result || true; // TODO:  @endenwer: Возвращать по API id профиля
        redirect(`/cabinet/${config.routes.settings}`);
      });
  }
}
