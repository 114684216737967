import {ajaxV2} from '../../helpers/index';

/**
 * @param {String} id
 * @param {String} category
 * @returns {Promise}
 */

export const getChildProducts = (id, category) => {
  return ajaxV2
    .get(`/children/${id}/products/` + (category ? '?category=' + category : ''))
    .then(response => {
      return response.data.map(e => e.product)
    })
    .catch(error => error);
};
