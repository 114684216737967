import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

export const Library = ({library}) => {
  return (
    <main className="library">
      <div className="library-list">
        <div>
          {library.filter((item, index) => index % 2 === 0 || index === 0).map((item, index) => {
              console.log(index)

            return <div key={item.category} className="library-block">
              <h3>{item.category}</h3>
              <ul>
                {item.lessons.map(e => {
                  return <li key={e.id}>
                    {e.is_finished && <div className="library-ok"/>}
                    <Link to={e.path} onlyActiveOnIndex>{e.name}</Link>
                  </li>
                })}
              </ul>
            </div>
          })}
        </div>
<div>
  {library.filter((item, index) => index % 2).map((item, index) => {

    return <div key={item.category} className="library-block">
      <h3>{item.category}</h3>
      <ul>
        {item.lessons.map(e => {
          return <li key={e.id}>
            {e.is_finished && <div className="library-ok"/>}
            <Link to={e.path} onlyActiveOnIndex>{e.name}</Link>
          </li>
        })}
      </ul>
    </div>
  })}
</div>

      </div>
    </main>
  );
};

Library.propTypes = {
  library: PropTypes.array
};
