import React, {Component} from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import {scaleMap, smoothScrollTo} from '../../helpers';

export class Map extends Component {
  mapRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      scale: scaleMap(),
      backgroundImageMap: 'linear-gradient(to bottom, #00B5ED 0%, #00B5ED 23%, #2679D1 23%, #6AD6F3 100%)'
    };

    this.scaleMap = this.scaleMap.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.newTranslateX !== this.props.newTranslateX) {
      setTimeout(() => {
        smoothScrollTo(this.mapRef.current, 0, 0, this.mapRef.current.scrollLeft, -this.props.newTranslateX, 300)
      }, 100);
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.scaleMap);
    this.scaleMap();
    this.moveMap();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.scaleMap);
  }

  moveMap() {
    let isDown = false;
    let startX;
    let scrollLeft;
    const {scale} = this.state;

    const scrollHorizontally = (e) => {
      e = window.event || e;
      e.preventDefault();
      let delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
      this.mapRef.current.scrollLeft -= 40 * delta * scale / window.devicePixelRatio;
    }

    // IE9, Chrome, Safari, Opera
    this.mapRef.current.addEventListener('mousewheel', scrollHorizontally, false);
    // Firefox
    this.mapRef.current.addEventListener('DOMMouseScroll', scrollHorizontally, false);

    this.mapRef.current.addEventListener('mousedown', (e) => {
      isDown = true;
      this.mapRef.current.classList.add('__scrolling');
      startX = e.pageX - this.mapRef.current.offsetLeft;
      scrollLeft = this.mapRef.current.scrollLeft;
    });
    this.mapRef.current.addEventListener('mouseleave', () => {
      isDown = false;
      this.mapRef.current.classList.remove('__scrolling');
    });
    this.mapRef.current.addEventListener('mouseup', () => {
      isDown = false;
      this.mapRef.current.classList.remove('__scrolling');
    });
    this.mapRef.current.addEventListener('mousemove', (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - this.mapRef.current.offsetLeft;
      const walk = (x - startX); //scroll-fast
      this.mapRef.current.scrollLeft = scrollLeft - walk;
    });
  }

  scaleMap() {
    let scaleValue = scaleMap();

    // масштабировать карту под размер экрана
    this.setState((prevState) => {
      prevState.scale = scaleValue;
      return prevState;
    });
  }

  render() {
    const {isQuestShown, children, itsToursPage, lessonsScreenType} = this.props;
    const {scale} = this.state;

    let cssStyle = {
      transform: `scale(${scale}) translate(0, -50%)`
    };

    if (isQuestShown) {
      cssStyle = {
        transform: `scale(${scale}) translateY(-50%)`
      };
    }

    document.documentElement.style.setProperty('--map-scale', `${scale}`);

    let shadowScale = scale > 1 ? 1 / scale : 1 * scale;

    return (
      <section
        ref={this.mapRef}
        className={c([
          'map',
          'custom_scrollbar',
          {
            'map_state_quest-shown': isQuestShown,
            'map_state_tour': itsToursPage,
            'map_state_hidden': lessonsScreenType === 'quest'
          }
        ])
        }
        //style={{
        //  backgroundImage: backgroundImageMap
        //}}
      >
        <style>
          {`.lesson-wrapper.not-ready .lesson {
                  filter: drop-shadow(${shadowScale}px ${shadowScale}px 0 #fff) drop-shadow(-${shadowScale}px -${shadowScale}px 0 #fff) drop-shadow(${shadowScale}px -${shadowScale}px 0 #fff) drop-shadow(-${shadowScale}px ${shadowScale}px 0 #fff)}`}
        </style>
        <div
          className="map__content"
          ref="mapContent"
          style={cssStyle}
        >
          {children}
        </div>
      </section>
    );
  }
}

Map.propTypes = {
  isQuestShown: PropTypes.bool
};
