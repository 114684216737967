import {
  TOGGLE_PRELOADER,
  TOGGLE_NAVBAR,
  GET_LESSON_DATA,
  GET_USER_ID,
} from "../actionTypes";

const initialState = {
  preloaderType: "",
  showNavbar: true,
  value: "data",
  id: "id",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TOGGLE_PRELOADER: {
      return {
        ...state,
        preloaderType: action.payload.type,
      };
    }

    case TOGGLE_NAVBAR: {
      return {
        ...state,
        showNavbar: action.payload.show,
      };
    }

    case GET_LESSON_DATA: {
      return {
        ...state,
        value: action.data_1,
      };
    }
    case GET_USER_ID: {
      return {
        ...state,
        id: action.id,
      };
    }
    default:
  }
  return state;
}
