import React from 'react';
import PropTypes from 'prop-types';

export const TestProgress = (props) => {

  const { questions, current } = props;

  let answers = questions.map((question, index) => {
    return (
      <li
        key={question.id}
        className={`test-progress__answer
          test-progress__answer_state_${question.error === false ? 'right' : 'wrong'}
          test-progress__answer_state_${current > index ? 'show' : 'hide'}
        `}
      >
      </li>
    )
  });

  let person = questions.map((question, index) => {

    //TODO: рефактор, возможно отдельно нужно добавить счётчик и отдельно questin_id
    // если начало теста, не показываем анимацию
    if (current === index && current === 0) {
      return (
        <div
          key={question.id}
          className='test-progress__person'
        >
        </div>
      )
    } else if (current-1 === index && current !== 0) {
      return (
        <div
          key={question.id}
          className={`test-progress__person
            test-progress__person_state_${question.error === false ? 'right_answer' : 'wrong_answer'}
          `}
        >
        </div>
      );

    // вконце теста показываем зацикленную анимацию
    } else if (current === questions.length && current === index + 1) {
      return (
        <div
          key={question.id}
          className={`test-progress__person test-progress__person_state_${question.error === false ? 'right_answer_twice' : 'wrong_answer_twice'}`}
        >
        </div>
      )
    }

    return null;
  });

  return (

    <div className='test-progress'>

      {person}

      <div className='test-progress__runner'/>

      <div className='test-progress__holders'>
      </div>

      <ul className="test-progress__answers">
        {answers}
      </ul>

    </div>
  );
};

TestProgress.propTypes = {
  questions: PropTypes.array,
  current: PropTypes.number,
};
