import { Component } from 'react';
import { redirect } from '../../helpers';
import queryString from 'query-string';
import { config } from '../../data'
import * as api from '../../api';
import { makeAbsoluteUrl } from '../../helpers'
import { handleAuthResponse } from '../../helpers/authenticationHelper'
import { connect } from 'react-redux';
import { closeAuthPopup, openAuthPopup } from '../../redux/actions';

class SocialAuthContainer extends Component {

  auth(code, type) {
    let apiCaller;
    let redirect_uri;

    switch (type) {
      case 'vk':
        apiCaller = api.authVk;
        redirect_uri = makeAbsoluteUrl(config.social_auth.vk.login_redirect_uri);
        break;
      case 'fb':
        apiCaller = api.authFb;
        redirect_uri = makeAbsoluteUrl(config.social_auth.fb.login_redirect_uri);
        break;
      default:
        //;
    }

    const auth = ({ fb: api.authFb, vk: api.authVk })[type];
    if (typeof auth !== 'function') return;

    apiCaller(code, redirect_uri)
      .then(result => {
        if (result.jwt) {
          // Пользователь уже есть
          handleAuthResponse(result);
          if (result.is_registration) {
            window.ga('send', 'event', 'all', 'registration');
            // todo gtag
            //window.gtag('event', 'conversion', { 'send_to': 'AW-578549561/Ah3QCMvJh-ABELnu75MC' });
          }
          redirect('/');

          //  Новый пользователь
        } else if (result.email) {
          redirect(`/?email=${result.email}`);
          this.props.closeAuthPopup('login');
          this.props.openAuthPopup('signup');
          // Не пришел ответ, показать ошибку и перенаправить на регистрацию
        } else {
          this.props.closeAuthPopup('login');
          this.props.openAuthPopup('signup');
        }
      })
      .catch(() => {
        this.props.closeAuthPopup('login');
        this.props.openAuthPopup('signup');
      })
  }

  render() {
    const params = this.props.match.params;
    const query = queryString.parse(this.props.location.search);

    const type = params && params.type;
    const code = query && query.code;

    this.auth(code, type);

    return null;
  }
}

export default connect(null, {
  closeAuthPopup,
  openAuthPopup
})(SocialAuthContainer);
