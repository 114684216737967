import React, {Component} from 'react';

import {
  Hero
} from '../../components';


export class PopupSuccessReg extends Component {

  constructor(props) {
    super(props);

    this.state = {};

  }

  componentWillUnmount() {

  }

  render() {
    return (
      <div className="popup-success-reg">
        <Hero width={275} height={360} animate={true} cssClass={'chevostik_hero'} name={'chevostik_wtf'}/>
        <Hero width={455} height={345} animate={true} cssClass={'rocket_horns'} name={'rocket_horns'}>
          <div className="popup-success-reg__rocket--svg">
            <svg preserveAspectRatio="xMidYMid meet" viewBox="0 0 350 300">
              <foreignObject width="100%" height="100%">
                {/*<div className="popup-success-reg__up"/>*/}
                <h3>Спасибо <br/> за регистрацию!</h3>
                <p>Начни с маршрутов – <br/> системы знаний по темам</p>
              </foreignObject>
            </svg>
          </div>
        </Hero>
      </div>
    )
  }
}
