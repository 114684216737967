import React, {Component} from 'react';
import {withRouter} from 'react-router'
import {redirect} from '../../helpers';
import Popup from '../../components/popup/popup';

class AuthContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onAuthPopupClose() {
    redirect('/');
  }

  render() {
    return (
      <div className="auth__popups">
        <Popup
          is_closed={false}
          item={{}}
          hidePopUp={this.onAuthPopupClose}
          location={this.props && this.props.location}
        />
      </div>
    );
  }
}

const componentWithRouter = withRouter(AuthContainer)
export {componentWithRouter as AuthContainer}
