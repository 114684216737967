import React from 'react';
import PropTypes from 'prop-types';
import { config } from '../../data';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import * as auth from '../../helpers/authenticationHelper';
import CloseIcon from './svg_icon/close';

export const GameButton = (props) => {
  const {showPopUp, game, index , name} = props;

  let userIsRegistered = auth.getProfile();

  let userHasSubscription = userIsRegistered && userIsRegistered.subscription && userIsRegistered.subscription.status !== 'off';
  let status = 'user_not_auth';
  let url = `${config.routes.enter}`;
  let previewImage = (game.lesson && game.lesson.preview_image.large) || game.without_lesson_image;
  let isFree = game.lesson && game.lesson.access.is_free;
  let isQuestFinished = game.quest && game.quest.is_finished;
  let isOpen = game.lesson && game.lesson.access.is_open;
  let isTestFinished = game.lesson && game.lesson.test && game.lesson.test.is_completed;

  if (!userIsRegistered) {
      status = 'user_not_auth';
  }

  if (userIsRegistered && userHasSubscription) {
    let isShare = game.share_bonus && !userIsRegistered.shared_link ;

    if (((!isOpen && game.lesson) || (!isQuestFinished && !game.lesson)) && !isShare) {
      previewImage = config.games_popup_preview_routes;

      if (game.lesson && game.lesson.quest && game.lesson.quest.id){
        status = 'routes';
        url = `/${config.routes.quests}/${game.lesson.quest.id}`;

      } else {
        status = 'logical_chain';
        url = `/cabinet/child/${auth.getCurrentChild()}/quests`;
      }
    }

    if (isFree && isOpen && !isTestFinished) {
      status = 'lesson_is_free';
      url = `${game.lesson.id}`;
    }

    if (!isFree && isOpen && !isTestFinished) {
      status = 'lesson_or_test_not_finished';
      url = `${game.lesson.id}`;
    }

    if (isShare) {
      status = 'not_shared';
    }
  }

  if (userIsRegistered && !userHasSubscription) {
    status = 'not_paid_subscription'

    let isShare = game.share_bonus && !userIsRegistered.shared_link ;

    if (isFree && isOpen && !isTestFinished) {
      status = 'lesson_or_test_not_finished';
      url = `${game.lesson.id}`;
    }

    if (!isFree && isOpen && !isTestFinished) {
      status = 'not_paid_subscription';
      url = `${game.lesson.id}`;
    }

    if (isShare) {
      status = 'not_shared';
    }


  }

  return (
    <button
      type="button"
      onClick={showPopUp}
      className='games__item-inner'
      data-status={status}
      data-lesson-preview={`${previewImage}`}
      data-url={url}
    >
      <span className="games__item-img-wrapper">
        <CloseIcon className="games__icon games__icon_close" id={index} />
        <LazyLoadImage
          className="games__item-img"
          alt={previewImage}
          src={`${config.base_url}${previewImage}`}
          placeholderSrc="../img_static/small-loader.svg"
        />

      </span>

      <div className='games__item-name'>{name}</div>
    </button>
  );
};

GameButton.propTypes = {
  game: PropTypes.object,
  showPopUp: PropTypes.func,
};
