import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { isPhone, redirect } from '../../helpers';
import * as auth from '../../helpers/authenticationHelper';
import { i18n, config } from '../../data';
import Popup from '../../components/popup/popup';

import {
  PageTooltip
} from '../../components';
import Footer from '../../components/footer/footer';

export class MainNav extends Component {

  constructor(props) {
    super(props);

    this.state = {
      is_open: false,
      is_popup_closed: true,
      is_focused: false
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.showPopup = this.showPopup.bind(this);
  }

  render() {
    let item = {section: 'check'};
    let mainNavComponents;
    let mobileMenu = null;
    let page = null;
    let popup = <Popup
      is_closed={this.state.is_popup_closed}
      item={item}
      location={this.props && this.props.location}
      hidePopUp={this.hidePopUp.bind(this)}
      focus={this.state.is_focused}
    />;

    const {
      prizes,
      tooltip
    } = this.props;

    if (this.props.navList && this.props.navList.length > 0) {
      mainNavComponents = this.props.navList.map((navList, index) => {
        let tooltipElem, prizesElem;

        switch (navList.url) {
          case '/prizes':
            // eslint-disable-next-line
            if (this.props.numberTour === 6) return;

            if (prizes) {
              let prizesList = prizes.map( (prize, index) => {
                return (<li className="prizes-list__item" style={{backgroundImage: 'url(' + config.base_url + prize.image_for_map + ')'}} key={index} />);
              } );
              prizesElem = (
                <ul className="prizes-list">
                  {prizesList}
                </ul>
              );
            }

            if (tooltip && Object.keys(tooltip).length) {
              tooltipElem = (<PageTooltip
                title={tooltip.title}
                message={tooltip.message}
                position={tooltip.position}
                autoKill={15000} />
              );
            }
            return (
              <div className={`main-nav__item ${navList.class_name}`} key={index} >
                {!isPhone() && prizesElem}
                <Link
                  to={`${navList.url}`}
                  className="main-nav__link"
                >
                  {navList.name === i18n.games && isPhone() ? i18n.not_available_at_home : navList.name}
                </Link>
                {tooltipElem}
              </div>
            );
          case '/cabinet':
            return (
              <button
                className={`main-nav__item ${navList.class_name}`}
                key={index}
                // onClick={this.showPopup}
                onClick={() => redirect('/cabinet')}
              >
                {navList.name === i18n.games && isPhone() ? i18n.not_available_at_home : navList.name}
              </button>
            );
          default:
            return (
              <span
                onClick={() => redirect(navList.url)}
                className={`main-nav__item ${navList.class_name}`}
                key={index}
              >
                {navList.name === i18n.games && isPhone() ? i18n.not_available_at_home : navList.name}
              </span>
            );
        }
      });
    } else {
      mainNavComponents = <li>{i18n.loading}</li>
    }

    if (this.props.mobileMenu){
      mobileMenu = <div className="main-nav__controls">
          <button
            type="button"
            className={`main-nav__btn main-nav__btn_state_${this.state.is_open ? 'open' : 'close'}`}
            onClick={this.toggleMenu}
          ></button>
        </div>;

      page = <nav className={`main-nav
        main-nav_state_${isPhone()? 'phone' : 'no-phone'}
        main-nav_state_${this.state.is_open ? 'phone-open' : 'phone-close'}
      `}>

        {mobileMenu}

        <div className="main-nav__content">
          <div className="main-nav__links">
            {mainNavComponents}
          </div>

          { isPhone() && !auth.getProfile() ? <Footer modifier="main-nav"/> : ''}
        </div>
      </nav>
    } else {
      page = <nav className='main-nav'>
        {mainNavComponents}
        </nav>
    }

    return (<div>
      {popup}
      {page}
      </div>);
  }

  toggleMenu() {
    this.setState({
      is_open: !this.state.is_open
    })
  }

  showPopup() {
    this.setState({
      is_popup_closed: false,
      is_focused: true
    })
  }

  hidePopUp() {
    this.setState({
      is_popup_closed: true
    })
  }
}

MainNav.propTypes = {
  navList: PropTypes.array,
  mobileMenu: PropTypes.bool
};
