/**
 * @param {HTMLElement} domNode в дом ноде, в псевдоэлементе храниться ссылка на спрайт
 * @returns {string} имя картинки с хэшем
 */
export const getImageName = (domNode) => {
  return window
    .getComputedStyle(domNode, '::before')
    .getPropertyValue('background-image')
    .match(/([A-z\-\.0-9]+)\"?\)/)[1]; // eslint-disable-line
};
