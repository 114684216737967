import React, {Component} from 'react';
import {setPreloader} from '../../redux/actions';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {config, PUZZLES_OUTLINES, PUZZLES, PUZZLE_FRAME} from '../../data';
import * as api from '../../api';
import * as auth from '../../helpers/authenticationHelper';
import {onEscapeKeyPress, redirect} from '../../helpers';

import {
  isPhone,
  checkImageLoad,
  checkImagesLoad,
  getImageName,
  isTablet
} from '../../helpers'

import {
  MemoryGame,
  PickUpSilhouette,
  CollectPuzzle,
  CollectItem,
  CollectItemV2,
  LessonPostLoader,
  LogicalChain,
  FindHiddenItems
} from '../../components';
import checkGameAccess from '../../helpers/checkGameAccess';
import {setGameStatus} from '../../api/games/set_game_status';

let audioGameLoop = null;

class GameContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image_loaded: false,
      is_game_finished: false,
      game_type: 'loading',
      games_url: config.routes.games,
      game_levels: [],
      pre_loader_type: 'game',
      show_post_loader: false,
      post_loader_class: '',
      is_audio_playing: true,
      counter: 0,
      startTimeGame: 0,
      timer: null,
      counterdown: 0,
      isShowNextFrameButton: true,
      popupGame: {
        status: '',
        type: '',
        preview: '',
        url: ''
      },
      isPopupClosed: true
    };

    this.showPostLoader = this.showPostLoader.bind(this);
    this.hidePostLoader = this.hidePostLoader.bind(this);
    this.setImagesLoaded = this.setImagesLoaded.bind(this);
    this.toggleAudioPlayingState = this.toggleAudioPlayingState.bind(this);
    this.uploadPickUpSilhouetteImg = this.uploadPickUpSilhouetteImg.bind(this);
    this.uploadMemoryGame = this.uploadMemoryGame.bind(this);
    this.getGameAndHideProLoader = this.getGameAndHideProLoader.bind(this);
    this.uploadCollectPuzzle = this.uploadCollectPuzzle.bind(this);
    this.uploadCollectItem = this.uploadCollectItem.bind(this);
    this.uploadCollectItemV2 = this.uploadCollectItemV2.bind(this);
    this.uploadLogicalChain = this.uploadLogicalChain.bind(this);
    this.uploadFindHiddenHero = this.uploadFindHiddenHero.bind(this);
    this.uploadFindHiddenItems = this.uploadFindHiddenItems.bind(this);
    this.setStartTimeGame = this.setStartTimeGame.bind(this);

    this.showNextFrame = this.showNextFrame.bind(this);
  }

  componentWillMount() {
    audioGameLoop = new Audio('/audio/game_loop.mp3');

    // Включает звук у теста, зацикленный.
    audioGameLoop.loop = true;
    this.setState({
      is_audio_playing: true
    });
  }

  componentDidMount() {
    this.props.setPreloader('');
    this.getGameAndHideProLoader();

    window.updateGameData = (info) => {
      setGameStatus('snake', info).then(data => {
        window.devMode && console.log('setGameStatus', data);
      })
    }
  }

  componentWillUnmount() {
    window.updateGameData = null;
    audioGameLoop.pause();
    document.documentElement.classList.remove('has-vertical');
  }

  isGodotGame(type) {
    let godotGames = ['collect-puzzle', 'find-hidden-hero', 'find-hidden-item', 'memory-game', 'pick-up-silhouette', 'collect-item'];
    return godotGames.indexOf(type) !== -1;
  }

  render() {
    let page = null;
    let isGameLoaded = this.state.image_loaded && this.state.game;

    if ((audioGameLoop && this.state.game && !this.isGodotGame(this.state.game.type))) {
      this.state.is_audio_playing
        ? audioGameLoop.play()
        : audioGameLoop.pause();
    }

    if (this.state.game && this.state.game.type === config.game_types.memory_game) {
      page = <MemoryGame
        showPostLoader={this.showPostLoader}
        gameData={this.state.game.data}
        image={this.state.game_image}
        setStartTimeGame={this.setStartTimeGame}
      />
    }

    if (isGameLoaded && this.state.game.type === config.game_types.pick_up_silhouette) {
      page = <PickUpSilhouette
        showPostLoader={this.showPostLoader}
        figures={this.state.figures}
        image={this.state.game_image}
        figuresOutline={this.state.figuresOutline}
        background={this.state.background}
        counterDown={this.state.game.counterdown}
      />
    }

    if (isGameLoaded && this.state.game.type === config.game_types.collect_puzzle) {
      page = <CollectPuzzle
        showPostLoader={this.showPostLoader}
        puzzlesOutline={this.state.puzzlesOutline}
        puzzles={this.state.puzzles}
        image={this.state.game_image}
        frame={this.state.frame}
        counterDown={this.state.game.counterdown}
      />
    }

    if (isGameLoaded && this.state.game.type === config.game_types.collect_item) {
      page = <CollectItem
        showNextFrame={this.showNextFrame}
        isShowNextLevelButton={this.state.isShowNextFrameButton}
        fragments={this.state.fragments}
        image={this.state.game_image}
        fragmentsOutline={this.state.fragmentsOutline}
        counterDown={this.state.game.countdown}
        timer={this.state.timer}
      />
    }

    if (isGameLoaded && this.state.game.type === config.game_types.collect_item_v2) {
      page = <CollectItemV2
        showNextFrame={this.showNextFrame}
        isShowNextLevelButton={this.state.isShowNextFrameButton}
        fragments={this.state.fragments}
        image={this.state.game_image}
        fragmentsOutline={this.state.fragmentsOutline}
        counterDown={this.state.game.countdown}
        timer={this.state.timer}
      />
    }

    if (isGameLoaded && this.state.game.type === config.game_types.logical_chain) {
      page = <LogicalChain
        showNextFrame={this.showNextFrame}
        image={this.state.game_image}
        chains={this.state.chains}
      />
    }

    if (isGameLoaded && this.state.game.type === config.game_types.find_hidden_item) {
      page = <FindHiddenItems
        items={this.state.gameItems}
        background={this.state.background}
        showPostLoader={this.showPostLoader}
        css={this.state.game.data.game_css}
      />
    }

    if (this.state.game && this.isGodotGame(this.state.game.type)) {
      document.documentElement.classList.add('has-vertical');
      page = <div className="game-holder">
        <iframe src={'../godot/index.html'} width="100%" height="100%" />
      </div>
    } else {
      document.documentElement.classList.remove('has-vertical');
    }

    return (
      <div className={`game-page game-page_state_${isPhone() ? 'phone' : 'not-phone'}`}>

        {page}

        {this.state.show_post_loader ?
          <LessonPostLoader
            gameUrl={this.state.game.id}
            gamesUrl={this.state.games_url}
            startAgain={this.hidePostLoader}
            isGameFinished={this.state.is_game_finished}
            postLoaderType={this.state.post_loader_class}
          /> : null}
      </div>
    );
  }

  /**
   * @param {string} className
   */
  showPostLoader(className) {
    let gameId = this.state.game.id;
    const startTime = this.state.startTimeGame;
    let audio = new Audio('/audio/game_success.mp3');
    audio.play();

    api.postGameHistory(gameId, auth.getCurrentChild(), Date.now() - startTime);

    this.setState({
      show_post_loader: true,
      is_game_finished: true,
      post_loader_class: className,
      counter: 0
    });
    if (this.state.show_post_loader === true) {
      this.setState({
        is_audio_playing: false
      });
    }
  }

  /**
   * Переключает состояние аудио (играет/не играет)
   */
  toggleAudioPlayingState() {
    this.setState({
      is_audio_playing: !this.state.is_audio_playing
    });
  }

  hidePostLoader() {
    this.setState({
      is_audio_playing: false
    });
    this.setState({
      show_post_loader: false,
      game: {},
      image_loaded: false
    }, () => {
      this.props.setPreloader('');
      this.getGameAndHideProLoader()
    })
  }

  getGameAndHideProLoader() {
    let currentChildId = auth.getCurrentChild();

    if (currentChildId) {
      api.getGame(currentChildId, this.props.match.params.id_game)
        .then((game) => {
          if (typeof (game) === 'undefined') return

          let gameImage = '/img/map/map.jpg';

          if (game.lesson) {
            gameImage = game.lesson.preview_image.large;
          }

          this.setState({
            game_image: gameImage,
            post_loader_class: game.type,
            game
          }, () => {
            if (!checkGameAccess(game)) {
              this.showPopUp();
            }

            if (!this.isGodotGame(this.state.game.type)) {
              switch (this.state.game.type) {
                case config.game_types.memory_game:
                  this.uploadMemoryGame();
                  break;
                case config.game_types.pick_up_silhouette:
                  this.uploadPickUpSilhouetteImg();
                  break;
                case config.game_types.collect_puzzle:
                  this.uploadCollectPuzzle();
                  break;
                case config.game_types.collect_item:
                  this.uploadCollectItem();
                  break;
                case config.game_types.collect_item_v2:
                  this.uploadCollectItemV2();
                  break;
                case config.game_types.logical_chain:
                  this.uploadLogicalChain();
                  break;
                case config.game_types.find_hidden_hero:
                  this.uploadFindHiddenHero();
                  break;
                case config.game_types.find_hidden_item:
                  this.uploadFindHiddenItems();
                  break;
                default:
                  break;
              }
            }
          });
        });
    } else {
      redirect();
    }
  }

  uploadMemoryGame() {
    let domNode = document.querySelector(`.${this.state.game.type}`);
    let gameSpriteImg = getImageName(domNode);
    let gameSpriteUrl = `${config.sprite_directory}${gameSpriteImg}`;

    checkImagesLoad(gameSpriteUrl).then(() => this.setImagesLoaded())
  }

  async uploadPickUpSilhouetteImg() {

    let figures = [];
    let figuresOutline = [];

    let backgroundImg = await new Promise((resolve, reject) => {
      resolve(checkImageLoad(`${config.base_url}${this.state.game.data.background}`))
    });

    let background = {
      link: `${config.base_url}${this.state.game.data.background}`,
      width: backgroundImg.width,
      height: backgroundImg.height
    };
    for (let figureIndex in this.state.game.data.figures) {
      let item = this.state.game.data.figures[figureIndex];

      let figuredImg = await new Promise((resolve, reject) => {
        resolve(checkImageLoad(`${config.base_url}${item.figure}`))
      });

      let figure = {
        width: figuredImg.width,
        height: figuredImg.height,
        link: `${config.base_url}${item.figure}`,
        options: {
          name: figureIndex
        }
      };

      let figureOutlineImg = await new Promise((resolve, reject) => {
        resolve(checkImageLoad(`${config.base_url}${item.figure_outline}`))
      });

      let figureOutline = {
        width: figureOutlineImg.width,
        height: figureOutlineImg.height,
        link: `${config.base_url}${item.figure_outline}`,
        options: {
          name: figureIndex,
          x: item.outline_x,
          y: item.outline_y
        }
      };

      figures.push(figure);
      figuresOutline.push(figureOutline);
    }

    //NOTE: Здесь можно менять координаты силуэтов
    //Пример: figuresOutline[0].options.x = 1200;
    this.setState({
      figures,
      figuresOutline,
      background,
      image_loaded: true,
      startTimeGame: Date.now()
    }, () => {
      if (!this.isGodotGame(this.state.game.type)) {
        this.props.setPreloader('');
      }
    });
  }

  async uploadCollectPuzzle() {
    const puzzleOffsetXTablet = isTablet() ? 330 : 0;
    const puzzleOffsetYTablet = isTablet() ? 50 : 0;
    const puzzleOutlineOffsetXTablet = isTablet() ? 150 : 0;
    const puzzleOutlineOffsetYTablet = isTablet() ? 50 : 0;

    let puzzles = [];
    let puzzlesOutline = [];

    let framedImg = await new Promise((resolve, reject) => {
      resolve(checkImageLoad(`${config.base_url}${this.state.game.data.frame}`))
    });

    const frame = {
      link: `${config.base_url}${this.state.game.data.frame}`,
      width: framedImg.width,
      height: framedImg.height,
      options: {
        x: PUZZLE_FRAME.x - puzzleOutlineOffsetXTablet,
        y: PUZZLE_FRAME.y + puzzleOutlineOffsetYTablet
      }
    };

    for (let puzzleOutlineIndex in PUZZLES_OUTLINES) {
      let item = PUZZLES_OUTLINES[puzzleOutlineIndex];

      let puzzleOutlineImg = await new Promise((resolve, reject) => {
        resolve(checkImageLoad(`${config.base_url}${item.img}`))
      });

      puzzlesOutline.push({
        width: puzzleOutlineImg.width,
        height: puzzleOutlineImg.height,
        link: `${config.base_url}${item.img}`,
        options: {
          x: item.x - puzzleOutlineOffsetXTablet,
          y: item.y + puzzleOutlineOffsetYTablet
        }
      });
    }

    for (let puzzleIndex in this.state.game.data.puzzles) {
      let item = this.state.game.data.puzzles[puzzleIndex];

      let puzzleImg = await new Promise((resolve, reject) => {
        resolve(checkImageLoad(`${config.base_url}${item.puzzle}`))
      });

      puzzles.push({
        width: puzzleImg.width,
        height: puzzleImg.height,
        link: `${config.base_url}${item.puzzle}`,
        options: {
          x: PUZZLES[puzzleIndex].x - puzzleOffsetXTablet,
          y: PUZZLES[puzzleIndex].y + puzzleOffsetYTablet
        }
      });
    }

    this.setState({
      puzzlesOutline,
      puzzles,
      frame,
      image_loaded: true,
      startTimeGame: Date.now()
    }, () => {
      if (!this.isGodotGame(this.state.game.type)) {
        this.props.setPreloader('');
      }
    })
  }

  setStartTimeGame() {
    this.setState({startTimeGame: Date.now()})
  }

  showNextFrame(className, timer = null, timeout = false) {
    const data = this.state.game.data || this.state.game.game_datas;
    if (data.length === this.state.counter + 1 ||
      (this.state.game_levels && this.state.game_levels.length === this.state.counter + 1)
    ) {
      this.showPostLoader(className)
    } else {
      this.setState({
        counter: timeout ? 0 : this.state.counter + 1,
        timer,
        image_loaded: false,
        fragments: [],
        fragmentsOutline: []
      }, () => {
        this.props.setPreloader('');
        if (this.state.game.type === config.game_types.collect_item) {
          this.uploadCollectItem()
        }
        if (this.state.game.type === config.game_types.collect_item_v2) {
          this.uploadCollectItemV2()
        }
        if (this.state.game.type === config.game_types.logical_chain) {
          this.uploadLogicalChain()
        }
      });
    }
  }

  async getCollectItemFragment(item, imageKeyName) {
    let itemFragmentImg = await new Promise((resolve, reject) => {
      resolve(checkImageLoad(`${config.base_url}${item[imageKeyName]}`))
    });
    return {
      link: `${config.base_url}${item[imageKeyName]}`,
      width: itemFragmentImg.width,
      height: itemFragmentImg.height,
      options: {
        x: 1,
        y: 1
      }
    };
  }

  async getCollectItemFragmentOutline(item, imageKeyName) {
    let itemFragmentOutlineImg = await new Promise((resolve, reject) => {
      resolve(checkImageLoad(`${config.base_url}${item[imageKeyName]}`))
    });
    return {
      link: `${config.base_url}${item[imageKeyName]}`,
      width: itemFragmentOutlineImg.width,
      height: itemFragmentOutlineImg.height,
      options: {
        x: item.x_outline,
        y: item.y_outline
      }
    };
  }

  async getCollectItemFragmentOutline_v2(item, imageKeyName) {
    let itemFragmentOutlineImg = await new Promise((resolve, reject) => {
      resolve(checkImageLoad(`${config.base_url}${item[imageKeyName]}`))
    });
    return {
      link: `${config.base_url}${item[imageKeyName]}`,
      width: itemFragmentOutlineImg.width,
      height: itemFragmentOutlineImg.height,
      options: {
        x: item.outline_x,
        y: item.outline_y
      }
    };
  }

  async uploadCollectItem() {
    let counter = this.state.counter;
    let itemFragments = [];
    let itemFragmentsOutline = [];
    let levels = [];

    // this.state.game.data[counter].fragments
    // Загружаем картинки по очереди:
    for (let fragmentIndex in this.state.game.data[counter].fragments) {
      let item = this.state.game.data[counter].fragments[fragmentIndex];
      itemFragments.push(await this.getCollectItemFragment(item, 'img'));
      itemFragmentsOutline.push(await this.getCollectItemFragmentOutline(item, 'img_outline'));
    }
    this.setState({
      fragments: itemFragments,
      fragmentsOutline: itemFragmentsOutline,
      image_loaded: true,
      game_levels: levels,
      pre_loader_type: this.state.game.type,
      startTimeGame: Date.now(),
      isShowNextFrameButton: this.state.game.data.length - 1 > counter
    }, () => {
      if (!this.isGodotGame(this.state.game.type)) {
        this.props.setPreloader('');
      }
    });
  }

  async uploadCollectItemV2() {
    let counter = (this.state.counter + 1).toString();
    let itemFragments = [];
    let itemFragmentsOutline = [];
    let levels = [];

    // Загружаем картинки по очереди:
    for (let fragmentIndex in this.state.game.game_datas) {
      let item = this.state.game.game_datas[fragmentIndex];
      if (-1 !== item.level.indexOf(counter)) {
        itemFragments.push(await this.getCollectItemFragment(item, 'image'));
        itemFragmentsOutline.push(await this.getCollectItemFragmentOutline_v2(item, 'image_outline'));
      }
      item.level.map(lv => {
        if (!levels.includes(lv)) {
          levels.push(lv);
        }
      });
    }

    this.setState({
      fragments: itemFragments,
      fragmentsOutline: itemFragmentsOutline,
      image_loaded: true,
      game_levels: levels,
      pre_loader_type: this.state.game.type,
      startTimeGame: Date.now(),
      isShowNextFrameButton: /*this.state.*/levels.length > counter
    }, () => {
      if (!this.isGodotGame(this.state.game.type)) {
        this.props.setPreloader('');
      }
    });
  }

  uploadLogicalChain() {
    let counter = this.state.counter;
    let items = [];

    this.state.game.data[counter].items.forEach(item => {
      items.push({
        link: `${config.base_url}${item.img}`,
        options: {
          is_excess: !!item.is_excess
        }
      })
    });

    checkImagesLoad(items).then(result => {
      this.setState({
        chains: result,
        image_loaded: true,
        pre_loader_type: this.state.game.type,
        startTimeGame: Date.now()
      }, () => {
        if (!this.isGodotGame(this.state.game.type)) {
          this.props.setPreloader('');
        }
      })
    });
  }

  uploadFindHiddenHero() {
    if (this.state.game.type === 'find-hidden-hero') {
      return
    }

    let scene = [];
    let heroes = [];
    let background = `${config.base_url}${this.state.game.data.background}`;

    this.state.game.data.heroes.forEach(item => {
      heroes.push(`${config.base_url}${item}`)
    });

    this.state.game.data.scene.forEach(item => {
      scene.push(`${config.base_url}${item}`)
    });

    Promise.all([
      checkImagesLoad(scene),
      checkImagesLoad(heroes),
      checkImagesLoad(background)
    ]).then(result => {
      this.setState({
        scene: result[0],
        heroes: result[1],
        background: result[2],
        image_loaded: true,
        startTimeGame: Date.now()
      }, () => {
        if (!this.isGodotGame(this.state.game.type)) {
          this.props.setPreloader('');
        }
      });
    });
  }

  uploadFindHiddenItems() {
    let gameItems = [];
    let background = `${config.base_url}${this.state.game.data.background}`;

    this.state.game.data.items.forEach(item => {
      gameItems.push(`${config.base_url}${item}`)
    });

    Promise.all([
      checkImagesLoad(gameItems),
      checkImagesLoad(background)
    ]).then(result => {
      this.setState({
        gameItems: result[0],
        background: result[1],
        image_loaded: true,
        startTimeGame: Date.now()
      }, () => {
        if (!this.isGodotGame(this.state.game.type)) {
          this.props.setPreloader('');
        }
      });
    });

  }

  setImagesLoaded() {
    this.setState({image_loaded: true}, () => {
      if (!this.isGodotGame(this.state.game.type)) {
        this.props.setPreloader('');
      }
    })
  }

  /**
   * Get lesson url
   * @returns {string}
   */
  getLessonLink = () => {
    let url = `${config.base_url}/lessons/`;

    if (typeof this.state.game !== 'undefined') {
      url += this.state.game.lesson.id;
    }

    return url;
  };

  /**
   * Get lesson preview image
   * @returns {string}
   */
  getLessonPreview = () => {
    if (typeof this.state.game === 'undefined') {
      return `${config.base_url}/img/map/map.jpg`;
    }

    return this.state.game.lesson.preview_image.large;
  };

  /**
   * Show popup
   */
  showPopUp = () => {
    window.addEventListener('keydown', (e) => onEscapeKeyPress(e, this.hidePopUp));

    this.setState({
      popupGame: {
        status: 'lesson_or_test_not_finished',
        section: 'games',
        preview: this.getLessonPreview(),
        url: this.getLessonLink()
      },
      isPopupClosed: false
    });
  };

  /**
   * Hide popup
   */
  hidePopUp = () => {
    window.removeEventListener('keydown', e => onEscapeKeyPress(e, this.hidePopUp));

    redirect(this.getLessonLink());
  };
}

const mapDispatchToProps = {
  setPreloader
};

const componentWithRouter = withRouter(connect(null, mapDispatchToProps)(GameContainer))

export {componentWithRouter as GameContainer};
