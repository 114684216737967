import { ajax } from '../../helpers';
import React from 'react';
import { render } from 'react-dom';

import {
  ErrorPage
} from '../../containers';

/**
 * @typedef {Object} Message
 */

/**
 *
 * @param {Object} feedback
 * @returns {Promise.<Message>}
 */
export const postFeedback = (feedback) => {
  return ajax
    .post('/feedback', feedback)
    .then(response => response)
    .catch(err => {
      console.error('postFeedback error', postFeedback) /*eslint-enable */

      if (err.response.status === 404) {
        render(
          <ErrorPage status={404} />,
          document.getElementById('root')
        );
      }
    })
};
