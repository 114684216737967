import React, {Component} from 'react';
import update from 'immutability-helper'; // https://github.com/kolodny/immutability-helper
import * as api from '../../api';
import * as auth from '../../helpers/authenticationHelper';
import {
  redirect,
  checkImagesLoad,
  getImageName
} from '../../helpers'
import {config} from '../../data';
import {
  TestQuestion,
  TestRadio,
  TestProgress,
  TestFinishScreen,
} from '../../components';
import {connect} from 'react-redux';
import MapNav from '../../components/map-nav/map-nav';
import {setPreloader} from '../../redux/actions';
import {withRouter} from 'react-router';

/**
 * Константы для таймеров
 *
 * @type {Object}
 */
const TIMINGS = {
  RENDER_RESULT_TIME_DELAY: 1,
  ANIMATION_TIME_PLAY: 1700,
  NEXT_QUESTION_TIME_DELAY: 2200,
  POST_LOADER_TIME_DELAY: 5600,
  BUTTON_DISABLER: 500
};

// Меняем фон и добавляем объекты на данный тест

let audioTestLoop = null;

// HACK: autostartPlay for Iphone
const audioHacker = new Audio();
const playHackAudio = () => {
  audioHacker.play()
  window.removeEventListener('click', playHackAudio)
}
window.addEventListener('click', playHackAudio)

class TestContainer extends Component {
  constructor(props) {
    super(props);

    //TODO: 2) нужно ли свойство post_loader_class?
    this.state = {
      coins: 0,
      counter: 0,
      points: 0,
      child_points: 0,
      forceUpdate: 0,
      is_finished: false,
      is_finished_success: false,
      show_lototron: false,
      image_loaded: false,
      is_animation_playing: false,
      is_audio_finished: true,
      is_game_finished: false,
      post_loader_class: '',
      pre_loader_type: 'test',
      lessonsScreenType: 'test',
      prize_url: '',
      prize_name: '',
      lesson_icon: null,
      questionId: 0,
      show_post_loader: false,
      isQuestionRight: false,
      exchangeView: false,
      backgroundTest: '',
      result: []
    };

    this.handleAnswerSelected = this.handleAnswerSelected.bind(this);
    this.showPostLoader = this.showPostLoader.bind(this);
    this.startAgain = this.startAgain.bind(this);
    this.setAudioVolume = this.setAudioVolume.bind(this);
  }

  setAudioVolume(level = 0) {
    audioTestLoop.volume = level
  }

  componentWillMount() {
    audioTestLoop = new Audio('/audio/test_loop.mp3');
  }

  componentDidMount() {
    this.props.setPreloader('test');
    const currentChildId = auth.getCurrentChild();
    const lessonId = this.props.match.params.id;

    audioTestLoop.loop = true;
    audioTestLoop.play();

    if (currentChildId && lessonId) {
      Promise.all([
        api.getTest(currentChildId, this.props.match.params.id_test),
        api.getLesson(currentChildId, lessonId)
      ]).then(result => {
        // Если это 91 урок и 90 тест, то добавляем дополнительные объекты
        this.changeView(result);
        this.setState({
          test: result[0],
          game_id: result[1].game.id,
          lesson_icon: result[1].map && result[1].map.icon,
          backgroundTest: (result[1].preview_image && result[1].preview_image.large) || '/img/test/bg_test.jpg'
        }, () => {
          let testSpriteImg = getImageName(this.refs.testPage);
          let testSpriteUrl = `${config.sprite_directory}${testSpriteImg}`;

          checkImagesLoad(testSpriteUrl)
            .then(result => this.setState({image_loaded: true}, () => {
              this.props.setPreloader('');
            }))
        })
      })
    } else {
      redirect();
    }
  }

  componentDidUpdate() {
    //scaleContainer('.test-page__layout');
  }

  componentWillUnmount() {
    audioTestLoop.pause();
  }

  changeView(result) {
    const test = result[0];
    const LESSON_ID = 91;
    const TEST_ID = 90;
    const idTest = test.id ? test.id : null;
    let idLesson;
    if (test.lesson) {
      idLesson = test.lesson.id ? test.lesson.id : null;
    }

    if ((idLesson === LESSON_ID) && (idTest === TEST_ID)) {
      this.setState({
        exchangeView: true
      })
    }
  }

  devTest() {
    this.showPostLoader(true, 'test_success');
  }

  spinCallback(data) {
    this.setState({
      forceUpdate: this.state.forceUpdate + 1
    });
  }

  render() {
    const test = this.state.test || {};
    const {forceUpdate} = this.state;
    const exchangeView = this.state.exchangeView;
    const classForTestPage = exchangeView ? 'test-page test-page--blue' : 'test-page';
    const classForTestPageLayout = exchangeView ? 'test-page__layout test-page__layout--blue' : 'test-page__layout';

    let page = null;

    if (test.questions && test.questions.length > 0) {

      // вопросы
      let questions = test.questions.map((question, index) => {
        return (
          <TestQuestion
            key={question.id}
            question={question}
            current={this.state.counter}
            setAudioVolume={this.setAudioVolume}
            index={index}
            testResult={this.state.result}
            playAudio={this.state.counter === index && !this.state.radioIsDisabled}
          />
        )
      });

      // ответы
      let answers = test.questions.map((question, index) => {
        return (
          <TestRadio
            key={question.id}
            question={question}
            onAnswerSelected={this.handleAnswerSelected}
            current={this.state.counter}
            index={index}
            is_animation_playing={this.state.is_animation_playing}
            is_disabled={this.state.radioIsDisabled}
          />
        )
      });

      // прогресс пользователя
      let testProgress = <TestProgress
        questions={this.state.test.questions}
        current={this.state.questionId}
      />;

      page = (<div
        className={`test-page__wrapper test-page__wrapper_state_${this.state.show_post_loader === true ? 'hide' : 'show'}`}>
        <div
          className={`test-page__inner test-page__inner_state_${this.state.is_finished_success === true ? 'hide' : 'show'}`}>

          <div className={classForTestPageLayout}>
            {testProgress}
          </div>

          <div className="test-page__content">
            <div className="test-page__counter"
            >{Math.min(questions.length, this.state.counter + 1)}/{questions.length}</div>

            <ul className="test-page__list">
              {questions}
            </ul>

            <div className="test-page__options">
              {answers}
            </div>
          </div>
        </div>
      </div>);
    }

    const user = auth.getProfile();
    const {lessonsScreenType, points, coins} = this.state;

    let opacity = false;

    return (
      <div className={classForTestPage} ref="testPage"
           style={{backgroundImage: `linear-gradient(58.63deg, rgba(80, 13, 133, 0.85) 2.74%, rgba(10, 40, 145, 0.85) 89.73%), url(${this.state.backgroundTest})`}}>

        <MapNav
          opacity={opacity}
          user={user}
          forceUpdate={forceUpdate}
          lessonsScreenType={lessonsScreenType}
        />

        {this.state.image_loaded && page}

        {this.state.show_post_loader && <TestFinishScreen
          answersResult={this.state.test.questions.map(e => !e.error)}
          spinCallback={this.spinCallback.bind(this)}
          success={this.state.is_finished_success}
          prizeUrl={this.state.prize_url}
          prizeName={this.state.prize_name}
          gameUrl={this.state.game_id}
          lessonUrl={this.state.test.lesson.id}
          lessonIcon={this.state.lesson_icon}
          testId={this.props.match.params.id_test}
          lessonPreview={this.state.lesson_preview}
          showLototron={this.state.show_lototron}
          childPoints={points}
          coins={coins}
          audio={audioHacker}
        />}

        {/*<PreLoader*/}
        {/*  image_loaded={this.state.image_loaded}*/}
        {/*  pre_loader_type={this.state.pre_loader_type}*/}
        {/*/>*/}
      </div>
    );
  }

  /**
   * Обработывает выбранный ответ
   *
   * @param event
   */
  handleAnswerSelected(event) {
    if (this.state.is_animation_playing) return;

    this.setState({
      is_animation_playing: true
    });

    // задержка во время показа следующего вопроса
    setTimeout(() => {
      this.setState({
        is_animation_playing: false
      })
    }, TIMINGS.ANIMATION_TIME_PLAY);

    const questionId = this.state.questionId;
    const userAnswer = +(event.currentTarget.value);
    const correctAnswer = this.state.test.questions[questionId].correct_answer;

    let newResult = update(this.state.result, {
      $push: [{
        id: questionId,
        error: userAnswer !== correctAnswer
      }]
    });

    // TODO: refactor, лучше не обновлять стейт у вопросов
    let newQuiz = update(this.state.test, {
      questions: {
        [questionId]: {
          error: {$set: userAnswer !== correctAnswer}
        }
      }
    });

    this.setState({
      result: newResult,
      test: newQuiz
    });

    // проверяю оставшиеся вопросы
    //TODO refactor, не очень понятно как показываются следующий вопросы
    if (questionId <= this.state.test.questions.length - 1) {
      this.setNextQuestion()
    }

    if (questionId === this.state.test.questions.length - 1) {
      setTimeout(() => this.renderResult(), TIMINGS.RENDER_RESULT_TIME_DELAY);
    }
  }

  /**
   * Показывает результат теста
   */
  renderResult() {
    api.postTest(this.props.match.params.id_test, this.state.result)
      .then(response => {
        let is_finished_success = response.test_success;
        let currentChildId = auth.getCurrentChild();

        // todo dev
        //if (completed_count > 1) {
        //  response = resp;
        //}

        if (currentChildId) {
          // если тест пройден верно
          if (is_finished_success) {
            this.setState({
              show_lototron: response.completed_count === 1
            });

            //api.getPrizes(currentChildId)
            //  .then(prizes => {
            //    let currentPrizes = prizes.filter(prize => {
            //      return prize.is_received
            //    }).length;
            //    if (!(currentPrizes % 3)) {
            //      window.localStorage.setItem('prize', JSON.stringify({
            //        status: true,
            //        isSuperPrize: !(currentPrizes % 9)
            //      }));
            //    }
            //  });

            // если тест пройден первый раз показываем приз
            //if (!(response.prize === undefined)) {
            //  setTimeout(() => {
            //    this.setState({
            //      prize_url: response.prize.image,
            //      prize_name: response.prize.name
            //    })
            //    this.showPostLoader(is_finished_success, 'test_success');
            //  }, TIMINGS.POST_LOADER_TIME_DELAY);
            //
            //  // если тест пройден второй раз не показываем приз
            //} else {
            //  setTimeout(() => {
            //    this.showPostLoader(is_finished_success, 'test_success');
            //  }, TIMINGS.POST_LOADER_TIME_DELAY);
            //}

            setTimeout(() => {
              this.setState({
                forceUpdate: this.state.forceUpdate + 1,
                child_points: response.child_points,
                points: response.points,
                coins: response.coins
              });

              this.showPostLoader(is_finished_success, 'test_success');
            }, TIMINGS.POST_LOADER_TIME_DELAY);

            // если тест пройден не верно
          } else {
            setTimeout(() => {
              this.showPostLoader(is_finished_success, 'test_defeat');
            }, TIMINGS.POST_LOADER_TIME_DELAY);
          }
        } else {
          redirect();
        }
      });
  }

  /**
   * Показывает следующий вопрос
   */
  setNextQuestion() {
    this.setState({
      questionId: this.state.questionId + 1,
      radioIsDisabled: true
    });

    setTimeout(() => {
      this.setState({
        radioIsDisabled: false
      })
    }, TIMINGS.ANIMATION_TIME_PLAY + TIMINGS.NEXT_QUESTION_TIME_DELAY + TIMINGS.BUTTON_DISABLER)

    setTimeout(() => {
      this.setState({
        counter: this.state.counter + 1
      })
    }, TIMINGS.NEXT_QUESTION_TIME_DELAY)
  }

  /**
   * Показывает постлоадер
   *
   * @param {boolean} isFinished
   * @param {string} PostLoaderClass
   */
  showPostLoader(isFinished, PostLoaderClass) {
    audioTestLoop.pause();
    this.setState({
      show_post_loader: true,
      is_finished: true,
      is_finished_success: isFinished,
      post_loader_class: PostLoaderClass
    });

    //const currentChildId = auth.getCurrentChild();
    //
    //api.getSpin(currentChildId, this.props.match.params.id_test)
    //  .then(spin => {
    //    window.devMode && console.log('getSpin', spin);
    //  });
  }

  //TODO: refactor
  /**
   * Начать проходить тест снова
   */
  startAgain() {
    const currentChildId = auth.getCurrentChild();

    if (currentChildId) {
      audioTestLoop.play();
      api.getTest(currentChildId, this.props.match.params.id_test)
        .then(test => {
          this.setState({
            questionId: 0,
            counter: 0,
            show_post_loader: false,
            is_finished_success: false,
            test: test,
            result: []
          })
        });
    } else {
      redirect();
    }
  }
}

const mapDispatchToProps = {
  setPreloader
};

export default withRouter(connect(null, mapDispatchToProps)(TestContainer));
