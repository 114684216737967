import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { config, i18n } from '../../data';
import { checkImagesLoad } from '../../helpers';

export class DialogLessons extends Component {

  constructor(props) {
    super(props);

    this.state = {
      images_state: 'not_loaded'
    };
  }

  componentDidMount(){
    let url = `${config.base_url}${this.props.preview}`;

    checkImagesLoad(url).then(result => {
      this.setState({
        images_state: 'loaded'
      })
    });
  }

  render() {
    const {
      status,
      preview,
      text,
      title,
      url
    } = this.props;

    let myUrl = url;

    if ( status === config.lesson_not_paid.status ) {
      myUrl = '/cabinet/select_tariff';
    }

    return (
      <div className={`dialog-lessons dialog-lessons_status_${status}`}>
        <div
          className={`dialog-lessons__image dialog-lessons__image_state_${this.state.images_state}`}
          style={{backgroundImage: `url(${config.base_url}${preview})`}}>
        </div>
        <div className="dialog-lessons__description">
          <div className="dialog-lessons__description-inner">
            <h2 className="dialog-lessons__title">{title}</h2>
            {text ? <p className="dialog-lessons__text">{text}</p> : null }
            {status === config.lesson_not_registered.status ? <p className="dialog-lessons__text dialog-lessons__text_type_additional">{i18n.popup_not_registered}</p> : null}
            {status === config.lesson_not_paid.status ? <p className="dialog-lessons__text dialog-lessons__text_type_additional">{i18n.popup_lesson_not_paid}</p> : null}
            <a
              href={ myUrl }
              className="btn btn_size_l"
            >
              {status === config.lesson_not_paid.status ? 'Выбрать тариф' : config[`lesson_${status}`].btn}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

DialogLessons.propTypes = {
  status: PropTypes.string,
  preview: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string
};
