import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import style from './alert.module.sass';
import * as img from './images';
import history from '../../history';

export const alertPosition = {
  center: 'center',
  bottom: 'bottom'
};

export const Alert = ({ position, open = true, fixed, children }) => {
  const [show, setShow] = useState(true);

  function close() {
    if (!fixed) {
      let now = new Date().getDate();
      document.cookie = `lastOpen=${now}`;
      setShow(false);
    }
  }

  // Обновляем данные при переходе по ссылкам
  useEffect(() => {
    const unListen = history.listen(() => {
      setShow(true);
    });

    return () => {
      unListen();
    };
  }, []);

  useEffect(() => {
    setShow(open);
  }, [open]);

  if (!show) {
    return null;
  }

  return (
    <div className={cn(style.container, style[position])}>
      <div className={style.alert}>
        {children}
        { !fixed && <button
          className={style.closeBtn}
          type="button"
          title="Закрыть"
          onClick={close}
        >
          <img src={img.close_black} width={31} height={31} alt="" />
        </button> }
      </div>
      <div className={style.background} onClick={close}></div>
    </div>
  );
};

Alert.propTypes = {
  position: PropTypes.string.isRequired,
  open: PropTypes.bool,
  fixed: PropTypes.bool,
  children: PropTypes.element.isRequired
};
