import React, {Component} from 'react';
import {setPreloader} from '../../redux/actions';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import * as auth from '../../helpers/authenticationHelper';
import {config} from '../../data';
import {ajaxV2, checkImagesLoad, redirect} from '../../helpers';
//import { PreLoader } from '../pre-loader';
import {POINTS_FOR_QUEST, QUEST_LINE_COLOR, QUEST_LINE_WIDTH} from '../../data';
import PreLoader from '../pre-loader/pre-loader';
import {Link} from 'react-router-dom';
import Button from '../button/button';
import Slider from "react-slick";
import c from "classnames";


let audioQuest = null;
let bgColor = Math.floor(Math.random() * 4);

let checkDesk = function(){
  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
    return false
  }else{
    return  true
  }
}()







class Quest extends Component {
  static defaultProps = {
    quest: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      clientIsSubscription: ['on', 'soon_finished'].includes(((auth.getProfile() || {}).subscription.status) || {recurrent_status: 2}),
      lessonsProgress: [],
      pointsForPath: [],
      iconsPosition: [],
      showPopup: false,
      currentQuest: null,
      chestImage: null,
      canvas: {
        width: 0,
        height: 0
      }
    };

    this.containerRef = null;

    this.setContainerRef = element => {
      this.containerRef = element
    };
  }

  componentDidMount() {

    this.props.setPreloader('quest__preloader');
    audioQuest.play();
    this.getData();
    window.addEventListener('resize', this.setCanvasSize)
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.lessons) !== JSON.stringify(this.props.lessons)) {
      //this.props.setPreloader(!this.props.quest_loaded ? 'quest__preloader' : '');
     // this.setState({loading: true});
      this.getData();
    }
  }

  componentWillMount() {
    audioQuest = new Audio('/audio/quest.mp3');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setCanvasSize)
  }

  getCanvasSize() {
    const container = this.containerRef;

    return {
      width: container.offsetWidth,
      height: container.offsetHeight
    }
  }

  setCanvasSize = () => {
    const container = this.containerRef;

    this.setState({
      canvas: {
        width: container.clientWidth,
        height: container.clientHeight
      }
    })
  }


  getData() {
    const {lessons, quest} = this.props;
    const {total_lessons, finished_lessons} = quest;

    const lessonCompleted = total_lessons === finished_lessons;
    const imagesURLs = lessons.map(lesson => lesson.map.icon);
    const chestImage = lessonCompleted ? '../img/quest/chest_opened.png' : '../img/quest/chest.png';
    imagesURLs.push(chestImage);
    const compatibleRoutes = POINTS_FOR_QUEST[lessons.length + 1];

    ajaxV2.get(`/lessons/progress?child_id=${auth.getCurrentChild()}`).then((response) => {
      this.lessonsProgress = response.data
      this.props.setPreloader('');
      checkImagesLoad(imagesURLs)
        .then(() => {

          this.setState({
          loading: false,
          chestImage,
          canvas: this.getCanvasSize(),
          pointsForPath: compatibleRoutes ? compatibleRoutes.pointsForPath : [],
          iconsPosition: compatibleRoutes ? compatibleRoutes.iconsPosition : []
        }, () => {

        })
    })
        .catch((e) => {
          //TODO Error handler
          console.error(e);
        })
    })


  }

  renderPath = (ctx) => {
    const {pointsForPath} = this.state;
    const {lessons} = this.props;
    if (!pointsForPath.length || (lessons.length + 1 !== pointsForPath.length)) return null

    const canvasSize = this.getCanvasSize();
    const pointsForPathWithScale = lessons.reduce((acc, _, i) => {
      return [
        ...acc,
        {
          position: {
            x: canvasSize.width / 100 * pointsForPath[i][0],
            y: canvasSize.height / 100 * pointsForPath[i][1]
          }
        }
      ]
    }, []);

    // for the chest in the end of the route
    pointsForPathWithScale.push({
      position: {
        x: canvasSize.width / 100 * pointsForPath[pointsForPath.length - 1][0],
        y: canvasSize.height / 100 * pointsForPath[pointsForPath.length - 1][1]
      }
    })

    const t = 1;
    console.log(pointsForPathWithScale)
    if (!pointsForPathWithScale.length) {
      return
    }

    ctx.beginPath();
    ctx.strokeStyle = QUEST_LINE_COLOR;
    ctx.lineWidth = QUEST_LINE_WIDTH;
    ctx.setLineDash([20, 10]);
    ctx.moveTo(pointsForPathWithScale[0].position);

    for (let i = 0; i < pointsForPathWithScale.length - 1; i++) {
      const p0 = {
        ...i > 0 ? pointsForPathWithScale[i - 1].position : pointsForPathWithScale[0].position
      };

      const p1 = {
        x: pointsForPathWithScale[i].position.x,
        y: pointsForPathWithScale[i].position.y
      };

      const p2 = {
        x: pointsForPathWithScale[i + 1].position.x,
        y: pointsForPathWithScale[i + 1].position.y
      }

      const p3 = (i !== pointsForPathWithScale.length - 2)
        ? {x: pointsForPathWithScale[i + 2].position.x, y: pointsForPathWithScale[i + 2].position.y}
        : p2;

      const cp1x = p1.x + (p2.x - p0.x) / 6 * t;
      const cp1y = p1.y + (p2.y - p0.y) / 6 * t;

      const cp2x = p2.x - (p3.x - p1.x) / 6 * t;
      const cp2y = p2.y - (p3.y - p1.y) / 6 * t;

      ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, p2.x, p2.y);
    }

    ctx.stroke();
    ctx.closePath();
  }

  handleLessonOpening(item) {
if(this.props.quest.category === 'paid'){
  if(this.props.quest.is_bought === false){
    this.props.buyLesson(true,this.props.quest)
  } else {
   redirect(`/lessons/${item.id}`);
  }
}
if(this.props.quest.category !== 'paid'){
  if ((this.state.clientIsSubscription || item.is_free === true)) {
      redirect(`/lessons/${item.id}`);
  }

  return this.setState({
    showPopup: true,
    currentQuest: item
  });
}

  }

  togglePointer = (e) => {
    const container = e.target.getStage().container();
    container.style.cursor = container.style.cursor === 'pointer' ? 'default' : 'pointer';
  }

  getIconStyle = (index) => {
    const {iconsPosition} = this.state;
    if (!iconsPosition.length || !iconsPosition[index]) return

    return {
      top: `${iconsPosition[index][1]}%`,
      left: `${iconsPosition[index][0]}%`
    }
  }

  getLessons() {
    let bgColor;
    const {lessons} = this.props;
    let iconBackground = '#84A9BD'
    const queryParameters = new URLSearchParams(window.location.search)
    bgColor = queryParameters.get('color');


    const color = [
      {number: '#84A9BD'},
      {number: '#E3974B'},
      {number: '#6BA96D'},
      {number: '#9A8BAA'},
      {number: '#C2947D'},
    ]
    if (bgColor !== null) {
      iconBackground = color[bgColor].number;
    }
    const lessonItems = lessons.map((l, i) => {
      let progress = false;
      if (this.lessonsProgress?.find((element) => element.id === l.id).progress.lesson_finished_level > 0) {
        progress = true

      }
      return (<div key={l.id} className="quest__element">
        <div onClick={() => this.handleLessonOpening(l)} style={this.getIconStyle(i)}>
          <div style={{backgroundColor: `${iconBackground}`}} className="quest__element__icon"><img
            src={l.map.large_icon} alt={`Иконка урока - ${l.name}`}/></div>
          <div style={{fontSize: '20px', marginTop: '15px', textAlign:'center'}}>{l.name}</div>
        </div>
        {progress && (<>
          <div className={'quest__progress'}>
            {this.lessonsProgress?.find((element) => element.id === l.id).progress.lesson_finished_level > 49 ?
              <img alt={'star icon'} src={process.env.PUBLIC_URL + '/icons/active_star.png'}/> :
              <img alt={'star icon'} src={process.env.PUBLIC_URL + '/icons/disabled_star.png'}/>}
            {this.lessonsProgress?.find((element) => element.id === l.id).progress.lesson_finished_level > 74 ?
              <img alt={'star icon'} src={process.env.PUBLIC_URL + '/icons/active_star.png'}/> :
              <img alt={'star icon'} src={process.env.PUBLIC_URL + '/icons/disabled_star.png'}/>}
            {this.lessonsProgress?.find((element) => element.id === l.id).progress.lesson_finished_level === 100 ?
              <img alt={'star icon'} src={process.env.PUBLIC_URL + '/icons/active_star.png'}/> :
              <img alt={'star icon'} src={process.env.PUBLIC_URL + '/icons/disabled_star.png'}/>}

          </div>
          <div className={'quest__status'}>
            {this.lessonsProgress?.find((element) => element.id === l.id).test.is_completed_successfully === true ?
              <img alt={'star icon'} src={process.env.PUBLIC_URL + '/icons/quest_complete.png'}/> :
              <img alt={'star icon'} src={process.env.PUBLIC_URL + '/icons/quest_inprogress.png'}/>}
          </div>
        </>)}
      </div>)
    })
    return <div >
      {checkDesk && window.innerWidth > 900 ? (<div className="quest__cards">
        {lessonItems}
      </div>) : (
        <div className={'quest_slider'} >
          <Slider
            dots
            slidesToShow={1}
            slidesToScroll={1}
            className={c('main-slider')}
            dotsClass="main-slider__dots"
            afterChange={() => {
              // clickable = true;
            }}
            beforeChange={(oldIndex, newIndex) => {
              sessionStorage.setItem('currentSlide', newIndex);
              //clickable = false;
            }}
            initialSlide={
              sessionStorage.getItem('currentSlide')
                ? +sessionStorage.getItem('currentSlide')
                : 0
            }
          >
            {lessonItems}
          </Slider>
        </div>

      )}


{/*      <div className={'quest__lock'}><img alt={'lock icon'} src={process.env.PUBLIC_URL + '/icons/lock.png'}/></div>*/}
      {/* <img
          className="quest__chest"
          style={this.getIconStyle(iconsPosition.length - 1)}
          src={chestImage}
          alt="Сундук"
        />*/}

    </div>
  }

  render() {
    const {quest,quest_loaded,buyLesson} = this.props;
    const {
      loading, showPopup,
      currentQuest
    } = this.state;
    const loadingQuest = quest_loaded;

    return (
      <>
       {/* <div className={'item__gradient'} style={{position: 'absolute', height: '400px', width: '100px',zIndex:'99999', left:'100px', top:'140px'}}></div>*/}
        <div className="quest" ref={this.setContainerRef}>

          {showPopup && (
            <div
              onClick={() => this.setState({showPopup: false, currentQuest: null})}
              className={'payment__wrapper'}>
              <div
                //  closePopup={() => this.setState({showPopup: false, currentQuest: null})}
                className="payment__modal"
              >
                <div className="quest_wrapper">
                  <div
                    className="quest_bg"
                    style={{backgroundImage: `url(${currentQuest.image})`}}
                  />
                  <div className="quest__subtitle">{currentQuest.name}</div>
                  <div className="questPopup__description">{currentQuest.description}</div>
                  {this.props.quest.category === 'paid' ? (<Button onClick={() => {
                    buyLesson(true, this.props.quest)
                  }} className="quest__btn orange-btn" to="/cabinet/select_tariff"
                                                                   color={'orange'}>Купить {this.props.quest.price}Р</Button>) : (
                    <Link className="quest__btn orange-btn" to="/cabinet/select_tariff">Выбрать тариф</Link>)}
                </div>
              </div>
            </div>
          )}
          {(!quest_loaded || loading) && <PreLoader className="quest__preloader" image_loaded={false}/>}
{loadingQuest && this.getLessons(bgColor)}


          <div className="quest__description">
            <img alt={'quest icon'} className="quest__image" role="presentation"
                 src={`${config.base_url}${quest.image}`}/>
            <div className="quest__text">{quest.description}</div>
          </div>
        </div>

      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    preloaderType: state.common.preloaderType
  }
};

const mapDispatchToProps = {
  setPreloader
};

const componentWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(Quest))

export {componentWithRouter as Quest};
