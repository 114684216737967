import React, {Component} from 'react';
import {Link} from 'react-router-dom';

export class Promo extends Component {

  constructor(props) {
    super(props);

    this.state = {
      day: 3,
      hour: 23,
      minutes: 59,
      seconds: 59,
      setTimeoutId: null
    };

    this.showPromo = this.showPromo.bind(this);
    this.showText = this.showText.bind(this);
    this.tickTick = this.tickTick.bind(this);
  }

  showPromo() {
    const user = this.props.user;
    if (!user) return false;

    if (user.subscription && user.subscription.status === 'on') {
      return false;
    }

    const durationShowPromoInMs = 86400000;

    let duration = (Date.now() - Date.parse(user.created_at)) / 1000;
    // TODO удалить корректировка времени сервера
    duration -= 3600 * 3;

    let show = !(duration > durationShowPromoInMs && duration < durationShowPromoInMs * 7);

    if (show) {

    }

    return show;
  }

  showPromoThirdDay() {
    const user = this.props.user;
    if (!user) return false;
    if (!user.subscription) return false;
    if (user.subscription.status !== 'soon_finished') return false;

    const time = Date.parse(user.created_at);
    const msInDay = 86400000;
    const durationShowPromoInMs = msInDay * 3;

    const nowDateInMs = Date.now();
    const untilPeriodDate = time + durationShowPromoInMs;
    const remainingTime = untilPeriodDate - nowDateInMs;

    let leftTimeInSec = remainingTime / 1000;
    // TODO удалить корректировка времени сервера
    leftTimeInSec -= 3600 * 3;
    if (leftTimeInSec <= 0 || leftTimeInSec >= 86400) return false;
    return true;
  }

  showPromoFourthDay() {
    const user = this.props.user;

    if (!user) return false;
    if (user && !user.subscription) return true;
    if (user && user.subscription && user.subscription.status === 'off') return true;
    return false;
  }

  showText() {
    const user = this.props.user;
    if (!user) return false;
    if (!user.subscription) return false;
    if (user.subscription.status !== 'soon_finished') return false;
    const durationShowPromoInMs = 86400000;
    const untilPeriodDate = Date.parse(user.created_at) + durationShowPromoInMs * 3;
    let leftTimeInSec = (untilPeriodDate - Date.now()) / 1000;
    leftTimeInSec -= 3600 * 3;
    if (leftTimeInSec <= 0) return false;
    return true;
  }

  tickTick() {
    const {hour, minutes, seconds} = this.state;
    let newHour = hour,
      newMinutes = minutes,
      newSeconds = seconds - 1;

    if (newSeconds < 0) {
      newSeconds = 59;
      newMinutes = minutes - 1;
    }

    if (newMinutes < 0) {
      newMinutes = 59;
      newHour = hour - 1;
    }

    if (newHour < 0) {
      return this.setState({
        hour: 0,
        minutes: 0,
        seconds: 0
      })
    }

    this.setState({
      hour: newHour,
      minutes: newMinutes,
      seconds: newSeconds
    }, () => setTimeout(this.tickTick, 1000))
  }

  timeToString(time) {
    return time < 10 ? `0${time}` : `${time}`;
  }

  componentDidMount() {
    const user = this.props.user;
    if (!user) return;
    if (!user.subscription) return;
    if (user.subscription.status !== 'soon_finished') return;

    const msInDay = 86400000;
    // TODO удалить корректировка времени сервера - 10800000
    const day = Math.ceil((Date.parse(user.created_at) + msInDay * 3 - Date.now() - 10800000) / msInDay);
    // TODO удалить корректировка времени сервера - 10800000
    const untilPeriodDate = Date.parse(user.created_at) - 10800000 + msInDay * 3;
    const remainingTime = untilPeriodDate - Date.now();
    let leftTimeInSec = 0;
    const showPromoFirstDay = remainingTime > (msInDay * 2);
    const showPromoThirdDay = remainingTime < msInDay;

    if (showPromoFirstDay) leftTimeInSec = (remainingTime - (msInDay * 2)) / 1000;
    if (showPromoThirdDay) leftTimeInSec = remainingTime / 1000;
    if (leftTimeInSec === 0) return;
    const hour = Math.floor(leftTimeInSec / 3600);
    const minutes = Math.floor((leftTimeInSec - hour * 3600) / 60);
    const seconds = Math.floor(leftTimeInSec - hour * 3600 - minutes * 60);
    this.setState({
      hour,
      minutes,
      seconds,
      day
    }, () => setTimeout(this.tickTick, 1000))
  }

  render() {
    const {day, hour, minutes, seconds} = this.state;
    const user = this.props.user;
    let share = 40;

    if (user) {
      let leftTimeInSec = (Date.now() - Date.parse(user.created_at)) / 1000;
      leftTimeInSec -= 3600 * 3;
      share = leftTimeInSec < 86400 ? 75 : 40;
    }

    return <div className="free-promo">
      {this.showText() && <Link className="free-promo__text" to="/cabinet/select_tariff">
        До конца пробного периода: <span className="free-promo__count">{day} {day === 1 ? 'день' : 'дня'}</span>
      </Link>}
      {this.showPromo() &&
        <Link className="free-promo__container free-promo__container--spring" to="/cabinet/select_tariff">
          <div>
            <div className="free-promo__title">ВЕСЕННИЕ СКИДКИ</div>
            <div className="free-promo__sale">
              Подпишись сейчас<br/> со скидкой {share}%!
            </div>
          </div>
        </Link>}

      {/* {this.showPromoFourthDay() && <FourthDayPromo />} */}
      {this.showPromoThirdDay() &&
        <Link className="free-promo__container free-promo__container--orange" to="/cabinet/select_tariff">
          <img className="free-promo__chevostik free-promo__chevostik--third" src="/img/tour/chev_tour2.png"
               alt="chevostik"/>
          <div className="free-promo__sale">
            ПОСЛЕДНИЙ ШАНС!
            <br/>
            Подпишись сейчас
            <br/>
            со скидкой 75%!
            <span className="free-promo__deadline">
            До конца акции
            <span
              className="free-promo__time">{this.timeToString(hour)}:{this.timeToString(minutes)}:{this.timeToString(seconds)}</span>
          </span>
          </div>
        </Link>}
    </div>
  }
}
