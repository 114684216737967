import MobileDetect from 'mobile-detect';

/**
 * Определяет телефонные устройства
 */
export const isTablet = () => {
  let md = new MobileDetect(window.navigator.userAgent);

  return !!(md.tablet() || md.mobile() || md.phone())
};
