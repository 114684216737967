import React from 'react';
import PropTypes from 'prop-types';
import { config } from '../../data';
import { Link } from 'react-router-dom';
import Button from '../button/button';

export const BillingLink = ({ subscription }) => {
  let title = 'Подписка:';
  let message = subscription && subscription.message ? subscription.message : '';
  let color = 'green';

  if (subscription && subscription.status === 'off') {
    title = 'Подписка закончилась';
    color = 'red';
  } else if (subscription && subscription.status === 'soon_finished') {
    title = 'Подписка заканчивается';
    color = 'yellow';
  }

  return (
    <Link
      to={`${config.routes.cabinet}/${config.routes.billing}`}
      className="cabinet-menu__link"
    >
      <Button mod="cabinet-menu__button" color={color} customTag="span">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M33.1352 14.4977C33.9632 13.6697 33.9632 12.3272 33.1352 11.4992C32.3072 10.6712 30.9647 10.6712 30.1367 11.4992L15.319 26.3169C14.491 27.1449 14.491 28.4874 15.319 29.3154C16.147 30.1434 17.4895 30.1434 18.3175 29.3154L33.1352 14.4977Z" fill="white"/>
          <path d="M10.4976 18.4992C9.66957 17.6712 8.32712 17.6712 7.49912 18.4992C6.67112 19.3272 6.67112 20.6697 7.49912 21.4977L15.3168 29.3153C16.1448 30.1433 17.4872 30.1434 18.3152 29.3154C19.1432 28.4874 19.1432 27.1449 18.3152 26.3169L10.4976 18.4992Z" fill="white"/>
        </svg>
      </Button>
      <div className="cabinet-menu__subscription">
        {title}<br/>
        {message}
      </div>
    </Link>
  );
};

BillingLink.propTypes = {
  subscription: PropTypes.object
};
