import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { isPhone } from '../../helpers';
import { redirect } from '../../helpers/redirect';

export const LessonNav = (props) => {

  const {
    lessonUrl,
    testUrl,
    gameUrl,
    gamesUrl,

    startAgain,

    isGameFinished,
    isTestFinished,
    isLessonAudioFinished,

    isTestFinishedSuccess
  } = props;

  let lessonItem = null;
  let testItem = null;
  let gameItem = null;
  let mapItem = null;

  let gamesItem = null;

  // если пройден урок
  if (isLessonAudioFinished) {
    lessonItem = <button
        type="button"
        className="lesson-nav__item lesson-nav__item_audio lesson-nav__item_finished"
        onClick={startAgain}>
        <i></i>
        Ещё раз
      </button>;

    testItem = <Link
        to={`/lessons/${lessonUrl}/tests/${testUrl}`}
        className="lesson-nav__item lesson-nav__item_test lesson-nav__item_current"
        onClick={startAgain}>
        <i></i>
        Проверить себя
      </Link>;

    gameItem = <button
        type="button" className="lesson-nav__item lesson-nav__item_game lesson-nav__item_state_disabled">
        <i></i>
        {isPhone() ? 'Недоступно на\xa0телефоне' : 'Играть'}
      </button>;
  }

  // если пройден тест
  if (isTestFinished) {
    lessonItem = <Link
        to={`/lessons/${lessonUrl}`}
        className="lesson-nav__item lesson-nav__item_audio lesson-nav__item_finished">
        <i></i>
        Слушать
      </Link>;

    testItem = <button
      to={`/lessons/${lessonUrl}/tests/${testUrl}`}
      className={`lesson-nav__item lesson-nav__item_test lesson-nav__item_${isTestFinishedSuccess ? 'finished' : 'current'}`}
      onClick={startAgain}
    >
      <i></i>
      Проверить<br/>себя
    </button>;

    gameItem = <Link
      to={`/games/${gameUrl}`}
      className={`lesson-nav__item lesson-nav__item_game lesson-nav__item_${isTestFinishedSuccess ? 'current' : 'next'}`}>
      <i></i>
      Играть
    </Link>;
  }

  // если пройдена игра
  if (isGameFinished) {
    gameItem = <button
        className="lesson-nav__item lesson-nav__item_game_again"
        onClick={startAgain}
      >
        <i></i>
        Играть ещё раз
      </button>;

    mapItem = <span
      onClick={() => isPhone() ? window.location.replace('/') : redirect()}
      className="lesson-nav__item lesson-nav__item_map">
      <i></i>
      На карту
    </span>;
  }

  if (gamesUrl === undefined){
    gamesItem = null;
  } else {
    gamesItem = <Link
      to={`${gamesUrl}`}
      className="lesson-nav__item lesson-nav__item_games">
      <i></i>
      Все игры
    </Link>;
  }

  let page = <nav className="lesson-nav">
      { lessonItem }
      { testItem }
      { gameItem }
      { gamesItem }
      { mapItem }
    </nav>;

  return page
};

LessonNav.propTypes = {
  gamesUrl: PropTypes.string,
  lessonUrl: PropTypes.number,
  testUrl: PropTypes.number,
  gameUrl: PropTypes.number,
  startAgain: PropTypes.func,
  isLessonAudioFinished: PropTypes.bool,
  isTestFinished: PropTypes.bool,
  isTestFinishedSuccess: PropTypes.bool,
  isGameFinished: PropTypes.bool
};
