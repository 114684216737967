import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import * as auth from '../../helpers/authenticationHelper';
import {config} from '../../data';
import {smoothScrollTo} from '../../helpers';

export const CabinetChildrenSelector = ({children, add}) => {

  const tabScroller = React.createRef();

  const selectChild = event => {
    let id = +(event.currentTarget.getAttribute('data-id'));
    let name = event.currentTarget.getAttribute('data-name');
    let avatar = event.currentTarget.getAttribute('data-avatar');
    auth.setCurrentChild(id, name, avatar);
  }

  const [activeTab, setActiveTab] = useState(auth.getCurrentChild())

  useEffect(() => {
    if (add || (activeTab !== auth.getCurrentChild())) {
      let target = tabScroller.current.querySelector('.cabinet-children-selector__tab_active');

      if (tabScroller.current && target) {
        setActiveTab(auth.getCurrentChild());
        smoothScrollTo(tabScroller.current, 0, 0, tabScroller.current.scrollLeft, target.getBoundingClientRect().x - tabScroller.current.getBoundingClientRect().x - target.getBoundingClientRect().width, 300)
      }
    }
  });

  return (
    <div ref={tabScroller} className="cabinet-children-selector">
      {
        (children !== null) &&
        children.map((item, index) => {
          return (
            ((item.id === auth.getCurrentChild()) && !add) ?
              <span key={index}
                    className={'cabinet-children-selector__tab' + (add ? '' : ' cabinet-children-selector__tab_active')}>
              {item.name}
                {add ? null : <Link
                  to={`${config.routes.child}/${item.id}/${config.routes.profile}`}
                  className="cabinet-children-selector__edit"
                  title="Редактировать анкету"
                />}
            </span>
              :
              <Link
                key={index}
                to={`${config.routes.child}/${item.id}`}
                className={'cabinet-children-selector__tab' + ((!add && children[index + 1]?.id === auth.getCurrentChild()) || (add && index === children.length - 1) ? ' __pre-active' : '')}
                data-id={item.id}
                data-name={item.name}
                data-avatar={item.avatar}
                onClick={selectChild}
              >
                {item.name}
              </Link>
          );
        })
      }
      <Link
        to={`${config.routes.cabinet}/${config.routes.add_new_child}`}
        className={'cabinet-children-selector__add' + (add ? ' cabinet-children-selector__tab_active' : '')}
        title="Добавить ребёнка"
      >+</Link>
    </div>
  );
};

CabinetChildrenSelector.propTypes = {
  add: PropTypes.bool,
  children: PropTypes.array,
  selectChild: PropTypes.func
};
