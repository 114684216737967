import React, {Component} from 'react';
import queryString from 'query-string';
import {redirect, handleAuthResponse} from '../../helpers';

export class RegMts extends Component {
  componentDidMount() {
    const query = queryString.parse(this.props.location.search);
    const token = query.token;
    if (!token) redirect();
    handleAuthResponse({jwt: token});
    window.location.replace('/');
  }

  render() {
    return <div className="mts"></div>
  }
}
