import React from 'react';
import PropTypes from 'prop-types';
import {config} from '../../data';
import { Link } from 'react-router-dom';

export const CabinetQuestLessons = ({ lessons, lessonsWrapRef }) => {
  let column = 0;

  return (
    <div ref={lessonsWrapRef} className="cabinet-quest-lessons">
      {
        lessons.map((item, index) => {

          return (
            <Link
              key={index}
              to={'/quests'}
              title={item.name}
              className={`cabinet-quest-lessons__item ${!item.is_test_completed_successfully ? 'cabinet-quest-lessons__item_completed' : ''}`}

            >
              <div className="cabinet-quest-lessons__img-wrap">
                <img
                  className="cabinet-lessons__img"
                  alt=""
                  role="presentation"
                  src={item.preview_icon.large}/>
              </div>
              <div className="cabinet-quest-lessons__name">{item.name}</div>
            </Link>
          );
        })
      }
    </div>
  );
};

CabinetQuestLessons.propTypes = {
  lessons: PropTypes.array.isRequired,
  lessonsWrapRef: PropTypes.func.isRequired
};
