import React from 'react';

export class TestAddImg extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showMap: false,
    }
  }

  showMap = (e) => {
    const element = e.target;
    window.devMode && console.log(element);
    this.setState((state)=>{
      if ((element.classList.contains('test__ef') && !this.state.showMap) ||
      (!element.classList.contains('test__map') && this.state.showMap)) {
        state.showMap = !this.state.showMap;
      }
    })
  }

  render() {
    const {showMap} = this.state;
    return (
      <div className={`test__img ${showMap ? 'test__img--open-map' : ''}`} onClick={this.showMap}>
        <img
          className="test__img-item test__airplan"
          role="presentation"
          src="/img/test/91/90/Eng_Test_airplane.png"
          alt="Airplain"
        />
        <img
          className="test__img-item test__airplan-word"
          role="presentation"
          src="/img/test/91/90/Eng_Test_airplane_text.png"
          alt="Airplain-word"
        />
        <img
          className="test__img-item test__flower"
          role="presentation"
          src="/img/test/91/90/Eng_Test_flower.png"
          alt="Flower"
        />
        <img
          className="test__img-item test__flower-word"
          role="presentation"
          src="/img/test/91/90/Eng_Test_flower_text.png"
          alt="Flower-word"
        />
        <img
          className="test__img-item test__icecream"
          role="presentation"
          src="/img/test/91/90/Eng_Test_icecream.png"
          alt="Icecream"
        />
        <img
          className="test__img-item test__icecream-word"
          role="presentation"
          src="/img/test/91/90/Eng_Test_icecream_text.png"
          alt="Icecream-word"
        />
        <img
          className="test__img-item test__turtle"
          role="presentation"
          src="/img/test/91/90/Eng_Test_turtle.png"
          alt="Turtle"
        />
        <img
          className="test__img-item test__turtle-word"
          role="presentation"
          src="/img/test/91/90/Eng_Test_turtle_text.png"
          alt="Turtle-word"
        />
        <img
          className="test__img-item test__caption"
          role="presentation"
          src="/img/test/91/90/Eng_Test_text.png"
          alt="Вопросы от EF English First"
        />
        <img
          className="test__img-item test__ef"
          role="presentation"
          src="/img/test/91/90/Eng_Test_EF_button.png"
          alt="Button EF"
        />
        <img
          className="test__img-item test__ef-bg"
          role="presentation"
          src="/img/test/91/90/Eng_Test_EF_shadow.png"
          alt="Button EF Background"
        />

        {showMap &&
          <img
            className="test__img-item test__map"
            role="presentation"
            src="/img/test/91/90/polaroid.png"
            alt="Map"
          />
        }
      </div>
    )
  }
}
