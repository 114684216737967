import React, {Component} from 'react';
import * as api from '../../api';
import {CabinetHeader, CurrentSubscription, BillingsTable} from '../../components';
import {setPreloader} from '../../redux/actions';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';

class BillingContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      payment: [],
      page_title: 'Подписка',
      content_loaded: false
    };
  }

  componentDidMount() {
    this.props.setPreloader('billings');

    api.getPayments()
      .then(payments => {
        window.devMode && console.log('payments', payments);
        this.setState({
          payments,
          content_loaded: true
        }, () => {
          setTimeout(() => {
            this.props.setPreloader('');
          }, 300);
        })
      });
  }

  render() {
    let header = null;
    let billingCurrent = null;
    let billingHistory;

    if (this.state.payments) {
      header = <CabinetHeader
        page_title={this.state.page_title}
      />;

      //console.log('payments', this.state.payments.filter(p => p.hasOwnProperty('product')));

      billingCurrent = <CurrentSubscription
        payments={this.state.payments.filter(p => p.hasOwnProperty('tariff'))}
      />;
      billingHistory = <div className="billings-history">
        <h3 className="billings-history__title">История платежей</h3>
        <BillingsTable payments={this.state.payments}/>
      </div>
    }

    return (
      <main className={`cabinet-billing cabinet__main ${this.state.content_loaded ? '' : 'cabinet__preloader'}`}>
        {header}

        <section className="cabinet__section">
          <div className="wrapper wrapper_cabinet">
            {billingCurrent}
          </div>
        </section>
        <section className="cabinet__section">
          <div className="wrapper wrapper_cabinet">
            {billingHistory}
          </div>
        </section>
      </main>
    );
  }
}

const mapDispatchToProps = {
  setPreloader
};

const componentWithRouter = withRouter(connect(null, mapDispatchToProps)(BillingContainer))

export {componentWithRouter as BillingContainer};
