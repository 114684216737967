import React, {Component} from 'react';
import queryString from 'query-string';

import {
  UserPassword,
  AuthHeader,
  AuthButton,
  Error
} from '../../components';

import * as api from '../../api';

import * as auth from '../../helpers/authenticationHelper';
import {connect} from 'react-redux';
import {closeAuthPopup, openAuthPopup} from '../../redux/actions';

class AuthPasswordCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      passwordConfirmation: '',
      isRequestSent: false,
      errorMessage: ''
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  render() {

    return (
      <div className="password-create">
        <AuthHeader text="Новый пароль"/>
        <form
          className="form"
          onSubmit={this.onSubmit}
        >
          <UserPassword
            placeholder="Придумайте пароль от 6 символом"
            name="password"
            value={this.state.password}
            onChange={this.onChange}
          />

          {this.state.errorMessage && <Error message={this.state.errorMessage}/>}
          <br/>

          <UserPassword
            placeholder="Введите пароль ещё раз"
            name="passwordConfirmation"
            value={this.state.passwordConfirmation}
            onChange={this.onChange}
          />
          <AuthButton text="Запомнить пароль"/>
        </form>
      </div>
    );
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const query = queryString.parse(window.location.search)
  console.log(query)
    api.patchPasswordAuth({
      reset_password_token: query.reset_password_token,
      password: this.state.password,
      password_confirmation: this.state.passwordConfirmation
    })
      .then((response) => {
        this.setState({
          isResetLinkSent: true
        });
        if (response.jwt) {
          this.setState({
            errorMessage: 'Пароль успешно изменен'
          });
        }
        if (response.errors) {
          // eslint-disable-next-line guard-for-in
          for (var key in response.errors) {
            this.setState({
              errorMessage: response.errors[key][0]
            });
          }
        }
        auth.handleAuthResponse(response);
      })
      .catch((err) => {
        if (err.message && err.message === 'Invalid token specified') {
          this.setState({
            errorMessage: 'Ссылка восстановления устарела'
          });
        } else {
          window.devMode && console.log('patchPasswordAuth', err);
        }
      });
  }

  showForm() {
    this.setState({
      isResetLinkSent: false
    });
  }
}

export default connect(null, {
  closeAuthPopup,
  openAuthPopup
})(AuthPasswordCreate);
