import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {config} from '../../data';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import PassedIcon from './svg_icon/passed';

export const GameLink = (props) => {
  const {url, previewImage, name, index, state} = props;
  let icon;
  if (state === true) {
    icon = (<PassedIcon id={index} className="game-link__icon"/>)
  }
  return (
    <Link
      to={url}
      onClick={() => {
        localStorage.setItem('gameReturnLInk', window.location.pathname + window.location.search);
      }}
      className={`games__item-inner ${state === false ? 'games__game' : ''}`}
    >
      <span className="games__item-img-wrapper">
        <LazyLoadImage
          className="games__item-img"

          alt={previewImage}
          src={`${config.base_url}${previewImage}`}
          placeholderSrc="../img_static/small-loader.svg"
        />
        {icon}
      </span>
      <div className="games__item-name">{name}</div>
    </Link>
  );
};

GameLink.propTypes = {
  url: PropTypes.string,
  previewImage: PropTypes.string,
  name: PropTypes.string
};
