import React from 'react';
import PropTypes from 'prop-types';
import { isTouch } from '../../helpers';

export const CabinetHeader = (props) => {

  const {
    page_title,
  } = props;

  return (
    <header className={`cabinet-header ${isTouch() ? 'cabinet-header_state_touch' : ''}`}>
      <div className="wrapper wrapper_cabinet">
        <div className="cabinet-header__profile">
          <h2 className="cabinet-header__title">
            {page_title}
          </h2>
        </div>
      </div>
    </header>
  );
};

CabinetHeader.propTypes = {
  page_title: PropTypes.string,
};
