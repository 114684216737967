import React, { Component } from 'react';
import * as api from '../../api';

export class PromoForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      messageView: 'hide',
      messageText: '',
      msgIcon: 'show',
      msgError: '',
    };

    this.getPromo = this.getPromo.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  };

  handleChange = (event) => {
    this.setState({value: event.target.value});
  }

  getPromo(code){
    const { value } = this.state;
    const { setPromo } = this.props;

    this.setState({
      messageView: 'show',
      messageText: 'Проверяем ...',
      msgIcon: 'hide',
    })

    api.getPromo(code)
      .then(response => {
        if(response.result === 2){
          setPromo(response.tariffs, value)

          this.setState({
            'messageView': 'show',
            'messageText': 'Промокод применён. Выберите тариф со скидкой',
            'msgIcon'    : 'show',
            'msgError'   : ''
          })

          return;
        }

        setPromo([], value)
        this.setState({
          'messageView': 'show',
          'messageText': `Промокод не применён. ${response.result === 1 ? 'Срок действия этого промокода истёк.' : 'Проверьте правильность промокода и период действия акции'} `,
          'msgIcon'    : 'hide',
          'msgError'   : 'error'
        })
      })
      .catch(() => {
        setPromo([], value)

        this.setState({
          'messageView': 'show',
          'messageText': 'Промокод не применён. Проверьте правильность промокода и период действия акции',
          'msgIcon'    : 'hide',
          'msgError'   : 'error'
        })
      })
  }

  onSubmit(e) {
    e.preventDefault();
    this.getPromo(this.state.value)
  }

  render() {
    return (
      <div className="promo-wrapper">
        <form
          className="promo-form promo-form_show"
          onSubmit={this.onSubmit}
        >
          <input
            ref={(input) => this.input = input}
            className="input-txt__field"
            placeholder="Промокод"
            name="promo"
            value={this.state.value}
            onChange={this.handleChange}
            type="text"
          />
          <button
            type="submit"
            className="promoBtn"
          />
          <div className={`message-view_${this.state.messageView}`}>
            <div className="msgMutual">
              <div className={`message-icon message-icon_${this.state.msgIcon}`}/>
              <span className={`message-text message-text_${this.state.msgError}`}>{this.state.messageText}</span>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
