import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { config } from '../../data';
import { scale, closest, setDelay } from '../../helpers';

const ANIMATE_TIME = 1000;

export class LogicalChain extends Component {

  constructor(props) {
    super(props);

    // значение в % для центрирования игры
    const defaultCenterAxisValue = -50;

    this.state = {
      /* CSS  */
      transform: {
        scale: 1,
        translate: {
          x: defaultCenterAxisValue,
          y: defaultCenterAxisValue
        }
      },
    };

    this.defaultCenterAxisValue = defaultCenterAxisValue;
    this.onClick = this.onClick.bind(this);
    this.scaleGame = this.scaleGame.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.scaleGame);
    this.scaleGame();
  }

  render() {
    let cssTransform = this.state.transform;
    let chain = null;

    // При зуме карта позиционируется по-разному
    let translateUnit = '%';
    let cssWrap = {
      transform: `
        scale(${cssTransform.scale})
        translate(${cssTransform.translate.x}${translateUnit}, ${cssTransform.translate.y}${translateUnit})
      `,
      backgroundImage: `url(${config.base_url}${this.props.image})`
    };

    if(this.props.chains){
      chain = this.props.chains.map((item, index) => {
        return(
          <li
            key={index}
            className={`
              logical-chain__item
              logical-chain__item_state_${JSON.parse(item.dataset.options).is_excess === true ? 'is_excess' : 'not_excess'}
            `}
            onClick={this.onClick}
            data-is-excess={JSON.parse(item.dataset.options).is_excess}
          >
            <img
              className="logical-chain__img"
              src={item.src}
              alt={`Изображение предмета логической цепочки ${index+1}`}
            />
          </li>
        );
      })
    }

    return (
      <section className="logical-chain">
        <h1 className="game-page__name">Найди лишний предмет</h1>
        <div
          className="logical-chain__wrapper game-page__wrap"
          style={cssWrap}
          ref="list"
        >
          <ul className="logical-chain__list">
            {chain}
          </ul>
        </div>
      </section>
    );
  }

  onClick(event) {
    let target = event.currentTarget;
    let isExcess = target.getAttribute('data-is-excess');
    target.classList.add('logical-chain__item_state_animate_on');

    setDelay(ANIMATE_TIME).then(() => {
      target.classList.remove('logical-chain__item_state_animate_on')
    });

    if (isExcess === 'true') {
      setDelay(ANIMATE_TIME)
        .then(() => {
          target.classList.add('logical-chain__item_state_top');
          return setDelay(ANIMATE_TIME);
        })
        .then(() => {
          closest(target, '.logical-chain__list').classList.add('logical-chain__list_state_left');
          return setDelay(ANIMATE_TIME);
        })
        .then(() => {
          this.props.showNextFrame('logical-chain');
        })
    }
  }

  scaleGame() {
    let scaleValue = scale();

    // маштабировать игру под размер экрана
    this.setState((prevState, props) => {
      prevState.transform.scale = scaleValue;
      return prevState;
    });
  }
}

LogicalChain.propTypes = {
  showNextFrame: PropTypes.func.isRequired,
  chains: PropTypes.array.isRequired
};
