import { ajax } from '../../helpers';

/**
 * @typedef {Array.<Object>} Children
 */

/**
 * @returns {Promise.<Children>}
 */
export const getChildren = () => {
  return ajax
    .get('/children')
    .then(response => response.data)
    .catch(error => console.log('getChildren error: ', error));
};
