import React, {memo, useEffect, useRef} from 'react';
import {config} from '../../data';
import {getExtension} from '../../helpers/getExtension';
import {array} from 'prop-types';

export const VideoFact = memo((props) => {
  const {
    sources,
  } = props;

  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      ref.current.play();
    }
  }, [ref, sources]);

  return (
    <video ref={ref} className="video-fact" key={JSON.stringify(sources)}>
      {sources.filter((item)=>getExtension(item.video) === 'mp4').map((item, index) => (
        <source
          key={index}
          src={`${config.base_url}${item.video}`}
          type={getExtension(item.video) === 'mp4' ? 'video/mp4' : 'video/ogg'}
        />
      ))}
    </video>
  );
});

VideoFact.propTypes = {
  sources: array.isRequired,
};
