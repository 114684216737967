import React from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import c from 'classnames';
import * as api from '../../api';
import Popup from '../popup/popup';
import {i18n} from '../../data';
import * as auth from '../../helpers/authenticationHelper';

class CoinShop extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      token: null,
      paymentId: null,
      selectedTariff: null,
      backUrl: '',
      goods: [],
      loading: true,
      popup: {
        url: '/',
        section: 'coin-shop',
        btnTitle: i18n.prize_open_secrets
      }
    }
  }

  componentDidMount() {
    api.getProducts('coin')
      .then((goods) => {
        this.setState({
          loading: false,
          goods
        });
      });
  }

  handleClickOutside = () => {
    'closeShop' in this.props && this.props.closeShop()
  }

  hidePopUp = () => {
    this.setState({token: null})
  }

  buyCoin = (id) => {
    window.devMode && console.log('buyCoin', id);

    this.setState({loading: true, selectedTariff: {price: id}});

    const user = auth.getProfile();

    api.paymentsV2({product_id: id, child_id: user.currentChildId, quantity: 1})
      .then(data => {
        let good = this.state.goods.filter(g => g.id === id);
        let backUrl = `https://game.chevostik.ru/successful-payment?price=${good.length ? good[0].price : 0}&idTariff=${id}&nameTariff=${good.length ? good[0].title : ''}&paymentId=${data.payment_id}`;

        window.devMode && console.log('coin shop', data);

        this.setState({paymentId: data.payment_id, token: data.confirmation_token, backUrl: backUrl, loading: false})

      })
      .catch(() => this.setState({loading: false}))
  }

  render() {
    const {goods, loading, token, paymentId, backUrl} = this.state;

    return (
      <>
        <ul className={c('coin-shop', {'coin-shop--loading': loading})}>
          {
            goods.map(g => <li className="coin-shop__item" key={g.id}>
              <span className="coin-shop__title">{g.title}</span>
              <div className="coin-shop__img" style={{backgroundImage: `url(${g.image})`}}/>

              <button className="infant-garb__button" onClick={() => this.buyCoin(g.id)}>
                <span>{g.price} руб</span>
              </button>
            </li>)
          }
        </ul>

        {token && <div className='popup_holder'>
          <Popup
            popupData={{
              token: token,
              backUrl: backUrl,
              paymentId: paymentId
            }}
            location={this.props && this.props.location}
            is_closed={this.state.is_popup_closed}
            hidePopUp={this.hidePopUp}
            item={this.state.popup}
          />
        </div>}
      </>
    )
  }
}

const _CoinShop = enhanceWithClickOutside(CoinShop)

export {_CoinShop as CoinShop}
