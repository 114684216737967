/**
 * Проверяет загружено ли одно изображение
 *
 * @param {string|object} url ссылка на изображение
 * @returns {*|Promise}
 */
export function checkImageLoad(url) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    let imgUrl = typeof url === 'object' ? url.link : url;

    img.addEventListener('load', () => {
      return resolve(img);
    });
    img.addEventListener('error', () => {
      console.error('image error', url);
      return reject;
    });

    img.src = imgUrl;

    if (url.options) {
      let options = url.options;

      try {
        options = JSON.stringify(options);
        img.setAttribute('data-options', options);
      } catch (e) {
        window.devMode && console.log(e);
      }
    }
  });
}

/**
 * Проверяет, загружены ли все изображения (или изображение)
 *
 * @param {Array.<string> | string} images ссылки на изображения
 * @returns {Promise.<Event>}
 *
 * Пример использования:
 *
 * checkImagesLoad(['img/IMG_01.jpg', 'img/IMG_02.jpg', 'img/IMG_03.jpg'])
 *   .then(result => console.log('изрображения загружены'))
 *
 * checkImagesLoad('img/IMG_01.jpg')
 *   .then(result => console.log('изрображение загружено'))
 *
 * checkImagesLoad([{link: 'img/IMG_01.jpg', options: {someKey: 'sameValue'}])
 *   .then(result => console.log('изрображение загружено'))
 *
 */
export function checkImagesLoad(images) {

  if (Array.isArray(images)) {
    let imagesPromises = images.map(image => checkImageLoad(image));

    return Promise.all(imagesPromises);
  }

  if (!(Array.isArray(images)) && typeof images !== 'string'){
    throw new Error('you need pass a string or an array')
  }

  return checkImageLoad(images);
}
