import { ajax } from '../../helpers';

/**
 * @param {string} email
 * @returns {Promise}
 */
export const passwordReset = (email) => {
  return ajax
    .post('/auth/password ', {email})
    .then(response => response.data)
    .catch(error => error);
};
