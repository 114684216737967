import React from 'react';
import ArrowIcon from './svg_icon/arrow';

export class QuestSwitcher extends React.Component {

  state = {
    loaded: false,
    prevQuest: null,
    currentQuest: null,
    nextQuest: null
  };

  // componentDidMount() {
  //   this.setQuest()
  //   document.addEventListener('touchstart', this.handleTouchStart);
  // }

  componentDidUpdate(prevProps) {
    if (!this.state.loaded || (prevProps.activeQuestID !== this.props.activeQuestID)) this.setQuest()
  }

  // componentWillUnmount() {
  //   document.addEventListener('touchstart', this.handleTouchStart);
  // }

  handleTouchStart = (eventStart) => {
    const xStart = eventStart.changedTouches[0].clientX;
    const handleTouchEnd = (eventEnd) => {
      const xEnd = eventEnd.changedTouches[0].clientX;
      const xDiff = xStart - xEnd;
      if (Math.abs(xDiff) > 150) {
        xDiff > 0
          ? this.handleForwardClick()
          : this.handleBackClick()
      }
      document.removeEventListener('touchend', handleTouchEnd)
    }

    document.addEventListener('touchend', handleTouchEnd)
  }

  setQuest = () => {
    const {quests, activeQuestID} = this.props;
    const activeID = parseInt(activeQuestID, 10);
    const currentQuestIndex = quests.findIndex(q => q.id === activeID);

    if (!(quests && quests.length) || currentQuestIndex < 0) {
      return null;
    }

    const prevQuest = currentQuestIndex - 1 >= 0 ? quests[currentQuestIndex - 1] : quests[quests.length - 1];
    const nextQuest = currentQuestIndex + 1 < quests.length ? quests[currentQuestIndex + 1] : quests[0];
    const newState = {
      loaded: true,
      prevQuest: prevQuest,
      currentQuest: quests[currentQuestIndex],
      nextQuest: nextQuest
    }
    this.setState(newState)
  }

  changeQuest = (id) => {
    window.history.replaceState(window.history.state, document.title, `/quests/${id}`);
  }

  handleBackClick = () => {
    const {onChange} = this.props;
    const {prevQuest} = this.state;

    onChange(prevQuest.id);

    this.changeQuest(prevQuest.id);
  }

  handleForwardClick = () => {
    const {onChange} = this.props;
    const {nextQuest} = this.state;

    onChange(nextQuest.id);

    this.changeQuest(nextQuest.id);
  }

  render() {
    const {prevQuest, currentQuest, nextQuest} = this.state;

    if (!prevQuest || !currentQuest || !nextQuest) return null;

    return (
      <div className="questSwitcher">
        <ArrowIcon className="questSwitcher__left" onClick={this.handleBackClick}/>
        <div className="questSwitcher__wrapper">
          <ul className="questSwitcher__list">
            {
              [prevQuest, currentQuest, nextQuest].map(({id, name, total_lessons, finished_lessons}, idArray) => (
                <li key={id}>
                  {name}

                  {
                    idArray === 1 && (
                      <div>({finished_lessons} / {total_lessons})</div>
                    )
                  }
                </li>
              ))
            }
          </ul>
        </div>
        <ArrowIcon className="questSwitcher__right" onClick={this.handleForwardClick}/>
      </div>
    )
  }
}
