import React, { Component } from 'react';
import * as api from '../../api';
import { i18n } from '../../data';
import { emailSimpleRegExp } from '../../helpers'

import {
  Icon
} from '../../components';

export class DialogFeedback extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      isFeedbackSent: false
    };
  
    this.postFeedback = this.postFeedback.bind(this)
  }
  
  render() {
    let form = <div className="dialog-feedback">
        <h2 className="dialog-feedback__title">
          <Icon name='#envelope' width='56px' height='56px' />
          {i18n.feedbackTitle}
        </h2>
        <form className="form js-write-us-form" onSubmit={this.postFeedback}>
          <div className="form__group">
            <label className="form__label" htmlFor="name">{i18n.yourName}</label>
            <div className="form__right">
              <input className="form__input"  id="name" type="text" name="name" required={true}/>
            </div>
          </div>
          <div className="form__group">
            <label className="form__label" htmlFor="email">
              {i18n.yourEmail}
            </label>
            <div className="form__right">
              <input className="form__input" id="email" type="email" name="email" placeholder="goodname@yandex.ru" required={true}/>
            </div>
          </div>
      
          <div className="form__group">
            <label className="form__label" htmlFor="subject">{i18n.feedBackTheme}</label>
            <div className="form__right">
              <div className="select form__select">
                <select className="select__input" id="subject" required={true} name="subject">
                  <option value="" disabled>{i18n.feedBackOptions[0]}</option>
                  <option value="Подписка">{i18n.feedBackOptions[1]}</option>
                  <option value="Подписка">{i18n.feedBackOptions[2]}</option>
                  <option value="Подписка">{i18n.feedBackOptions[3]}</option>
                  <option value="Другое">{i18n.feedBackOptions[4]}</option>
                </select>
              </div>
            </div>
          </div>
      
          <div className="form__group">
            <label className="form__label" htmlFor="message">
              {i18n.feedBackMessage}
            </label>
            <div className="form__right">
              <textarea className="form__textarea" id="message" name="message" required={true}></textarea>
              <div className="form__controls">
                <button type="submit" className="btn btn_size_l form__btn">{i18n.feedBackSentBtn}</button>
                <span className="form__message js-feedback-form-message"></span>
              </div>
            </div>
          </div>
        </form>
      </div>;
      
    let formSent = <div className="dialog-feedback dialog-feedback_state_feedback_sent">
        <Icon name='#envelope' width='56px' height='56px' />
        <h2 className="dialog-feedback__title">{i18n.feedBackSentTitle}</h2>
        <p className="dialog-feedback__text">{i18n.feedBackSentText}</p>
      </div>;
    
    return (
      this.state.isFeedbackSent ? formSent : form
    );
  }
  
  postFeedback(event) {
    event.preventDefault();
  
    let form = event.currentTarget;
    let btn = event.currentTarget.querySelector('.form__btn');
  
    form.addEventListener('change', () => {
      btn.classList.remove('disabled')
    });
    
    if (!emailSimpleRegExp.test(form['email'].value)){
      btn.classList.add('disabled');
      return;
    }
    
    const feedback = {
      'name': form.elements['name'].value,
      'email': form.elements['email'].value,
      'subject': form.elements['subject'].value,
      'message': form.elements['message'].value
    };
    
    api.postFeedback(feedback).then(result => this.setState({ isFeedbackSent: true }));
  }
}
