import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { bannerType } from '../const';
import style from './spring.module.sass';
import * as images from './images';

export const Spring = ({type,handleButtonClick }) => {

  return (
    <div className={cn(style.spring, style[type])}>
      {
        (type === bannerType.routes)
        && <img
          src={images.cheRoutes}
          srcSet={`${images.cheRoutesRetina} 2x`}
          alt=""
          className={style.image}
        />
      }

      {
        (type === bannerType.small || bannerType.big)
        && <>
        <img alt={'pointer'} width={'100px'} style={{position:'absolute', bottom:'0', right:'0'}} src={'img_static/pointer.png'}/>
          <div>
          <div style={{fontSize:'70px'}}>Все курсы<br/>чевостика<br/>на год</div><div><img alt={'promo'} style={{marginTop:'51px'}} src={'/img_static/banner-promo.png'}/></div>
        </div>
          <div>
            <div style={{display:'flex', marginLeft:'50px'}}>
              <div className={style.promo_price}>Всего<br/> 1990Р</div>
              <div style={{marginLeft:'10px',padding:'5px',fontWeight:'normal',fontSize:'21px',color:'rgba(0,0,0,0.6)'}}>Вместо<br/><span style={{textDecoration:'line-through'}}>6600₽</span><br/>выгода<br/>4610₽

          </div></div>
          <div className={style.promo_list}>
            <div>✔ более 100 игр</div>
            <div>✔ более 100 тестов</div>
            <div>✔ более 100 приключений</div>
            <div>   <Link
              to="/cabinet/select_tariff/"
              className={style.button}
              onClick={handleButtonClick}
              type="button"
            >
              Купить со скидкой
            </Link></div>
          </div>
          </div></>
      }


    </div>
  );
};

Spring.propTypes = {
  type: PropTypes.string.isRequired,
  handleButtonClick: PropTypes.func
};
