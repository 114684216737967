import React, {Component} from 'react';
import {setPreloader} from '../../redux/actions';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {config} from '../../data';
import update from 'immutability-helper'; // https://github.com/kolodny/immutability-helper
import * as api from '../../api';
import {ChildForm} from '../../components';

import {
  redirect,
  checkImagesLoad,
} from '../../helpers';

import * as auth from '../../helpers/authenticationHelper';

class AddChildContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      child: {},
      avatar_id: config.avatars[0].toString(),
      content_loaded: false,
      categories: [],
      birthday: '',
      is_add_new_child_page: true,
      new_child: {},
      page_title: 'Добавить ребёнка'
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.onAvatarChange = this.onAvatarChange.bind(this);
  }

  componentDidMount() {
    if (this.state.child && this.state.categories) {
      this.props.setPreloader('add-child');
    }

    // 2. Получаю категории
    api.getCategories()
      .then(response => {
        let images = [];
        let currentChildCategories = response.map(item => {
          item.is_checked = false;
          return item;
        });

        currentChildCategories.forEach((item) => {
          if (item.icon) {
            images.push(`${config.base_url}${item.icon}`);
          }
        });

        checkImagesLoad(images)
          .then(() => {
            this.setState({
              categories: currentChildCategories,
              content_loaded: true
            }, () => {
              this.props.setPreloader('');
            })
          });
      })
  }

  render() {
    return (this.state.child && this.state.categories ? <ChildForm
        categories={this.state.categories}
        onCheckboxChange={this.onCheckboxChange}
        onFormChange={this.onFormChange}
        onAvatarChange={this.onAvatarChange}
        onFormSubmit={this.onFormSubmit}
        avatar_id={this.state.avatar_id}
        birthday={this.state.child.birthday}
        child={this.state.child}
        is_add_new_child_page={this.state.is_add_new_child_page}
      /> : null
    );
  }

  // смена полей в дате рождения и имени
  onFormChange(event) {
    let childEdited = {};
    childEdited[event.target.name] = event.target.value;

    this.setState((prevState, props) => ({
      new_child: Object.assign({}, prevState.new_child, childEdited)
    }));
  }

  // смена аватарок
  onAvatarChange(event) {
    let updatedAvatar = update(this.state.avatar_id, {$set: event.target.value});

    this.setState({
      avatar_id: updatedAvatar
    });
  }

  // смена чекбоксов с категориями
  onCheckboxChange(event) {
    let updatedCategories = update(this.state.categories, {
      [event.target.name]: {
        is_checked: {$set: !!event.target.checked}
      }
    });

    this.setState({
      categories: updatedCategories
    });
  }

  /**
   *
   * 1. Формирую дату
   * 2. Формирую выбранные категории
   * 3. Формирую объект с данными ребёнка
   * 4. Делаю запрос на сервер
   * 5. Меняю данные в localStorage
   * 6. Делаю редирект на страницу ребёнка
   *
   * @param event {MouseEvent}
   */
  onFormSubmit(event) {
    event.preventDefault();

    // 1. Формат даты для отправки: ГГГГ-ММ-ДД
    let birthday;
    if(this.state.new_child.birthday){
     birthday = this.state.new_child.birthday.replace(/(\d{1,2})\D(\d{1,2})\D(\d{4})/, '$3-$2-$1');
    }



    // 2.
    let categoryId = this.state.categories
      .filter(item => item.is_checked)
      .map(item => item.id);

    // 3.
    const CHILD = {
      name: this.state.new_child.name,
      avatar: this.state.avatar_id,
      birthday: birthday,
      category_ids: categoryId
    };

    this.setState({
      content_loaded: false
    }, () => {

      // 4.
      api.postChild(CHILD)
        .then(new_child => {
          // 5.
          auth.setCurrentChild(new_child.id, new_child.name, new_child.avatar);

          // 6.
          redirect(`/cabinet/child/${auth.getCurrentChild()}`)
          window.location.reload();
        })
    })
  }
}

const mapDispatchToProps = {
  setPreloader
};

const componentWithRouter = withRouter(connect(null, mapDispatchToProps)(AddChildContainer))

export {componentWithRouter as AddChildContainer};
