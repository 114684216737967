import { ajax } from '../../helpers';

/**
 * Получаем данные о текущем пользователе
 * @returns {Promise<Object>}
 */
export async function getCurrentUserProfile() {
  const response = await ajax.get('/users/me');
  return response.data;
}
