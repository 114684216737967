import React, {Component} from 'react';

export class GoldSale extends Component {
  constructor(props) {
    super(props);

    this.state = {
      skin: this.props.skin
    };
  }

  render() {
    const {skin} = this.state;

    return (
      <div className="gold">
        <h2 className="gold__title">
          Золотой<br/>
          костюм<br/>
          со скидкой!
        </h2>
        {skin.price && <div className="gold__count">{skin.price}</div>}
        <div className="gold__text">Успей купить!</div>
        <img className="gold__image"
             src='/img/promo/gold-popup.png'
             srcSet='/img/promo/gold-popup@2x.png 2x'
             alt=""
        />
        <button type="button" className="gold__button" onClick={() => {
          this.props.goToLink('gold')
        }}>Примерить
        </button>
      </div>
    )
  }
}
