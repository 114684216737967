import * as auth from './authenticationHelper';

const checkGameAccess = (game) => {

  let user = auth.getProfile();
  let userSubscription = user && user.subscription && user.subscription.status !== 'off';
  let isLessonsAccessIsOpen = game.lesson && game.lesson.access.is_open;
  let isShared = user && user.shared_link && game.share_bonus;
  let isQuestFinished = (game.lesson && game.lesson.quest && game.lesson.quest.id) && (game.lesson && game.lesson.test && game.lesson.test.is_completed);
  let isTestFinished = isLessonsAccessIsOpen && game.lesson && game.lesson.test && game.lesson.test.is_completed;
  let isFree = game.lesson && game.lesson.access.is_free;
  if (!game.lesson && userSubscription === true) return true;
  if (game.is_open === true) return true;

  return user
    && (
      isShared
      || (
        (isFree || userSubscription)
        && (isQuestFinished || isTestFinished)
      )
    );
};

export default checkGameAccess;
