import React, {Component} from 'react';
import {redirect, isPhone} from '../../helpers';
import {LessonsForTours} from '../../containers';
import {toursData} from '../../data';

export class Tours extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0
    };

    this.nextTour = this.nextTour.bind(this);
    this.prevTour = this.prevTour.bind(this);
  }

  keyPress = (event) => {
    if (event.keyCode === 37) {
      this.prevTour();
    }
    if (event.keyCode === 39) {
      this.nextTour();
    }
  }

  componentDidMount() {
    // Не отображаем tour в мобилке, а ридеректим пользователя
    if (isPhone()) redirect('/lessons');
    document.addEventListener('keydown', this.keyPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyPress);
  }

  nextTour = () => {
    if (this.state.currentStep + 1 === toursData.length) return redirect('/lessons');

    this.setState({currentStep: this.state.currentStep + 1});
  };

  prevTour = () => {
    this.setState({currentStep: this.state.currentStep - 1});
  };

  render() {
    const {currentStep} = this.state;
    const currentTour = toursData[currentStep];

    return (
      <div className="tours">
        <LessonsForTours highlightLessons={currentTour.highlightLessons}/>
        <div className="tours__wrapper">
          {currentTour.img.map((item) => <img key={item.path} className={item.className} src={item.path} style={item.style} alt="chevostik"/>)}
          <div className="tours__block">
            {currentStep + 1 === toursData.length && <button className="tours__close" onClick={this.nextTour}/>}
            {currentStep + 1 !== toursData.length && <button className="tours__next" onClick={this.nextTour}/>}
            {currentStep !== 0 && <button className="tours__prev" onClick={this.prevTour}/>}
            <div className="tours__container">
              <div className={`tours__text ${currentTour.linkBtn && 'tours__text--withLink'}`}>{currentTour.description}</div>
              {currentTour.icon && <div className="tours__icon" style={{backgroundImage: `url(${currentTour.icon})`}} alt="Иконки"/>}
              {currentTour.linkBtn && <button className="tours__link" onClick={() => redirect(currentTour.linkBtn.link)}>{currentTour.linkBtn.name}</button>}
            </div>
            <ul className="tours__steps">
              {toursData.map((e, index) => <li
                key={index}
                className={`tours__step ${index === currentStep && 'tours__step--active'}`}
                onClick={() => this.setState({currentStep: index})}
              ></li>)}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
