import React, {Component} from 'react';
import PropTypes from 'prop-types';

export class LessonProgressAnimation extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showenStatus50: 'not-shown',
      showenStatus75: 'not-shown',
      showenStatusFinish: 'not-shown',
      showenStatus50TimerId: null,
      showenStatus75TimerId: null
    };

    this.updateStatus = this.updateStatus.bind(this)
    this.closeBanner = this.closeBanner.bind(this)
    this.goPlayAfterFinish = this.goPlayAfterFinish.bind(this)
  }

  componentDidMount() {
    const {percent, isPersonAudioPlaying} = this.props;

    this.setState({
      showenStatus50: percent >= 50 ? 'shown' : 'not-shown',
      showenStatus75: percent >= 75 ? 'shown' : 'not-shown',
      showenStatusFinish: percent === 100 && isPersonAudioPlaying !== null ? 'show' : 'not-shown'
    });

    window.resetStatus = () => {
      this.setState({
        showenStatus50: 'not-shown',
        showenStatus75: 'not-shown',
        showenStatusFinish: 'not-shown',
        showenStatus50TimerId: null,
        showenStatus75TimerId: null
      })
    }
  }

  componentDidUpdate(prevProps) {
    const {percent, isPersonAudioPlaying} = this.props;
    const {showenStatus50, showenStatus75, showenStatusFinish} = this.state;

    if ((percent >= 50 && showenStatus50 === 'not-shown')) {
      const timerId = setTimeout(() => this.updateStatus('showenStatus50', 'shown'), 3000000)
      this.setState({
        showenStatus50: 'show',
        showenStatus50TimerId: timerId
      })
      return
    }

    if (percent >= 75 && showenStatus75 === 'not-shown') {
      const timerId = setTimeout(() => this.updateStatus('showenStatus75', 'shown'), 3000000)
      this.setState({
        showenStatus75: 'show',
        showenStatus75TimerId: timerId
      })
      return
    }

    if (
      percent === 100 &&
      !isPersonAudioPlaying &&
      isPersonAudioPlaying !== null &&
      showenStatusFinish === 'not-shown'
    ) {
      this.setState({
        showenStatusFinish: 'show'
      })
    }
  }

  componentWillUnmount() {
    const {showenStatus50TimerId, showenStatus75TimerId} = this.state;
    clearTimeout(showenStatus50TimerId);
    clearTimeout(showenStatus75TimerId);
  }

  updateStatus(type, status) {
    this.setState({
      [type]: status
    })
  }

  closeBanner() {
    const {showenStatus50, showenStatus75, showenStatus50TimerId, showenStatus75TimerId} = this.state;

    if (showenStatus50 === 'show') {
      clearTimeout(showenStatus50TimerId);
      this.updateStatus('showenStatus50', 'shown');
    } else if (showenStatus75 === 'show') {
      clearTimeout(showenStatus75TimerId);
      this.updateStatus('showenStatus75', 'shown');
    }
  }

  goPlayAfterFinish() {
    this.props.setContinue()
    this.setState({showenStatusFinish: 'not-show'})
  }

  render() {
    const {showBlur} = this.props;
    const {showenStatus50, showenStatus75} = this.state;

    return (<>
        {showenStatus50 === 'show' && showBlur ?
          <div className="lesson-progress-animation">
            <div className="lesson-progress-animation__hello"/>
            <div className="lesson-progress-animation__message">
              <p>Вот это да!<br/>
                Ты прошёл уже половину
                урока! Так держать!</p>
              <p><span onClick={this.closeBanner} className={'infant-garb__button __orange'}>
                <span>Продолжить</span>
              </span></p>
            </div>
          </div> : null}

        {showenStatus75 === 'show' && showBlur ?
          <div className="lesson-progress-animation">
            <div className="lesson-progress-animation__hello"/>
            <div className="lesson-progress-animation__message">
              <p>Теперь тебе <br/>
                доступен тест!</p>
              <p><span onClick={this.closeBanner} className={'infant-garb__button __orange'}>
                  <span>Продолжить</span>
                </span></p>
            </div>
          </div> : null}

        {/*{showenStatusFinish === 'show' ? <Redirect to={`/lessons/${lessonUrl}/tests/${testUrl}`}/> : null}*/}

        {/*{showenStatusFinish === 'show' ?*/}
        {/*  <div className="lesson-progress-animation">*/}
        {/*    <div className="lesson-progress-animation__success"/>*/}
        {/*    <div className="lesson-progress-animation__message lesson-progress-animation__message--success">*/}
        {/*      <p>Вот это да! <br/>*/}
        {/*        Ты прослушал все факты!</p>*/}
        {/*      <p>*/}
        {/*            <span className={'lesson-progress-animation__center'}>*/}
        {/*              <span className={'lesson-progress-animation__question'}/>*/}
        {/*              <span className={'lesson-progress-animation__center--rays'}/>*/}
        {/*            </span>*/}
        {/*      </p>*/}
        {/*      <p><Link className="infant-garb__button __orange" to={`/lessons/${lessonUrl}/tests/${testUrl}`}>*/}
        {/*        <span>Пройти тест</span></Link></p>*/}
        {/*    </div>*/}
        {/*  </div> : null}*/}
      </>
    )
  }
}

LessonProgressAnimation.propTypes = {
  showBlur: PropTypes.bool,
  percent: PropTypes.number
};
