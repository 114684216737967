/**
 * Проверяет URL аватарки
 * @returns string
 * @param child
 */
export const buildAvatarUrl = (child) => {
  let ava = 'url(/img/avatars/default_avatar.png)';
  if (child && child.hasOwnProperty('avatar') && child.avatar) {
    ava = `url(${child.avatar})`;
  }
  return ava;
}
