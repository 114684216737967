import { ajax } from '../../helpers';

/**
 * @typedef {Array.<Object>} Tariffs
 */

/**
 * @returns {Promise.<Tariffs>}
 */
export const getTariffs = () => {
  return ajax
    .get('/tariffs')
    .then(response => response.data)
    .catch(error => console.log('getTariffs error: ', error));
};
