import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import {getProfile} from '../../helpers/authenticationHelper';
import queryString from 'query-string';

class SuccessfulPayment extends Component {

  state = {
    sendStatsGtag: false
  }

  componentDidMount() {
    window.ym(92335881,'reachGoal','pokupka');
    window._tmr.push({ type: 'reachGoal', id: 3319847, goal: 'Pokupka'});

    this.sendStats(true);
  }

  componentWillUnmount() {
    const {sendStatsGtag} = this.state;
    if (!sendStatsGtag) this.sendStats();
  }

  sendStats = (updateState) => {
    const {sendStatsGtag} = this.state;
    const query = queryString.parse(this.props.location.search);

    if (window.gtag && window.dataLayer && !sendStatsGtag) {
      // todo gtag
      //window.gtag(
      //  'event',
      //  'conversion',
      //  {
      //    'send_to': 'AW-578549561/4ZtiCJvjhuoBELnu75MC',
      //    'transaction_id': query.paymentId,
      //  },
      //)
      window.dataLayer.push({
        ecommerce: {
          purchase: {
            actionField: {
              id: query.paymentId,
              affiliation: 'Chevostik.ru',
              revenue: query.price,
              ...(query.promoWord ? {coupon: query.promoWord} : {})
            },
            products: [{
              name: query.nameTariff,
              id: query.idTariff,
              price: query.price,
              paymentType: query.type,
              brand: 'Chevostik.ru',
              ...(query.promoWord ? {coupon: query.promoWord} : {})
            }]
          }
        }
      });

      window.gtag({event: 'order', value: query.price, transactionID: query.paymentId});

      if (updateState) this.setState({sendStatsGtag: true});
    }
  }

  render() {
    const profile = getProfile();
    const paymentType = queryString.parse(this.props.location.search).type
    const questName = queryString.parse(this.props.location.search).questName

    return <div className="successful-payment">
      <h1 className="successful-payment__header">Спасибо! Оплата прошла успешно.</h1>
      {profile && <p className="successful-payment__text">
        {paymentType === 'quest' ? (<>Маршрут {questName} доступен</>) :(<>Подписка оформлена {profile.subscription.message}</>)}
      </p>}
      {paymentType === 'quest' ? (
        <p className="successful-payment__description">Играйте, изучайте, проходите тесты!</p>) : (
        <p className="successful-payment__description">Теперь вам доступны все функции портала,<br/>изучайте уроки,
          проходите тесты и играйте в игры!</p>)}

      <Link className="successful-payment__close" to="/">Закрыть и продолжить</Link>
    </div>
  }
}

const componentWithRouter = withRouter(SuccessfulPayment)
export {componentWithRouter as SuccessfulPayment}
