// Изначальные размеры фоновой картинки
const BASE_CONTAINER = {
  width: 3524,
  height: 848
};
const placeForTopAndBottomTools = 200

/**
 * @param {HTMLElement|String} container
 */
export function scaleContainer(container) {
  // От контейнера будет маштабироваться всё, что лежит внутри
  let domNode = typeof container === 'string' ?
    document.querySelector(container) :
    container;

  if (!domNode) return;

  scale(domNode);
  window.addEventListener('resize', () => scale(domNode));
}

/**
 * Изменяет маштаб блока, если блок не передан, возвращает коэффициент масштабирования
 *
 * @param {HTMLElement} [block]
 * @returns {Number}
 */
function scale(block, isLesson = false) {
  let scaleX = window.innerWidth / BASE_CONTAINER.width;
  let scaleY = window.innerHeight / BASE_CONTAINER.height;
  let scaleBothValue = isLesson ? {scaleX, scaleY} : Math.max(scaleX, scaleY);

  if (block) {
    let transform = block.style.transform;

    scaleBothValue = `scale(${scaleBothValue})`;

    if ((/scale/).test(transform)) {
      transform = transform.replace(/scale([^)]+\))/, scaleBothValue);
    } else {
      transform = `${transform} ${scaleBothValue}`;
    }

    block.style.transform = transform;
  } else {
    return scaleBothValue;
  }
}

function scaleMap(block, isLesson = false) {
  let scaleX = window.innerWidth / BASE_CONTAINER.width;
  let scaleY = (window.innerHeight - placeForTopAndBottomTools) / BASE_CONTAINER.height;
  let scaleBothValue = isLesson ? {scaleX, scaleY} : Math.max(scaleX, scaleY);

  if (block) {
    let transform = block.style.transform;

    scaleBothValue = `scale(${scaleBothValue})`;

    if ((/scale/).test(transform)) {
      transform = transform.replace(/scale([^)]+\))/, scaleBothValue);
    } else {
      transform = `${transform} ${scaleBothValue}`;
    }

    block.style.transform = transform;
  } else {
    return scaleBothValue;
  }
}

function getWidthMap() {
  const scaleX = window.innerWidth / BASE_CONTAINER.width;
  const scaleY = (window.innerHeight - placeForTopAndBottomTools) / BASE_CONTAINER.height;
  const scaleBothValue = Math.max(scaleX, scaleY);
  return [Math.round(BASE_CONTAINER.width * scaleBothValue), window.innerWidth, scaleBothValue];
}

export {scale, scaleMap, getWidthMap};
