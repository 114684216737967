import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { closest, isTouch } from '../../helpers';


export class DialogBook extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isVideoShown: false
    };
    this.showVideo = this.showVideo.bind(this);
  }

  render() {
    const {
      preview
    } = this.props;

    let videoWidth = '896';
    let videoHeight = '504';

    if (isTouch()) {
      videoWidth = '100%';
      videoHeight= '100%';
    }

    let DialogBookStep1 = <div
        className="dialog-book"
        style={{backgroundImage: `url(${preview[0].src})`}}
      >

        <span className="dialog-book__btn" onClick={this.showVideoDisabled}>
          <img
            src={preview[1].src}
            srcSet={`${preview[2].src} 2x`}
            alt="Видео о Чевостике"
            onClick={this.showVideo}
          />
        </span>


        <div
          className="dialog-book__btn-arrow"
          style={{backgroundImage: `url(${preview[3].src})`}}
          alt="Видео о Чевостике"
          onClick={this.showVideo}
        >
        </div>


        <div className="dialog-book__content">

          <span className="dialog-book__title">Привет, меня<br/> зовут Чевостик!</span>

          <ul className="dialog-book__list">
            <li>Слушай ожившие истории обо&nbsp;всём на&nbsp;свете</li>
            <li>Проверяй свои знания
              с&nbsp;помощью тестов</li>
            <li>Проходи игры на&nbsp;логику и&nbsp;внимание</li>
          </ul>
        </div>
      </div>;
    let DialogBookStep2 = <div className="dialog-book dialog-book_state_step2">
      <iframe width={videoWidth} height={videoHeight} src="https://www.youtube.com/embed/-KTGTJCrzQ8?autoplay=1" frameborder="0" allowfullscreen></iframe>
    </div>;

    return this.state.isVideoShown ? DialogBookStep2 : DialogBookStep1;
  }

  showVideo(event) {
    //TODO: refactor
    closest(event.currentTarget, '.popup').classList.remove('popup_state_book_step1');
    closest(event.currentTarget, '.popup__dialog').classList.remove('popup__dialog_book_step1');
    closest(event.currentTarget, '.popup').classList.add('popup_state_book_step2');
    closest(event.currentTarget, '.popup__dialog').classList.add('popup__dialog_book_step2');

    this.setState({
      isVideoShown: true
    })
  }
}

DialogBook.propTypes = {
  preview: PropTypes.array
};
