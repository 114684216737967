import React, {Component} from 'react';
import {config, i18n} from '../../data';
import * as api from '../../api';
import * as auth from '../../helpers/authenticationHelper';

import {
  redirect,
  checkImagesLoad,
  isPhone,
  isTablet,
  onEscapeKeyPress
} from '../../helpers'
import {
  PageTooltip,
  Slider
} from '../../components';
import MapNav from '../../components/map-nav/map-nav';
import {setPreloader} from '../../redux/actions';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';

const LOCAL_DATA = {
  message: 'Слушай истории, отвечай на вопросы и получай призы!',
  prizes_in_row_desktop: 6,
  prizes_in_row_tablet: 5,
  prizes_in_row_mobile: 3
};

class PrizesContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      image_loaded: false,
      pre_loader_type: 'prize',
      super_prizes: [],
      prizes: [],
      is_super_prizes_show: false
    };

    this.onSuperPrizesBtnClick = this.onSuperPrizesBtnClick.bind(this);
  }

  componentDidMount() {
    this.props.setPreloader('prize');
    let currentChildId = auth.getCurrentChild();

    if (currentChildId) {
      Promise.all([
        api.getPrizes(currentChildId),
        api.getSuperPrizes(currentChildId)
      ]).then(result => {

        this.setState({
            prizes: result[0],
            super_prizes: result[1]
          }, () => {
            let images = [];

            this.state.prizes.forEach((item) => {
              if (item.image) {
                images.push(`${config.base_url}${item.image}`);
              }
            });

            checkImagesLoad(images)
              .then(result => this.setState({image_loaded: true}, () => {
                this.props.setPreloader('');
              }))
          }
        )
      });

      // добавляем класс для скролла по вертикали
      document.querySelector('.app').classList.add('app_type-overflow-auto', 'app_state_bg_prizes');
      document.querySelector('body').classList.add('body_color_prizes');
    } else {
      redirect();
    }
  }

  componentWillUnmount() {
    document.querySelector('.app').classList.remove('app_type-overflow-auto', 'app_state_bg_prizes');
    document.querySelector('body').classList.remove('body_color_prizes');
    window.removeEventListener('keydown', () => onEscapeKeyPress(this.hidePopUp));
  }

  render() {
    const user = auth.getProfile();
    let arr = [];
    let prizesCopy = [...this.state.prizes];
    let prizesInRow = LOCAL_DATA.prizes_in_row_desktop;
    let superPrizeList = null;
    let superPrize = this.state.super_prizes.map((item, index) => {
      let imgUrl = item.stage_0_image;

      if (item.stage === 1) {
        imgUrl = item.stage_1_image
      }

      if (item.stage === 2) {
        imgUrl = item.stage_2_image
      }

      if (item.stage === 3) {
        imgUrl = item.stage_3_image
      }

      return (
        <div
          className={`super-prizes__item super-prizes__item_state_${item.stage === 3 ? 'full' : 'not_full'} super-prizes__item_index_${index}`}
          key={index}
        >
          <i style={{backgroundImage: `url(${`${config.base_url}${imgUrl}`})`}}>
            {
              index === 0 && item.stage === 0
                ? (<PageTooltip
                    title="Это времяскок!"
                    message="Получи 9 призов, чтобы открыть времяскок и узнать секретные факты"
                    position="top"/>
                )
                : null
            }
            {
              item.stage && item.stage !== 3
                ? (<PageTooltip
                    title={i18n.prize_tooltip_title_less}
                    message={i18n.prize_tooltip_text_less}
                    position="top"
                    autoKill={5000}/>
                )
                : null
            }
          </i>
        </div>
      )
    });
    let btnSuperPrizes = null;

    // 1. Вывод таблицы

    // 1.2 Выбираем количество призов в ряду
    if (isTablet()) {
      prizesInRow = LOCAL_DATA.prizes_in_row_tablet
    } else if (isPhone()) {
      prizesInRow = LOCAL_DATA.prizes_in_row_mobile
    }

    // 1.3 Добавляем каждый ряд в отдельную ячейку массива
    while (prizesCopy.length) {
      let p = prizesCopy.splice(0, prizesInRow);
      arr.push(p);
    }

    // 1.4 Выводим ряд
    let rows = arr.map((row, index) =>
      // вывожу ряд
      <tr
        key={index}
        className="prizes-table__row">
        {
          row.map((cell, cellIndex) => {
            return (
              // вывожу ячейку
              <td
                className={`prizes-table__cell ${cell.is_received ? '' : 'prizes-table__cell_state_disabled'}`}
                key={cellIndex}
              >
                <img
                  src={`${config.base_url}${cell.image}`}
                  alt={cell.name}
                />
                <span className="prizes-table__cell-description">
                  <span className="prizes-table__cell-text">
                    {cell.name}
                  </span>
                </span>
              </td>
            )
          })
        }
      </tr>
    );

    // 1.5 Выводим таблицу
    let table = <table
      className={`prizes-table
          ${isPhone() ? 'prizes-table_state_phone' : ''}
          ${isTablet() ? 'prizes-table_state_tablet' : ''}
        `}
    >
      <tbody>
      {rows}
      </tbody>
    </table>;

    // 2. Вывод супер призов
    if (!isPhone()) {
      superPrizeList = <section className="super-prizes">
        {superPrize}
      </section>
    } else {

      superPrizeList = <div
        className={`super-prizes super-prizes_type_nav super-prizes_type_nav_${this.state.is_super_prizes_show ? 'show' : 'hide'}`}>
        <Slider
          slidesToShow={1}
          cellAlign={'center'}
          slideItems={superPrize}
        >
        </Slider>

        <p className="super-prizes__text">Собери полный времяскок, чтобы получить секретные знания</p>
      </div>
    }

    // 3. Кнопка для призов
    if (isPhone()) {

      btnSuperPrizes = <button
        className={`prizes-page__btn-super-prizes prizes-page__btn-super-prizes_state_${this.state.is_super_prizes_show ? 'on' : 'off'}`}
        type="button"
        onClick={this.onSuperPrizesBtnClick}
      >
      </button>

    }

    return (
      <div className={`prizes-page ${isPhone() ? 'prizes-page_state_phone' : ''}`}>

        <MapNav
          user={user}
          lessonsScreenType="prizes"
        />

        <header className="prizes-page__header">
          <button type="button"
                  className={`prizes-page__btn prizes-page__btn_state_${this.state.is_super_prizes_show ? 'super-prizes' : 'prizes'}`}>
            <i className="prizes-page__icon">
              <PageTooltip
                message={LOCAL_DATA.message}
                link="/"
              />
            </i>
            {this.state.is_super_prizes_show ? 'Времяскоки' : 'Сокровищница'}
          </button>
          {/* Непонятная кнопка */}
          {!isPhone() && btnSuperPrizes}
        </header>

        <div className="wrapper wrapper_prizes">
          {table}
          <div className="prize-page__shadow"/>
          {superPrizeList}
        </div>

        {/*<PreLoader*/}
        {/*  image_loaded={ this.state.image_loaded}*/}
        {/*  pre_loader_type={ this.state.pre_loader_type}*/}
        {/*/>*/}
      </div>
    );
  }

  onSuperPrizesBtnClick() {
    if (this.state.is_super_prizes_show) {
      document.querySelector('.app').classList.add('app_type-overflow-auto', 'app_state_bg_prizes');
      document.querySelector('.app').classList.add('app_type-overflow-auto', 'app_state_bg_prizes');
    } else {
      document.querySelector('.app').classList.remove('app_type-overflow-auto', 'app_state_bg_prizes');
      document.querySelector('.app').scrollTop = 0
    }

    this.setState({
      is_super_prizes_show: !this.state.is_super_prizes_show
    })
  }
}

const mapDispatchToProps = {
  setPreloader
};

const componentWithRouter = withRouter(connect(null, mapDispatchToProps)(PrizesContainer))

export {componentWithRouter as PrizesContainer};
