import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper'; // https://github.com/kolodny/immutability-helper
import { scaleGame, setDelay } from '../../helpers';
import { config } from '../../data';

const ANIMATE_TIME = 1300;

export class FindHiddenHero extends Component {

  constructor(props) {
    super(props);

    // значение в % для центрирования игры
    const defaultCenterAxisValue = -50;

    this.state = {
      counter: 0,
      /* CSS  */
      transform: {
        scale: 1,
        translate: {
          x: defaultCenterAxisValue,
          y: defaultCenterAxisValue
        }
      },
    };

    this.defaultCenterAxisValue = defaultCenterAxisValue;
    this.scaleGame = this.scaleGame.bind(this);
    this.onHeroClick = this.onHeroClick.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.scaleGame);
    this.scaleGame();
    let answers = [];

    this.props.heroes.forEach(item => {
      answers.push({
        is_found: false
      })
    });

    this.setState({ answers })
  }

  render() {
    let cssTransform = this.state.transform;
    let heroes = null;
    let panelItem = null;
    let backgroundImage = this.props.background.src;
    let cssTransformStyle = {
      transform: `
        scale(${cssTransform.scale})
        translate(${cssTransform.translate.x}%, ${cssTransform.translate.y}%)
      `
    };
    let sceneItem = null;
    let css = this.props.css ? this.props.css : null;

    if (this.state.answers) {
      panelItem = this.state.answers.map((item, index) => {
        return (
          <div
            key={index}
            className={`find-hidden-hero__panel-item find-hidden-hero__panel-item_state_${item.is_found ? 'is_found' : 'not_found'}`}
          >

          </div>
        )
      });
    }

    if (this.props.heroes) {
      heroes = this.props.heroes.map((item, index) => {
        return (
          <div
            key={index}
            className={`find-hidden-hero__hero find-hidden-hero__hero_${index+1}`}
            onClick={this.onHeroClick}
            style={{backgroundImage: `url(${item.src})`, width: item.width, height: item.height}}
          >
          </div>
        )
      });
    }

    if (this.props.scene) {
      sceneItem = this.props.scene.map((item, index) => {
        return (
          <div
            key={index}
            style={{backgroundImage: `url(${item.src})`, width: item.width, height: item.height}}
            className={`find-hidden-hero__decoration find-hidden-hero__decoration_${index + 1}`}
          >
          </div>
        )
      });

    }

    return (
      <section
        className="find-hidden-hero"

      >
        <h1 className="game-page__name">
          Где спрятался Чевостик?
        </h1>
        <div
          className="find-hidden-hero__inner"
          style={cssTransformStyle}
        >
          <div
            className="find-hidden-hero__layout"
            style={{backgroundImage: `url(${backgroundImage})`}}>
            >
            <style>{css}</style>
            {heroes}
            {sceneItem}

          </div>
        </div>


        <div className="find-hidden-hero__panel">
          {panelItem}
        </div>
      </section>
    );
  }

  onHeroClick(event) {
    event.currentTarget.classList.add('find-hidden-hero__hero_translate_top');

    let counter = this.state.counter;

    let newAnswer = update(this.state.answers, {
      [counter]: {
        is_found: {$set : true}
      }
    });

    this.setState({
      counter: this.state.counter + 1,
      answers: newAnswer
    });

    if (counter >= this.state.answers.length-1){
      setDelay(ANIMATE_TIME).then(() => {
        this.props.showPostLoader(`${config.game_types.find_hidden_hero}`)
      });
    }
  }

  scaleGame() {
    let scaleValue = scaleGame();

    // маштабировать игру под размер экрана
    this.setState((prevState, props) => {
      prevState.transform.scale = scaleValue;
      return prevState;
    });
  }
}

FindHiddenHero.propTypes = {
  showPostLoader: PropTypes.func.isRequired,
  scene: PropTypes.array.isRequired,
  heroes: PropTypes.array.isRequired,
  background: PropTypes.string.isRequired,
  css: PropTypes.string.isRequired
};
