import React, {Component} from 'react';

export class BlackFridayBanner extends Component {
  render() {
    return (
      <div className="black-friday-banner">
        <h2 className="black-friday-banner__title">Черная пятница</h2>
        <div className="black-friday-banner__image-wrap">
          <img src="/img/promo/black-friday.png"
               srcSet="/img/promo/black-friday@2x.png 2x"
               alt=""
               className="black-friday-banner__image"/>
        </div>
        <div className="black-friday-banner__sale">Скидка 75%</div>
        <div className="black-friday-banner__description">Оформи только сегодня годовую подписку</div>
        <div className="black-friday-banner__actions">
          <button onClick={this.props.onAction} type="button" className="subscribe-btn black-friday-banner__button">
            <span className="subscribe-btn__inner">Подписаться</span>
          </button>
        </div>
      </div>
    );
  }
}
