import React from 'react';
import PropTypes from 'prop-types';
import {
  getVkLink,
  getFbLink
} from '../../helpers/generateSocialAuthUrl';
import {
  Icon
} from '../../components';

export const AuthSocialButton = props => (
  <a href={props.href} className={`auth-button auth-button_type_${props.type}`}>
    <Icon name={`#social-links--${props.type}_icon`} width='60px' height='30px' fill="white"/>
  </a>
)
AuthSocialButton.propTypes = {
  href: PropTypes.string,
  type: PropTypes.string
};

export const AuthSocialButtonVk = props => (
  <AuthSocialButton
    href={getVkLink({ redirect_uri: props.redirect_uri })}
    type="vk"
  />
);
AuthSocialButtonVk.propTypes = {
  redirect_uri: PropTypes.string
};

export const AuthSocialButtonFb = props => (
  <AuthSocialButton
    href={getFbLink({ redirect_uri: props.redirect_uri })}
    type="fb"
  />
);
AuthSocialButtonFb.propTypes = {
  redirect_uri: PropTypes.string
};

export const AuthSocialButtonOk = props => (
  <AuthSocialButton
    href={getVkLink({ redirect_uri: props.redirect_uri })}
    type="ok"
  />
);
