import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NukaCarousel from 'nuka-carousel';
import createReactClass from 'create-react-class';

// Навигация для кнопок
let SliderNav = [
  {
    component: createReactClass({
      render() {
        return (
          <button
            onClick={this.props.previousSlide}
            type="button"
            className={`slider__btn slider__btn_type_previous slider__btn_type_${this.props.currentSlide === 0 && !this.props.wrapAround ? 'disabled': ''}`}
          >
            Previous Slide
          </button>
        )
      }
    }),
    position: 'CenterLeft',
    style: {
      padding: 0
    }
  },
  {
    component: createReactClass({
      render() {
        return (
          <button
            className={`slider__btn slider__btn_type_next slider__btn_type_${this.props.currentSlide + this.props.slidesToScroll >= this.props.slideCount && !this.props.wrapAround ? 'disabled': ''}`}
            type="button"
            onClick={this.props.nextSlide}>
            Next Slide
          </button>
        )
      }
    }),
    position: 'CenterRight',
    style: {
      padding: 0
    }
  }
];

export class Slider extends Component {

  mixins: [Slider.ControllerMixin];

  render() {
    const {
      slideItems,
      slidesToShow,
      cellAlign
    } = this.props;

    return (
      <NukaCarousel
        slidesToShow={slidesToShow}
        cellAlign={cellAlign}
        decorators={slideItems.length > slidesToShow ? SliderNav : []}
      >
        {slideItems}
      </NukaCarousel>
    );
  }
}

Slider.propTypes = {
  slideItems: PropTypes.array,
  cellAlign: PropTypes.string,
  slidesToShow: PropTypes.number
};
