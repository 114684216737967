import React, {memo, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import cn from 'classnames';
import {bool, array, func, string} from 'prop-types';
import {config} from '../../data';
import {VideoFact} from '../video-fact';
import {getExtension} from '../../helpers/getExtension';

const checkSafari = () => navigator.userAgent.search('Safari') >= 0 && navigator.userAgent.search('Chrome') < 0;

export const PopupFact = memo((props) => {
  const {
    show,
    popups,
    currentPopupDomId,
    onClose,
    onFactPlay,
  } = props;

  const [popupIndex, setPopupIndex] = useState(0);
  const isLoadedRef = useRef(false);

  const handlerOnClick = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handlerPrevClick = useCallback(() => {
    if (popupIndex > 0) {
      setPopupIndex((popupIndex) => popupIndex - 1);
    }
  }, [popupIndex]);

  const handlerNextClick = useCallback(() => {
    if (popupIndex < popups.length - 1) {
      setPopupIndex((popupIndex) => popupIndex + 1);
    }
  }, [popupIndex, popups]);

  const getVideo = useCallback(() => {
    const isSafari = checkSafari();
    const videos = popups[popupIndex].fact?.content.fact_videos;
    let isSafariFactVideoExists = false;

    if (videos.length) {
      isSafariFactVideoExists = videos
        .filter((item) => getExtension(item.video) === 'mp4')
        .length > 0;
    }

    if (videos.length && !(isSafari && !isSafariFactVideoExists)) {
      return videos.sort((a) => getExtension(a.video) === 'mp4');
    }

    return false
  }, [popupIndex, popups]);

  useEffect(() => {
    if (currentPopupDomId) {
      for (let i = 0; i < popups.length; i++) {
        if (popups[i].dom_id === currentPopupDomId) {
          setPopupIndex(i);
          break;
        }
      }
    }
  }, [currentPopupDomId, popups]);

  useEffect(() => {
    if (isLoadedRef.current && onFactPlay) {
      onFactPlay(popups[popupIndex].fact, popups[popupIndex].personDomId);
    }
  }, [onFactPlay, popupIndex, popups, isLoadedRef]);

  useEffect(() => {
    if (!isLoadedRef.current) {
      isLoadedRef.current = true;
    }
  }, []);

  const imageStyle = useMemo(() => ({
    backgroundImage: `url(${config.base_url}${popups[popupIndex]?.animation})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  }), [popupIndex, popups]);

  if (!show) {
    return null;
  }

  const video = getVideo();

  return (
    <div
      className={cn(
        'popup-fact',
        {
          'popup-fact_showed': show,
        }
      )}
    >
      <div
        className="popup-fact__inner"
        onClick={handlerOnClick}
      >
        {window.innerWidth > 900 ? (<style>{`.popup-fact__image {${popups[popupIndex].css}}`}</style>) : (
          <style>{`.popup-fact__image`}</style>)}

        <div className={'fact__name'}>{popupIndex + 1}.{popups[popupIndex].fact.content.name}</div>
        <div
          className={cn(
            'popup-fact__card',
            {
              'popup-fact__card_video': !!video,
            }
          )}
        >
          {video
            ? <VideoFact sources={video} />
            : <div className="popup-fact__image" style={imageStyle} />
          }
        </div>
      </div>
      <button
        className="popup-fact__btn popup-fact__btn_left"
        type="button"
        onClick={handlerPrevClick}
      >
        {'<'}
      </button>
      <button
        className="popup-fact__btn popup-fact__btn_right"
        type="button"
        onClick={handlerNextClick}
      >
        {'>'}
      </button>
    </div>
  )
});

PopupFact.propTypes = {
  show: bool,
  popups: array.isRequired,
  currentPopupDomId: string,
  onClose: func,
  onFactPlay: func,
};
