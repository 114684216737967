import React, { Component } from 'react';
import { config } from '../../data';
import CounterWrapper from '../counter/counter-wrapper';
import {Drop, Drag, DragPreview} from '../dnd';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {isTablet} from '../../helpers';
import {TouchBackend} from 'react-dnd-touch-backend';

const animationTimeOut = 800;
const postLoaderTimeOut = animationTimeOut * 2;

const BASE_CANVAS = {
  width: 1920,
  height: 1080,
};

const getScale = () => {
  const scaleX = window.innerWidth / BASE_CANVAS.width;
  const scaleY = window.innerHeight / BASE_CANVAS.height;

  return Math.min(scaleX, scaleY);
};

export class CollectPuzzle extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loadedPuzzlesOutline: null,
      counter: 0,
      timer: null,
      width: window.innerWidth,
      height: window.innerHeight,
      draggingFigureName: null,
      scaleBothValue: getScale(),
    };

    this.onFigureDragEnd = this.onFigureDragEnd.bind(this);
    this.onDragging = this.onDragging.bind(this);
    this.resizeDisplay = this.resizeDisplay.bind(this);
  }

  resizeDisplay() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
      scaleBothValue: getScale(),
    })
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeDisplay)

    let puzzles = this.props.puzzles;
    let puzzlesOutline = this.props.puzzlesOutline;
    let frame = this.props.frame;
    let loadedPuzzles = [];
    let loadedPuzzlesOutline = [];

    /**
     * Пазлы
     */
    puzzles.forEach((item, index) => {
      loadedPuzzles.push({
        link: item.link,
        width: item.width,
        height: item.height,
        x: item.options.x,
        y: item.options.y,
        puzzle_name: index,
        isDraggable: true,
        isDragged: false,
      });
    });

    /**
     * Пазлы с контуром
     */
    puzzlesOutline.forEach((item, index) => {
      loadedPuzzlesOutline.push({
        link: item.link,
        width: item.width,
        height: item.height,
        x: item.options.x,
        y: item.options.y,
        puzzle_name: index,
      });
    });

    /**
     * Рамка
     */
    const loadedFrame = {
      link: frame.link,
      width: frame.width,
      height: frame.height,
      x: frame.options.x,
      y: frame.options.y,
    };

    this.setState({
      puzzlesOutline: loadedPuzzlesOutline,
      puzzles: loadedPuzzles,
      frame: loadedFrame
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeDisplay)
  }

  showPostLoader(instance, gameName) {
    setTimeout(() => {
      instance.props.showPostLoader(gameName)
    }, postLoaderTimeOut);
  }

  onDragging(fragmentName) {
    let draggingFigureName = null;
    this.state.puzzles.forEach(item => {
      if (fragmentName === item.puzzle_name) {
        draggingFigureName = item.puzzle_name;
      }
    });
    if (this.state.draggingFigureName !== draggingFigureName) {
      this.setState({
        draggingFigureName
      });
    }
  }

  onFigureDragEnd (puzzleName) {
    let puzzles = this.state.puzzles.map(item => {
      if (puzzleName === item.puzzle_name) {
        item.isDragged = true;
      }
      return item;
    });

    this.setState({
      counter: this.state.counter + 1,
      puzzles
    }, () => {
      if (this.state.counter === this.state.puzzles.length) {
        this.showPostLoader(this, 'collect-puzzle');
      }
    });
  }

  onChangeTimer(instance, timer, gameName) {
    if (0 === timer.days && 0 === timer.hours && 0 === timer.minutes && 0 === timer.seconds) {
      instance.showPostLoader(instance, gameName);
    }
  }

  render() {
    let puzzles = null;
    let puzzlesOutline = null;
    let frame = null;

    let cssWrap = {
      backgroundImage: `url(${config.base_url}${this.props.image})`
    };
    const { width, height, scaleBothValue } = this.state;
    const options = { enableMouseEvents: true };

    if (this.state.puzzles){
      puzzles = this.state.puzzles.map((item, index) => {
        return <Drag key={index}
                     name={'puzzle-' + item.puzzle_name}
                     src={item.link}
                     height={item.height * scaleBothValue}
                     width={item.width * scaleBothValue}
                     left={item.x * scaleBothValue}
                     top={item.y * scaleBothValue}
                     draggable={item.isDraggable}
                     scaleBothValue={scaleBothValue}
                     onDragEnd={this.onFigureDragEnd.bind(this, item.puzzle_name)}
                     onDragging={this.onDragging.bind(this, item.puzzle_name)} />;
      });
    }

    if (this.state.puzzlesOutline){
      puzzlesOutline = this.state.puzzlesOutline.map((item, index) => {
        return <Drop key={index}
                     name={'puzzle-' + item.puzzle_name}
                     src={item.link}
                     height={item.height * scaleBothValue}
                     width={item.width * scaleBothValue}
                     left={item.x * scaleBothValue}
                     top={item.y * scaleBothValue}
                     zIndex={this.state.draggingFigureName === item.puzzle_name ? 9930 : 9900} />
      })
    }

    if (this.state.frame){
      frame = <div style={{
        position: 'absolute',
        backgroundImage: `url(${config.base_url}${this.state.frame.link})`,
        backgroundSize: '100% 100%',
        width: this.state.frame.width * scaleBothValue,
        height: this.state.frame.height * scaleBothValue,
        left: this.state.frame.x * scaleBothValue,
        top: this.state.frame.y * scaleBothValue,
      }}>
      </div>
    }

    return (
      <div className="collect-puzzle game-page__wrap" style={cssWrap}>
        <h1 className='game-page__name'>
          <CounterWrapper minutes={this.props.counterDown} onChange={(timer) => this.onChangeTimer(this, timer, 'collect-puzzle')} />
        </h1>

        <div className="canvas-layer"
             style={{ position: 'relative', zIndex: 1, maxWidth: '100%', overflow: 'hidden', width: width + 'px', height: height + 'px' }}>

          <div className="canvas" style={{width: (BASE_CANVAS.width * scaleBothValue) + 'px', height: (BASE_CANVAS.height * scaleBothValue) + 'px'}}>
            <DndProvider backend={ isTablet() ? TouchBackend : HTML5Backend } options={isTablet() ? options : {}} >
              {frame}
              {puzzlesOutline}
              {puzzles}
              { isTablet() ? <DragPreview />  : ''}
            </DndProvider>
          </div>
        </div>

      </div>
    );
  }
}
