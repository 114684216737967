import React, { Component } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { config } from '../../data';
import { scale, shuffle } from '../../helpers';

export class MemoryGame extends Component {

  constructor(props) {
    super(props);

    // значение в % для центрирования игры
    const defaultCenterAxisValue = -50;

    this.state = {
      /* CSS  */
      transform: {
        scale: 1,
        translate: {
          x: defaultCenterAxisValue,
          y: defaultCenterAxisValue
        }
      },
      game_type: '',
      show_game: false,
      cards: [],
      maxTurned: false,
      maxCardPairs: 1
    };

    this.defaultCenterAxisValue = defaultCenterAxisValue;

    this.onChooseBtnClick = this.onChooseBtnClick.bind(this);
    this.onCardClick = this.onCardClick.bind(this);
    this.scaleGame = this.scaleGame.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.scaleGame);
    this.scaleGame();
  }

  render() {
    let cssTransform = this.state.transform;
    // При зуме карта позиционируется по-разному
    let translateUnit = '%';
    let cssWrap = {
      transform: `
        scale(${cssTransform.scale})
        translate(${cssTransform.translate.x}${translateUnit}, ${cssTransform.translate.y}${translateUnit})
      `,
      backgroundImage: `url(${config.base_url}${this.props.image})`
    };

    let cardList = null;

    if (this.state.cards.length > 0) {
      cardList = this.state.cards.map((card, index)=> {
        return (
          <li
            key={index}
            className='memory-game__card memory-game__card_type_unturned'
            data-card={`${card.name}`}
            onClick={this.onCardClick}
          >
            <span className='memory-game__card-inner'>
              <span className='memory-game__card-front'></span>
              <span
                className={`memory-game__card-back memory-game__card-back_type_${card.name}`}
              >
                <span
                  className="memory-game__card-back-img"
                  style={{backgroundImage: `url(${config.base_url}${card.image})`}}>
                </span>
              </span>
            </span>
          </li>
        )
      });
    }

    return (
      <div
        className={`memory-game memory-game_state_${this.state.game_type}`}

      >
        <h1 className='game-page__name'>Найди пару</h1>
        <div
          className='memory-game__wrapper game-page__wrap'
          style={cssWrap}
        >
          <nav className='memory-game__nav'>
            <button className='memory-game__btn'
              onClick={this.onChooseBtnClick}
              data-game='8-cards'
            >
              8 карточек
            </button>

            <button className='memory-game__btn'
              onClick={this.onChooseBtnClick}
              data-game='16-cards'
            >
              16 карточек
            </button>

            <button className='memory-game__btn'
              onClick={this.onChooseBtnClick}
              data-game='36-cards'
            >
              36 карточек
            </button>
          </nav>

          <ul className={`memory-game__card-list memory-game__card-list_size_${this.state.game_type}`}
              ref='cardList'
          >
            {cardList}
          </ul>

        </div>

      </div>
    );
  }

  onChooseBtnClick(event) {
    // Начинаем отсчет старта игры
    this.props.setStartTimeGame();
    //TODO: refactor, возможно переписать в функцию
    switch (event.currentTarget.getAttribute('data-game')) {

      case '8-cards':

        // дублирую массив
        let newCards = update(this.props.gameData.cards_8, {$push: this.props.gameData.cards_8});

        // переменшиваю карты
        shuffle(newCards);

        this.setState({
          game_type: '8-cards',
          cards: newCards,
          maxCardPairs: 4,
          show_game: true,
        });
        break;

      case '16-cards':

        // дублирую массив
        newCards = update(this.props.gameData.cards_16, {$push: this.props.gameData.cards_16});

        // переменшиваю карты
        shuffle(newCards);

        this.setState({
          game_type: '16-cards',
          cards: newCards,
          maxCardPairs: 8,
          show_game: true
        });
        break;

      case '36-cards':

        // дублирую массив
        newCards = update(this.props.gameData.cards_36, {$push: this.props.gameData.cards_36});

        // переменшиваю карты
        shuffle(newCards);

        this.setState({
          game_type: '36-cards',
          cards: newCards,
          maxCardPairs: 18,
          show_game: true
        });
        break;

      default:
        window.devMode && console.log('not correct data-attribute: data-game')
    }
  }

  onCardClick(event) {

    //TODO: refactor
    // если карта не перевёрнута
    if (!(event.currentTarget.classList.contains('memory-game__card_type_turned'))){

      // если уже показано максимальное количество перевёрнутых карт, то не переворачиваем другие
      if (this.state.maxTurned) return;

      event.currentTarget.classList.add('memory-game__card_type_turned');

      // перевёрнута первая карта
      if (this.firstFlippedCard === undefined){
        this.firstFlippedCard = event.currentTarget.getAttribute('data-card');
      }

      // перевёрнута вторая карта
      else {
        this.secondFlippedCard = event.currentTarget.getAttribute('data-card');

        let matched = this.firstFlippedCard === this.secondFlippedCard;
        let cards = this.refs.cardList.querySelectorAll('.memory-game__card_type_turned');

        this.setState({maxTurned: true});

        delete this.firstFlippedCard;
        delete this.secondFlippedCard;

        // если карты совпали
        if (matched) {

          cards.forEach((item) => {
            item.classList.add('memory-game__card_type_matched')
          });

          //TODO: 1) refactor, все таймауты в одном месте
          setTimeout(() => {
            this.setState({
              maxTurned: false,
              maxCardPairs: this.state.maxCardPairs - 1
            }, () => {

              if (this.state.maxCardPairs === 0){

                setTimeout(() => {
                  this.props.showPostLoader(`${config.game_types.memory_game} ${config.game_types.memory_game}_type_${this.state.game_type}`)

                  this.setState({
                    game_type: ''
                  });

                  cards.forEach((item) => {
                    item.classList.remove('memory-game__card_type_matched')
                    item.classList.remove('memory-game__card_type_turned')
                  });

                }, 800)

              }
            })
          }, 1000);

        // если карты не совпали
        } else {

          setTimeout(() => {
            cards.forEach((item) => {
              if (!(item.classList.contains('memory-game__card_type_matched'))){
                item.classList.remove('memory-game__card_type_turned')
              }
            });

            this.setState({maxTurned: false})
          }, 1000)
        }
      }
    }
  }

  scaleGame() {

    let scaleValue = scale();

    // маштабировать карту под размер экрана
    this.setState((prevState, props) => {
      prevState.transform.scale = scaleValue;
      return prevState;
    });
  }
}

MemoryGame.propTypes = {
  showPostLoader: PropTypes.func,
  gameData: PropTypes.object
};
