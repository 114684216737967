import axios from "axios";
/**
 * @typedef {Array.<Object>} Tariffs
 */

/**
 * @returns {Promise.<Tariffs>}
 */
export async function sendFeedback(feedback) {
  try {
    const response = await axios.post(`https://game.chevostik.ru/api/v1/feedback`,feedback);
    return response.data;
  } catch (error) {
    throw new Error('Server Error!');
  }
}
