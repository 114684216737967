import { ajaxV2 } from '../../helpers';

export const gamesTypes = () => {
  return ajaxV2
    .get('/games/types').then((e) => {
      return e.data.map((item) => {
        return {
          icon: item.image,
          type: item.type.replace(/_/g, '-')
        }
      })
    })
};
