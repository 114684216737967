import React from 'react';
import { useDrop } from 'react-dnd';

let style = {
    position: 'absolute',
    height: 0,
    width: 0,
    top: 0,
    left: 0,
    zIndex: 9900
};

export const Drop = ({ name, height, width, top, left, src, zIndex = 9900 }) => {
    style.height = height;
    style.width = width;
    style.top = top;
    style.left = left;
    style.zIndex = zIndex;

    const [{isOver}, drop] = useDrop(() => ({
        accept: name,
        collect: (monitor) => ({
          isOver: monitor.isOver(),
        }),
        drop: (item, monitor) => {
            item.left = left;
            item.top = top;
        },
    }));

    return (
      <>
        <div ref={drop} role="Drop" style={{ ...style }} />
        <div style={{ ...style, filter: isOver ? 'brightness(80%)' : '', zIndex: 9900 }}>
          <img
            src={src}
            width={width}
            height={height}
            alt=""
            style={{ padding: 0, margin: 0}}
          />
        </div>
      </>
    );
};
