import { ajax } from '../../helpers';

/**
 * @param {Object} password
 * @returns {Promise}
 */
export const patchPasswordAuth = (password) => {
  console.log(password)
  return ajax
    .patch('/auth/password ', password)
    .then(response => response.data)
    .catch(error => error);
};
