import React from 'react';
import PropTypes from 'prop-types';
import {config} from '../../data';

export const CabinetResults = ({ child, stats }) => {
  let childStatsFiltered = [];

  if (stats !== null && stats.categories) {
    childStatsFiltered = stats.categories.filter(object => object.successfully_completed_tests_count > 0);
  }

  return (
    <div className="cabinet-results">
      {
        (child !== null) &&
        stats.categories.map((item, index) => {
          return (
            <div key={index} className="cabinet-results__item">
              <div className="cabinet-results__label">{item.name}</div>
              <img
                width={40}
                height={40}
                className="cabinet-results__img"
                alt=""
                role="presentation"
                src={`${config.base_url}${item.icon}`}
              />
              {
                (childStatsFiltered.length > 0) ?
                <div className="cabinet-results__progress-wrap">
                  <div
                    className="cabinet-results__progress"
                    style={{width: `${(item.successfully_completed_tests_count * 100) / item.lessons_count}%`}}
                  />
                </div>
                :
                <div className="cabinet-results__text">
                  Данные собираются
                </div>
              }
            </div>
          );
        })
      }
    </div>
  );
};

CabinetResults.propTypes = {
  child: PropTypes.object,
  stats: PropTypes.object
};
