import React from 'react';
import { Ny, withBanner } from '../../banners';

const NyBanner = ({ type }) => {
  return (
    <Ny type={type} />
  );
};

export const NyRoutesBanner = withBanner(NyBanner);
