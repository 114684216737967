import React from 'react';
import { config } from '../../data';

import Button from '../button/button';

export const ShopLink = () => {
  return (
    <a
      target='_blank'
      href={config.routes.shop}
      className="cabinet-menu__link"
    >
      <Button mod="cabinet-menu__button" color="blue" customTag="span">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.3061 31.2H27.4087C27.8539 31.2 28.2181 30.8472 28.2181 30.3937C28.2181 30.0409 27.9753 29.7285 27.6515 29.6175C26.3766 29.1135 26.1136 27.4707 27.3683 26.2108C27.7527 25.8278 28.2181 25.3338 28.2181 24.3259V12.7649C28.2181 10.6684 27.176 9.59998 25.0816 9.59998H14.3365C12.2421 9.59998 11.2 10.6583 11.2 12.7649V28.0855C11.2 30.1619 12.2522 31.2 14.3061 31.2ZM14.3972 29.5773C13.3753 29.5773 12.8289 29.0329 12.8289 28.0754C12.8289 27.1683 13.4765 26.5837 14.4882 26.5837H24.8692C25.0006 26.5837 25.1221 26.5736 25.2233 26.5535C24.7477 27.6017 24.8287 28.7104 25.3649 29.5773H14.3972ZM14.0936 25.0516C13.78 25.0516 13.5169 24.7896 13.5169 24.467V11.7368C13.5169 11.4143 13.78 11.1623 14.0936 11.1623C14.4073 11.1623 14.6805 11.4143 14.6805 11.7368V24.467C14.6805 24.7896 14.4073 25.0516 14.0936 25.0516ZM18.0396 22.0278C17.6349 22.0278 17.3718 21.7758 17.3718 21.4028C17.3718 21.2719 17.3921 21.1408 17.4629 20.9392L19.9923 14.1558C20.1745 13.6922 20.5083 13.4503 20.994 13.4503C21.4898 13.4503 21.8338 13.6922 22.0159 14.1558L24.5454 20.9392C24.6263 21.1408 24.6465 21.2719 24.6465 21.413C24.6465 21.7758 24.3733 22.0278 23.9686 22.0278C23.6448 22.0278 23.4121 21.8766 23.2705 21.4533L22.6128 19.5483H19.3954L18.7377 21.4533C18.5961 21.8766 18.3634 22.0278 18.0396 22.0278ZM19.7596 18.4597H22.2486L21.0547 14.9924H20.9535L19.7596 18.4597Z" fill="white"/>
        </svg>
      </Button>
      Магазин
    </a>
  );
};
