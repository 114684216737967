import { ajax } from '../../helpers';

/**
 * @typedef {Array.<Object>} Payments
 */

/**
 * @returns {Promise.<Payments>}
 */
export const getPayments = () => {
 return ajax
    .get('/payments')
    .then(response => response.data)
    .catch(error => console.log('getPayments error: ', error));
};
