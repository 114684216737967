import React from 'react';
import {getImageAverageColor, redirect, splitThousands} from '../../helpers';
import {togglePopup} from '../../containers';
import * as api from '../../api';
import * as auth from '../../helpers/authenticationHelper';
import {Thing, Achievka, InfantPopup, Garb} from '../../components';

import Swiper from 'swiper/bundle';
import 'swiper/swiper.min.css';

const preloader = '../img_static/small-loader.svg';

let podiumLight = <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 699.76 2495.87">
  <g className="cls-1">
    <polygon className="cls-2" points="699.76 2495.87 0 2495.87 349.88 0 349.88 0 699.76 2495.87"/>
    <polygon className="cls-3" points="1.98 2493.57 349.88 8.99 349.88 8.99 697.77 2493.57 1.98 2493.57"/>
    <polygon className="cls-4" points="3.96 2491.27 349.88 17.98 349.88 17.98 695.78 2491.27 3.96 2491.27"/>
    <polygon className="cls-5" points="5.93 2488.97 349.88 26.97 349.88 26.97 693.79 2488.97 5.93 2488.97"/>
    <polygon className="cls-6" points="7.91 2486.68 349.88 35.97 349.88 35.97 691.8 2486.68 7.91 2486.68"/>
    <polygon className="cls-7" points="9.89 2484.38 349.88 44.96 349.88 44.96 689.81 2484.38 9.89 2484.38"/>
    <polygon className="cls-8" points="11.87 2482.08 349.88 53.95 349.88 53.95 687.82 2482.08 11.87 2482.08"/>
    <polygon className="cls-9" points="13.85 2479.78 349.88 62.94 349.88 62.94 685.83 2479.78 13.85 2479.78"/>
    <polygon className="cls-10" points="15.82 2477.48 349.88 71.93 349.88 71.93 683.84 2477.48 15.82 2477.48"/>
    <polygon className="cls-11" points="17.8 2475.18 349.88 80.92 349.88 80.92 681.85 2475.18 17.8 2475.18"/>
    <polygon className="cls-12" points="19.78 2472.88 349.88 89.91 349.88 89.91 679.86 2472.88 19.78 2472.88"/>
    <polygon className="cls-13" points="21.76 2470.58 349.88 98.9 349.88 98.9 677.87 2470.58 21.76 2470.58"/>
    <polygon className="cls-14" points="23.73 2468.29 349.88 107.89 349.88 107.89 675.88 2468.29 23.73 2468.29"/>
    <polygon className="cls-15" points="25.71 2465.99 349.88 116.89 349.88 116.89 673.88 2465.99 25.71 2465.99"/>
    <polygon className="cls-16" points="27.69 2463.69 349.88 125.88 349.88 125.88 671.89 2463.69 27.69 2463.69"/>
    <polygon className="cls-17" points="29.67 2461.39 349.88 134.87 349.88 134.87 669.9 2461.39 29.67 2461.39"/>
    <polygon className="cls-18" points="31.65 2459.09 349.88 143.86 349.88 143.86 667.91 2459.09 31.65 2459.09"/>
    <polygon className="cls-19" points="33.62 2456.79 349.88 152.85 349.88 152.85 665.92 2456.79 33.62 2456.79"/>
    <polygon className="cls-20" points="35.6 2454.49 349.88 161.84 349.88 161.84 663.93 2454.49 35.6 2454.49"/>
    <polygon className="cls-21" points="37.58 2452.19 349.88 170.83 349.88 170.83 661.94 2452.19 37.58 2452.19"/>
    <polygon className="cls-22" points="39.56 2449.9 349.88 179.82 349.88 179.82 659.95 2449.9 39.56 2449.9"/>
    <polygon className="cls-23" points="41.53 2447.6 349.88 188.82 349.88 188.82 657.96 2447.6 41.53 2447.6"/>
    <polygon className="cls-24" points="43.51 2445.3 349.88 197.81 349.88 197.81 655.97 2445.3 43.51 2445.3"/>
    <polygon className="cls-25" points="45.49 2443 349.88 206.8 349.88 206.8 653.98 2443 45.49 2443"/>
    <polygon className="cls-26" points="47.47 2440.7 349.88 215.79 349.88 215.79 651.99 2440.7 47.47 2440.7"/>
    <polygon className="cls-27" points="49.45 2438.4 349.88 224.78 349.88 224.78 650 2438.4 49.45 2438.4"/>
    <polygon className="cls-28" points="51.42 2436.1 349.88 233.77 349.88 233.77 648.01 2436.1 51.42 2436.1"/>
    <polygon className="cls-29" points="53.4 2433.8 349.88 242.76 349.88 242.76 646.02 2433.8 53.4 2433.8"/>
    <polygon className="cls-30" points="55.38 2431.51 349.88 251.76 349.88 251.76 644.02 2431.51 55.38 2431.51"/>
    <polygon className="cls-31" points="57.36 2429.21 349.88 260.75 349.88 260.75 642.03 2429.21 57.36 2429.21"/>
    <polygon className="cls-32" points="59.34 2426.91 349.88 269.74 349.88 269.74 640.04 2426.91 59.34 2426.91"/>
    <polygon className="cls-33" points="61.31 2424.61 349.88 278.73 349.88 278.73 638.05 2424.61 61.31 2424.61"/>
    <polygon className="cls-34" points="63.29 2422.31 349.88 287.72 349.88 287.72 636.06 2422.31 63.29 2422.31"/>
    <polygon className="cls-35" points="65.27 2420.01 349.88 296.71 349.88 296.71 634.07 2420.01 65.27 2420.01"/>
    <polygon className="cls-36" points="67.25 2417.71 349.88 305.7 349.88 305.7 632.08 2417.71 67.25 2417.71"/>
    <polygon className="cls-37" points="69.22 2415.41 349.88 314.69 349.88 314.69 630.09 2415.41 69.22 2415.41"/>
    <polygon className="cls-38" points="71.2 2413.11 349.88 323.69 349.88 323.69 628.1 2413.11 71.2 2413.11"/>
    <polygon className="cls-39" points="73.18 2410.82 349.88 332.68 349.88 332.68 626.11 2410.82 73.18 2410.82"/>
    <polygon className="cls-40" points="75.16 2408.52 349.88 341.67 349.88 341.67 624.12 2408.52 75.16 2408.52"/>
    <polygon className="cls-41" points="77.14 2406.22 349.88 350.66 349.88 350.66 622.13 2406.22 77.14 2406.22"/>
    <polygon className="cls-42" points="79.11 2403.92 349.88 359.65 349.88 359.65 620.14 2403.92 79.11 2403.92"/>
    <polygon className="cls-43" points="81.09 2401.62 349.88 368.64 349.88 368.64 618.15 2401.62 81.09 2401.62"/>
    <polygon className="cls-44" points="83.07 2399.32 349.88 377.63 349.88 377.63 616.16 2399.32 83.07 2399.32"/>
    <polygon className="cls-45" points="85.05 2397.02 349.88 386.63 349.88 386.63 614.17 2397.02 85.05 2397.02"/>
    <polygon className="cls-46" points="87.03 2394.72 349.88 395.62 349.88 395.62 612.17 2394.72 87.03 2394.72"/>
    <polygon className="cls-47" points="89 2392.43 349.88 404.61 349.88 404.61 610.18 2392.43 89 2392.43"/>
    <polygon className="cls-48" points="90.98 2390.13 349.88 413.6 349.88 413.6 608.19 2390.13 90.98 2390.13"/>
    <polygon className="cls-49" points="92.96 2387.83 349.88 422.59 349.88 422.59 606.2 2387.83 92.96 2387.83"/>
    <polygon className="cls-50" points="94.94 2385.53 349.88 431.58 349.88 431.58 604.21 2385.53 94.94 2385.53"/>
    <polygon className="cls-51" points="96.91 2383.23 349.88 440.57 349.88 440.57 602.22 2383.23 96.91 2383.23"/>
    <polygon className="cls-52" points="98.89 2380.93 349.88 449.56 349.88 449.56 600.23 2380.93 98.89 2380.93"/>
    <polygon className="cls-53" points="100.87 2378.63 349.88 458.56 349.88 458.56 598.24 2378.63 100.87 2378.63"/>
    <polygon className="cls-54" points="102.85 2376.34 349.88 467.55 349.88 467.55 596.25 2376.34 102.85 2376.34"/>
    <polygon className="cls-55" points="104.83 2374.04 349.88 476.54 349.88 476.54 594.26 2374.04 104.83 2374.04"/>
    <polygon className="cls-56" points="106.8 2371.74 349.88 485.53 349.88 485.53 592.27 2371.74 106.8 2371.74"/>
    <polygon className="cls-57" points="108.78 2369.44 349.88 494.52 349.88 494.52 590.28 2369.44 108.78 2369.44"/>
    <polygon className="cls-58" points="110.76 2367.14 349.88 503.51 349.88 503.51 588.29 2367.14 110.76 2367.14"/>
    <polygon className="cls-59" points="112.74 2364.84 349.88 512.5 349.88 512.5 586.3 2364.84 112.74 2364.84"/>
    <polygon className="cls-60" points="114.72 2362.54 349.88 521.49 349.88 521.49 584.31 2362.54 114.72 2362.54"/>
    <polygon className="cls-61" points="116.69 2360.24 349.88 530.49 349.88 530.49 582.32 2360.24 116.69 2360.24"/>
    <polygon className="cls-62" points="118.67 2357.95 349.88 539.48 349.88 539.48 580.33 2357.95 118.67 2357.95"/>
    <polygon className="cls-63" points="120.65 2355.65 349.88 548.47 349.88 548.47 578.34 2355.65 120.65 2355.65"/>
  </g>
</svg>

class Infant extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      podiumState: 0,
      swiperAchievements: null,
      achievementsList: [],
      garbList: [],
      thingsList: [],
      user: {},
      child: {},
      activeTab: 1,
      enoughCoins: true,
      editGarb: false,
      nextGarb: null,
      popup: {},
      popupOpened: false,
      garbSkin: preloader,
      loading: true
    }
  }

  updateSkinData(child, newSkin) {
    let skinUrl = preloader;
    let {garbList} = this.state;
    let {user} = this.props;

    if (child && child.hasOwnProperty('available_skins')) {
      for (let i = 0; i < child.available_skins.length; i++) {
        const skin = child.available_skins[i];

        for (let j = 0; j < garbList.length; j++) {
          let g = garbList[j];

          g.newly = g.id === newSkin;

          if (g.id === skin.id || g.id === newSkin) {
            g.count = 1;
            g.current_skin = g.id === newSkin;
          }
        }

        skin.current_skin = skin.id === child.skin_id;

        if (skin.id === newSkin) {
          skinUrl = skin.image;
        }
      }
    }

    if (child && child.hasOwnProperty('avatar') && child.avatar) {
      user.children = user.children.map(c => c.id === child.id ? child : c);

      auth.setProfile(user);

      let userAvatar = document.querySelector('.user-avatar');
      let avatar = child?.avatar ? child.avatar : 'img/avatars/default_avatar.png';

      if (userAvatar) {
        let userAvatarImage = userAvatar.querySelector('.user-avatar__image');
        let userAvatarBg = userAvatar.querySelector('.user-avatar__bg');
        let userAvatarBorder = userAvatar.querySelector('.user-avatar__border');
        let userAvatarProgressLine = userAvatar.querySelector('.user-avatar__progress-line');

        userAvatarImage.src = avatar;
        getImageAverageColor(avatar)
          .then(color => {
            userAvatarBg.style.fill = color
            userAvatarBorder.style.stroke = color
            userAvatarProgressLine.style.stroke = color
          });
      }
    }

    this.setState({
      child: child,
      nextGarb: newSkin,
      garbList: garbList.slice(0),
      garbSkin: skinUrl
    });
  }

  componentDidMount() {
    const {user} = this.props;

    api.getChild(user.currentChildId).then(child => {
      window.devMode && console.log('getChild', child);

      this.updateSkinData(child);

      this.setState({
        loading: false
      });

      setTimeout(() => {
        if (user.currentChildId) {

          Promise.all([
            api.getProducts(),
            api.getChildProducts(user.currentChildId, '')
          ]).then(data => {
            let skins = data[0].filter(f => f.category === 'skin').map(f => {
              f.current_skin = f.id === child.skin_id;
              f.count = data[1].filter(s => s.id === f.id).length || 0;
              return f;
            });
            let cheats = data[0].filter(f => f.category === 'cheat').map(c => {
              c.count = data[1].filter(s => s.id === c.id).length;
              return c
            });
            let achievements = data.filter(f => f.category === 'level');

            this.setState({
              garbList: skins,
              //garbSkin: skins[0].image,
              achievementsList: achievements,
              thingsList: cheats,
              loading: false
            }, () => {
              setTimeout(() => {
                this.updateSkinData(child, child.skin_id);
                this.initSwiperAchievements();
              }, 5);
            });
          });
        }
      }, 100);
    });

    window.addEventListener('keydown', this.closePopupByPressEsc);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.closePopupByPressEsc);
  }

  initSwiperAchievements = () => {
    let that = this;

    setTimeout(() => {
      let swiper = new Swiper('.swiperAchievements .swiper-container', {
        speed: 500,
        setWrapperSize: false,
        spaceBetween: 0,
        slidesPerView: 1,
        slidesPerColumn: 2,
        //slidesPerColumnFill: 'row',
        //slidesPerColumn: 1,

        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 1024px
          //1024: {
          //  slidesPerColumn: 2,
          //  slidesPerGroup: 2,
          //},
          //// when window width is >= 1440px
          1024: {
            slidesPerColumn: 2,
            slidesPerView: 2
          },
          // when window width is >= 1600px
          1400: {
            slidesPerColumn: 3,
            //slidesPerGroup: 3,
            slidesPerView: 3
          }
        },
        on: {
          init: (swp) => {
            that.fixSwiperWidth();
          },
          transitionEnd: (swp) => {
            that.fixSwiperWidth();
          }
        }
      });

      this.setState({swiperAchievements: swiper});
    }, 1);
  }

  fixSwiperWidth = () => {
    window.devMode && console.log('swiper', this.state.swiperAchievements);
  }

  closePopupByPressEsc = e => {
    if (e.keyCode === 27) redirect()
  }

  handleClickOutside() {
    redirect()
  }

  buyWithCoins = (item, cb) => {
    let {user} = this.props;
    let {child} = this.state;

    window.devMode && console.log('buyWithCoins', item, user);

    if (child.balance < (item.price * item.count)) {
      this.setState({
        popup: {
          item: {
            alert: 'Недостаточно монет <br /> для совершения покупки!'
          }
        }
      });
      this.setState({popupOpened: true});
    } else {
      api.paymentsV2({product_id: item.id, child_id: user.currentChildId, quantity: item.count})
        .then(data => {

          if (data) {
            if (window.hasOwnProperty('updateProfile') && typeof window.updateProfile === 'function') {
              window.updateProfile();
            }
          }

          cb(data);
        })
        .catch((err) => {
          window.devMode && console.log('error', err);
        });
    }
  }

  selectGarb = () => {
    let podiumYellow = new Image();
    podiumYellow.src = '/img/garb/podium_yellow.png';

    let podiumRed = new Image();
    podiumRed.src = '/img/garb/podium_red.png';

    if (this.state.editGarb && this.state.nextGarb) {
      // https://stage.chevostik.ru/apipie/2.0/children/update.html
      window.devMode && console.log('apply skin');
      this.updateChildSkin(this.state.nextGarb);
    }

    this.setState({editGarb: !this.state.editGarb, podiumState: 0});

    setTimeout(() => {
      if (!this.state.editGarb) {
        this.initSwiperAchievements();
      }
    }, 1);
  }

  changeTab = (tab) => {
    this.setState({activeTab: tab, popupOpened: false});

    if (tab === 0) {
      this.initSwiperAchievements();
    }
  }

  addThingCallback = (id) => {
    let item = this.state.thingsList.filter(f => f.id === id);

    window.devMode && console.log('addThingCallback', id, item);

    if (item.length) {
      this.setState({
        popup: {
          item: item[0]
        }
      });

      this.setState({popupOpened: true});
    } else {
      window.devMode && console.log('check item id', id);
    }
  }

  addGarbCallback = (id) => {
    let item = this.state.garbList.filter(f => f.id === id);

    if (item.length) {
      this.setState({
        popup: {
          item: item[0]
        }
      });

      this.setState({popupOpened: true});
    } else {
      window.devMode && console.log('check item id');
    }
  }

  buyThingCallback = (item) => {
    window.devMode && console.log('buyThingCallback', item, this.state.user);

    this.setState({popupOpened: false});

    if (item.count) {
      this.buyWithCoins(item, (data) => {
        let {thingsList} = this.state;

        for (let i = 0; i < thingsList.length; i++) {
          let thing = thingsList[i];

          if (thing.id === item.id) {
            thing.count += item.count;
          }
        }

        this.setState({thingsList: thingsList})

        window.devMode && console.log('thingsList', thingsList, data, item);
      });
    }
  }

  buyGarbCallback = (item) => {
    window.devMode && console.log('buyGarbCallback', item, this.state.user);
    let {child} = this.state;

    this.setState({popupOpened: false});

    if (item.count) {
      this.buyWithCoins(item, (data) => {
        this.updateSkinData(child, data.product_id);
        this.updateChildSkin(data.product_id);
      });
    } else {
      this.setState({
        popup: {
          item: null
        }
      });
    }

    setTimeout(() => {
      window.devMode && console.log(this.state);
    }, 10);
  }

  shopThingCallback = (item) => {
    window.devMode && console.log('shopThingCallback', item);

    this.setState({
      popupOpened: false,
      popup: {
        item: {
          alert: ''
        }
      }
    });

    if (item) {
      togglePopup('coinShop', true);
    }
  }

  shopGarbCallback = (item) => {
    window.devMode && console.log('shopGarbCallback', item);

    this.setState({
      popupOpened: false,
      popup: {
        item: {
          alert: ''
        }
      }
    });

    if (item) {
      togglePopup('coinShop', true);
    }
  }

  selectGarbCallback = (item) => {
    let {garbList, child} = this.state;
    let skin = item.image;

    for (let i = 0; i < garbList.length; i++) {
      let g = garbList[i];
      g.current_skin = g.id === item.id;
      g.count = g.count || child.available_skins.filter(s => s.id === g.id).length || 0;
    }

    window.devMode && console.log('selectGarbCallback', item, garbList);

    this.setState({popupOpened: false});
    this.setState({
      garbList: garbList.slice(0),
      nextGarb: item.id,
      garbSkin: skin
      //podiumState: !this.state.editGarb ? 0 : this.state.garbList[item].count ? 1 : 2
    });
  }

  updateChildSkin(id) {
    api.putChild({child: {skin_id: id}})
      .then(child => {
        for (let i = 0; i < child.available_skins.length; i++) {
          let skin = child.available_skins[i];
          skin.newly = skin.id === id;
          skin.count = 1;
        }

        this.updateSkinData(child, child.skin_id);
      })
  }

  render() {
    const {child, loading, popup, nextGarb} = this.state;

    let podiumStates = [
      '__blue',
      '__yellow',
      '__red'
    ];

    return (
      <div className={'infant-holder'}>
        {loading && <div className="score-tabel__loading"/>}

        <div className="infant-garb">
          <div
            className={'infant-garb__spotlight' + (nextGarb !== null && nextGarb !== child.skin_id ? ' __change' : '') + (this.state.editGarb ? ' __edit' + (popup && popup.item ? ' __error' : '') : '')}>
            {podiumLight}
          </div>

          <div className={'infant-garb__info'}>
            {/*<div className="infant-garb__info--text __name">{child.name}</div>*/}
            <div className="infant-garb__info--text __icon">
                  <span>
                    <img src="/img/garb/level.png" alt=""/>
                  </span>
              {child.level}</div>
            <div className="infant-garb__info--text __icon">
                  <span>
                    <img src="/img/garb/star.png" alt=""/>
                  </span>
              {child.points}</div>
            <div className="infant-garb__info--text __icon">
                  <span>
                    <img src="/img/garb/cup.png" alt=""/>
                  </span>
              {splitThousands(child.rating_position || 0)} место
            </div>
          </div>

          <div className={'infant-garb__view'}>
            <div className={'infant-garb__img'}>
              <img src={this.state.garbSkin} alt=""/>
            </div>
          </div>

          <div className={'infant-garb__podium ' + (podiumStates[this.state.podiumState])}/>

          <div className="infant-garb__footer">
            <div onClick={() => this.selectGarb()}
                 className={'infant-garb__button' + (this.state.editGarb ? ' __edit' : '')}
            ><span>{this.state.editGarb ? 'Сохранить' : 'Сменить'}</span></div>
          </div>
        </div>

        {this.state.editGarb ?
          <div className={'infant-garb__switch' + (this.state.popupOpened ? ' __popup' : '')}>
            <div className="infant-achievements__list __garb">
              {this.state.garbList.map((a, i) => <Garb key={i} selectGarb={this.selectGarbCallback}
                                                       addGarb={this.addGarbCallback} item={a}/>)}
            </div>

            {this.state.popupOpened ?
              popup.item && popup.item.alert ?
                <div className={'infant-achievements__popup'}>
                  <InfantPopup btnTitle={'В магазин'} hidePopUp={this.shopGarbCallback}
                               item={popup.item}/>
                </div>
                : <div className={'infant-achievements__popup'}>
                  <InfantPopup btnTitle={'Купить'} hidePopUp={this.buyGarbCallback} quantity={false}
                               item={popup.item}/>
                </div>
              : null
            }
          </div>
          :
          <div
            className={'infant-achievements' + (this.state.activeTab === 0 ? ' __first __last' : this.state.activeTab === 1 ? ' __first __last' : '')}>
            <div className="infant-achievements__tabs">
              {/*<span className={'infant-achievements__tab' + (this.state.activeTab === 0 ? ' active' : '')}*/}
              {/*      onClick={() => this.changeTab(0)}*/}
              {/*>Достижения</span>*/}
              <span className={'infant-achievements__tab' + (this.state.activeTab === 1 ? ' active' : '')}
                    onClick={() => this.changeTab(1)}
              >Предметы</span>
            </div>

            {this.state.activeTab === 0 ?
              <div
                className={'infant-achievements__panel' + (this.state.popupOpened ? ' __popup' : '') + (this.state.achievementsList.length ? '' : ' __msg')}>
                {this.state.achievementsList.length ?
                  <div className="swiperAchievements infant-achievements__slider">
                    <div className="swiper-container">
                      <div className="swiper-wrapper infant-achievements__list">
                        {this.state.achievementsList.map((a, i) => <Achievka key={i} index={i} item={a}/>)}
                      </div>
                    </div>
                  </div>
                  :
                  <p>
                    Здесь будут отображаться твои награды за прохождение уроков, тестов и маршрутов
                  </p>
                }

              </div>
              : this.state.activeTab === 1 ?
                <div
                  className={'infant-achievements__panel' + (this.state.popupOpened ? ' __popup' : '') + (this.state.thingsList.length ? '' : ' __msg')}>
                  {this.state.thingsList.length ?
                    <div className="infant-achievements__list __things">
                      {this.state.thingsList.map((a, i) => <Thing key={i} addThing={this.addThingCallback} item={a}/>)}
                    </div>
                    :
                    <p>
                      Здесь будут отображаться твои предметы
                    </p>
                  }

                  {this.state.popupOpened ?
                    popup.item && popup.item.alert ?
                      <div className={'infant-achievements__popup'}>
                        <InfantPopup btnTitle={'В магазин'} hidePopUp={this.shopThingCallback}
                                     item={popup.item}/>
                      </div>
                      : <div className={'infant-achievements__popup'}>
                        <InfantPopup btnTitle={'Купить'} hidePopUp={this.buyThingCallback} quantity={true}
                                     item={popup.item}/>
                      </div>
                    : null
                  }
                </div>
                : null}
          </div>
        }

      </div>
    )
  }
}

//const _Infant = enhanceWithClickOutside(Infant)

export {Infant}
