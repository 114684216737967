import React, {Component} from 'react';

import './banner.styl';
import { BlackFridayBanner, NewYearBanner } from '../../components';

class Banner extends Component {
  render() {
    let {section, title, share, text, onClose, onAction, bannerClass, image, imageRetina} = this.props;

    return (
      <div className={'banner ' + bannerClass}>
        <div onClick={() => {
          onClose()
        }} className={'banner__close'}/>

        {
          (section === 'less-a-week') ? <>
            <div className={'banner__title'}>{title}</div>
            {share && <div className={'banner__share'}>{share}</div>}
            <div className={'banner__text'} dangerouslySetInnerHTML={{__html: text}}/>
            {
              image
              && <img className={'banner__image'}
                      src={image}
                      srcSet={`${imageRetina} 2x`}
                      alt=""
              />
            }
            <div className="banner__button">
          <span className={'infant-garb__button'} onClick={() => {
            onAction();
          }}><span>Подписаться</span></span>
            </div>
          </> : null
        }

        {
          (section === 'black-friday') ? <BlackFridayBanner onAction={onAction} /> : null
        }

        {
          (section === 'new-year') ? <NewYearBanner onAction={onAction} /> : null
        }

      </div>
    );
  }
}

export default Banner
