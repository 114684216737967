import React, {Component} from 'react';
import PropTypes from 'prop-types';

import './test-lototron.styl';
import * as auth from '../../helpers/authenticationHelper';
import * as api from '../../api';
import {config} from '../../data';

export class TestLototron extends Component {
  constructor(props) {
    super(props);

    this.state = {
      img: new Image(),
      //imageURL: this.props.noPreloader ? '' : (config.base_url + '/img_static/small-loader.svg'),
      imageURL: '',
      imgLoaded: false,
      startSpinner: false,
      showNext: false,
      showZoom: false,
      prizeCount: 0,
      prizeUrl: '',
      prizeTitle: '',
      prizeCategory: '',
      nextTimer: null,
      zoomTimer: null
    };
  }

  imgLoader(url) {

    setTimeout(() => {
      this.setState({
        imageURL: url,
        imgLoaded: true,
        nextTimer: setTimeout(() => {
          this.setState({showNext: true});
        }, 5000),
        zoomTimer: setTimeout(() => {
          this.setState({showZoom: true});
        }, 2500)
      });
    }, 10);
  }

  componentWillUnmount() {
    clearTimeout(this.state.zoomTimer);
    clearTimeout(this.state.nextTimer);
  }

  tryFortune() {
    const currentChildId = auth.getCurrentChild();
    const {testId, clickCallback} = this.props;
    const {img} = this.state;

    window.devMode && console.log('id_test', testId);

    let animationTimer = new Promise((res, rej) => {
      setTimeout(() => {
        res(true);
      }, 1500);
    })

    Promise.all([
      animationTimer,
      api.getSpin(currentChildId, testId)
    ]).then(result => {
      let spin = result[1];
      window.devMode && console.log('tryFortune', result);


      // todo dev
      //if (!spin) {
      //  spin = rslt;
      //}

      if (spin && spin.hasOwnProperty('prize')) {
        img.addEventListener('load', this.imgLoader.bind(this, spin.prize.image));
        img.src = spin.prize.image;

        this.setState({
          prizeUrl: spin.prize.image,
          prizeCategory: spin.prize.category,
          prizeTitle: spin.prize.title,
          prizeCount: spin.count
        });
      } else {
        clickCallback({empty: true});
      }
    });
  }

  render() {
    const {startSpinner, prizeUrl, showNext, prizeCategory, showZoom, prizeCount, prizeTitle, imageURL} = this.state;
    const {clickCallback} = this.props;

    let prizeInner = <div ref="prizeInner"
                          className={'test-prize__inner test-prize__inner_state_flip'}
                          onAnimationStart={this.onAnimationStart}
                          onAnimationEnd={this.onAnimationEnd}
    >
      <div className="test-prize__back" ref="prizeBack">
        <div className="test-prize__back-img">
          <img src={config.base_url + prizeUrl} alt=""/>
        </div>
      </div>
    </div>;

    let prizeName = <p className="test-prize__name">{this.props.prizeName}</p>

    let giftWrapper = <div className="test-prize">
      <ul className="test-prize__lights">
        <li/>
        <li/>
        <li/>
        <li/>
        <li/>
        <li/>
        <li/>
        <li/>
        <li/>
        <li/>
        <li/>
        <li/>
        <li/>
      </ul>
      {prizeInner}
      {prizeName}
    </div>

    return (
      <div className="test-lototron__wrapper" onClick={() => {
        if (!startSpinner) {
          this.setState({startSpinner: true});
          this.tryFortune();
        }
      }}>
        <div className="test-finish-screen__title">
          {startSpinner ?
            <>&nbsp;</>
            : <span>Сыграем?</span>
          }
          {showNext ? <span>Награда:</span> : null}
        </div>

        <div className="test-lototron">
          {showNext ? <span onClick={() => {
            clickCallback({count: prizeCount, category: prizeCategory});
          }} className={'test-lototron__next'}>Далее</span> : null}

          <div>
            <div>
              {startSpinner ? <div className={'test-lototron__star' + (prizeUrl ? ' _prize' : '') + (showZoom ? ' __opacity' : '')}/> : null}
              <div className={'lesson-progress-animation__center' + (prizeUrl ? ' _zoom' : '') + (showZoom ? ' _prize' : '')}>
                {startSpinner && prizeCategory ? <div className={'test-lototron__gift' + (!showZoom ? ' __zoom' : '')}>
                  {['point', 'coin', 'skin'].indexOf(prizeCategory) >= 0 ?
                    <>
                      <img src={imageURL} alt=""/>
                      {prizeTitle && showNext ? <span>{prizeTitle}</span> : null}
                    </>
                    :
                    giftWrapper
                  }
                </div> : null}
                <span className={'lesson-progress-animation__center--rays' + (showNext ? ' __opacity' : '')}/>
              </div>
            </div>
            <div className={'test-lototron__text' + (startSpinner ? ' __opacity' : '')}>
              {startSpinner ? <img className={'__zoom'} src={prizeUrl} alt=""/> : <span>Жми!</span>}
            </div>

            <img src={'/img/lototron/lototron.png'} className={'lototron' + (showZoom ? ' __opacity' : '')} alt=""/>
          </div>
          }
        </div>
      </div>
    );
  }
}

TestLototron.propTypes = {
  clickCallback: PropTypes.func,
  isFinished: PropTypes.bool,
  prizeUrl: PropTypes.string,
  prizeName: PropTypes.string
};
