import { ajax } from '../../helpers';

/**
 * @typedef {Array.<Object>} Categories
 */

/**
 * @returns {Promise.<Categories>}
 */
export const getCategories = () => {
  return ajax
    .get('/categories')
    .then(response => response.data)
    .catch(error => console.log('getCategories error: ', error));
};
