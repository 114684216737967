/**
 * @fileOverview
 * 
 * Координаты и изображения которые настроены для игры типа 
 * "Собери пазл"
 * 
 */

const puzzleOffsetY = 100;
const puzzleOutlineOffsetY = 70;

export const PUZZLES_OUTLINES = [
  {
    img: '/img/games/puzzle-game/Sport_puzzle_white_1.png',
    x: 230,
    y: 318 - puzzleOutlineOffsetY
  },
  {
    img: '/img/games/puzzle-game/Sport_puzzle_white_2.png',
    x: 230+156,
    y: 318 - puzzleOutlineOffsetY
  },
  {
    img: '/img/games/puzzle-game/Sport_puzzle_white_3.png',
    x: 230+302,
    y: 318 - puzzleOutlineOffsetY
  },
  {
    img: '/img/games/puzzle-game/Sport_puzzle_white_4.png',
    x: 230,
    y: 318+136 - puzzleOutlineOffsetY
  },
  {
    img: '/img/games/puzzle-game/Sport_puzzle_white_5.png',
    x: 230+136,
    y: 318+156 - puzzleOutlineOffsetY
  },
  {
    img: '/img/games/puzzle-game/Sport_puzzle_white_6.png',
    x: 230+322,
    y: 318+136 - puzzleOutlineOffsetY
  },
  {
    img: '/img/games/puzzle-game/Sport_puzzle_white_7.png',
    x: 230,
    y: 318+322 - puzzleOutlineOffsetY
  },
  {
    img: '/img/games/puzzle-game/Sport_puzzle_white_8.png',
    x: 230+156,
    y: 318+302 - puzzleOutlineOffsetY
  },
  {
    img: '/img/games/puzzle-game/Sport_puzzle_white_9.png',
    x: 230+302,
    y: 318+322 - puzzleOutlineOffsetY
  },
];

export const PUZZLES = [
  // 1
  {
    x: 1526,
    y: 528 - puzzleOffsetY
  },
  // 2
  {
    x: 1530,
    y: 765 - puzzleOffsetY
  },
  // 3
  {
    x: 969,
    y: 782 - puzzleOffsetY
  },
  // 4
  {
    x: 1263,
    y: 263 - puzzleOffsetY
  },
  // 5
  {
    x: 977,
    y: 272 - puzzleOffsetY
  },
  // 6
  {
    x: 972,
    y: 495 - puzzleOffsetY
  },
  // 7
  {
    x: 1255,
    y: 775 - puzzleOffsetY
  },
  // 8
  {
    x: 1519,
    y: 269 - puzzleOffsetY
  },
  // 9
  {
    x: 1222,
    y: 524 - puzzleOffsetY
  }
];

export const PUZZLE_FRAME = {
  x: 216,
  y: 234
};
