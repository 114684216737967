import React from "react";
import { useState, useEffect, useRef } from "react";
import styles from "./mobileLesson.module.css";
import store from "../../redux/store";
import { setDataLesson } from "../../redux/actions";
import * as api from "../../api";
import { Link } from "react-router-dom";
import * as auth from "../../helpers/authenticationHelper";
import {getExtension} from "../../helpers/getExtension";
import {config} from "../../data";

const MobileLesson = (props) => {
  let data = store.getState().common.value;
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  const [allData, setAllData] = useState(data[1]);
  const [content, setContent] = useState(
    data[1].filter(
      (item) => item.fact && item.fact.options && item.fact.options.is_fact
    )
  );
  const [Fact, setFact] = useState(content[0]);
  const [index, setIndex] = useState(0);
  const [previewModal, setPreviewModal] = useState(true);
  const [ImgFact, setImgFact] = useState("");
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  let isModal = true;
  let checkPromo = () => {
    const user = auth.getProfile();
    if (user.subscription.status !== "on") {
      return true;
    } else {
      return false;
    }
  };
  const videoRef = useRef(null);
  const audioRef = useRef(null);

  const handlePlay = () => {
    const pauseButton = document.getElementById("pauseButton");
    const playButton = document.getElementById("playButton");
    pauseButton.style.display = "block";
    playButton.style.display = "none";
    videoRef.current.play();
  };
  const handlePause = () => {
    const pauseButton = document.getElementById("pauseButton");
    const playButton = document.getElementById("playButton");
    pauseButton.style.display = "none";
    playButton.style.display = "block";
    videoRef.current.pause();
  };
  const handleAudioPlay = () => {
    const pauseButton = document.getElementById("pauseAudioButton");
    const playButton = document.getElementById("playAudioButton");
    pauseButton.style.display = "block";
    playButton.style.display = "none";
    audioRef.current.play();
  };
  const handleAudioPause = () => {
    const pauseAudioButton = document.getElementById("pauseAudioButton");
    const playAudioButton = document.getElementById("playAudioButton");
    pauseAudioButton.style.display = "none";
    playAudioButton.style.display = "block";
    audioRef.current.pause();
  };

  const openModal = () => {
    const modal = document.getElementById("modalMain");
    if (!isModal) {
      modal.style.display = "block";
      return (isModal = true);
    } else {
      modal.style.display = "none";
      return (isModal = false);
    }
  };

  const handleFactSwitcher = (move) => {
    if (move == "next" && content.length > index + 1) {
      factFunction(
        content[index + 1],
        index + 1,
        content[index + 1].id,
        content[index + 1].status,
        content[index + 1].dom_id
      );
    }
    if (move == "prev" && index > 0) {
      factFunction(
        content[index - 1],
        index - 1,
        content[index - 1].id,
        content[index - 1].status,
        content[index - 1].dom_id
      );
    }
  };

  const calcProgressNum = () => {
    let checked = 0;
    content.map((elem, ind, array) => {
      if (elem.status == 2) {
        return (checked = checked + 1);
      }
    });
    return checked;
  };

  const calcProgress = () => {
    let checked = Math.round((100 / content.length) * calcProgressNum());
    return checked;
  };

  const factFunction = (fact, ind, factId, factStatus, domId) => {
    setIndex(ind);
    setFact(fact);

    const findMatchingPopup = (numberId) => {
      const id = numberId.split("-")[1];
      return allData.find((item) => item.dom_id === `popup_number-${id}`);
    };
    const numberObject = domId;
    const matchingPopup = findMatchingPopup(numberObject);
    setImgFact(matchingPopup);
    calcProgressNum();
    calcProgress();

    Player();
    ControllPlayer();
    if (factStatus == 1) {
      content[ind].status = 2;
      setContent(content);
      api.setPersonStatus(factId, 2);
    }
  };
  let Player = () => {
    const factVideos = Fact?.fact?.content?.fact_videos;
    const videoSrc = factVideos && factVideos[1]?.video;

    return (
      <>
        {videoSrc ? (
          <video ref={videoRef} width="100%">
            {Fact.fact.content.fact_videos.filter((item)=>getExtension(item.video) === 'mp4').map((item, index) => (
              <source
                key={index}
                src={`${config.base_url}${item.video}`}
                type={getExtension(item.video) === 'mp4' ? 'video/mp4' : 'video/ogg'}
              />
            ))}
            Sorry, your browser doesn't support videos.
          </video>
        ) : (
          <div className={styles.audioWallpapper}>
            <img
              className={styles.audioImage}
              src={`https://game.chevostik.ru${ImgFact.animation}`}
            />
            <audio ref={audioRef}>
              <source
                src={`https://game.chevostik.ru${Fact.fact.content.audio}`}
                type="audio/mp3"
              />
            </audio>
          </div>
        )}
      </>
    );
  };

  let Duration = () => {
    return (
      <div className={styles.timer}>
        00:{videoRef.current.duration - videoRef.current.currentTime}
      </div>
    );
  };

  let ControllPlayer = () => {
    const factVideos = Fact?.fact?.content?.fact_videos;
    const videoSrc = factVideos && factVideos[1]?.video;
    return (
      <>
        {videoSrc ? (
          <>
            {window.innerHeight > 428 ? (
              <div className={styles.largePlayer}>
                <div className={styles.facts}>
                  <div className={styles.factName}>
                    {Fact.fact.content.name}
                  </div>
                  <div className={styles.factNumber}>
                    {index + 1}/{content.length}
                  </div>
                </div>
                <div className={styles.menu}>
                  <button
                    className={styles.factsMenu}
                    onClick={() => openModal()}
                  ></button>
                  <div className={styles.groupMenu}>
                    <button
                      className={styles.factsPrev}
                      onClick={() => handleFactSwitcher("prev")}
                    ></button>

                    <button
                      id={"pauseButton"}
                      className={styles.factsPause}
                      onClick={() => handlePause()}
                    ></button>

                    <button
                      id={"playButton"}
                      className={styles.factsPlay}
                      onClick={() => handlePlay()}
                    ></button>

                    <button
                      className={styles.factsNext}
                      onClick={() => handleFactSwitcher("next")}
                    ></button>
                  </div>
                  {/* <div className={styles.factsDuration}>
                    <div className={styles.factsFull}>
                      <div
                        className={styles.factsDone}
                        style={{ width: "90%" }}
                      ></div>
                    </div>
                    <div className={styles.timer}>
                      00::{duration.toFixed(0) - currentTime.toFixed(0)}
                    </div>
                  </div> */}
                </div>
              </div>
            ) : (
              <div className={styles.smallPlayer}>
                <button
                  id={"pauseButton"}
                  className={styles.factsPause}
                  onClick={() => handlePause()}
                ></button>

                <button
                  id={"playButton"}
                  className={styles.factsPlay}
                  onClick={() => handlePlay()}
                ></button>
                <button
                  className={styles.factsMenu}
                  onClick={() => openModal()}
                ></button>
              </div>
            )}
          </>
        ) : (
          <>
            {window.innerHeight > 428 ? (
              <div className={styles.largePlayer}>
                <div className={styles.facts}>
                  <div className={styles.factName}>
                    {Fact.fact.content.name}
                  </div>
                  <div className={styles.factNumber}>
                    {index + 1}/{content.length}
                  </div>
                </div>
                <div className={styles.menu}>
                  <button
                    className={styles.factsMenu}
                    onClick={() => openModal()}
                  ></button>
                  <div className={styles.groupMenu}>
                    <button
                      className={styles.factsPrev}
                      onClick={() => handleFactSwitcher("prev")}
                    ></button>

                    <button
                      id={"pauseAudioButton"}
                      className={styles.factsPause}
                      onClick={handleAudioPause}
                    ></button>

                    <button
                      id={"playAudioButton"}
                      className={styles.factsPlay}
                      onClick={handleAudioPlay}
                    ></button>

                    <button
                      className={styles.factsNext}
                      onClick={() => handleFactSwitcher("next")}
                    ></button>
                  </div>
                  {/* <div className={styles.factsDuration}>
                    <div className={styles.factsFull}>
                      <div
                        className={styles.factsDone}
                        style={{ width: "90%" }}
                      ></div>
                    </div>
                    <div className={styles.timer}>-00:00</div>
                  </div> */}
                </div>
              </div>
            ) : (
              <div className={styles.smallPlayer}>
                <button
                  id={"pauseAudioButton"}
                  className={styles.factsPause}
                  onClick={handleAudioPause}
                ></button>

                <button
                  id={"playAudioButton"}
                  className={styles.factsPlay}
                  onClick={handleAudioPlay}
                ></button>
                <button
                  className={styles.factsMenu}
                  onClick={() => openModal()}
                ></button>
              </div>
            )}
          </>
        )}
      </>
    );
  };
  let Progress = () => {
    return (
      <div className={styles.progressFull}>
        <div
          className={styles.progressDone}
          style={{ height: `${calcProgress()}%` }}
        ></div>
        <div
          className={`${
            calcProgress() >= 50 ? styles.firstStarWhite : styles.firstStar
          }`}
        ></div>
        <div
          className={`${
            calcProgress() >= 75 ? styles.secondStarWhite : styles.secondStar
          }`}
        ></div>
        <div
          className={`${
            calcProgress() >= 100 ? styles.thirdStarWhite : styles.thirdStar
          }`}
        ></div>
      </div>
    );
  };

  return (
    <>
      <div
        className={`${styles.mainPreview} ${!previewModal ? styles.none : ""}`}
        style={{
          backgroundImage: `URL("https://game.chevostik.ru${data[0].preview_image.large}")`,
        }}
      >
        <div className={styles.header}>
          <button className={styles.btnClose} onClick={props.exit}></button>
          {calcProgress() >= 75 ? (
            <Link to={props.question}>
              <button className={styles.btnQuestion}></button>
            </Link>
          ) : (
            <button className={styles.btnQuestion}></button>
          )}
        </div>
        <div className={styles.wrapper}>
          <div className={styles.leftSidebar}>
            <div className={styles.logo}>
              <div className={styles.logoTitle}>{data[0].name}</div>
              <div className={styles.logoCounter}>
                {calcProgressNum()}/{content.length}
              </div>
              <div
                className={styles.btnCircle}
                onClick={() => {
                  setPreviewModal(false);
                  factFunction(
                    content[0],
                    0,
                    content[0].id,
                    content[0].status,
                    content[0].dom_id
                  );
                }}
              ></div>
            </div>
          </div>
          <div className={styles.rightSidebar}>{/* <Progress /> */}</div>
        </div>
      </div>
      <div
        className={styles.main}
        style={{
          backgroundImage: `URL("https://game.chevostik.ru${data[0].preview_image.large}")`,
        }}
      >
        <div id={"modalMain"} className={styles.modalMain}>
          <div className={styles.header}>
            <button
              className={styles.btnClose}
              onClick={() => openModal()}
            ></button>
          </div>
          <div
            className={styles.modalWrapper}
            style={{
              height: "inherit",
              overflow: "auto",
              paddingBottom: "140px",
            }}
          >
            <div className={styles.title}>Список фактов</div>
            <div className={styles.modalTitle}>
              {" "}
              <div className={styles.miniPlay}></div> {data[0].id}.{" "}
              {data[0].name}
            </div>
            <div className={styles.factList}>
              {content.map((elem, ind, array) => {
                return (
                  <div
                    className={styles.fact}
                    onClick={() => (
                      factFunction(
                        elem,
                        ind,
                        elem.id,
                        elem.status,
                        elem.dom_id
                      ),
                      openModal()
                    )}
                  >
                    <div
                      className={`${styles.factPointer} ${
                        elem.status == 1 ? styles.checked : ""
                      } }`}
                    >
                      {ind + 1}
                    </div>
                    <div
                      className={`${styles.factText} ${
                        elem.status == 1 ? styles.checked : ""
                      } }`}
                    >
                      {elem.fact.content.name}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className={styles.header}>
          <button
            className={styles.btnClose}
            onClick={() => {
              setPreviewModal(true);
            }}
          ></button>
          <div className={styles.lessonTitle}>{data[0].name}</div>
          {calcProgress() >= 75 ? (
            <Link to={props.question}>
              <button className={styles.btnQuestion}></button>
            </Link>
          ) : (
            <button className={styles.btnQuestion}></button>
          )}
        </div>
        {window.innerHeight > 428 && (
          <>
            {checkPromo() && (
              <Link to="/cabinet/select_tariff/">
                <div className={styles.promo}>
                  <div className={styles.BlueText}>УСПЕЙ ОФОРМИТЬ ПОДПИСКУ</div>
                  <button className={styles.btnBuy}>Купить за 1990₽</button>
                  <div className={styles.OrangeText}>
                    {" "}
                    СО СКИДКОЙ <span></span>
                  </div>
                </div>
              </Link>
            )}
          </>
        )}
        <div
          className={styles.wrapper}
          style={
            checkPromo() && window.innerHeight > 428
              ? { maxHeight: "calc(100% - (58px + 100px))" }
              : null
          }
        >
          <div className={styles.leftSidebar}>
            <div className={styles.contentFrame}>
              <div className={styles.content}>
                <Player />
              </div>
            </div>
            <ControllPlayer />
          </div>
          <div className={styles.rightSidebar}>
            <Progress />
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileLesson;
