import React, {Component} from 'react';
import queryString from 'query-string';
import {emailSimpleRegExp, setTimeZoneOffsetInHours} from '../../helpers';
import {i18n} from '../../data';
import * as api from '../../api';
import * as auth from '../../helpers/authenticationHelper';
import {
  UserEmail,
  UserPassword,
  MultiInput,
  AuthHeader,
  AuthText
} from '../../components';

export class SingUpContainer extends Component {
  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search)

    this.state = {
      email: query.email || '',
      password: '',
      password_confirmation: '',
      children_attributes: [],
      errorMessage: {
        email: false,
        password: false,
        password_confirmation: false,
        child: false
      },
      time_zone: `${setTimeZoneOffsetInHours(new Date())}`
    };

    this.setChildren = this.setChildren.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  checkEmail(email) {
    switch (true) {
      case email.length === 0:
        return i18n.validationRequired;
      case !emailSimpleRegExp.test(email):
        return i18n.validationEmailError;
      default:
        return false;
    }
  }

  checkChild(child) {
    const clean = child.filter(child => {
      if (child.name.length === 0 || !child.name) {
        return false;
      } else {
        return true;
      }
    });

    if (clean.length === 0) {
      return i18n.validationRequiredChild;
    } else {
      return false;
    }
  }

  checkPasswordLength(password, length = 6) {
    switch (true) {
      case password.length === 0:
        return i18n.validationRequired;
      case password.length < length:
        return i18n.validationPassLength;
      default:
        return false;
    }
  }

  checkPasswordEqual(firstField, secondField) {
    if (firstField.length && firstField !== secondField) {
      return i18n.validationPassEqual;
    } else {
      return false;
    }
  }

  render() {
    return (
      <form className="sign-up-container" onSubmit={this.onSubmit}>
        <AuthHeader text={i18n.welcome}/>
        <AuthText text={i18n.signupAndGetFreeLesson}/>

        <UserEmail
          value={this.state.email}
          onChange={this.onChange}
          message={this.state.errorMessage.email}
        />

        <UserPassword
          value={this.state.password}
          autoFocus={true}
          onChange={this.onChange}
          message={this.state.errorMessage.password}
        />
        <UserPassword
          value={this.state.password_confirmation}
          name="password_confirmation"
          placeholder="Повторите пароль"
          onChange={this.onChange}
          message={this.state.errorMessage.password_confirmation}
        />

        <MultiInput
          className={`input-txt__field ${
            this.state.errorMessage.child ? 'input-txt__field_state_error' : ''
          }`}
          placeholder_text={i18n.yourChildName}
          onChange={this.setChildren}
          message={this.state.errorMessage.child}
        />

        <button
          type="submit"
          className="auth-form__submit btn btn_full btn_size_l"
        >
          {i18n.register}
        </button>
      </form>
    );
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
      errorMessage: {
        ...this.state.errorMessage,
        [e.target.name]: ''
      }
    });
  }

  onSubmit(e) {
    e.preventDefault();

    // debugger;

    this.setState(
      {
        errorMessage: {
          email: this.checkEmail(this.state.email),
          child: this.checkChild(this.state.children_attributes),
          password: this.checkPasswordLength(this.state.password),
          password_confirmation: this.checkPasswordEqual(
            this.state.password,
            this.state.password_confirmation
          )
        }
      },
      () => {
        if (
          !this.state.errorMessage.email &&
          !this.state.errorMessage.child &&
          !this.state.errorMessage.password &&
          !this.state.errorMessage.password_confirmation
        ) {
          api
            .postUserSession('sign_up', {
              roistat:'123456',
              utm_source:'some_source',
              utm_campaign:'some_campaing',
              email: this.state.email,
              password: this.state.password,
              password_confirmation: this.state.password_confirmation,
              children_attributes: this.state.children_attributes,
              time_zone: this.state.time_zone
            })
            .then(response => {
              this.setState({errorMessage: ''});
              // eslint-disable-next-line no-undef
              fbq('track', 'Lead');
              auth.handleAuthResponse(response);
            })
            .catch(error => {
              if (
                error.response.data.errors &&
                error.response.data.errors.email
              ) {
                this.setState({
                  errorMessage: {
                    email:
                      'Такой email уже зарегистрирован.<br/><span class="text">Хотите <a href="/?auth=login" class="link">войти</a>?</span>'
                  }
                });
              }
            });
        }
      }
    );
  }

  setChildren(children) {
    let children_attributes = children.map(name => {
      return {name};
    });

    this.setState({
      children_attributes,
      errorMessage: {
        ...this.state.errorMessage,
        child: ''
      }
    });
  }
}
