import React, {Component} from 'react';
import {redirect} from '../../helpers';

export class LockdownSale extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hour: 0,
      minutes: 0,
      seconds: 0,
      id: null
    };
  }

  timeToString = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  componentDidMount() {
    this.setTime()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.time !== this.props.time) this.setTime()
  }

  componentWillUnmount() {
    const {id} = this.state;
    clearTimeout(id)
  }

  setTime = () => {
    const {time} = this.props;
    const {id} = this.state;
    clearTimeout(id);

    if (time) {
      const remainingTime = (2 * 86400000 + time - (new Date()).getTime()) / 1000;

      const hour = Math.floor(remainingTime / 3600);
      const minutes = Math.floor((remainingTime - hour * 3600) / 60);
      const seconds = Math.floor(remainingTime - hour * 3600 - minutes * 60);
      const idTimeout = setTimeout(this.tickTick, 1000)

      this.setState({
        hour: this.timeToString(hour),
        minutes: this.timeToString(minutes),
        seconds: this.timeToString(seconds),
        id: idTimeout
      })
    }
  }

  tickTick = () => {
    const {hour, minutes, seconds} = this.state;

    let newHour, newMinutes, newSeconds;
    newSeconds = parseInt(seconds, 10) - 1;
    if (newSeconds <= 0) {
      newSeconds = 59;
      newMinutes = parseInt(minutes, 10) - 1;
    } else {
      newMinutes = parseInt(minutes, 10);
    }
    if (newMinutes <= 0) {
      newMinutes = 59;
      newHour = parseInt(hour, 10) - 1;
    } else {
      newHour = parseInt(hour, 10);
    }
    if (newHour < 0) {
      newHour = 0;
      newMinutes = 0;
      newSeconds = 0;

      this.setState({
        hour: this.timeToString(newHour),
        minutes: this.timeToString(newMinutes),
        seconds: this.timeToString(newSeconds)
      })
      return;
    }

    const id = setTimeout(this.tickTick, 1000)
    this.setState({
      hour: this.timeToString(newHour),
      minutes: this.timeToString(newMinutes),
      seconds: this.timeToString(newSeconds),
      id
    })
  }

  goToTariff = () => {
    this.props.goToLink();
    redirect('/cabinet/select_tariff');
  }

  render() {

    return (
      <div className="lockdown">
        <h2 className="lockdown__title">
          ЛОКДАУН <br/>
          С ПОЛЬЗОЙ!
        </h2>
        <p className="lockdown__block">Скидка 75%</p>
        <div className="lockdown__info">
          <p className="lockdown__text">1 год подписки за <span className="lockdown__old-price">4600</span> руб</p>
          <p className="lockdown__text">
            <span className="lockdown__new-price">1149 руб</span>
          </p>
        </div>
        <div
          onClick={this.goToTariff}
          className="infant-garb__button"
        ><span>Подписаться</span></div>
      </div>
    )
  }
}
