import React, {Component} from 'react';
import {config} from '../../data';
import {classes} from '../../helpers/classes';

export class TestQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questionAudio: null
    }
  }

  componentDidMount() {
    const {question, setAudioVolume} = this.props;
    if (!question.audio) return;
    const questionAudio = new Audio(config.base_url + question.audio);
    questionAudio.addEventListener('ended', () => setAudioVolume(1));
    this.setState({questionAudio})
  }

  componentDidUpdate() {
    const {playAudio, index} = this.props;
    if (playAudio) setTimeout(() => this.playQuestion(), index === 0 ? 0 : 500);
  }

  componentWillUnmount() {
    const {questionAudio} = this.state;
    if (!questionAudio)
      questionAudio.pause();
    questionAudio.src = '';
  }

  playQuestion() {
    const {setAudioVolume, question} = this.props;
    const {questionAudio} = this.state;

    if (!question.audio) return true;
    if (questionAudio && !questionAudio.paused) questionAudio.pause();
    setAudioVolume(0.2);
    questionAudio.currentTime = 0;
    if (questionAudio) setTimeout(() => questionAudio.play(), 0);
  }

  render() {
    const {
      current,
      index,
      question,
      testResult
    } = this.props;

    const className = classes(
      'test-question test-question_state_' + (current === index ? 'current' : ''),
      !!question.audio && 'hasAudio',
      'test-question_state_' + (current > index ? 'hide' : 'show'),
      testResult[current] && 'test-question_state_' + (testResult[current].error ? 'wrong' : 'right') + '_answer_before',
      'test-question_state_' + (question.error ? 'wrong_answer' : 'right_answer')
    );

    return (
      <li className={className}>
        <span>{question.title}</span>
        {question.audio &&
        <span onClick={() => this.playQuestion()}
              className={'test-question-audio__btn'}/>}
      </li>
    );
  }
}
