import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PromoForm, Tariff } from '../../components';
import Popup from '../../components/popup/popup';
import { onEscapeKeyPress } from '../../helpers';
import {getTokenForYoukassa} from '../../api/payments';
import * as images from './images';

export class Tariffs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tariffs: [],
      popupData: {},
      is_popup_closed: true,
      selectedTariff: null,
    };
    this._getPromoCodes = this._getPromoCodes.bind(this);
    this.selectTariff = this.selectTariff.bind(this);
    this.hidePopUp = this.hidePopUp.bind(this);
  };

  componentDidMount() {
    const tariffsSort = this.props.tariffs.filter(tariff => tariff.apple_pay_id.length === 0);
    tariffsSort.sort(function(a, b) {
      return parseFloat(a.months_number) - parseFloat(b.months_number);
    });
    this.setState({
      tariffs: tariffsSort,
    })
  }

  _getPromoCodes(result, promo) {
    this.setState((state) => {
      const tariffs = state.tariffs.map((tariff) => {
        const tariffWithPromo = result.find((e) => e.id === tariff.id);

        return {
          ...tariff,
          promo: tariffWithPromo
            ? {word: promo, price: tariffWithPromo.price, is_recurrent:tariffWithPromo.is_recurrent }
            : null,
        }
      })

      return { tariffs }
    })
  }

  selectTariff(tariff, isProlong) {
    window.addEventListener('keydown', () => onEscapeKeyPress(this.hidePopUp));

    const utm = window.sessionStorage.getItem('utmString') || '';
    const promo_code = tariff.promo && tariff.promo.word
      ? {promo_code: tariff.promo.word.toLowerCase()}
      : null;

    getTokenForYoukassa({
      tariff_id: tariff.id,
      is_recurrent: isProlong ? '1' : '0',
      utm,
      ...(promo_code ? promo_code : {})
    }).then(data => {
      let backUrl = `https://game.chevostik.ru/successful-payment?price=${tariff.promo ? tariff.promo.price : tariff.price}&idTariff=${tariff.id}&nameTariff=${tariff.title}&paymentId=${data.paymentId}`;

      if (tariff.promo) backUrl += `&promoWord=${tariff.promo.word}`;

      data.backUrl = backUrl;

      this.setState({popupData: {...data}});

      this.setState({
        selectedTariff: {
          ...tariff,
          isProlong,
        },
        is_popup_closed: false,
      });
    });
  }

  hidePopUp() {
    window.removeEventListener('keydown', () => onEscapeKeyPress(this.hidePopUp));

    this.setState({
      is_popup_closed: true,
      selectedTariff: null,
    })
  }

  render () {
    const {
      selectedTariff,
      tariffs,
      is_popup_closed,
    } = this.state;

    if (!tariffs.length) return null;

    let {popupData} = this.state;
    window.ym(92335881,'reachGoal','paymentint')
    return (
      <section className="tariffs">
        <Link
          to={'/cabinet/billing'}
          className="tariffs__close">
        </Link>
        {selectedTariff && <Popup
          popupData={popupData}
          location={this.props && this.props.location}
          selectedTariff={selectedTariff}
          is_closed={is_popup_closed}
          hidePopUp={this.hidePopUp}
          item={{
            section: 'payment-tariff'
          }}
        />}
        <div className="tariffs__wrapper">
          <div className="tariffs__container">
            <h2 className="tariffs__title">Подписка</h2>

          </div>
          <ul className={'tariffs__list'}>
            {tariffs.map(tariff => <Tariff tariff={tariff} key={tariff.id} selectTariff={this.selectTariff}/>)}
          </ul>
          <img alt={'logo'} src={images.logo} style={{width:"80vw"}}/>
          <PromoForm setPromo={this._getPromoCodes}/>
        </div>
      </section>
    );
  }
}

Tariffs.propTypes = {
  tariffs: PropTypes.array,
  onChooseBtnClick: PropTypes.func,
};
