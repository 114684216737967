import {HIDE_POPUP, SHOW_POPUP} from '../actionTypes';

const initialState = {
  isLoginPopupVisible: false,
  isSignupPopupVisible: false,
  isPasswordResetPopupVisible: false,
  isPasswordCreatePopupVisible: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case HIDE_POPUP: {
      const {popup} = action.payload;
      if (popup === 'login') {
        return {
          ...state,
          isLoginPopupVisible: false
        };
      } else if (popup === 'signup') {
        return {
          ...state,
          isSignupPopupVisible: false
        };
      } else if (popup === 'password-reset') {
        return {
          ...state,
          isPasswordResetPopupVisible: false
        };
      } else if (popup === 'password-create') {
        return {
          ...state,
          isPasswordCreatePopupVisible: false
        };
      }
    }
      break;
    case SHOW_POPUP: {
      const {popup} = action.payload;
      if (popup === 'login') {
        return {
          ...state,
          isLoginPopupVisible: true
        };
      } else if (popup === 'signup') {
        return {
          ...state,
          isSignupPopupVisible: true
        };
      } else if (popup === 'password-reset') {
        return {
          ...state,
          isPasswordResetPopupVisible: true
        };
      } else if (popup === 'password-create') {
        return {
          ...state,
          isPasswordCreatePopupVisible: true
        };
      }
    }
      break;
    default:
  }
  return state;
}
