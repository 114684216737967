import React, { Component } from 'react';
import { config } from '../../data';
import * as api from '../../api';
import * as auth from '../../helpers/authenticationHelper';

export class ShareOffer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isShared: false,
      isClosed: false
    };

    this.showGame = this.showGame.bind(this);

  }


  render() {
    let user = auth.getProfile() || {};
    return (
            <div className="box__wrapper">
              <div className={`share__offer ${ this.state.isShared || user.shared_link || window.localStorage.getItem('link_shared') ? 'share__offer_hide' : ''}`}>
                <div className="share__img"></div>
                <div className="share__text">Поделись ссылкой на нас — получи бонусную игру</div>
                <div className="share__area">
                  <a onClick={this.showGame} className="share__link share__link--vk" target="_blank" rel="noopener noreferrer" href={`https://vk.com/share.php?url=${config.base_url}&title=Приключения Чевостика. Интерактивный развлекательно-познавательный сайт для детей от 3 до 8 лет.&description=Интерактивный, развлекательно-познавательный сайт для дошкольников и младших школьников&image=https://chevostik.ru/preview_meta.png&noparse=true`}>
                    <svg className="share__icon share__icon--vk" viewBox="0 0 24 15" xmlns="http://www.w3.org/2000/svg"><path d="M19.937 6.89S23.1 2.43 23.414.963c.104-.523-.126-.817-.67-.817H20c-.628 0-.86.273-1.047.67 0 0-1.483 3.16-3.288 5.153-.58.642-.874.836-1.194.836-.257 0-.376-.215-.376-.796V.92c0-.71-.084-.92-.67-.92H9.026c-.335 0-.544.195-.544.46 0 .67 1.026.823 1.026 2.64v3.748c0 .754-.042 1.047-.398 1.047-.942 0-3.183-3.246-4.44-6.932C4.42.21 4.147 0 3.435 0H.69C.294 0 0 .272 0 .67c0 .733.86 4.126 4.23 8.67 2.262 3.058 5.236 4.712 7.917 4.712 1.633 0 2.03-.272 2.03-.963v-2.347c0-.586.23-.837.566-.837.377 0 1.043.12 2.597 1.654 1.843 1.76 1.97 2.492 2.974 2.492h3.08c.313 0 .606-.146.606-.67 0-.69-.9-1.927-2.283-3.393-.565-.754-1.487-1.57-1.78-1.948-.42-.44-.293-.69 0-1.152"  fillRule="evenodd"/></svg>
                  </a>
                  <a onClick={this.showGame} className="share__link share__link--fb" target="_blank" rel="noopener noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=${config.base_url}&picture=https://chevostik.ru/preview_meta.png`}>
                    <svg className="share__icon share__icon--fb" viewBox="0 0 11 22" xmlns="http://www.w3.org/2000/svg"><path d="M6.49 22H2.31V11H0V7.37h2.31V4.84C2.31 1.76 3.63 0 7.04 0h3.08v3.63H8.14c-1.43 0-1.76.55-1.76 1.54v2.09h3.63L9.68 11H6.49v11z" fillRule="nonzero"/></svg>
                  </a>
                </div>
              </div>
              <div className={`game__offer ${this.state.isShared || window.localStorage.getItem('link_shared') ? '' : 'game__offer_hide'} ${ this.state.isClosed || window.localStorage.getItem('game_offer_closed') ? 'game__offer_hide' : '' }`}>
                  <div className="close__btn" onClick={ this.closeBtn.bind(this) }></div>
                  <div className="game__text">Спасибо, что рассказали про нас в соцсетях </div>
                  <a className="game__link" href="/games/25/"></a>
                  <svg className="game__arrow" viewBox="0 0 36 39" xmlns="http://www.w3.org/2000/svg"><path d="M.41 36.346C7.576 22.528 17.39 12.024 30.267 3.77c-2.413-.12-4.83-.207-7.24-.118-1.82.068-2.287-2.755-.447-2.824 3.81-.14 7.63.05 11.433.28.972.056 1.446 1.107 1.174 1.938-1.14 3.483-2.282 6.963-3.437 10.44-.58 1.748-3.25.71-2.668-1.036.638-1.925 1.267-3.85 1.9-5.774-11.947 7.8-21.214 17.81-27.9 30.71-.84 1.617-3.52.597-2.67-1.04" fill="#F77600" fillRule="evenodd"/></svg>
                  <div className="game__text">А вот и подарочная игра!</div>
              </div>
              <div className={`share__offer ${window.localStorage.getItem('game_offer_closed' || user.shared_link) ? '' : 'share__offer_hide'}`}>
                <div className="share__img"></div>
                <div className="share__text">Расскажи о нас в социальных сетях!</div>
                <div className="share__area">
                  <a className="share__link share__link--vk" target="_blank" rel="noopener noreferrer" href={`https://vk.com/share.php?url=${config.base_url}&title=Приключения Чевостика. Интерактивный развлекательно-познавательный сайт для детей от 3 до 8 лет.&description=Интерактивный, развлекательно-познавательный сайт для дошкольников и младших школьников&image=https://chevostik.ru/preview_meta.png&noparse=true`}>
                    <svg className="share__icon share__icon--vk" viewBox="0 0 24 15" xmlns="http://www.w3.org/2000/svg"><path d="M19.937 6.89S23.1 2.43 23.414.963c.104-.523-.126-.817-.67-.817H20c-.628 0-.86.273-1.047.67 0 0-1.483 3.16-3.288 5.153-.58.642-.874.836-1.194.836-.257 0-.376-.215-.376-.796V.92c0-.71-.084-.92-.67-.92H9.026c-.335 0-.544.195-.544.46 0 .67 1.026.823 1.026 2.64v3.748c0 .754-.042 1.047-.398 1.047-.942 0-3.183-3.246-4.44-6.932C4.42.21 4.147 0 3.435 0H.69C.294 0 0 .272 0 .67c0 .733.86 4.126 4.23 8.67 2.262 3.058 5.236 4.712 7.917 4.712 1.633 0 2.03-.272 2.03-.963v-2.347c0-.586.23-.837.566-.837.377 0 1.043.12 2.597 1.654 1.843 1.76 1.97 2.492 2.974 2.492h3.08c.313 0 .606-.146.606-.67 0-.69-.9-1.927-2.283-3.393-.565-.754-1.487-1.57-1.78-1.948-.42-.44-.293-.69 0-1.152"  fillRule="evenodd"/></svg>
                  </a>
                  <a className="share__link share__link--fb" target="_blank" rel="noopener noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=${config.base_url}&picture=https://chevostik.ru/preview_meta.png`}>
                    <svg className="share__icon share__icon--fb" viewBox="0 0 11 22" xmlns="http://www.w3.org/2000/svg"><path d="M6.49 22H2.31V11H0V7.37h2.31V4.84C2.31 1.76 3.63 0 7.04 0h3.08v3.63H8.14c-1.43 0-1.76.55-1.76 1.54v2.09h3.63L9.68 11H6.49v11z" fillRule="nonzero"/></svg>
                  </a>
                </div>
              </div>
            </div>
          )
        }

  showGame(){
    api.sharedLink();
    this.setState({
      isShared: true
    })
    window.localStorage.setItem('link_shared', true);
  }

  closeBtn(){
    window.localStorage.setItem('game_offer_closed', true);
    this.setState({
      isClosed: true
    })
  }
}



