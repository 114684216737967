import React from 'react';
import PropTypes from 'prop-types';

export const DialogPayment = (props) => {

  const {
    title,
    text,
    btnTitle
  } = props;

  return (
    <div className="dialog-payment">
      <h2 className="dialog-payment__title">{title}</h2>
      <p className="dialog-payment__text">{text}</p>
      <a
        className="btn btn_size_l"
        href="/cabinet"
      >
        {btnTitle}
      </a>
    </div>
  );
};

DialogPayment.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  btnTitle: PropTypes.string,
  hidePopUp: PropTypes.func,
};
