import React, {Component} from 'react';
import queryString from 'query-string';
import c from 'classnames'
import {emailSimpleRegExp, setTimeZoneOffsetInHours} from '../../helpers';
import {connect} from 'react-redux';
import {closeAuthPopup, openAuthPopup} from '../../redux/actions';
import {i18n, config} from '../../data';
import * as api from '../../api';
import * as auth from '../../helpers/authenticationHelper';
import {
  UserEmail,
  UserPassword,
  MultiInput,
  AuthText,
  AuthSocialButtonVk,
  AuthSocialButtonFb
} from '../../components';

class SingUpNewContainer extends Component {
  constructor(props) {
    super(props);
    const query = this.props.location ? queryString.parse(this.props.location.search) : {}

    this.state = {
      email: query.email || '',
      password: '',
      password_confirmation: '',
      children_attributes: [],
      errorMessage: {
        email: false,
        password: false,
        password_confirmation: false,
        child: false
      },
      time_zone: `${setTimeZoneOffsetInHours(new Date())}`
    };

    this.setChildren = this.setChildren.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  checkEmail(email) {
    switch (true) {
      case email.length === 0:
        return i18n.validationRequired;
      case !emailSimpleRegExp.test(email):
        return i18n.validationEmailError;
      default:
        return false;
    }
  }

  checkChild(child) {
    const clean = child.filter(child => {
      if (child.name.length === 0 || !child.name) {
        return false;
      } else {
        return true;
      }
    });

    if (clean.length === 0) {
      return i18n.validationRequiredChild;
    } else {
      return false;
    }
  }

  checkPasswordLength(password, length = 6) {
    switch (true) {
      case password.length === 0:
        return i18n.validationRequired;
      case password.length < length:
        return i18n.validationPassLength;
      default:
        return false;
    }
  }

  checkPasswordEqual(firstField, secondField) {
    if (firstField.length && firstField !== secondField) {
      return i18n.validationPassEqual;
    } else {
      return false;
    }
  }

  switchDialogs() {
    this.props.closeAuthPopup('signup');
    this.props.openAuthPopup('login');
  }

  render() {
    return (
      <form className="sign-up" onSubmit={this.onSubmit}>
        <h2 className="sign-up__title">{i18n.signUpTitle}</h2>
        <AuthText text=""/>
        <div className="sign-up__required">
          <MultiInput
            className={c('sign-up__name', {
              'sign-up__name--error': this.state.errorMessage.child
            })}
            placeholder_text={i18n.yourChildName}
            onChange={this.setChildren}
            message={this.state.errorMessage.child}
          />
        </div>
        <div className="sign-up__required">
          <UserEmail
            value={this.state.email}
            onChange={this.onChange}
            message={this.state.errorMessage.email}
          />
        </div>
        <div className="sign-up__required">
          <UserPassword
            value={this.state.password}
            autoFocus={true}
            onChange={this.onChange}
            message={this.state.errorMessage.password}
          />
        </div>
        <div className="sign-up__required">
          <UserPassword
            value={this.state.password_confirmation}
            name="password_confirmation"
            placeholder="Повторите пароль"
            onChange={this.onChange}
            message={this.state.errorMessage.password_confirmation}
          />
        </div>
        <div className="auth-container__social">
          <h4 className="auth-social-title"> Или войдите через соцсети</h4>
          <div className="auth-social-buttons">
            <AuthSocialButtonVk
              redirect_uri={config.social_auth.vk.login_redirect_uri}
            />
            <AuthSocialButtonFb
              redirect_uri={config.social_auth.fb.login_redirect_uri}
            />
          </div>
        </div>
        <button
          type="submit"
          className="auth-form__submit btn btn_full btn_size_l"
        >
          {i18n.register}
        </button>
        {' '}
        <span className="login_link" onClick={() => this.switchDialogs()}>У меня есть аккаунт</span>
        <p className="sign-up__agreement"
        >Нажимая на кнопку “Зарегистрироваться”, <br/> вы принимаете&nbsp;
          <a className="sign-up__agreement-link" href="/eula">пользовательское соглашение</a>
        </p>
      </form>
    );
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
      errorMessage: {
        ...this.state.errorMessage,
        [e.target.name]: ''
      }
    });
  }

  onSubmit(e) {
    e.preventDefault();

    // debugger;

    this.setState(
      {
        errorMessage: {
          email: this.checkEmail(this.state.email),
          child: this.checkChild(this.state.children_attributes),
          password: this.checkPasswordLength(this.state.password),
          password_confirmation: this.checkPasswordEqual(
            this.state.password,
            this.state.password_confirmation
          )
        }
      },
      () => {
        if (
          !this.state.errorMessage.email &&
          !this.state.errorMessage.child &&
          !this.state.errorMessage.password &&
          !this.state.errorMessage.password_confirmation
        ) {
          let data2send = {}, roistat_visit = false, utm = {};
          roistat_visit = document.cookie.match(/roistat_visit=(\d+)/);
          if (roistat_visit) {
            data2send.roistat = roistat_visit[1];
          }
          let urlParams = new URLSearchParams(window.location.search);
          let storedUTM = localStorage && localStorage.getItem("utm");
          for (let [key, value] of urlParams.entries()) {
            if (["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"].includes(key) && value) {
              utm[key] = value
            }
          }
          if (utm.utm_source) {
            localStorage && localStorage.setItem("utm", JSON.stringify(utm))
          } else if (storedUTM) {
            try {
              utm = JSON.parse(storedUTM)
            } catch (e) {}
          }
          data2send = {...data2send, ...utm}

          api
            .postUserSession('sign_up', {
              data2send,
              email: this.state.email,
              password: this.state.password,
              password_confirmation: this.state.password_confirmation,
              children_attributes: this.state.children_attributes.filter(e => e.name),
              time_zone: this.state.time_zone
            })
            .then(response => {
              this.props.closeAuthPopup('signup');
              this.setState({errorMessage: ''});

              if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
                // eslint-disable-next-line no-undef
             //   VK.Goal('complete_registration');

                // eslint-disable-next-line no-undef
                ym(92335881,'reachGoal','registration')

                // eslint-disable-next-line no-undef
             //   gtag('event', 'registration', { 'event_category': 'lead', 'event_label': 'registration' });

                // eslint-disable-next-line no-undef
            //    _tmr.push({ type: 'reachGoal', id: 3319847, goal: 'Registration'});
              }

              auth.handleNewAuthResponse(response);
            })
            .catch(error => {
              if (error.response && error.response.data.errors && error.response.data.errors.email) {
                this.setState({
                  errorMessage: {
                    email:
                      'Такой email уже зарегистрирован.<br/><span class="text">Хотите <a href="/?auth=login" class="link">войти</a>?</span>'
                  }
                });
              }
            });
        }
      }
    );
  }

  setChildren(children) {
    let children_attributes = children.map(name => {
      return {name};
    });

    this.setState({
      children_attributes,
      errorMessage: {
        ...this.state.errorMessage,
        child: ''
      }
    });
  }
}

export default connect(null, {
  closeAuthPopup,
  openAuthPopup
})(SingUpNewContainer);
