import { ajax, redirect } from '../../helpers';

/**
 * @typedef {Object} User
 */

/**
 * @param {string} email
 * @returns {Promise.<User>}
 */
export const getUserByEmail = (email) => {
  return ajax
    .get(`/users/search?email=${email}`)
    .then(response => response.data)
    .catch(err => {
      if (err.response.status === 404) {
        redirect(`/?auth=signup&email=${email}`)
      }
    });
};
