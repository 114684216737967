import { ajax } from '../../helpers';

/**
 * @typedef {Array.<Object>} Prizes
 */

/**
 * @returns {Promise.<Prizes>}
 */
export const getPrizes = (childId) => {
  return ajax
    .get(`/prizes?child_id=${childId}`)
    .then(response => response.data)
    .catch(error => console.log('getPrizes error: ', error));
};
