import React, { Component } from 'react';

export default class GameNextLevelBtn extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="next-level">
        <div className="next-level__board-icon" />
        <div className="next-level-btn" onClick={() => this.props.showNextFrame(this.props.gameName, this.props.timer)}>
          <div>Следующий<br />уровень</div>
          <div className="next-level-btn__arrow-icon" />
        </div>
      </div>
    );
  }
}
