import { ajax } from '../../helpers';
import React from 'react';
import { render } from 'react-dom';

import {
  ErrorPage
} from '../../containers';

/**
 * @typedef {Object} Message
 */

/**
 *
 * @param {string} email
 * @returns {Promise.<Message>}
 */
export const postEmailConfirmation = (email) => {
  return ajax
    .post('/auth/confirmation', {
      email: email
    })
    .then(response => response.data)
    .catch(err => {
      console.error('postEmailConfirmation error', postEmailConfirmation) /*eslint-enable */

      if (err.response.status === 404) {
        render(
          <ErrorPage status={404} />,
          document.getElementById('root')
        );
      }
    })
};
