import React, {Component} from 'react';
import c from 'classnames';

import {Youkassa} from '../youkassa';

export class PaymentTariff extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true
    }
  }

  componentDidMount() {
  }

  render() {
    const {loading} = this.state;
    const {children, popupData} = this.props;
    const {token, backUrl} = popupData;

    return (
      <div className={c('payment-tariff', {'payment-tariff--loading': loading})}>
        {children}
        <div className="payment-tariff__widget" id="widget-youkassa"/>
        {token && <Youkassa
          token={token}
          backUrl={backUrl}
          parentId="widget-youkassa"
          endLoad={() => this.setState({loading: false})}
        />}
      </div>
    );
  }
}
