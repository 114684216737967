import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class PageTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: true
    };
  }

  startTimer( timer = 15000, cb = () => {} ){
    if(!this.timerId){
      this.timerId = setTimeout(()=>{
        this.setState({
          isActive: false
        });
        cb();
      }, timer );
    }
  }

  componentDidMount(){
    if ( this.props.autoKill ) {
      this.startTimer( this.props.autoKill, this.props.autoKillCB );
    }
  }

  componentWillUnmount(){
    clearTimeout(this.timerId);
  }

  render () {
    const {
      title,
      message,
      link,
      position,
    } = this.props;

    let content = (
      <div className="page-tooltip__content">
        {title ? (<span className="page-tooltip__title">{title}</span>) : null}
        {message ? (<span className="page-tooltip__text">{message}</span>) : null}
      </div>
    );

    if (link) {
      return (
        <a href={link} className={`page-tooltip page-tooltip_${position ? position : 'right' }`}>
          {content}
        </a>
      )
    } else {
      return (
        <span className={`page-tooltip page-tooltip_${position ? position : 'right' } page-tooltip_state_${this.state.isActive ? 'show' : 'hide'}`}>
          {content}
        </span>
      )
    }
  }
};

PageTooltip.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  link: PropTypes.string,
  position: PropTypes.string
};
