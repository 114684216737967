import { ajax } from '../../helpers';
import * as auth from '../../helpers/authenticationHelper';

/**
 * @typedef {Object} Prize
 *
 * Save Test Results Response resolve
 * @typedef {Object} saveTestResultsResponse
 * @property {boolean} test_success
 * @property {Prize} [prize]
 */

/**
 * @param {number} id – test id
 * @param {Array<Object>} testResult
 * @returns {Promise.<saveTestResultsResponse>}
 */
export const postTest = (id, testResult) => {
  return ajax
    .post(`/tests/${id}/complete`,
      {
        questions: testResult,
        child_id: auth.getCurrentChild(),
        api_auth_token: auth.getToken()
      }
    )
    .then(response => response.data)
    .catch(error => console.log('postTest error: ', error));
};
