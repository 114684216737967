import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import Button from '../button/button';

const LinkButton = (props) => {
  const {
    children,
    onClick,
    history,
    to,
    ...rest
  } = props;

  return (
    <Button
      onClick={(event) => {
        onClick && onClick(event);
        history.push(to);
      }}
      {...rest}
    >{children}</Button>
  );
}

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired
}

export default withRouter(LinkButton);
