import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class PersonFact extends Component {
  render() {
    const {
      person,
      factPositionX,
      factPositionY,
    } = this.props;
    const icon = document.querySelector(`#${person.dom_id} .person__fact-icon`);
    const notShowFact = !(person.fact && person.fact.content && person.fact.content.description) || !icon;

    if (notShowFact) return null;
    const iconTop = icon.getBoundingClientRect().top;
    const iconLeft = icon.getBoundingClientRect().left;

    const styleForFact = {
      [(factPositionY === 'top') ? 'top' : 'bottom'] : (factPositionY === 'top')
        ? (`${iconTop - 30}px`)
        : (`calc(100vh - ${Math.round(iconTop)}px - 68px)`),
      [(factPositionX === 'right') ? 'left' : 'right'] : (factPositionX === 'right')
        ? (`${iconLeft + 79}px`)
        : (`calc(100vw - ${Math.round(iconLeft)}px + 45px)`),
    };
    const positionFactX = (factPositionX === 'left') ? 'person__fact--left' : 'person__fact--right';
    const positionFactY = (factPositionY === 'top')  ? 'person__fact--top'  : 'person__fact--bottom';
    return <article
      className={`person__fact ${positionFactX} ${positionFactY}`}
      style={styleForFact}
      dangerouslySetInnerHTML={{__html: person.fact.content.description}}
    />
  }
}

PersonFact.propTypes = {
  person: PropTypes.object,
  factPositionX: PropTypes.string,
  factPositionY: PropTypes.string
};
