import React from 'react';
import PropTypes from 'prop-types';

export const Icon = (props) => {
  return (
    <svg width={props.width} height={props.height}>
      <use xlinkHref={props.name} />
    </svg>
  );
};

Icon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  name: PropTypes.string
};
