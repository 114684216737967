import {ajaxV2} from '../../helpers/index';

/**
 * @param {String} code
 *  * @param {String} redirect_uri
 * @returns {Promise}
 */

let thnList = [{
  name: 'Батарейка',
  count: 3,
  info: 'Дает дополнительные 30 секунд',
  img: '/img/things/thing_1.png'
}, {
  name: 'Волшебный ключ',
  info: 'Открывает новый уровень, даже если не пройден предыдущий',
  img: '/img/things/thing_2.png'
}, {
  name: 'Рентгеновские очки',
  count: 0,
  info: 'На 10 секунд показывают расположение всех предметов на экране.',
  img: '/img/things/thing_3.png'
}];

let fixture = JSON.parse(JSON.stringify([].concat(thnList, thnList, thnList)));

for (let i = 0; i < fixture.length; i++) {
  let thing = fixture[i];
  thing.id = i;
  thing.price = 50 + (i * 10);
  if (i === 3) thing.newly = true;
}

export const getThingsList = () => {
  return ajaxV2
    .get('/ratings')
    .then(response => {

        window.devMode && console.log('getThingsList');
        return fixture || response.data.map(e => ({
          avatar: e.avatar,
          name: e.name,
          level: e.level,
          points: e.points
        }))
      }
    )
    .catch(error => error);
};
