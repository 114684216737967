import React, { useState } from 'react';
import { Alert, alertPosition } from '../../../components';
import { Autumn, withBanner, bannerType } from '../../banners';

const SpringAlert = ({ type }) => {
  const [open, setOpen] = useState(true);
  let position = '';

  if (type === bannerType.big) {
    position = alertPosition.center;
  } else if (type === bannerType.small) {
    position = alertPosition.bottom;
  }

  function close() {
    setOpen(false);
  }

  return (
    <Alert position={position} open={open}>
      <Autumn type={type} handleButtonClick={close} />
    </Alert>
  );
};

export const AutumnAlertBanner = withBanner(SpringAlert);
