import React, {useEffect, useState} from 'react';
import history from '../../history';

export const withBanner = (Component) => {
  const WithBanner = ({ name, expression = true, repeat = null, type, afterUpdatedShowed, ...props }) => {
    const storageFieldName = `${name}-showed`;

    const [showed, setShowed] = useState(() => {
      return Number(localStorage.getItem(`${storageFieldName}`)) + 1;
    });

    // Обновляем данные при переходе по ссылкам
    useEffect(() => {
      const unListen = history.listen(() => {
        if (expression) {
          setShowed(Number(localStorage.getItem(`${storageFieldName}`)) + 1);
        }
      });

      return () => {
        unListen();
      };
    }, [expression, storageFieldName]);

    useEffect(() => {
      if (expression) {
        localStorage.setItem(storageFieldName, `${repeat ? showed : 1}`);

        if (typeof afterUpdatedShowed === 'function') {
          // Хук после обновления данных
          afterUpdatedShowed(showed, repeat);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expression, repeat, showed, storageFieldName]);

    if (!expression
        || (repeat && (showed % repeat > 0)) // Если баннер нужно повторять (repeat = 1 - показывать всегда)
        || (!repeat && showed > 1) // Если баннер показывается только один раз
    ) {
      return null;
    }

    return (
      <Component type={type} {...props} />
    );
  };

  WithBanner.displayName = `${Component.name}WithBanner`;
  return WithBanner;
};
