const outlineRatio = 25;

/**
 * 
 * @param {CanvasRenderingContext2D} figure
 * @param {CanvasRenderingContext2D} outline
 * @returns {boolean} 
 */
export const isNearOutline = (figure, outline) =>{
  let f = figure;
  let o = outline[0];
  let fx = f.getX();
  let fy = f.getY();
  
  return fx > o.x - outlineRatio && fx < o.x + outlineRatio && fy > o.y - outlineRatio && fy < o.y + outlineRatio
};
