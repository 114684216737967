import React, {useState} from 'react';
import {DragPreviewImage, useDrag} from 'react-dnd';
import {isTablet} from '../../helpers';

const getStyle = ({height, width, left, top}) => {
  return {
    position: 'absolute',
    left,
    top,
    width,
    height,
    margin: 0,
    padding: 0,
    cursor: 'move',
    zIndex: 9910,
  };
}

export const Drag = ({name, width, height, top, left, src, draggable, scaleBothValue, onDragEnd = () => {}, onDragging = () => {}}) => {
  const [styleCss, setStyleCss] = useState(getStyle({
    height,
    width,
    left,
    top
  }));

  // console.log('scaleBothValue', name, scaleBothValue, width, height, top, left)
  // console.log('styleCss', styleCss)

  const [{isDragging}, drag, preview] = useDrag(() => ({
    type: name,
    item: {name, width, height, top, left, src, styleCss, scaleBothValue},
    canDrag: draggable,
    collect: (monitor) => {
      if (monitor.isDragging()) {
        onDragging();
      }
      return {
        isDragging: monitor.isDragging(),
      };
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        setStyleCss(getStyle({
          left: item.left,
          top: item.top
        }));
        onDragEnd();
      }
    },
  }));

  if (isDragging) {
    return isTablet() ? '' : <div ref={drag} style={{...styleCss, opacity: 0.4}}/>;
  }
  return (
    <>
      {!isTablet() && <DragPreviewImage connect={preview} />}
      <div ref={drag} role="Drag" style={{...styleCss}} data-testid={`drag-${name}`}>
        <img
          src={src}
          width={width}
          height={height}
          alt=""
          style={{padding: 0, margin: 0}}
        />
      </div>
    </>
  );
}
