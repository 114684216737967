import React, {Component} from 'react';
import PropTypes from 'prop-types';
import * as auth from '../../helpers/authenticationHelper';
import {formatDayAndMonth, redirect} from '../../helpers';
import {config} from '../../data';
import * as api from '../../api';

// export const CurrentSubscription = (props) => {
export class CurrentSubscription extends Component {

  constructor(props) {
    super(props);

    this.message = '';

    this.state = {
      title: 'Активна',
      btnName: 'btn',
      btnText: 'Продлить подписку',
      className: 'current-subscription',
      paymentFailure: ''
    };

    this.onLinkClick = this.onLinkClick.bind(this);
    this.onSelectTariff = this.onSelectTariff.bind(this);
  }

  onLinkClick(isRec) {
    if (isRec === false) {
      redirect(`/cabinet/${config.routes.select_tariff}`);
    } else {
      api.cancelSubscription()
      this.setState({
        title: 'Активна',
        btnName: 'btn',
        btnText: 'Продлить подписку',
        className: 'current-subscription'
      })
    }
  }

  onSelectTariff(event, id, rec) {
    event.preventDefault();
    api.postPayments({'tariff_id': id, is_recurrent: rec})
      .then(response => {
        window.devMode && console.log(response);
        // window.location.replace(response.url);
      })
  }

  componentWillMount() {
    const {
      payments
    } = this.props;

    this.subscription = ((auth.getProfile() || {}).subscription) || {recurrent_status: 2};
    let filteredPayment = [];

    payments.forEach(item => {
      if (item.status === 'completed') {
        filteredPayment.push(item)
      }
    });

    this.currentPayment = filteredPayment[filteredPayment.length - 1] || {
      tariff: {
        name: 'Промо'
      }
    };

    window.devMode && console.log('this.currentPayment', this.currentPayment);

    this.fullDate = new Date(this.currentPayment.created_at * 1000);
    this.date = `${this.fullDate.getDate()}.${formatDayAndMonth(this.fullDate.getMonth() + 1)}.${this.fullDate.getFullYear()}`;
    this.message = <span className="nowrap">{this.subscription.message}</span>;


    if (this.subscription && this.subscription.status === 'off') {
      this.setState({
        title: 'Подписка закончилась',
        className: 'current-subscription current-subscription_status_off'
      })
      this.message = null;
    }

    if (this.subscription && this.subscription.is_recurrent === true) {
      this.setState({
        title: 'Автопродление подписки',
        btnName: 'link',
        btnText: 'Отключить автопродление'
      })
      this.message = null;
    }

    if (this.subscription && this.subscription.recurrent_status !== 0) {

      if (this.subscription.recurrent_status_retry !== 0) {
        this.retryDate = new Date(this.subscription.recurrent_status_retry * 1000);
        this.fullRetryDate = `${this.retryDate.getDate()}.${formatDayAndMonth(this.retryDate.getMonth() + 1)}.${this.retryDate.getFullYear()}`;
      }

      this.setState({
        title: 'Ошибка (перезагрузите страницу)',
        btnName: 'link',
        btnText: 'Отключить автопродление',
        paymentFailure: `Повторная попытка списания средств будет проведена ${this.fullRetryDate}`
      })
      this.message = null;
    }
  }

  render() {

    return (
      <article className={this.state.className}>

        <div className="current-subscription__validity">
          <h4 className="current-subscription__sub-title">Срок действия</h4>
          <h3 className="current-subscription__title">{this.state.title} {this.message}</h3>
          <div
            className={this.subscription.recurrent_status !== 0 ? 'btn retry_show' : 'retry_hide'}
            onClick={(event) => {
              this.onSelectTariff(event, this.subscription.tariff.id, '1')
            }}
          >
            Перейти в Яндекс.Кассу
          </div>
          <div className={this.subscription.recurrent_status === 1 ? 'retry_renew' : 'retry_hide'}
          >
            {this.state.paymentFailure}
          </div>
          <div
            onClick={() => {
              this.onLinkClick(this.subscription.is_recurrent)
            }}
            className={this.state.btnName}
          >{this.state.btnText}
          </div>
        </div>
        <div className="current-subscription__current-tariff">
          <h3 className="current-subscription__sub-title">Текущий тариф</h3>
          <h3 className="current-subscription__title">{this.currentPayment.tariff.name}</h3>
          {this.currentPayment.tariff.name !== 'Промо' && <p className="current-subscription__text">Оплачен {this.date} {this.subscription.message}</p>}
        </div>

      </article>
    );
  }
}

CurrentSubscription.propTypes = {
  payments: PropTypes.array
};
