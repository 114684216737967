import React, { Component } from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { config } from '../../data';
import rangeSlider from 'rangeslider-pure';
import { formatTime } from '../../helpers';
import * as api from '../../api';
import * as auth from '../../helpers/authenticationHelper';

export class LessonAudio extends Component {
  intervalDuration = 1000 * 60; // 1 минута
  firstSendProgressDelay = 1000 * 60 * 10; // 10 минут

  constructor(props) {
    super(props);
    this.toggleAudioPlay = this.toggleAudioPlay.bind(this);
    this.handleTimeUpdate = this.handleTimeUpdate.bind(this);
    this.setMaxDurationValue = this.setMaxDurationValue.bind(this);
    this.onAudioEnded = this.onAudioEnded.bind(this);
    this.startAudio = this.startAudio.bind(this);
    this.sendProgress = this.sendProgress.bind(this);
    this.sendProgressAtIntervalInit = this.sendProgressAtIntervalInit.bind(this);
    this.showControls = this.showControls.bind(this);

    this.state = {
      isAudioLoadedMetadata: false,
      timeFromSendProgress: 0,
      showControls: false,
    };
  }

  componentWillUpdate(nextProps) {
    if (nextProps.audio_start_play) {
      this.startAudio();
    } else {
      return;
    }
  }

  componentDidMount() {
    this.audioNode = this.refs.lessonAudio;
    let browser;
    function browserDetection() {
      //Check if browser is Chrome
      if (navigator.userAgent.search('Chrome') >= 0) {
        browser = 'Chrome';
      }
      //Check if browser is Safari
      else if (
        navigator.userAgent.search('Safari') >= 0 &&
        navigator.userAgent.search('Chrome') < 0
      ) {
        browser = 'Safari';
      }
    }
    browserDetection();

    if(browser === 'Safari'){
      this.audioNode = document.querySelector('#audioSafari');
    }

    if (this.props.startPlay) this.audioNode.play();
    this.audioNode.addEventListener('timeupdate', () => {
      this.customSlider.range.querySelector(
        '.rangeSlider__time span'
      ).textContent = `${formatTime(this.audioNode.currentTime)}`;
      this.customSlider.update({ value: this.audioNode.currentTime });
    });

    let range = this.refs.inputRange;
    let that = this;
    let currentTimeTemplate = `
      <time class='rangeSlider__time'>
        <span>${formatTime(this.audioNode.currentTime)}</span>
      </time>`;
    const duration = that.audioNode.duration;
    rangeSlider.create(range, {
      polyfill: true,
      rangeClass: 'lesson-audio__slider',
      onInit() {
        this.range.querySelector(
          '.rangeSlider__handle'
        ).innerHTML = currentTimeTemplate;
      },
      onSlide() {
        // update audio position
        that.audioNode.currentTime = this.value;
      },
      ...(duration ? {max: Math.ceil(duration)} : {})
    });
    if (!duration) this.audioNode.addEventListener('loadedmetadata', this.setMaxDurationValue);
    this.customSlider = range.rangeSlider;

    // Play/pause on space button
    document.addEventListener('keydown', this.keydownHandler);
  }

  componentDidUpdate(prevProps) {
    this.sendProgressAtIntervalInit();
    if (prevProps.triggerShowAudioProgree !== this.props.triggerShowAudioProgree && this.state.showControls !== false) this.setState({showControls: false})
  }

  componentWillUnmount() {
    this.customSlider.destroy();
    this.audioNode.pause();
    // когда пользователь уходит со страницы урока отсылаем количество прослушанных минут
    // если их прослушано больше 10 (минут)
    // if (this.state.timeFromSendProgress >= this.firstSendProgressDelay) {
    //   this.sendProgress();
    // }

    // когда пользователь уходит со страницы урока отсылаем количество прослушанных минут
    this.sendProgress();

    clearInterval(this.sendProgressIntervalID);

    // Remove play/pause on space button
    document.removeEventListener('keydown', this.keydownHandler);
  }

  showControls() {
    const {showControls} = this.state;
    if (showControls) return this.toggleAudioPlay()
    if (!showControls) this.setState({showControls: true})
  }

  render() {
    const {showControls} = this.state;
    const {is_audio_playing} = this.props;

    if (this.audioNode !== undefined) {
      is_audio_playing
        ? this.audioNode.play()
        : this.audioNode.pause();
    }
    let audioDuration;

    if (this.state.isAudioLoadedMetadata) {
      audioDuration = (
        <time className="lesson-audio__time">
          {formatTime(this.audioNode.duration)}
        </time>
      );
    }

    return (
      <div className="lesson-audio">
        <div>
          <button
            type="button"
            className={c(
              'lesson-audio__btn',
              {
                'lesson-audio__btn_state_controls' : !showControls,
                'lesson-audio__btn_state_pause' : showControls && is_audio_playing,
                'lesson-audio__btn_state_play' : showControls && !is_audio_playing,
              },
            )}
            onClick={this.showControls}
          />

          <div className={c('lesson-audio__controls', {
            'lesson-audio__controls--show' : showControls
          })}>
            <div className="lesson-audio__slider--wrapper">
              <input
                type="range"
                step="any"
                className="lesson-audio__range"
                value="0"
                ref="inputRange"
                readOnly
              />
              <button className='lesson-audio__controls-close' onClick={() => this.setState({showControls : false})}/>
            </div>
          </div>

          {audioDuration}

          <div className="lesson-audio__inner">
            <audio
              id="lesson-player"
              className="lesson-audio__player"
              preload="none"
              src={`${config.base_url}${this.props.audio}`}
              type="audio/mp3"
              controls="controls"
              onTimeUpdate={this.handleTimeUpdate}
              onEnded={this.onAudioEnded}
              ref="lessonAudio"
            />
          </div>
        </div>
      </div>
    );
  }

  /**
   * Обрабатывает событие нажатия на клавиатуру
   */
  keydownHandler = e => {
    if (e.keyCode === 32) {
      this.toggleAudioPlay();
    }
  };

  /**
   * Переключает аудио
   */
  toggleAudioPlay() {
    this.audioNode.paused ? this.audioNode.play() : this.audioNode.pause();
    this.props.toggleAudioPlayingState();
  }

  /**
   * Обновляет текущее время:
   * 1) в аудиодорожке (rangeSlider)
   * 2) в блоке (в секундах), который появляетсяс при наведении на ползунок у rangeSlider
   */
  handleTimeUpdate() {
    this.customSlider.range.querySelector(
      '.rangeSlider__time span'
    ).textContent = `${formatTime(this.audioNode.currentTime)}`;
    this.customSlider.update({ value: this.audioNode.currentTime });
  }

  /**
   * Устанавливает максимальную длину проигрывания аудио
   */
  setMaxDurationValue() {
    this.setState({
      isAudioLoadedMetadata: true
    });

    this.customSlider.update({ max: Math.ceil(this.audioNode.duration) });

    if (
      this.props.audio_progress.listened_seconds &&
      !(
        this.props.audio_progress.listened_seconds ===
        this.props.audio_progress.total_seconds
      )
    ) {
      this.audioNode.currentTime = this.props.audio_progress.listened_seconds;
      this.audioNode.play();
    }
    this.audioNode.removeEventListener('loadedmetadata', this.setMaxDurationValue)
  }

  /**
   * Начинает игру аудио сначала
   */
  startAudio() {
    // this.audioNode.currentTime = 0;
    this.audioNode.play();
  }

  /**
   * Отправляет прогресс количества прослушанных минут у аудио
   */
  sendProgress() {
    let totalListenedSeconds = Math.round(this.audioNode.currentTime);
    let totalSeconds = Math.round(this.audioNode.duration);
    if (!isNaN(totalSeconds)) {
      let childProgress = {
        child_id: auth.getCurrentChild(),
        api_auth_token: auth.getToken(),
        progress: {
          total_seconds: totalSeconds,
          listened_seconds: totalListenedSeconds
        }
      };
      api.postLessonProgress(this.props.lesson_url, childProgress).then(data => {
        window.devMode && console.log('postLessonProgress', data);
      });
    }
  }
  /**
   * Показывает лоадер, когда аудио закончилось
   */
  onAudioEnded() {
    this.props.showPostLoader();
  }

  /**
   * Устанавливает интервал для отправки прогресса прослушивания аудио
   */
  sendProgressAtIntervalInit() {
    if (!this.state.isAudioLoadedMetadata) return;

    let timeFromFirstSendProgress = this.state.timeFromSendProgress;

    clearInterval(this.sendProgressIntervalID);

    if (this.props.is_audio_playing) {
      this.sendProgressIntervalID = setInterval(() => {
        timeFromFirstSendProgress += this.intervalDuration;

        // Посылать запросы после отсрочки `firstSendProgressDelay`
        // т.е первый запрос отсылается после 10 минут, послеующие через intervalDuration
        if (timeFromFirstSendProgress >= this.firstSendProgressDelay) {
          this.setState(
            {
              timeFromSendProgress: timeFromFirstSendProgress
            },
            () => this.sendProgress()
          );
        }

        // если остановили аудио, записываем количество прослушанных секунд
        if (!this.props.is_audio_playing) {
          clearInterval(this.sendProgressIntervalID);
          this.setState({ timeFromSendProgress: timeFromFirstSendProgress });
        }
      }, this.intervalDuration);
    }
  }
}

LessonAudio.propTypes = {
  audio: PropTypes.string,
  audio_start_play: PropTypes.bool,
  is_audio_playing: PropTypes.bool,
  showPostLoader: PropTypes.func,
  toggleAudioPlayingState: PropTypes.func,
  audio_progress: PropTypes.object,
  is_audio_finished: PropTypes.bool,
  lesson_url: PropTypes.number,
};
