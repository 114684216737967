import React, {Component} from 'react';
import {connect} from 'react-redux';
import {closeAuthPopup, openAuthPopup} from '../../redux/actions';

import {emailSimpleRegExp} from '../../helpers'
import {
  i18n
} from '../../data';
import {redirect} from '../../helpers';
import * as api from '../../api';
import {
  UserEmail,
  AuthHeader,
  Error
} from '../../components';

class EnterContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      errorMessage: ''
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  render() {
    return (
      <form
        className="login-container"
        onSubmit={this.onSubmit}
      >
        <AuthHeader text={i18n.startAdventureWithChevostik}/>

        <UserEmail value={this.state.email} onChange={this.onChange}/>

        {this.state.errorMessage && <Error message={this.state.errorMessage}/>}

        <button
          type="submit"
          className="auth-form__submit btn btn_full btn_size_l"
        >
          {i18n.next}!
        </button>

       {/* <div className="auth-container__social">
          <h4 className="auth-social-title">или войдите через соцсети</h4>
          <div className="auth-social-buttons">
            <AuthSocialButtonVk redirect_uri={config.social_auth.vk.login_redirect_uri}/>
            <AuthSocialButtonFb redirect_uri={config.social_auth.fb.login_redirect_uri}/>
          </div>
        </div>*/}
        <div className="auth-container__agreement">
          Нажимая кнопку "Вперёд!", я соглашаюсь получать информацию от "приключений Чевостика", уведомления о заказах,
          а также принимаю <a href="/agreement">пользовательское соглашение</a> и политику конфидециальности.
        </div>
      </form>
    );
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  onSubmit(e) {
    e.preventDefault();

    if (emailSimpleRegExp.test(this.state.email)) {
      api.getUserByEmail(this.state.email)
        .then(user => {
          if (user) {
            this.props.closeAuthPopup('signup');
            this.props.openAuthPopup('login');
            redirect(`/?email=${this.state.email}`);
          }
        });
    } else {
      this.setState({
        errorMessage: this.state.email ? i18n.validationEmailError : i18n.validationRequired
      });
    }
  }
}

export default connect(null, {
  closeAuthPopup,
  openAuthPopup
})(EnterContainer);
