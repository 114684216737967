import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {config} from '../../data';
import {getImageAverageColor} from '../../helpers';

export class Garb extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageURL: '../img_static/small-loader.svg',
      loaded: false,
      img: new Image(),
      backgroundColor: '',
      current: false
    };
  }

  static propTypes = {
    addGarb: PropTypes.func,
    selectGarb: PropTypes.func
  };

  imgLoader(url) {
    this.setState({imageURL: url, loaded: true})
  }

  setBackgroundColor(imgUrl) {
    getImageAverageColor(imgUrl)
      .then(color => {
        this.setState({ backgroundColor: color });
      });
  }

  componentDidMount() {
    const {item} = this.props;
    const {img} = this.state;
    const imgUrl = `${config.base_url}${item.image}`;
    this.setBackgroundColor(imgUrl);
    img.addEventListener('load', this.imgLoader.bind(this, imgUrl));
    img.src = imgUrl;
  }

  render() {
    const {item} = this.props;

    let garbUnitStyle = 'garb-unit';
    let backgroundStyle = {};

    if (item.current_skin) {
      garbUnitStyle += ' garb-unit_type_current';
      backgroundStyle = {backgroundColor: this.state.backgroundColor};
    }

    if (item.newly) {
      garbUnitStyle += ' garb-unit_type_newly';
    }

    return (
      <div
        onClick={item.count ? () => this.props.selectGarb(item) : () => this.props.addGarb(item.id)}
        className={garbUnitStyle}
        style={backgroundStyle}>

        {
          item.count ?
            null
          : (
            <div className="garb-unit__hover">
              <div className="infant-popup__price">х {item.price}</div>
              <div className="infant-garb__button">
                <span>Купить</span>
              </div>
            </div>
          )
        }

        <img className="garb-unit__image" src={this.state.imageURL} alt=""/>
      </div>
    )
  }
}
