import React from 'react';
import PropTypes from 'prop-types';
import { Layer, Shape, Stage } from 'react-konva';

export const CabinetQuestPath = ({ path }) => {
  return (
    <Stage
      width={path.width + 4}
      height={path.height}
      className="cabinet-quest-lessons__path"
    >
      <Layer>
        <Shape
          width={path.width}
          height={path.height}
          x={2}
          sceneFunc={function (context) {
            createShape(path, 40, context, this); // eslint-disable-line no-use-before-define
          }}
        />
      </Layer>
    </Stage>
  );
};

/**
 * Рисуем кривую
 * @param path Массив точек
 * @param radius Радиус скругления углов
 * @param context Контекст Canvas
 * @param shape Shape
 */
const createShape = (path, radius, context, shape) => {
  let point = 1;

  path.coords.forEach((item, index) => {

    // point:
    // 1------------------2
    //                    |
    //                    |
    // 4------------------3
    // |
    // |
    // 1...

    // Lines
    if (index === 0) {
      context.beginPath();
      context.moveTo(item.x, item.y);
    } else if (index === path.coords.length - 1) {
      context.lineTo(item.x, item.y);
    } else if (point === 1 || point === 3) {
      context.lineTo(item.x, item.y - radius);
    } else if (point === 2) {
      context.lineTo(item.x - radius, item.y);
    } else if (point === 4) {
      context.lineTo(item.x + radius, item.y);
    }

    // Radiuses
    if (point === 1) {
      context._context.arcTo(item.x, item.y, item.x + radius, item.y, radius);
    } else if (point === 3) {
      context._context.arcTo(item.x, item.y, item.x - radius, item.y, radius);
    } else if (point === 2 || point === 4) {
      context._context.arcTo(item.x, item.y, item.x, item.y + radius, radius);
    }

    point = (point === 4) ? 1 : ++point;
  });

  context.strokeStyle = '#ff7a00';
  context.lineWidth = 2;
  context.setLineDash([20, 10]);
  context.stroke();
  context.closePath();

  context.fillStrokeShape(shape);
}

CabinetQuestPath.propTypes = {
  path: PropTypes.object.isRequired
};
