import React, {Component} from 'react';

export class NewYearSale extends Component {
  render() {
    return (
      <div className="new-year-sale">
        <h2 className="new-year-sale__title">
          Зимние<br/>
          &nbsp;&nbsp;&nbsp;&nbsp;чудеса
        </h2>
        <div className="new-year-sale__image-wrap">
          <img src='/img/promo/winter.png'
               srcSet='/img/promo/winter@2x.png 2x'
               alt=""
               className="new-year-sale__image"/>
        </div>
        <div className="new-year-sale__sale">скидка 75%</div>
        <div className="new-year-sale__description">Успей оформить подписку</div>
        <div className="new-year-sale__actions">
          <button onClick={this.props.goToLink} type="button" className="subscribe-btn new-year-sale__button">
            <span className="subscribe-btn__inner subscribe-btn__inner_color_green">Подписаться</span>
          </button>
        </div>
      </div>
    )
  }
}
