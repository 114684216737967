import React, { Component} from 'react';
import { redirect } from '../../helpers';

export class ThirdDayPromo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hour: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  timeToString = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  componentDidMount() {
    const {time} = this.props;
    const msInDay = 86400000;
    const durationShowPromoInMs = msInDay * 3;

    const nowDateInMs = Date.now();
    const untilPeriodDate = time + durationShowPromoInMs;
    const remainingTime = untilPeriodDate - nowDateInMs;

    let leftTimeInSec = remainingTime / 1000;

    const hour = Math.floor(leftTimeInSec / 3600);
    const minutes = Math.floor((leftTimeInSec - hour * 3600) / 60);
    const seconds = Math.floor(leftTimeInSec - hour * 3600 - minutes * 60);

    this.setState({
      hour,
      minutes,
      seconds,
    });
  }

  tickTick = () => {
    const {hour, minutes, seconds} = this.state;
    let newHour, newMinutes, newSeconds;
    newSeconds = parseInt(seconds,10) - 1;
    if (newSeconds <= 0) {
      newSeconds = 59;
      newMinutes = parseInt(minutes,10) - 1;
    } else {
      newMinutes = parseInt(minutes,10);
    }
    if (newMinutes <= 0) {
      newMinutes = 59;
      newHour = parseInt(hour,10) - 1;
    } else {
      newHour = parseInt(hour,10);
    }
    if(newHour < 0) {
      newHour = 0;
      newMinutes = 0;
      newSeconds = 0;

      this.setState({
        hour: this.timeToString(newHour),
        minutes: this.timeToString(newMinutes),
        seconds: this.timeToString(newSeconds),
      })
      return;
    }
    this.setState({
      hour: this.timeToString(newHour),
      minutes: this.timeToString(newMinutes),
      seconds: this.timeToString(newSeconds),
    })
  }

  goToTariff = () => {
    this.props.goToLink();
    redirect('/cabinet/select_tariff');
  }

  render() {
    const {hour, minutes, seconds} = this.state;
    if (parseInt(minutes,10) || parseInt(seconds,10) || parseInt(hour,10)) setTimeout(this.tickTick, 1000);

    return <div className="promo-third">
      <h2 className="promo-third__title">СПЕЦПРЕДЛОЖЕНИЕ!</h2>
      <span className="promo-third__text">
        ГОД БЕЗГРАНИЧНЫХ ЗНАНИЙ
        <br />
        ЗА
        <span className="promo-third__old-price">4800</span>
        <span className="promo-third__new-price">1149 Р!</span>
      </span>
      <p className="promo-third__number promo-third__number--time">{`${hour}:${minutes}:${seconds}`}</p>
      <span className="promo-third__sale">
        СПЕЦИАЛЬНАЯ СКИДКА
        <span className="promo-third__number">75%</span>
        ДЕЙСТВУЕТ 24 ЧАСА
      </span>
      <span className="promo-third__link" onClick={this.goToTariff}>
        Подписаться
      </span>
    </div>
  }
}
