import { ajax } from '../../helpers/index';

/**
 * @param {String} code
 *  * @param {String} redirect_uri
 * @returns {Promise}
 */
export const bindOk = (code, redirect_uri) => {
  return ajax
    .post('/users/add_ok', { code, redirect_uri })
    .then(response => response.data)
    .catch(error => error);
};
