import {ajaxV2} from '../../helpers';

/**
 * @typedef {Object} Child
 */

/**
 * @returns {Promise.<Child>}
 */
export const getChild = (id) => {
  return ajaxV2
    .get(`/children/${id}`)
    .then(response => {
      return response.data;
    })
    .catch(error => console.log('getChild error: ', error));
};
