import {ajaxV2} from '../../helpers';
/**
 * @typedef {Object} Spin
 */

/**
 * @param {number} childId
 * @param {number} testId
 * @returns {Promise.<Spin>}
 */
export const getSpin = (childId, testId) => {
  return ajaxV2
    .post('/lototrons/spin', {child_id: String(childId), test_id: String(testId)})
    .then(response => response.data)
    .catch(err => {
      window.devMode && console.log('getSpin error: ', err)
    })
};
