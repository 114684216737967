import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {config, i18n} from '../../data';
import {PageTooltip} from '../../components';
import {Tooltip} from '../../components';


export class LessonMapLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipPosition: 'top',
      loaded: false,
      // для лучшего UI не отображаем лоадер в /tour
      imageURL: props.fakeParams ? '' : '../img_static/small-loader.svg',
      bgImg: new Image()
    };
    this.handleOnMouseOver = this.handleOnMouseOver.bind(this);
    this.setNewImageUrl = this.setNewImageUrl.bind(this);
  }

  componentDidMount() {
    const {lesson} = this.props;
    const {bgImg} = this.state;
    const background = `${config.base_url}${lesson.map.icon}`;

    bgImg.addEventListener('load', this.setNewImageUrl)
    bgImg.src = background;
  }

  componentWillUnmount() {
    const {bgImg} = this.state;
    bgImg.removeEventListener('load', this.setNewImageUrl)
    this.setState({bgImg: null})
  }

  setNewImageUrl() {
    const {lesson} = this.props;
    const background = `${config.base_url}${lesson.map.icon}`;

    this.setState({loaded: true, imageURL: background})
  }

  handleOnMouseOver(e) {
    const tooltip = e.currentTarget.lastElementChild;

    if (tooltip.getBoundingClientRect().y < 150) {
      this.setState({
        tooltipPosition: 'bottom'
      });
    }
  }

  handleOnMouseOut(e) {
    this.setState({
      tooltipPosition: 'top'
    });
  }

  render() {
    const {
      lesson,
      quest,
      linkClass,
      fakeParams
    } = this.props;
    const {imageURL, loaded} = this.state;
    let url = fakeParams ? null : `/lessons/${lesson.id}`;
    let lessonStarClassName = null;
    let background = imageURL;
    let isLessonStar = lesson.access.is_open === false;
    let isOpenLessonFinished = lesson.quest && lesson.quest.is_open_lessons_finished;
    let tooltip = null;

    if (isLessonStar && !isOpenLessonFinished && lesson.quest) {
      url = fakeParams ? null : `/${config.routes.quests}/${lesson.quest.id}`;
      lessonStarClassName = 'lesson_type_star';
      background = null;
      tooltip = (quest === true && !fakeParams)
        ?
        <PageTooltip
          message={i18n.lesson_quest_text}
          position="left"
          autoKill={5000}
        />
        : null
    }

    const status = () => {
      // if (lesson.can_see_closed_facts) {
      //   return 'facts_is_open';
      // }
      if ((lesson.test || {}).is_completed_successfully) {
        return 'is_completed';
      }
      // if ((lesson.test || {}).is_completed) {
      //   return 'is_failed'
      // }
      return '';
    }

    return (
      <div className={'lesson-wrapper' + (loaded ? linkClass : '')} data-map-position={lesson.map.position}
           onMouseOver={(e) => this.handleOnMouseOver(e)} onMouseOut={(e) => this.handleOnMouseOut(e)}>
        <Link
          to={url}
          className={`lesson lesson_type_${status()} ${lessonStarClassName || ''} lesson_type_${lesson.access.is_free ? 'free' : 'not_free'}`}
          data-map-position={lesson.map.position}
          style={{backgroundImage: `url(${background})`}}
        >
          {lesson.name}
          {tooltip}
        </Link>

        {(lesson.analytics && lesson.analytics.lesson_finished_level >= 95 && !lesson.can_see_closed_facts) &&
        <div className={'lesson-test__question'}/>}

        {(lesson.analytics && lesson.analytics.lesson_finished_level > 0) && <ul className="lesson-stars__holder">
          <li className={'lesson-star' + ((lesson.analytics.lesson_finished_level >= 50) ? ' __filled' : '')}/>
          <li className={'lesson-star' + ((lesson.analytics.lesson_finished_level >= 75) ? ' __filled' : '')}/>
          <li className={'lesson-star' + ((lesson.analytics.lesson_finished_level >= 95) ? ' __filled' : '')}/>
        </ul>}

        {(!isLessonStar && !this.props.fakeParams) && <Tooltip
          title={lesson.name}
          position={this.state.tooltipPosition}
          topIndent={lesson.access.is_free || status().length > 0}
        />}
      </div>
    );
  }
}

LessonMapLink.propTypes = {
  lesson: PropTypes.object,
  linkClass: PropTypes.string
};
