import React from 'react';
import { Friday, withBanner } from '../../banners';

const FridayBanner = ({ type }) => {
  return (
    <Friday type={type} />
  );
};

export const FridayRoutesBanner = withBanner(FridayBanner);
