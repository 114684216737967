import { ajax } from '../../helpers';

export const postGameHistory = (id, child_id, completion_time) => {
  return ajax
    .post(`/games/${id}/save`, {
      id,
      child_id,
      completion_time,
    })
    .then(response => response)
    .catch(error => console.log('postGameProgress error: ', error))
};
