import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {counterEffect} from '../../helpers';
import {config} from '../../data';

export class InfantPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      imageURL: '../img_static/small-loader.svg',
      count: 1,
      img: new Image(),
      timer: 0
    };
  }

  counterRef = React.createRef();

  increaseCounter() {
    let newVal = Math.min(99 - this.props.item.count, this.state.count + 1);
    this.setState({count: newVal});

    counterEffect(this.counterRef.current, parseInt(this.counterRef.current.innerHTML), newVal * this.props.item.price, 13, 1000);
  }

  decreaseCounter() {
    let newVal = Math.max(1, this.state.count - 1);
    this.setState({count: newVal});
    counterEffect(this.counterRef.current, parseInt(this.counterRef.current.innerHTML), newVal * this.props.item.price, 13, 1000);
  }

  componentDidMount(prevProps) {
    const {item} = this.props;
    const {img} = this.state;
    const imgUrl = `${config.base_url}${item.image}`;

    img.addEventListener('load', () => {
      this.setState({imageURL: imgUrl});
    });

    img.src = imgUrl;

    counterEffect(this.counterRef.current, 0, this.state.count * this.props.item.price, 13, 1000);
  }

  componentDidUpdate() {

  }

  render() {
    let {
      hidePopUp,
      quantity,
      btnTitle,
      item: {alert},
      item: {id},
      item: {title},
      item: {price},
    } = this.props;

    let buyBtn = <div onClick={() => hidePopUp({id: id, price: price, count: this.state.count})}
                      className="infant-garb__button">
      <span>{btnTitle}</span></div>;

    let leftSide = <div className="infant-popup__left">
      <div className="infant-popup__img">
        <img src={this.state.imageURL} alt=""/>
      </div>
    </div>;

    let rightSide = <div className="infant-popup__right">
      <div className="infant-popup__title">{title}</div>
      <div className="infant-popup__price">х <span ref={this.counterRef}>{price}</span></div>

      {quantity ? null : buyBtn}
    </div>;

    return (
      <div className={'infant-popup' + (alert ? ' __alert' : '')}>
        <button
          onClick={() => hidePopUp(alert ? false : {id: id, count: 0, price: price})}
          type="button"
          className="popup__close"
        />

        {alert ?
          <>
            <div className="infant-popup__inner">
              <p dangerouslySetInnerHTML={{__html: `${alert}`}}/>
            </div>
            <div className="infant-popup__inner">
              <div className="infant-popup__center">
                <div onClick={() => hidePopUp({id: id, count: 0, price: price})} className="infant-garb__button">
                  <span>{btnTitle}</span></div>
              </div>
            </div>
          </>
          : <>
            <div className="infant-popup__inner">
              {leftSide}
              {rightSide}
            </div>

            {quantity ?
              <div className="infant-popup__inner">
                <div className="infant-popup__left">
                  <div className={'infant-popup__quantity'}>
                    <div onClick={() => this.decreaseCounter()} className="infant-garb__button __orange"><span>-</span>
                    </div>
                    <div className="infant-popup__count">{this.state.count}</div>
                    <div onClick={() => this.increaseCounter()} className="infant-garb__button __green"><span>+</span>
                    </div>
                  </div>
                </div>
                <div className="infant-popup__right">
                  {buyBtn}
                </div>
              </div>
              : null
            }
          </>}
      </div>
    )
  }
}

InfantPopup.propTypes = {
  focus: PropTypes.bool,
  hidePopUp: PropTypes.func,
  quantity: PropTypes.bool,
  btnTitle: PropTypes.string,
  item: PropTypes.shape({
    alert: PropTypes.string,
    status: PropTypes.string,
    preview: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    count: PropTypes.number,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    title: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    text: PropTypes.string,
    image: PropTypes.string
  })
};
