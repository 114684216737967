import React from 'react';
import PropTypes from 'prop-types';

export const AuthText = (props) => {
  const paragraphs = props.text.split(/\n/).map((line, i) => <p key={i}>{line}</p>)

  return (
    <div className="auth-text">
      {paragraphs}
    </div>
  );
};

AuthText.propTypes = {
  text: PropTypes.string
};
