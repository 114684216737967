import React from 'react';
import enhanceWithClickOutside from 'react-click-outside';
import {buildAvatarUrl, redirect} from '../../helpers';
import * as api from '../../api';
import history from '../../history';

class ScoreTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      usersRating: [],
      user: {},
      loading: true
    }
  }

  componentDidMount() {
    const {user} = this.props;

    if (user) {
      Promise.all([
        api.getChild(user.currentChildId),
        api.getRatings()
      ]).then(data => this.setState({
        user: data[0],
        usersRating: data[1],
        loading: false
      }))
      window.addEventListener('keydown', this.closePopupByPressEsc);
    } else {
      redirect();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.closePopupByPressEsc);
  }

  closePopupByPressEsc = e => {
    if (e.keyCode === 27) redirect()
  }

  handleClickOutside() {
    !this.props.noClickOutside && redirect()
  }

  getWord = (number) => {
    const cases = [2, 0, 1, 1, 1, 2]
    const txt = ['очко', 'oчка', 'очков']
    return txt[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
  }

  getAge = (birthday) => {
    if (!birthday) return ''
    const age = ((new Date().getTime() - new Date(birthday)) / (24 * 3600 * 365.25 * 1000)) | 0
    let text;
    let count = age % 100;
    if (count >= 5 && count <= 20) {
      text = 'лет';
    } else {
      count %= 10;
      if (count === 1) {
        text = 'год';
      } else if (count >= 2 && count <= 4) {
        text = 'года';
      } else {
        text = 'лет';
      }
    }
    return ` (${age} ${text})`
  }

  render() {
    const {usersRating, user, loading} = this.state;
    return (
<div>
      <div className="score-table">
        <div onClick={()=>{
          const url = new URL(document.location);
          const searchParams = url.searchParams;
          searchParams.delete('popup'); // удалить параметр "test"
          window.history.pushState({}, '', url.toString());
          history.push(window.location.pathname);
        }} className={'close-btn'}>Х</div>
        <ul className="score-table__list">
          {loading && <div className="score-tabel__loading"/>}
          {usersRating.map((e, i) => <li className="score-table__item score-table__item--table" key={i}>
            <span className="score-table__number">{i + 1}</span>
            <div className="score-tabe__avatar"
                 style={{backgroundImage: buildAvatarUrl(e)}}/>
            <span className="score-table__name">{e.name}{this.getAge(e.birthday)}</span>
            <span className="score-table__level">LV {e.level}</span>
            <div className="score-table__count">{e.points}<br/>{this.getWord(e.points)}</div>
          </li>)}
        </ul>
        <div className="score-table__footer">
          <div className="score-table__item">
            <span className="score-table__number">{user.rating_position}</span>
            <div className="score-tabe__avatar"
                 style={{backgroundImage: buildAvatarUrl(user)}}/>
            <span className="score-table__name">{user.name}{this.getAge(user.birthday)}</span>
            <span className="score-table__level">LV {user.level}</span>
            <div className="score-table__count">{user.points}<br/>{this.getWord(user.points)}</div>
          </div>
        </div>
      </div>
</div>
    )
  }
}

const _ScoreTable = enhanceWithClickOutside(ScoreTable)

export {_ScoreTable as ScoreTable}
