import {ajaxV2} from '../../helpers/index';

/**
 * @param {String} code
 *  * @param {String} redirect_uri
 * @returns {Promise}
 */

let fixture = [];

for (let i = 1; i < 10; i++) {
  fixture.push({
    name: 'Достижение ' + i,
    count: 0,
    newly: i === 9,
    img: '/img/achievements/ach_' + i + '.png'
  })
}

export const getAchievementList = () => {
  return ajaxV2
    .get('/ratings')
    .then(response => {

        window.devMode && console.log('getAchievementList');
        return fixture || response.data.map(e => ({
          avatar: e.avatar,
          name: e.name,
          level: e.level,
          points: e.points
        }))
      }
    )
    .catch(error => error);
};
