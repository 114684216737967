import React, {Component} from 'react';
import isEqual from 'lodash/isEqual';
import Popup from '../../components/popup/popup';

import * as auth from '../../helpers/authenticationHelper';
import * as api from '../../api';

import {
  CabinetHeader
} from '../../components';
import {Link} from 'react-router-dom';

export class UserSettingsContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: auth.getUserEmail(),
      page_title: 'Настройки аккаунта',
      is_password_fields_show: false,
      password: '',
      passwordConfirmation: '',
      errorMessage: '',
      profile: auth.getProfile(),
      options: {section: 'password_approve'},
      myAwesomePopupIsClose: true
    };

    this.showNewPasswordFields = this.showNewPasswordFields.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.myAwesomeHidePopUp = this.myAwesomeHidePopUp.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    // TODO: внедрить Redux
    const profile = auth.getProfile();

    if (!isEqual(prevState.profile, profile)) {
      this.setState(prevState => Object.assign({}, {profile}));
    }
  }

  render() {
    let email = <div className="child-form__group">
      {window.innerWidth > 900 && (
        <label className="child-form__label">Email</label>
      )}

      <div className="child-form__right">
      <input type="text" className="child-form__input" placeholder={this.state.email} name="name"
               disabled="disabled"/>
      </div>
    </div>

    let password = <>
      <div className={'child-form__group' + (this.state.errorMessage ? ' __error' : '')}>
        {window.innerWidth > 900 && (
        <label className="child-form__label">Новый пароль</label>
          )}
        <div className="child-form__right">
          <input type="password"
                 className="child-form__input"
                 placeholder="Введите новый пароль"
                 pattern=".{6,}"
                 required
                 title="В пароле должно быть минимум 6 символов"
                 onChange={this.onChange}
                 name="password"
                 value={this.state.password}
          />
        </div>
      </div>

      <div className={'child-form__group' + (this.state.errorMessage ? ' __error' : '')}>
        {window.innerWidth > 900 && (
        <label className="child-form__label">Подтвердите пароль</label>
          )}
        <div className="child-form__right">
          <input type="password"
                 className="child-form__input"
                 placeholder="Ещё раз"
                 pattern=".{6,}"
                 required
                 title="В пароле должно быть минимум 6 символов"
                 onChange={this.onChange}
                 name="passwordConfirmation"
                 value={this.state.passwordConfirmation}
          />
          {this.state.errorMessage ? <div className="child-form__error">{this.state.errorMessage}</div> : null}
        </div>
      </div>
    </>

    return (
      <main className="cabinet__main">
        <CabinetHeader
          page_title={this.state.page_title}
        />

        <div className="cabinet-settings-page">

          <form className="child-form"
                onSubmit={this.onSubmit}>

            <div className="wrapper wrapper_cabinet">

              <fieldset className="child-form__inner">

                {email}
                {password}

                <div className="child-form__bottom">
                  {/*<button type="reset" className="btn btn_type_secondary_white">Отменить</button>*/}
                  <button type="submit" className="infant-garb__button __edit"><span>Сохранить</span></button>
                </div>

                <div className="child-form__bottom __agreement">
                  <Link to="/eula">Пользовательское соглашение</Link>
                </div>
              </fieldset>
            </div>
          </form>

          {this.props.children}
        </div>
        <Popup
          is_closed={this.state.myAwesomePopupIsClose}
          item={this.state.options}
          location={this.props && this.props.location}
          hidePopUp={this.myAwesomeHidePopUp}
        />

      </main>
    );
  }

  onChange(e) {
    this.setState({
      errorMessage: '',
      [e.target.name]: e.target.value
    });
  }

  myAwesomeHidePopUp() {
    this.setState({myAwesomePopupIsClose: true});
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({errorMessage: ''});

    if (this.state.password !== this.state.passwordConfirmation) {
      this.setState({
        errorMessage: 'Пароли не совпадают'
      });
      return;
    }

    api.patchPassword(this.state.password, this.state.passwordConfirmation)
      .then(response => {
        if (response && (!response.errors || response.errors.length === 0)) {
          this.setState({
            myAwesomePopupIsClose: false
          });
        }
      })
  }

  showNewPasswordFields() {
    this.setState({
      is_password_fields_show: true
    })
  }
}
