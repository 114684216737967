import { ajax } from '../../helpers';
import React from 'react';
import { render } from 'react-dom';

import {
  ErrorPage
} from '../../containers';

/**
 * @typedef {Object} Lesson
 */

/**
 * @param {number} childId
 * @param {number} lessonId
 * @returns {Promise.<Lesson>}
 */
export const getLesson = (childId, lessonId) => {
  return ajax
    .get(`/lessons/${lessonId}?child_id=${childId}`)
    .then(response => response.data)
    .catch(err => {
      window.devMode && console.log('getLesson error: ', err)

      if (err.response.status === 404) {
        render(
          <ErrorPage status={404} />,
          document.getElementById('root')
        );
      }
    })
};
