import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {config} from '../../data';

export class TestPrize extends Component {

  constructor(props) {
    super(props);
    this.onAnimationStart = this.onAnimationStart.bind(this);
    this.onAnimationEnd = this.onAnimationEnd.bind(this);
  }

  onAnimationStart() {
    const {audio} = this.props;
    this.refs.prizeInner.classList.add('test-prize__inner_state_flip')
    audio.pause();
    audio.src = '/audio/prize.mp3';
    audio.play();
  }

  onAnimationEnd() {
    this.refs.prizeInner.classList.add('test-prize__inner_animation-end')
  }

  render() {
    let prizeInner = null;
    let prizeName = null;

    if (this.props.isFinished) {
      prizeInner = (
        <div
          className="test-prize__inner"
          ref="prizeInner"
          onAnimationStart={this.onAnimationStart}
          onAnimationEnd={this.onAnimationEnd}
        >
          <div className="test-prize__front"></div>

          <div className="test-prize__back" ref="prizeBack">
            <div
              className="test-prize__back-img"
              style={{backgroundImage: `url(${config.base_url}${this.props.prizeUrl})`}}
              onTransitionEnd={this.onTransitionEnd}>
            </div>
          </div>
        </div>
      );
      prizeName = <p className="test-prize__name">{this.props.prizeName}</p>
    }

    return (
      <>
        <div className="test-finish-screen__title">Награда:</div>

        <div className="test-prize">
          <ul className="test-prize__lights">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
          {prizeInner}
          {prizeName}
        </div>
      </>
    )
  }
}

TestPrize.propTypes = {
  isFinished: PropTypes.bool,
  prizeUrl: PropTypes.string,
  prizeName: PropTypes.string
};
