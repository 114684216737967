import React, {Component} from 'react';
import {redirect} from '../../helpers';

export class SpringSale extends Component {
  goToTariff = () => {
    this.props.goToLink();
    redirect('/cabinet/select_tariff');
  }

  render() {
    return (
      <div className="spring-sale">
        <div className="spring-sale__title">
          Весна<br/>
          знаний
        </div>
        <div className="spring-sale__share">Скидка 75%</div>
        <div className="spring-sale__text">
          Оформи только сегодня<br/>
          годовую подписку
        </div>
        <img className="spring-sale__image"
             src="/img/promo/science-popup.png"
             srcSet="/img/promo/science-popup@2x.png 2x"
             alt=""
        />
        <button type="button" className="spring-sale__button" onClick={this.goToTariff}>Подписаться</button>
      </div>
    )
  }
}
