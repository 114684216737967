import React, { Component } from 'react';
import { closest, isTouch } from '../../helpers';

export class DialogVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isVideoShown: false
    };
    this.showVideo = this.showVideo.bind
}

  render() {
    let videoWidth = '896';
    let videoHeight = '504';

    if (isTouch()) {
      videoWidth = '100%';
      videoHeight= '100%';
    }

    let video = <div className="dialog-video">
      <iframe width={videoWidth} height={videoHeight} src="https://www.youtube.com/embed/-KTGTJCrzQ8?autoplay=1" frameBorder="0" allowFullScreen></iframe>
    </div>;
    return video
  }

    showVideo(event) {
    closest(event.currentTarget, '.popup').classList.add('dialog-video');
    closest(event.currentTarget, '.popup__dialog').classList.add('dialog-video');

    this.setState({
      isVideoShown: true
    })
  }

}
