import React, {Component} from 'react'
import PropTypes from 'prop-types';
import {config} from '../../data';
import {addShortGameName} from '../../helpers';
import {gamesTypes} from '../../api'
import {NavLink} from 'react-router-dom';

export class GamesFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      games: []
    };
  }

  componentDidMount() {
    gamesTypes().then((games) => {
      this.setState({games})
    })
  }

  render() {
    const {games} = this.state;
    let search = window.location.search.substr(1);
    let gameType = 'all';

    if (search) {
      const query = new URLSearchParams(search);
      gameType = query.get('type') || 'all';
    }

    return (
      <nav className="games-filter">
        {games.map((item, index) => (
          <NavLink key={item.type}
                   data-type={`${item.type}`}
                   className={`js-game-types games-filter__label games-filter__label_type_${item.type}${gameType.indexOf(item.type) > -1 ? ' is-active' : ''}`}
                   to={`/games?type=${item.type}`}>
            <img alt={'icon'} className="games-filter__icon" src={`${config.base_url}${item.icon}`} width='50px'
                 height='50px' role='presentation'/>
            <span>
              {addShortGameName(item.type)}
            </span>
          </NavLink>
        ))}
      </nav>
    )
  }
}

GamesFilter.propTypes = {
  gamesList: PropTypes.array
};
