import React, {Component} from 'react';
import {removeOverflowAuto} from '../../helpers'
import * as api from '../../api';
import {Tariffs} from '../../components';
import c from 'classnames'
import {setPreloader} from '../../redux/actions';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';

class SelectTariffContainer extends Component {
  state = {
    tariffs: [],
    loading: true
  }

  componentDidMount() {
    api.getTariffs()
      .then(tariffs => {
        this.setState({
          tariffs
        }, () => {
          removeOverflowAuto();
          this.setState({
            loading: false
          }, () => {
            this.props.setPreloader('');
          })
        })
      });
  }

  render() {
    const {tariffs} = this.state

    return (
      <div className={c('select-tariff')}>
        {/*<ChildContainer /> */}
        {tariffs.length > 0 && <Tariffs tariffs={this.state.tariffs}/>}
      </div>
    );
  }
}

const mapDispatchToProps = {
  setPreloader
};

const componentWithRouter = withRouter(connect(null, mapDispatchToProps)(SelectTariffContainer))

export {componentWithRouter as SelectTariffContainer};
