import {ajaxV2} from '../../helpers';
import * as auth from '../../helpers/authenticationHelper';

/**
 * @typedef {Object} EditedChild
 */

/**
 * @param {Object} child
 * @returns {Promise.<EditedChild>}
 */
export const putChild = (child) => {
  return ajaxV2
    .put(`/children/${auth.getCurrentChild()}`, child)
    .then(response => response.data)
    .catch(error => this.putChild(error))
};
