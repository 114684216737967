import React, {Component} from 'react';
import {connect} from 'react-redux';
import {openAuthPopup} from '../../redux/actions';
import {counterEffect} from '../../helpers';
import {
  Hero
} from '../../components';

class PopupWelcome extends Component {

  constructor(props) {
    super(props);

    this.state = {
      userCounter: 200000,
      childCounter: 3000000
    };
  }

  userCounterRef = React.createRef();
  userCounterBlock = React.createRef();
  childCounterRef = React.createRef();
  childCounterBlock = React.createRef();
  controlsBlock = React.createRef();

  animateCSS(node, animation, prefix = 'animate__') {
    return new Promise((resolve, reject) => {
      const animationName = `${prefix}${animation}`;

      function handleAnimationEnd(event) {
        event.stopPropagation();
        node.classList.remove(animationName);
        resolve('Animation ended');
      }

      node.addEventListener('animationend', handleAnimationEnd, {once: true});

      node.classList.add(`${prefix}animated`, animationName);
    });
  }

  componentDidMount() {
    setTimeout(() => {
      if (this.userCounterRef.current) {
        counterEffect(this.userCounterRef.current, parseInt(this.userCounterRef.current.innerHTML), this.state.userCounter, 237, 1500);

        this.animateCSS(this.userCounterBlock.current, 'bounceIn').then((message) => {
          setTimeout(() => {
            if (this.childCounterRef.current) {
              counterEffect(this.childCounterRef.current, parseInt(this.childCounterRef.current.innerHTML), this.state.childCounter, 237, 1500);

              this.animateCSS(this.childCounterBlock.current, 'bounceIn').then((message) => {

                //this.animateCSS(this.controlsBlock.current, 'bounceIn');
              });
            }
          }, 500);
        });
      }
    }, 1500);
  }

  componentWillUnmount() {

  }

  showAuthPopups = (pId) => {
    this.props.openAuthPopup(pId);
    this.props.togglePopup();
  }

  render() {
    return (
      <div className="popup-welcome">
        <div className="popup-welcome__block">
          <div className="popup-welcome__effect" ref={this.userCounterBlock}>
            <div className="popup-welcome__counter" ref={this.userCounterRef}>0</div>
            <div className="popup-welcome__text">зарегистрированных пользователей</div>
          </div>

          <div className="popup-welcome__effect" ref={this.childCounterBlock}>
            <div className="popup-welcome__counter" ref={this.childCounterRef}>0</div>
            <div className="popup-welcome__text">детей знают нашего героя</div>
          </div>

          <div className="popup-welcome__effect animate__animated" ref={this.controlsBlock}>
            <div className="popup-welcome__controls">
              {/* <div onClick={() => redirect('/auth/signup')} className="popup-welcome__button __green"
              ><span>Регистрация</span></div> */}
              <div onClick={() => this.showAuthPopups('signup')} className="popup-welcome__button __green"
              ><span>Регистрация</span></div>

              {/* <div onClick={() => redirect('/auth/login')} className="popup-welcome__button __orange"
              ><span>Войти</span></div> */}
              <div onClick={() => this.showAuthPopups('login')} className="popup-welcome__button __orange"
              ><span>Войти</span></div>
            </div>
          </div>
        </div>

        <Hero width={330} height={360} animate={true} cssClass={'chevostik_hero'} name={'chevostik_hi'}
              text={'Привет, я Чевостик, <br /> герой образовательно-<br />развлекательного проекта <br /> «‎Приключения Чевостика»!'}/>

        <Hero width={610} height={680} animate={true} cssClass={'ukuzya_hero'} name={'ukuzya_hi'}
              text={'С 2001 года Чевостик <br /> обучает ваших детей!'}/>

      </div>
    )
  }
}


export default connect(
  null,
  {openAuthPopup}
)(PopupWelcome);
