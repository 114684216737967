/*eslint-disable */
/**
 * Возвращает короткое название игры
 *
 * @param string
 * @returns string
 */
export const addShortGameName = (string) => {
  let name = '';

  switch (string) {
    case 'all':
      name = 'Все\nигры'
      break;
    case 'collect-puzzle':
      name = 'Собери \nпазлы'
      break;
    case 'pick-up-silhouette':
      name = 'Подбери\nсилуэт'
      break;
    case 'find-hidden-hero':
      name = 'Найди \nЧевостика'
      break;
    case 'find-hidden-item':
      name = 'Найди \nпредмет'
      break;
    case 'collect-item':
      name = 'Собери\nпредмет'
      break;
    case 'memory-game':
      name = 'Найди\nпару'
      break;
    case 'remove-excess':
    case 'logical-chain':
      name = 'Убери\nлишнее'
      break;
    default:
      break;
  }

  return name;
};
/*eslint-enable */
