import { ajaxV2 } from '../../helpers';

/**
 * @typedef {Array.<Object>} Library
 */

/**
 * @returns {Promise.<Library>}
 */
export const getLibrary = (childId) => {
  // http://localhost:3000/api/v2/contents?child_id=136
  return ajaxV2.get(`/contents?child_id=${childId}`)
    .then(response => {
      return response.data
    })
    .catch(error => console.log('getLibrary error: ', error));
};
