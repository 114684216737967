import React from 'react';
import { config } from '../../data'

export const Menu = (props) => {
  return (
    <nav className="menu">
      <span style={{cursor:'pointer',marginRight:'10px', fontSize:'16px', color:'#f77600'}} onClick={props.feebackToggle}>Контакты</span>
      <a href={config.routes.shop}>Магазин</a>
    </nav>
  );
};
