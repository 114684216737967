import React from 'react';
import {render} from 'react-dom';

import {Provider} from 'react-redux';
import store from './redux/store';

import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';

import {
  Router
} from 'react-router-dom';

import history from './history';

import './resources/styles/index.css';

import RoutesApp from './routes.js';

history.listen(function (loc) {
  if (window.location.host.indexOf('localhost') === -1) {
    if (window.ga) {
      window.ga('send', 'pageview', loc.pathname || '/');
    }

    if (window.gtag) {
      window.gtag({event: 'pageview', location: loc.pathname || '/'});
    }
  } else {
    window.devMode = true;
  }
});

Sentry.init({
  dsn: 'https://743d96a19edb47478309170990b60758@o977170.ingest.sentry.io/5933657',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
});

function detectBrowser() {
  var ie, userAgent = navigator.userAgent.toLowerCase(),
    html = document.documentElement;

  if ((userAgent.indexOf('msie') !== -1)) {
    ie = ((userAgent.indexOf('msie') !== -1) ? parseInt(userAgent.split('msie')[1]) : false);
    html.className += ' ie';
    html.className += ' ie' + ie;
  } else if (!!userAgent.match(/trident.*rv\\:11\./)) {
    ie = 11;
    html.className += ' ie' + ie;
  }

  if (userAgent.indexOf('safari') !== -1) {
    if (userAgent.indexOf('chrome') === -1) {
      html.className += ' safari';
    } else {
      html.className += ' chrome';
    }
  }

  if (userAgent.indexOf('firefox') !== -1) {
    html.className += ' firefox';
  }

  var platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = '';

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'macos';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'ios';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'windows';
  } else if (/android/.test(userAgent)) {
    os = 'android';
  } else if (!os && /linux/.test(platform)) {
    os = 'linux';
  }

  html.className += os ? ' ' + os : '';
}

const appHeight = () => {
  const html = document.documentElement;
  const sab = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sab')) || 0;
  const sat = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--sat')) || 0;

  html.style.setProperty('--app-height', `${window['innerHeight'] - sab - sat}px`);
};


// Hot Module Reloading (HMR) is a tool to reload your application in the browser.
// The browser doesn’t perform a page refresh.
if (module.hot) {
  module.hot.accept()
}

const load = () => {
  window.addEventListener('resize', appHeight);

  appHeight();

  detectBrowser();

  render(
    <Provider store={store}>
      <Router history={history}>
        <RoutesApp/>
      </Router>
    </Provider>,
    document.getElementById('root')
  );
}

if ((new URLSearchParams(window.location.search)).get('eruda') === '1') {
  window.devMode = true;
  let el = document.createElement('div');
  el.id = 'eruda-container';
  document.body.appendChild(el);
  let s = document.createElement('script');
  s.type = 'text/javascript';
  s.src = '//cdn.jsdelivr.net/npm/eruda';
  s.addEventListener('load', function () {
    window.eruda && window.eruda.init({
      container: el,
      tool: ['console', 'elements', 'snippets']
    });
    load();
  });

  document.head.appendChild(s);
} else {
  load();
}
