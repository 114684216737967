import React from 'react';
import PropTypes from 'prop-types';
import {config} from '../../data';

import {
  Icon
} from '../../components';

export const CabinetGames = (props) => {

  const {
    child,
    stats
  } = props;

  let text = null;
  let icons = null;

  if (child === null) {
    text = <p className="cabinet-games__text">
        Игры открываются после завершения урока и теста
        <span className="cabinet-games__arrow">
          <Icon name='#cabinet--arrow-3' width='44px' height='78px'/>
        </span>
      </p>;

    icons = <div>
        <span className="cabinet-games__first">
           <Icon name='#cabinet--collect-puzzle' width='52px' height='52px'/>
         </span>

        <span className="cabinet-games__second">
           <Icon name='#cabinet--remove-excess' width='52px' height='52px'/>
         </span>

        <span className="cabinet-games__third">
           <Icon name='#cabinet--pick-up-silhouette' width='52px' height='52px'/>
         </span>
      </div>

  } else if (child !== null && stats.games.length > 0) {


    if (stats.games.length > 0 && stats.games.length < 2) {
      icons = <div>
        <span className="cabinet-games__first">
          <img alt={'icon'} src={`${config.base_url}${stats.games[0].icon}`} width='52px' height='52px' role='presentation'/>
          <span className="cabinet-games__name">{stats.games[0].name}</span>
        </span>
      </div>
    }

    if (stats.games.length > 1 && stats.games.length < 3) {
      icons = <div>
        <span className="cabinet-games__first">
          <img alt={'icon'} src={`${config.base_url}${stats.games[0].icon}`} width='52px' height='52px' role='presentation'/>
          <span className="cabinet-games__name">{stats.games[0].name}</span>
        </span>

        <span className="cabinet-games__second">
          <img alt={'icon'} src={`${config.base_url}${stats.games[1].icon}`} width='52px' height='52px' role='presentation'/>
          <span className="cabinet-games__name">{stats.games[1].name}</span>
        </span>
      </div>
    }

    if (stats.games.length > 2 && stats.games.length < 4) {
      icons = <div>
        <span className="cabinet-games__first">
          <img alt={'icon'} src={`${config.base_url}${stats.games[0].icon}`} width='52px' height='52px' role='presentation'/>
          <span className="cabinet-games__name">{stats.games[0].name}</span>
        </span>

        <span className="cabinet-games__second">
          <img alt={'icon'} src={`${config.base_url}${stats.games[1].icon}`} width='52px' height='52px' role='presentation'/>
          <span className="cabinet-games__name">{stats.games[1].name}</span>
        </span>

        <span className="cabinet-games__third">
          <img alt={'icon'} src={`${config.base_url}${stats.games[2].icon}`} width='52px' height='52px' role='presentation'/>
          <span className="cabinet-games__name">{stats.games[2].name}</span>
        </span>
      </div>
    }

  } else {
    text = <p className="cabinet-games__text cabinet-games__text_type_no_games">Ещё нет любимых игр :)</p>
  }

  return (
    <div className="cabinet-games">
      <h2 className="cabinet-games__title">Любимые игры</h2>
      {text}

       <div className={`cabinet-games__base cabinet-games__base_type_${child !== null && stats.games.length > 0 ? 'stats_yes' : 'stats_no'}`}>

         {icons}

         <span className="cabinet-games__base-icon">
           <Icon name='#cabinet--base' width='316px' height='92px'/>
         </span>

       </div>
    </div>
  );
};

CabinetGames.propTypes = {
  child: PropTypes.object,
  stats: PropTypes.object
};
