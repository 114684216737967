import React, { Component } from "react";
import { Link } from "react-router-dom";
import { config } from "../../data";
import {
  checkImagesLoad,
  isPhone,
  redirect,
  scaleLessons,
} from "../../helpers";
import * as api from "../../api";
import {
  AudioFactPlayer,
  LessonAudio,
  LessonLoader,
  LessonPostLoader,
  LessonProgress,
  LessonProgressAnimation,
  Person,
  PersonsFacts,
  PopupFact,
} from "../../components";
import * as auth from "../../helpers/authenticationHelper";
import MapNav from "../../components/map-nav/map-nav";
import { connect } from "react-redux";
import { setPreloader, setDataLesson, setUserId } from "../../redux/actions";
import { withRouter } from "react-router";
import Button from "../../components/button/button";
import store from "../../redux/store";

let audioSafari = new Audio();

class LessonContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: null,
      child: null,
      forceUpdate: 0,
      prev_balance: 0,
      updateBalance: 0,
      finish_percent: 0,
      show_blur: true,
      audio_start_play: false,
      audio_loader_visible: false,
      factPosition: "",
      factPositionY: "",
      image_loaded: false,
      image_loading: false,
      is_audio_playing: true,
      is_audio_finished: false,
      loader_visible: true,
      pre_loader_type: "lesson",
      duration: null,
      selectedPerson: "",
      is_person_sounds: false,
      is_person_audio_playing: null,
      transform: {
        scaleX: 1,
        scaleY: 1,
        translate: {
          x: config.default_center_axis_value,
          y: config.default_center_axis_value,
        },
      },
      animatePerson: "",
      show_message_at_mobile: true,
      currentAudio: null,
      currentAudioDomId: null,
      triggerShowAudioProgree: 0,
      personsData: [],
      lesson: {},
      goPlayAfterFinish: false,
      currentPersonDomId: null,
      currentPopupDomId: null,
      popups: null,
      popupShow: false,
    };

    this.onLoaderClick = this.onLoaderClick.bind(this);
    this.showPostLoader = this.showPostLoader.bind(this);
    this.hidePostLoader = this.hidePostLoader.bind(this);
    this.startAudioPlay = this.startAudioPlay.bind(this);
    this.toggleAudioPlayingState = this.toggleAudioPlayingState.bind(this);
    this.checkCursorPosition = this.checkCursorPosition.bind(this);
    this.scaleLesson = this.scaleLesson.bind(this);
    this.setPersonAudioIsPlaying = this.setPersonAudioIsPlaying.bind(this);
    this.clickMap = this.clickMap.bind(this);
    this.setPersonStatus = this.setPersonStatus.bind(this);
  }

  componentDidMount() {
    this.props.setPreloader("default");
    let browser;
    const profile = auth.getProfile();

    function browserDetection() {
      //Check if browser is Chrome
      if (navigator.userAgent.search("Chrome") >= 0) {
        browser = "Chrome";
      }
      //Check if browser is Safari
      else if (
        navigator.userAgent.search("Safari") >= 0 &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        browser = "Safari";
      }
    }

    window.updateBalance = (bal, poi, per) => {
      let { child, forceUpdate } = this.state;

      child.balance = bal;
      child.points = poi;

      this.setState({
        forceUpdate: forceUpdate + 1,
        child,
        finish_percent: per,
      });
    };

    browserDetection();
    const lessonId = this.props.match.params.id;
    const currentChildId = auth.getCurrentChild();

    if (currentChildId) {
      let child = profile.children.find((c) => c.id === profile.currentChildId);
      this.setState({ child, prev_balance: child.balance });
    }

    this.setState({ profile });

    if (currentChildId && lessonId) {
      // let data= []
      // data.push(currentChildId)
      // data.push(lessonId)
      store.dispatch(setUserId(currentChildId));

      Promise.all([
        api.getLesson(currentChildId, lessonId),
        api.getPersonStatistics(currentChildId, lessonId),
      ]).then((result) => {
        store.dispatch(setDataLesson(result));
        if (browser === "Safari") {
          audioSafari.src = result[0].audio;
          audioSafari.id = "audioSafari";
          document.body.appendChild(audioSafari);
        }
        let user = auth.getProfile();
        let userSubscription =
          user && user.subscription && user.subscription.status !== "off";

        if (!result[0]?.access?.is_free) {
          if (!user || !userSubscription) {
            localStorage.setItem("lesson", JSON.stringify(result[0]));
            redirect();
          }
        }

        const popups = this.getPopups(result[0].scene);

        return this.setState({
          lesson: result[0],
          personsData: result[1],
          popups,
        });
      });
    } else {
      redirect();
    }

    window.addEventListener("resize", this.scaleLesson);
    this.scaleLesson();
  }

  componentWillUnmount() {
    if (this.state.currentAudio) this.state.currentAudio.pause();
    window.removeEventListener("resize", this.scaleLesson);
  }

  clickMap() {
    const {
      location: { search },
    } = this.props;
    const id = search.includes("back") ? +search.split("=")[1] : false;
    if (isPhone() && id) return window.location.assign(`/quest/${id}`);
    if (isPhone()) return window.location.assign("/");
    return redirect();
  }

  render() {
    const {
      personsData,
      lesson,
      currentAudio,
      is_person_audio_playing,
      duration,
      finish_percent,
      profile,
      forceUpdate,
      currentPersonDomId,
      currentPopupDomId,
      popups,
      popupShow,
    } = this.state;
    let { lessonsScreenType } = this.props;

    const finishedPersond = personsData.filter((e) => e.status === 2).length;

    const percent =
      finish_percent ||
      Math.round((finishedPersond / personsData.length) * 100);

    // показывается лоадер

    let nav = null;
    let persons = null;

    const {
      location: { search },
    } = this.props;
    const id = search.includes("back") ? +search.split("=")[1] : false;
    let content = lesson.scene ? (
      <div>
        <LessonLoader
          lesson={lesson}
          is_loading={this.state.image_loading}
          onClick={this.onLoaderClick}
          onHome={this.clickMap}
          onQuestion={`/lessons/${lesson.id}/tests/${lesson.test.id}${
            id ? `?back=${id}` : ""
          }`}
        />
      </div>
    ) : null;
    // показываю меню с навигацией
    if (this.hasLessonPersons(lesson)) {
      nav = (
        <div className="lesson-menu">
          {
            percent < config.configStartShow.secondStarPercentShow &&
            lesson.is_special ? (
              <span className="lesson-menu__item lesson-menu__item_test--disabled" />
            ) : (
              <Link
                to={`/lessons/${lesson.id}/tests/${lesson.test.id}${
                  id ? `?back=${id}` : ""
                }`}
                disabled={
                  percent < config.configStartShow.secondStarPercentShow
                }
                className={`lesson-menu__item lesson-menu__item_test lesson-menu__item_state_${
                  this.state.audio_loader_visible ? "hide" : "show"
                }`}
              />
            )
            // ВОТ ЭТООО КНОООПКА!!!!
          }
          {!!lesson.is_special && (
            <LessonProgress percent={percent} count={finishedPersond} />
          )}

          {!!lesson.is_special && !this.state.loader_visible && (
            <LessonProgressAnimation
              percent={percent}
              showBlur={this.state.show_blur}
              lessonUrl={lesson.id}
              testUrl={lesson.test.id}
              isPersonAudioPlaying={this.state.is_person_audio_playing}
              setContinue={() => this.setState({ goPlayAfterFinish: true })}
            />
          )}

          {lesson.is_special ? (
            <Button
              mod="lesson-menu__home"
              onClick={this.clickMap}
              color="orange"
              size="xl"
            >
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M30.0008 17.4026L20.4358 10.5359C20.1611 10.3547 19.8315 10.3547 19.5751 10.5359L10.0101 17.4026C9.27757 17.8859 9.58889 19.1142 10.4496 19.1142C10.9074 19.1142 11.292 19.517 11.292 20.0405V27.8737C11.292 28.3772 11.6582 28.8 12.1344 28.8H16.2479C16.6691 28.8 17.0171 28.4174 17.0171 27.9543V23.3832C17.0171 22.4569 17.6946 21.7119 18.537 21.7119H21.5105C22.3529 21.7119 23.0304 22.4569 23.0304 23.3832V27.914C23.0304 28.3772 23.3784 28.7597 23.7996 28.7597H27.9131C28.3709 28.7597 28.7555 28.357 28.7555 27.8335V20.0204C28.7555 19.517 29.1218 19.0941 29.5979 19.0941C30.4037 19.0941 30.715 17.8657 30.0008 17.4026Z"
                  fill="white"
                />
              </svg>
            </Button>
          ) : (
            <Button
              mod="lesson-menu__home"
              onClick={this.clickMap}
              color="orange"
              size="xl"
            >
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M30.0008 17.4026L20.4358 10.5359C20.1611 10.3547 19.8315 10.3547 19.5751 10.5359L10.0101 17.4026C9.27757 17.8859 9.58889 19.1142 10.4496 19.1142C10.9074 19.1142 11.292 19.517 11.292 20.0405V27.8737C11.292 28.3772 11.6582 28.8 12.1344 28.8H16.2479C16.6691 28.8 17.0171 28.4174 17.0171 27.9543V23.3832C17.0171 22.4569 17.6946 21.7119 18.537 21.7119H21.5105C22.3529 21.7119 23.0304 22.4569 23.0304 23.3832V27.914C23.0304 28.3772 23.3784 28.7597 23.7996 28.7597H27.9131C28.3709 28.7597 28.7555 28.357 28.7555 27.8335V20.0204C28.7555 19.517 29.1218 19.0941 29.5979 19.0941C30.4037 19.0941 30.715 17.8657 30.0008 17.4026Z"
                  fill="white"
                />
              </svg>
            </Button>
          )}
        </div>
      );
    }

    // показывается урок
    if (!this.state.loader_visible && this.hasLessonPersons(lesson)) {
      let scene = lesson.scene;
      let cssTransform = this.state.transform;
      let cssTransformStyle = {
        transform: `
        scaleX(${cssTransform.scaleX})
        scaleY(${cssTransform.scaleY})
        translate(${cssTransform.translate.x}%, ${cssTransform.translate.y}%)
      `,
      };
      let backgroundImage = scene.background_mobile_image;
      // только для планшетов и десктопов показываем персонажей и факты
      // на телефонах – не показываем
      let factsArray = [];
      let lessonsElement;
      let lessonNumber = [];

      if (1 || !isPhone()) {
        scene.persons.map((person) => {
          if (person.fact) {
            lessonNumber.push(person);
          }
        });
        lessonsElement = lessonNumber
          .filter((item) => item.dom_id.match(/^number-(\d+)$/))
          .map((item, index) => {
            const personData = personsData.find(
              (person) => person.dom_id === item.dom_id
            );
            return (
              <div
                onClick={() => this.handlerPersonClick(item)}
                className={
                  personData?.status === 2 ? "element__isopened" : "element"
                }
              >
                {index + 1}
              </div>
            );
          });
        persons = scene.persons.map((person, index) => {
          const personData =
            personsData.find((item) => item.dom_id === person.dom_id) || {}; // TODO: Так делать нельзя, нужно вынести из цикла
          return (
            <Person
              number={index}
              key={person.dom_id}
              person={person}
              status={lesson.is_special ? personData.status : 3}
              cssSprite={lesson.scene.css_sprite}
              animation={currentPersonDomId === person.dom_id}
              onClick={this.handlerPersonClick}
            />
          );
        });

        scene.persons.forEach((item) => {
          if (
            ((item.fact && item.fact.options && item.fact.options.is_open) ||
              lesson.can_see_closed_facts) &&
            item.fact &&
            item.fact.content &&
            item.fact.content.description
          ) {
            factsArray.push(item);
          }
        });

        backgroundImage = scene.background_image;
      }
      //!!moble content+banner
      content = (
        <div className="lesson-page">
          <style>{scene.css}</style>

          {is_person_audio_playing && (
            <AudioFactPlayer audio={currentAudio} duration={duration} />
          )}
          {this.state.popupShow === false && (
            <div className={"lesson__name"}>{lesson.name}</div>
          )}
          <PersonsFacts
            facts={factsArray}
            factPositionX={this.state.factPosition}
            factPositionY={this.state.factPositionY}
            selectedPerson={this.state.selectedPerson}
            stopShowFact={() =>
              this.setState({
                selectedPerson: "",
                animatePerson: "",
              })
            }
          />

          <div className="lesson-page__inner" style={cssTransformStyle}>
            <div
              className="lesson-page__layout"
              style={{
                backgroundImage: `url(${`${config.base_url}${backgroundImage}`})`,
              }}
            />
            {persons}
          </div>
          <PopupFact
            popups={popups}
            currentPopupDomId={currentPopupDomId}
            show={popupShow}
            onClose={this.handlerPopupClose}
            onFactPlay={this.factPlay}
          />
          <div className={"lesson__elements"}>{lessonsElement}</div>
          <LessonAudio
            audio={lesson.audio}
            audio_progress={lesson.progress}
            is_audio_playing={this.state.is_audio_playing}
            showPostLoader={this.showPostLoader}
            toggleAudioPlayingState={this.toggleAudioPlayingState}
            audio_start_play={this.state.audio_start_play}
            is_audio_finished={this.state.is_audio_finished}
            lesson_url={lesson.id}
            show_message_at_mobile={this.state.show_message_at_mobile}
            is_person_audio_playing={this.state.is_person_audio_playing}
            setPersonAudioIsPlaying={this.setPersonAudioIsPlaying}
            triggerShowAudioProgree={this.state.triggerShowAudioProgree}
            startPlay={!lesson.is_special}
          />

          {!lesson.is_special && this.state.audio_loader_visible ? (
            <LessonPostLoader
              lessonUrl={lesson.id}
              testUrl={lesson.test.id}
              gameUrl={lesson.game.id}
              startAgain={this.startAudioPlay}
              isLessonAudioFinished={this.state.is_audio_finished}
              postLoaderType={this.state.pre_loader_type}
            />
          ) : null}
        </div>
      );
    }

    let opacity = lessonsScreenType === "lesson";
    let searchParam = new URLSearchParams(window.location.search.slice(1));
    let allPopups = searchParam.has("popup")
      ? searchParam.get("popup").split(",")
      : [];

    if (allPopups.length) {
      opacity = false;
    }

    return (
      <div
        className={
          "lessons-holder" + (lesson.is_special ? " special-buttons" : "")
        }
      >
        <MapNav
          opacity={opacity}
          user={profile}
          forceUpdate={forceUpdate}
          lessonsScreenType={lessonsScreenType}
        >
          {nav}
        </MapNav>
        {/* here shoud be logic */}
        {content}
      </div>
    );
  }

  getPopupNumberId = (id) => {
    const result = id.match(/^popup_number-(\d+)$/);

    if (result) {
      return result[1];
    }

    return false;
  };

  getPersonNumberId = (id) => {
    const result = id.match(/^number-(\d+)$/);

    if (result) {
      return result[1];
    }

    return false;
  };

  getPopupCss = (id, css) => {
    const result = css.match(
      new RegExp(
        `\\.person#number-${id} ~ \\.person#popup_number-${id}\\{([^}]+)}`
      )
    );

    const removeRules = [
      "position",
      "visibility",
      "opacity",
      "top",
      "left",
      "pointer-events",
      "z-index",
      "transition",
      "-webkit-transition",
    ];

    if (result) {
      return result[1].replace(
        new RegExp(`((${removeRules.join("|")})[^;]+);?`, "g"),
        ""
      );
    }

    return false;
  };

  getPopups(scene) {
    const { persons, css: sceneCss } = scene;

    const popups = persons
      .filter((person) => this.getPopupNumberId(person.dom_id))
      .sort(
        (a, b) =>
          this.getPopupNumberId(a.dom_id) > this.getPopupNumberId(b.dom_id) ||
          false
      );

    return popups.map((popup) => {
      const number = this.getPopupNumberId(popup.dom_id);
      const linkedPerson = persons.find(
        (person) => this.getPersonNumberId(person.dom_id) === number
      );
      const css = this.getPopupCss(number, sceneCss);

      if (linkedPerson?.fact) {
        return {
          ...popup,
          personDomId: linkedPerson.dom_id,
          fact: linkedPerson.fact,
          css,
        };
      }

      return popup;
    });
  }

  handlerPersonClick = (person) => {
    this.setState({
      currentPersonDomId: person.dom_id,
    });

    const number = this.getPersonNumberId(person.dom_id);

    if (number) {
      const linkedPopup = this.state.lesson.scene.persons.find(
        (fact) => fact.dom_id === `popup_number-${number}`
      );

      if (linkedPopup) {
        this.setState({ currentPopupDomId: linkedPopup.dom_id }, () => {
          this.setState({ popupShow: true });
        });
      }
    }

    this.factPlay(person.fact, person.dom_id);
  };

  handlerPopupClose = () => {
    this.stopAllAudio();

    this.setState({
      currentAudio: null,
      currentAudioDomId: null,
      currentPersonDomId: null,
      currentPopupDomId: null,
      popupShow: false,
    });
  };

  factPlay = (fact, personDomId) => {
    this.setState({
      currentAudio: null,
      currentAudioDomId: null,
    });

    if (fact?.content.audio && !fact?.content.fact_videos.length) {
      this.playSong(
        fact.content.audio,
        this.state.lesson.can_see_closed_facts,
        personDomId
      );
    }

    if (fact?.content.fact_videos.length) {
      this.stopAllAudio();
    }

    this.updatePersonStatusShowed(personDomId);
  };

  /**
   * @property {Object} preview_image
   * @property {Object} scene
   * @property {Array} scene.persons
   * */
  onLoaderClick() {
    audioSafari.play();
    this.setState(
      {
        image_loading: true,
      },
      () => {
        let images;

        if (isPhone()) {
          images = [
            `${window.location.origin}${this.state.lesson.scene.background_mobile_image}`,
          ];
        } else {
          images = [
            `${config.base_url}${this.state.lesson.scene.background_image}`,
            `${config.base_url}${
              this.state.lesson.scene.css_sprite
                ? this.state.lesson.scene.css_sprite
                : this.state.lesson.scene.background_image
            }`,
          ];
        }

        checkImagesLoad(images).then(() =>
          this.setState({
            loader_visible: false,
            image_loading: false,
            image_loaded: true,
            is_audio_playing: true,
          })
        );
      }
    );
  }

  /**
   * Переключает состояние аудио (играет/не играет)
   */
  toggleAudioPlayingState() {
    this.setState({
      is_audio_playing: !this.state.is_audio_playing,
      is_person_audio_playing: false,
      show_message_at_mobile: false,
    });
    if (this.state.currentAudio) {
      this.state.currentAudio.pause();
    }
  }

  /**
   * Начинает проигрывать аудио сначала и прячет постлоадер
   */
  startAudioPlay() {
    this.setState(
      {
        audio_start_play: true,
        is_audio_playing: true,
        is_audio_finished: false,
      },
      () => {
        this.hidePostLoader();
      }
    );
  }

  /**
   * Показывает пост лоадер, когда аудио закончилось
   */
  showPostLoader() {
    this.setState({
      audio_loader_visible: true,
      is_audio_playing: false,
      is_audio_finished: true,
    });
  }

  /**
   * Скрывает пост лоадер, аудио начинает играть
   */
  hidePostLoader() {
    this.setState({
      audio_loader_visible: false,
      audio_start_play: false,
    });
  }

  /**
   * Проверяет, есть ли в уроке Персонажи
   *
   * @param {Lesson} lesson
   * @returns {Boolean}
   */
  hasLessonPersons(lesson) {
    return (
      lesson.scene && lesson.scene.persons && lesson.scene.persons.length > 0
    );
  }

  updatePersonStatusShowed = (dom_id) => {
    const { personsData } = this.state;

    const personData = personsData.find((item) => item.dom_id === dom_id);

    if (personData && personData.status !== 2) {
      this.setPersonStatus(personData.id, 2);
    }
  };

  stopAllAudio = () => {
    setTimeout(() => {
      this.setState({
        is_audio_playing: false,
      });
    }, 200);

    this.setState({
      is_person_audio_playing: false,
      duration: 0,
    });

    if (this.state.currentAudio) {
      this.state.currentAudio.pause();
    }
  };

  setPersonStatus(id, status) {
    let { personsData, lesson, profile, child, forceUpdate, finish_percent } =
      this.state;
    if (!lesson.is_special) return;

    api.setPersonStatus(id, status).then((data) => {
      window.devMode &&
        console.log("setPersonStatus", profile, status, data.data);
      const newPersonData = personsData.map((item) => {
        return item.id === id
          ? {
              ...item,
              status,
            }
          : item;
      });

      child.balance = data.data.coins;
      child.points = data.data.points;
      child.ratingPosition = data.data.ratingPosition;

      if (finish_percent !== data.data.finish_percent) {
        forceUpdate += 1;
      }

      this.setState({
        child,
        forceUpdate: forceUpdate,
        show_blur: data.data.blur,
        finish_percent: data.data.finish_percent,
        personsData: newPersonData,
      });
    });
  }

  setPersonAudioIsPlaying(bool) {
    this.setState({ is_person_audio_playing: bool, is_audio_playing: !bool });
  }

  /**
   * Включает звук у Person.js
   */
  playSong(audioUrl, can_see, dom_id) {
    const { personsData, is_audio_playing } = this.state;
    const personData = personsData.find((item) => item.dom_id === dom_id);
    if (personData && personData.status !== 2)
      this.setPersonStatus(personData.id, 2);

    this.setPersonAudioIsPlaying(true);

    if (this.state.currentAudio) {
      this.state.currentAudio.pause();
      // eslint-disable-next-line
      this.state.currentAudio.currentTime = 0;
    }
    if (audioUrl) {
      let audio = new Audio();
      audio.addEventListener("loadedmetadata", () =>
        this.setState({ duration: audio.duration })
      );
      audio.src = config.base_url + audioUrl;
      this.setState({
        currentAudio: audio,
        currentAudioDomId: dom_id,
      });
      audio.play();
      audio.onended = () => {
        // не включать автоматом музыку, если была пауза
        if (!is_audio_playing)
          return this.setState({ is_person_audio_playing: false });
        this.setPersonAudioIsPlaying(false);
      };
    } else if (can_see && !audioUrl) {
      let audio = new Audio();
      audio.addEventListener("loadedmetadata", () =>
        this.setState({ duration: audio.duration })
      );
      audio.src = "/audio/fact.mp3";
      this.setState({
        currentAudio: audio,
      });
      if (this.state.currentAudio) {
        this.state.currentAudio.pause();
        // eslint-disable-next-line
        this.state.currentAudio.currentTime = 0;
      }
      audio.play();
      audio.onended = () => {
        // не включать автоматом музыку, если была пауза
        if (!is_audio_playing)
          return this.setState({ is_person_audio_playing: false });
        this.setPersonAudioIsPlaying(false);
      };
    } else {
      this.setState({
        is_person_audio_playing: false,
      });
    }
  }

  /**
   * Проверяет позицию курсора, если кликаю справа, то факт показываем слева, иначе справа
   *
   * @param {MouseEvent} event
   */
  checkCursorPosition(event) {
    this.setState({
      factPosition: event.clientX > window.outerWidth / 2 ? "left" : "right",
      factPositionY: event.clientY > window.outerHeight / 2 ? "bottom" : "top",
    });
  }

  /**
   * Маштабирует прелоадер урока под размер экрана
   */
  scaleLesson() {
    let scaleValue = scaleLessons();

    this.setState((prevState) => {
      prevState.transform.scaleX = scaleValue.scaleX;
      prevState.transform.scaleY = scaleValue.scaleY;
      return prevState;
    });
  }
}

const mapDispatchToProps = {
  setPreloader,
};

export default withRouter(connect(null, mapDispatchToProps)(LessonContainer));
