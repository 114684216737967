import React, { Component } from 'react';
import { config } from '../../data';

export class DialogPrize extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let button = this.props.status !== 'disabled'
      ? (<a
          className="btn btn_size_l"
          href={this.props.status ? false : this.props.url}
        >
          {this.props.btnTitle}
        </a>)
      : null;
    return (
      <div className="dialog-prize">
        <h2 className="dialog-prize__title">
          {this.props.title}
        </h2>

        <div
          className={`dialog-prize__content dialog-prize__content_prize_${this.props.text}`}
          style={{backgroundImage: `url(${`${config.base_url}${this.props.preview}`})`}}
        >
        </div>

        <div className="dialog-prize__footer">
          {button}
        </div>
      </div>
    );
  }
}
