import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Error
} from '../../components';

export class MultiInput extends Component {

  constructor(props) {
    super(props);

    let initItem = {};
    initItem[Date.now()] = '';

    this.state = initItem;

    this.onChange = this.onChange.bind(this);
  }

  render() {
    let itemsComponent = Object.keys(this.state).map((itemKey, index) => {
      if (this.state[itemKey] === false) return false;
      let itemValue = this.state[itemKey];

      return (
        <label className="input-txt item" key={itemKey}>

          <input
            className={`item__input ${this.props.className}`}
            value={itemValue}
            name={itemKey}
            onChange={this.onChange}
            placeholder={this.props.placeholder_text || 'placeholder'}/>

          <button
            className={`item__control ${index ? 'item__control_remove': 'item__control_add'}`}
            onClick={this.onControlClick.bind(this, itemKey, index)}
            type="button"
          >
            {this.props.add_text || ''}
          </button>

        </label>

      );
    });

    return (
      <div className="multi-input">
        {itemsComponent}
        {this.props.message && <Error message={this.props.message} type="inline" />}
      </div>
    );
  }

  onControlClick(key, index) {
    if (index) {
      this.setState({
        [key]: false
      });
    } else {
      let newItem = {};
      newItem[Date.now()] = '';
      this.setState(newItem);
    }
  }

  onChange(e) {
    this.setState({
        [e.target.name]: e.target.value
      },
      () => {
        let items = Object.keys(this.state).map(item => {
          return this.state[item] && this.state[item];
        });

        typeof this.props.onChange === 'function' && this.props.onChange(items);
      });
  }
}

MultiInput.propTypes = {
  onChange: PropTypes.func,
  add_text: PropTypes.string,
  placeholder_text: PropTypes.string
};
