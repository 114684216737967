import React from 'react';
import {config} from '../../data';

export const CabinetSocialLinks = () => {
  return (
    <nav className="cabinet-social-links">
      <a href={config.social_links.instagram} target="_blank" rel="noopener noreferrer" className="cabinet-social-links__link cabinet-social-links__link_insta"/>
      <a href={config.social_links.fb} target="_blank" rel="noopener noreferrer" className="cabinet-social-links__link cabinet-social-links__link_fb"/>
      <a href={config.social_links.vk} target="_blank" rel="noopener noreferrer" className="cabinet-social-links__link cabinet-social-links__link_vk"/>
    </nav>
  );
};
