
import React from 'react';

import {Link} from "react-router-dom";



export const MapPromo = () =>{
  return(
    <div className={'wrapper__map_promo'}>
      <img alt={'pointer'} width={'50px'} style={{position: 'absolute', bottom: '-15px', right: '-15px'}} src={'img_static/pointer.png'}/>
      <img alt={'promo'} height={'30px'} src={'/img_static/map-promo.png'}/>
      <Link
        to="/cabinet/select_tariff/"

        className={'promo_button'}
        type="button"
      >Купить за 1990₽
      </Link>
    </div>
  )
}
