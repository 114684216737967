import React, {Component} from 'react';
import queryString from 'query-string';
import Isvg from 'react-inlinesvg'
import {config} from '../../data';
import * as api from '../../api';
import * as auth from '../../helpers/authenticationHelper';
import {onEscapeKeyPress, checkImagesLoad, redirect} from '../../helpers';

import Popup from '../../components/popup/popup';
import {Library} from '../../components/library';
import {LibraryTitle} from '../../components/library-title';
import {setPreloader} from '../../redux/actions';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

class LibraryContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image_loaded: false,
      pre_loader_type: 'game',
      is_popup_closed: true,
      library: [],
      type: 'all',
      game: {
        status: '',
        type: '',
        preview: '',
        url: ''
      },
      lessonsScreenType: 'game'
    };

    this.showPopUp = this.showPopUp.bind(this);
    this.hidePopUp = this.hidePopUp.bind(this);
    this.hidePreLoader = this.hidePreLoader.bind(this);
  }

  componentDidMount() {
    this.props.setPreloader('quest__preloader');
    let currentChildId = auth.getCurrentChild();

    if (currentChildId) {
      api.getLibrary(currentChildId).then(library => {
        this.setState({
          library
        }, () => {
          let images = [
            config.games_popup_preview_routes
          ];

          checkImagesLoad(images)
            .then(result => this.hidePreLoader());
        })
      });
    } else {
      redirect();
    }

    // добавляем класс для скролла по вертикали
    document.querySelector('.app').classList.add('app_type-overflow-auto');
  }

  componentWillUnmount() {
    document.querySelector('.app').classList.remove('app_type-overflow-auto');
    window.removeEventListener('keydown', () => onEscapeKeyPress(this.hidePopUp));
  }

  componentDidUpdate(prevProps) {
    const query = queryString.parse(this.props.location.search);
    let currentChildId = auth.getCurrentChild();

    if (query.type !== queryString.parse(prevProps.location.search).type) {
      let str = query.type
      let url = str.replace('-', '_').replace('-', '_');
      api.getGames(currentChildId, url).then(games => {
        this.setState({
          games
        })
        let images = [
          config.games_popup_preview_routes
        ];

        checkImagesLoad(images)
          .then(result => this.hidePreLoader());
      });
    }
  }

  render() {

    return (
      <div className="library-page" onKeyDown={this.hidePopUp}>
        <Popup
          is_closed={this.state.is_popup_closed}
          hidePopUp={this.hidePopUp}
          location={this.props && this.props.location}
          item={this.state.game}
        />

        <Isvg
          src={process.env.PUBLIC_URL + '/img/_icons/games-page/sprite.svg'}
          uniquifyIDs={false}
        >
        </Isvg>

        {/*<MapNav*/}
        {/*  user={user}*/}
        {/*  lessonsScreenType={lessonsScreenType}*/}
        {/*/>*/}

        <LibraryTitle/>

        <Library
          library={this.state.library}
          showPopUp={this.showPopUp}
        />

        {/*<PreLoader*/}
        {/*  image_loaded={ this.state.image_loaded}*/}
        {/*  pre_loader_type={ this.state.pre_loader_type}*/}
        {/*/>*/}
      </div>
    );
  }

  /**
   * {MouseEvent} event
   */
  showPopUp(event) {
    window.addEventListener('keydown', () => onEscapeKeyPress(this.hidePopUp));
    let preview = config.base_url + event.currentTarget.getAttribute('data-lesson-preview');
    let gameStatus = event.currentTarget.getAttribute('data-status');


    // c сервера приходит число, у нас строка
    let url = null;
    let path = event.currentTarget.getAttribute('data-url');

    if (gameStatus === config.games_routes.status || config.games_logical_chain.status) {
      url = path
    }

    if (gameStatus === config.games_user_not_auth.status) {
      url = path
    }

    if (gameStatus === config.games_not_paid_subscription.status) {
      url = '/cabinet/select_tariff'
    }

    if (gameStatus === config.games_lesson_or_test_not_finished.status) {
      url = `/lessons/${path}`
    }

    if (gameStatus === config.games_lesson_is_free.status) {
      url = `/lessons/${path}`
    }

    if (gameStatus === config.games_not_shared.status) {
      url = '/cabinet'
    }

    this.setState({
      game: {
        status: gameStatus,
        section: 'games',
        preview: gameStatus === config.games_routes.status || config.games_logical_chain.status ? config.games_popup_preview_routes || config.games_logical_chain.status : preview,
        url: url
      },
      is_popup_closed: false
    });
  }

  hidePopUp() {
    window.removeEventListener('keydown', () => onEscapeKeyPress(this.hidePopUp));
    this.setState({
      is_popup_closed: true,
      game: {
        status: '',
        type: '',
        preview: '',
        url: ''
      }
    })
  }

  hidePreLoader() {
    this.setState({image_loaded: true}, () => {
      this.props.setPreloader('');
    })
  }
}

const mapStateToProps = (state) => {
  return {
    preloaderType: state.common.preloaderType
  }
};

const mapDispatchToProps = {
  setPreloader
};

const componentWithRouter = withRouter(connect(mapStateToProps, mapDispatchToProps)(LibraryContainer))

export {componentWithRouter as LibraryContainer};
