/**
 * Эффект счетчика от начального значения до конечного за заданный промежуток времени
 *
 * @param el
 * @param {number} startValue
 * @param {number} endValue
 * @param {number} stepCount
 * @param {number} duration
 *
 * @returns {number}
 */

let formatter = new Intl.NumberFormat('ru', {
  minimumFractionDigits: 0
});

let timer;

function priceFormatter(val) {
  return formatter.format(val);
}

export function counterEffect(el, startValue, endValue, stepCount, duration) {
  if (!el) {
    return false;
  }

  // assumes integer values for start and end
  clearInterval(timer);

  if (endValue === void 0 || startValue === void 0) return;

  let range = endValue - startValue;

  if (endValue <= 1 && endValue > startValue) {
    el.innerHTML = priceFormatter(endValue.toFixed(0));
  } else {
    // no timer shorter than 50ms (not really visible any way)
    let minTimer = 77;
    // calc step time to show all interediate values
    let stepTime = Math.abs(Math.floor(duration / range));

    // never go below minTimer
    stepTime = Math.max(stepTime, minTimer);

    // get current time and calculate desired end time
    let startTime = new Date().getTime();
    let endTime = startTime + duration;

    function run() {
      let now = new Date().getTime();

      if (el) {
        el.classList.add('__busy');

        if (now > endTime) {
          el.innerHTML = priceFormatter(endValue.toFixed(0));
          el.classList.remove('__busy');
          clearInterval(timer);
        } else {
          let remaining = Math.max((endTime - now) / duration, 0);
          let value = endValue - remaining * range;
          el.innerHTML = priceFormatter(value.toFixed(0));
        }
      } else {
        clearInterval(timer);
      }
    }

    timer = setInterval(run, stepTime);
    run();
  }
}
