import {Component} from 'react';

export class Youkassa extends Component {
  state = {
    youkassa: null
  }

  componentDidMount() {
    const {token, parentId, endLoad, backUrl} = this.props;

    const youkassa = new window.YooMoneyCheckoutWidget({
      confirmation_token: token,
      return_url: backUrl,
      error_callback(error) {
        window.devMode && console.log('Error yookassa', error)
      },
      customization: {
        colors: {
          controlPrimary: '#f77600',
          background: '#ddf6f8'
        }
      }
    });
    youkassa.render(parentId)
      .then(endLoad)
    this.setState({youkassa})
  }

  componentWillUnmount() {
    this.state.youkassa && this.state.youkassa.destroy()
  }

  render() {
    return null
  }
}
