import { ajaxV2 } from '../../helpers';

/**
 * @typedef {Array.<Object>} Quests
 */

/**
 * @returns {Promise.<Quests>}
 */
export const getQuests = (childId) => {
  return ajaxV2
    .get(`/quests?child_id=${childId}`)
    .then(response => response.data)
    .catch(error => console.log('getQuest error: ', error));
};
