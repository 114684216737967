import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {buildAvatarUrl, isPhone} from '../../helpers';
import {Slider} from '../../components';

export class ChildSelector extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentChildId: null
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  render() {
    let children = this.props.children || [];

    let slidesToShow = children.length < 3 ? 2 : 3;

    if (isPhone()) {
      slidesToShow = 2;
    }

    let selector = children.map(child => {
      return (
        <div
          className="child-selector__child"
          key={child.id}
        >
          <label
            className={`child-selector__label child-selector__label_state_${child.avatar ? child.avatar : 1}`}
            htmlFor={`child_${child.id}`}>

            <i
              style={{
                backgroundImage: buildAvatarUrl(child)
              }}
            />
            <span className="child-selector__name">{child.name}</span>

            <input
              id={`child_${child.id}`}
              className="child-selector__input"
              type="radio"
              name="current"
              value={child.id}
              data-id={child.id}
              data-name={child.name}
              data-avatar={child.avatar}
              checked={this.state.currentChildId === child.id}
              onChange={this.handleInputChange}
            />
          </label>
        </div>
      );
    });

    return (
      <div className="child-selector">

        <div className="child-selector__form">
          <Slider
            slidesToShow={slidesToShow}
            slideItems={selector}
            children={children}
          >
          </Slider>
        </div>
      </div>
    );
  }

  handleInputChange(e) {
    e.preventDefault();
    let childId = parseInt(e.target.value, 10);
    let name = e.currentTarget.getAttribute('data-name');
    let avatar = e.currentTarget.getAttribute('data-avatar');

    if (this.state.currentChildId === childId) return;

    window.localStorage.setItem('auth', true);
    this.setState({
      currentChildId: childId
    }, () => this.props.onSelect(this.state.currentChildId, name, avatar));
  }
}

ChildSelector.propTypes = {
  children: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired
};
