import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {redirect, addShortGameName} from '../../helpers';
import c from 'classnames';
import {getGame} from '../../api';
import {TestLototron} from '../test-lototron';
import * as auth from '../../helpers/authenticationHelper';

const STEP_FINISH = 1;
const STEP_PRIZE = 2;
const STEP_NEW_GAME = 3;
const STEP_LOOSE = 4;

export class TestFinishScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      gamePreview: null,
      typeGame: null,
      animation: this.getAnimationName()
    }
    this.nextStep = this.nextStep.bind(this);
  }

  componentDidMount() {
    const {gameUrl, success, audio} = this.props;
    let currentChildId = auth.getCurrentChild();

    if (success) {
      audio.src = '/audio/game_success.mp3';
      audio.play();
    } else {
      audio.src = '/audio/test_fail.mp3';
      audio.play();
      return;
    }

    if (currentChildId) {
      getGame(currentChildId, gameUrl).then((game) => this.setState({
        gamePreview: (game.lesson && game.lesson.preview_image.small)
          || (game.lesson && game.lesson.preview_image.large)
          || game.without_lesson_image,
        typeGame: addShortGameName(game.type)
      }))
    } else {
      redirect();
    }
  }

  getFooterText() {
    const {answersResult} = this.props;
    const rightAnswerCount = answersResult.filter(i => i).length

    switch (rightAnswerCount) {
      case 1:
        return 'Ты ответил верно на 1 вопрос!'
      case 2:
      case 3:
      case 4:
        return `Ты ответил верно на ${rightAnswerCount} вопроса!`
      case 5:
        return 'Ты ответил верно на все вопросы!'
      default:
        return ''
    }
  }

  nextStep() {
    const {step} = this.state;
    const {success, showLototron, lessonUrl} = this.props;

    switch (step) {
      case STEP_FINISH:
        if (success && showLototron) return this.setState({step: STEP_PRIZE});
        if (success) return redirect();
        return this.setState({step: STEP_LOOSE});
      case STEP_PRIZE:
        return this.setState({step: STEP_NEW_GAME});
      case STEP_NEW_GAME:
        return redirect();
      default:
        return redirect(`/lessons/${lessonUrl}`);
    }
  }

  getAnimationName() {
    const {success} = this.props;

    if (!success) return 'fail';

    const arr = ['default', 'dab', 'yaaau', 'yes'];
    const i = Math.floor(Math.random() * arr.length);
    return arr[i];
  }

  render() {
    const {success, answersResult, prizeUrl, prizeName, lessonIcon, audio, gameUrl, testId, childPoints, coins, spinCallback} = this.props;
    const {step, gamePreview, typeGame, animation} = this.state;

    return (
      <section className="test-finish-screen">
        <div
          className={'test-finish-screen__content' + (step === STEP_FINISH ? ' __finish' : step === STEP_PRIZE ? ' __prize' : '')}>
          {step === STEP_FINISH && <div>
            <p className="test-finish-screen__title">
              {success ? 'Успех!' : 'Будь внимательней!'}
            </p>
            <ul className="test-finish-screen__list">
              {answersResult.map((r, i) => <li
                key={i}
                className={c(
                  'test-finish-screen__item',
                  {'test-finish-screen__item--success': r}
                )}
              />)}
            </ul>
            <p className="test-finish-screen__text">
              {this.getFooterText()}
            </p>
          </div>}

          {/*{step === STEP_PRIZE && <TestPrize*/}
          {/*  isFinished={success}*/}
          {/*  prizeUrl={prizeUrl}*/}
          {/*  prizeName={prizeName}*/}
          {/*  audio={audio}*/}
          {/*/>}*/}

          {step === STEP_PRIZE && <TestLototron
            clickCallback={(data) => {
              window.devMode && console.log('TestLototron', data);
              if (data.empty) {
                redirect();
              } else {
                this.setState({
                  step: STEP_NEW_GAME
                });

                spinCallback(data);
              }
            }}
            testId={testId}
            isFinished={success}
            prizeUrl={prizeUrl}
            prizeName={prizeName}
            audio={audio}
          />}

          {step === STEP_NEW_GAME && <div>
            <p className="test-finish-screen__title">Доступна новая игра</p>
            <div className="test-finish-screen__game" onClick={() => {
              localStorage.setItem('gameReturnLInk', '/?mapScrollToLesson=' + window.location.pathname.split('/')[2]);
              redirect(`/games/${gameUrl}`)
            }}>
              <span className="lesson-progress-animation__center--rays"/>
              <img className="test-finish-screen__game-img" src={gamePreview} alt="Иконка игры"/>
            </div>
            <p className="test-finish-screen__text">{typeGame}</p>
          </div>}

          {step === STEP_LOOSE && <div>
            <p className="test-finish-screen__text">Не расстраивайся!<br/>Попробуй пройти урок еще раз<br/>и возвращайся
              к тесту!</p>
            <p><img className="test-finish-screen__lesson" src={lessonIcon} alt="Иконка урока"/></p>
          </div>}

          {step !== STEP_NEW_GAME && step !== STEP_PRIZE ?
            <div className="test-finish-screen__next" onClick={this.nextStep}/> : null}

          {step === STEP_FINISH ? <div className="test-finish-screen__extra">
            {coins ? <div className="test-bonus __coins">х {coins}</div> : null}
            {childPoints ? <div className="test-bonus __stars">х {childPoints}</div> : null}
          </div> : null}
        </div>

        {step === STEP_FINISH ?
          <div className={c('test-finish-screen__animation', `test-finish-screen__animation--${animation}`)}/> : null}

        {/* For test
        <button style={{position: 'absolute', top: '100px', right: '100px', backgroundColor: 'white', padding: '10px'}} onClick={() => this.setState({step: step + 1})}>next</button>
        <button style={{position: 'absolute', top: '100px', right: '50px', backgroundColor: 'white', padding: '10px'}} onClick={() => this.setState({step: step - 1})}>prev</button> */}
      </section>
    )
  }
}

TestFinishScreen.propTypes = {
  success: PropTypes.bool,
  spinCallback: PropTypes.func,
  showLototron: PropTypes.bool,
  answersResult: PropTypes.array,
  prizeUrl: PropTypes.string,
  prizeName: PropTypes.string,
  lessonIcon: PropTypes.string,
  gameUrl: PropTypes.number
};
