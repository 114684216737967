import React from 'react';
import {Preview} from 'react-dnd-preview';
import {config} from '../../data';

const generatePreview = ({item, style}) => {
  const {width, height, src} = item;

  return (
    <div style={{
      ...style,
      width,
      height,
      backgroundImage: `url(${config.base_url}${src})`,
      backgroundSize: '100% 100%',
      zIndex: 9999
    }}/>
  );
}
export const DragPreview = () => {
  return (
    <Preview>{generatePreview}</Preview>
  );
}
