import React from 'react';
import {Sale, withBanner} from '../../banners';

const SaleBanner = ({ type }) => {
  return (
    <Sale type={type} />
  );
};

export const SaleRoutesBanner = withBanner(SaleBanner);
