import React, { Component } from 'react';
import { redirect } from '../../helpers';


export class DialogCheck extends Component {


  constructor(props) {
    super(props);

    const first = this.getRandomInt(1, 9);
    const second = this.getRandomInt(1, 9);
    const result = first * second;

    this.state = {
      first,
      second,
      result: `${result}`,
      value: '',
      status: ''
    };
    this.clearState = this.clearState.bind(this);
  }

  handleKeyPress = (event) => {
    this.setState({
      value: '' + this.state.value + event.key
    }, function() {
      if (this.state.value === this.state.result) {
            redirect('/cabinet');
      } else if (this.state.value.length >= this.state.result.length) {
        this.setState({
          status: 'shake-top'
        })
        setTimeout(this.clearState, 1000);
      }
    });

  }

  componentWillReceiveProps(arg) {
    if(arg.focus === true){
      setTimeout(()=>{
        this.input.focus()
      }, 1000)
    }
  }

  access(value) {
    this.setState({
      value: '' + this.state.value + value
    }, function() {
      if (this.state.value === this.state.result) {
            redirect('/cabinet');
      } else if (this.state.value.length >= this.state.result.length) {
        this.setState({
          status: 'shake-top'
        })
        setTimeout(this.clearState, 1000);
      }
    });
  }

  clearState() {
    this.setState({
      status: '',
      value: ''
    })
  }

  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  render() {
    let calc = <div className="calc_inner">
                  <div className="calc_header_area">
                     <h2 className="popup_title">Доступ к этому разделу есть только у взрослых</h2>
        <label htmlFor="age_check_area" className="input_area_label">Сколько будет {this.state.first}х{this.state.second}?</label>
                     <input ref={(input) => this.input = input} id="age_check_area" onKeyPress={this.handleKeyPress} className={`input_area ${this.state.status}`} value={this.state.value} />
                  </div>
                  <div className="calc_area">
                       <button type="button" onClick={this.access.bind(this, '1')}>1</button>
                       <button type="button" onClick={this.access.bind(this, '2')}>2</button>
                       <button type="button" onClick={this.access.bind(this, '3')}>3</button>
                       <button type="button" onClick={this.access.bind(this, '4')}>4</button>
                       <button type="button" onClick={this.access.bind(this, '5')}>5</button>
                       <button type="button" onClick={this.access.bind(this, '6')}>6</button>
                       <button type="button" onClick={this.access.bind(this, '7')}>7</button>
                       <button type="button" onClick={this.access.bind(this, '8')}>8</button>
                       <button type="button" onClick={this.access.bind(this, '9')}>9</button>
                       <button type="button" onClick={this.access.bind(this, '0')}>0</button>
                  </div>
               </div>
      return (
        calc
    );
  }

}
