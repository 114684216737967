import React, {Component} from 'react';
import {whichAnimationEvent} from '../../helpers';

export class MapCloud extends Component {

  constructor(props) {
    super(props);

    this.state = {
      animate: true,
      firstCircle: true
    };

  }

  cloudRef = React.createRef();

  componentDidMount() {
    let animationEvent = whichAnimationEvent();

    if (this.cloudRef && this.cloudRef.current) {
      this.cloudRef.current.addEventListener(animationEvent, () => {

        if (this.state.firstCircle) {
          this.setState({animate: false});

          setTimeout(() => {
            this.setState({firstCircle: false, animate: true});
          }, 10)
        }
      })
    }
  }

  render() {
    let {index} = this.props;

    return (
      <div ref={this.cloudRef}
           className={(this.state.firstCircle ? '' : '_out-of-screen ')
             + (this.state.animate ? '_animate ' : '')
             + 'map-cloud _cloud-' + index}>
        <img src={`./img/map/cloud_${index}.png`} alt=""/>
      </div>
    );
  }
}
