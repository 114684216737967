import {ajax, redirect} from '../../helpers';

/**
 * @typedef {Array.<Object>} Payments
 */

/**
 * @returns {Promise.<Payments>}
 */
export const getTokenForYoukassa = (body) => {
  return ajax
    .post('/payments', body)
    .then(({ data }) => {
      if (data.hasOwnProperty('url')) {
        return redirect(data.url);
      } else {
        if (!data || (data && !data.confirmation_token)) throw new Error('Not data or data.confirmation_token')
        return { token: data.confirmation_token, paymentId: data.payment_id }
      }
    })
    .catch(error => console.log('getPayments error: ', error));
};
