import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LessonNav } from '../../components'

export class LessonPostLoader extends Component {

  constructor(props) {
    super(props);
    this.onAnimationEnd = this.onAnimationEnd.bind(this);
  }

  render() {

    const {
      lessonUrl,
      testUrl,
      gameUrl,
      gamesUrl,

      startAgain,

      isGameFinished,
      isTestFinished,
      isLessonAudioFinished,

      isTestFinishedSuccess,

      postLoaderType
    } = this.props;

    const personType = this.randPersonType();

    return (

      <section
        className={
          `lesson-post-loader lesson-post-loader_type_${postLoaderType} lesson-post-loader_person_${personType}`
        }
        onAnimationEnd={this.onAnimationEnd}
        ref='postLoader'
      >
        <LessonNav
          isLessonAudioFinished={isLessonAudioFinished}
          isTestFinished={isTestFinished}
          isTestFinishedSuccess={isTestFinishedSuccess}
          isGameFinished={isGameFinished}
          lessonUrl={lessonUrl}
          testUrl={testUrl}
          gameUrl={gameUrl}
          gamesUrl={gamesUrl}
          startAgain={startAgain}
        />

      </section>
    );
  }

  randPersonType() {
    if (!this.props.isTestFinishedSuccess && window.location.href.indexOf('tests') !== -1)
      return 'defeat';

    const arr = [ 'default', 'dab', 'yaaau', 'yes' ];
    const i = Math.floor(Math.random() * arr.length);
    return arr[i];
  }

  onAnimationEnd() {
    this.refs.postLoader.classList.add('lesson-post-loader_state_animation_end')
  }
}

LessonPostLoader.propTypes = {
  gamesUrl: PropTypes.string,
  lessonUrl: PropTypes.number,
  testUrl: PropTypes.number,
  gameUrl: PropTypes.number,
  startAgain: PropTypes.func,
  isLessonAudioFinished: PropTypes.bool,
  isTestFinished: PropTypes.bool,
  isTestFinishedSuccess: PropTypes.bool,
  isGameFinished: PropTypes.bool,
  postLoaderType: PropTypes.string
};
