import React, {Component} from 'react';
import * as auth from '../../helpers/authenticationHelper';
import {
  ChildQuestsFilter,
  ChildHeader,
  ChildQuests
} from '../../components';
import * as api from '../../api';

export class ChildQuestsContainer extends Component {

  constructor(props) {
    super(props);

    this.state = {
      content_loaded: false,
      child: null,
      is_back_link: true
    };

    this.initFilter = this.initFilter.bind(this);
  }

  componentDidMount() {
    let currentChildId = auth.getCurrentChild();
    let promises = [];

    if (currentChildId) {
      promises = [
        api.getChild(currentChildId),
        api.getQuests(currentChildId)
      ]
    }

    promises.push(api.getLessons(currentChildId));

    Promise.all(promises).then(result => this.setState({
      child: result[0],
      quests: result[1],
      lessons: result[2],
      content_loaded: true
    }, () => {
      this.initFilter()
    }));
  }

  render() {
    let page = null;

    if (this.state.child && this.state.quests) {
      page = <div>
        <ChildHeader
          child={this.state.child}
          is_back_link={this.state.is_back_link}
        />

        <section className="child-routes-section">
          <div className="wrapper wrapper_cabinet">
            <ChildQuestsFilter/>
            <ChildQuests
              quests={this.state.quests}
              lessons={this.state.lessons}
            />
          </div>
        </section>
      </div>
    }

    return (
      <main className={`cabinet__main ${this.state.content_loaded ? '' : 'cabinet__preloader'}`}>
        {page}
      </main>
    );
  }

  initFilter() {
    let tags = document.querySelectorAll('.js-quests-types');
    let list = document.querySelector('.js-quests-list');

    // Включаю по умолчанию первый тэг
    tags && tags[0] && (tags[0].checked = true);

    for (var i = 0; i < tags.length; i++) {
      tags[i].addEventListener('change', (event) => {
        list.dataset.filter = event.target.value
      });
    }
  }
}
