import { ajax } from '../../helpers';

/**
 * @typedef {Object} NewChild
 */

/**
 *
 * @param {Object} id – new child id
 * @returns {Promise.<NewChild>}
 */
export const postChild = (id) => {
  return ajax
    .post('/children/', id)
    .then(response => response.data)
    .catch(error => this.addChild(error))
};


