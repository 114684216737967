import queryString from 'query-string';

/**
 * Определение типа экрана
 *
 * @param {location} location
 * @param {boolean} isQuest
 *
 * @returns {string}
 */

export function getScreenType(location, isQuest) {
  const {pathname, search} = location;
  const query = queryString.parse(search);

  return pathname.indexOf('/library') === 0 ? 'game'
    : pathname.indexOf('/games') === 0 ? 'game'
      : pathname.indexOf('/lessons') === 0 && pathname.length > 9 ? 'lesson'
        : pathname.indexOf('/cabinet') === 0 ? 'cabinet'
          : pathname.indexOf('/agreement') === 0 ? 'cabinet'
            : pathname.indexOf('/quests') === 0 ? 'quest'
              : isQuest ? 'quest'
                : query.popup || 'default';
}
