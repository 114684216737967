import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {config, i18n} from '../../data';
import * as auth from '../../helpers/authenticationHelper';
import {Tooltip} from '../../components'

export class LessonMapBtn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipPosition: 'top',
      loaded: false,
      // для лучшего UI не отображаем лоадер в /tour
      imageURL: props.fakeParams ? '' : '../img_static/small-loader.svg'
    };
    this.handleOnMouseOver = this.handleOnMouseOver.bind(this);
    this.bgImg = new Image();
  }

  componentDidMount() {
    const {lesson} = this.props;

    this.bgImg.addEventListener('load', this.setImage)
    this.bgImg.src = `${config.base_url}${lesson.map.icon}`;
  }

  componentWillUnmount() {
    this.bgImg.removeEventListener('load', this.setImage)
    this.bgImg = null
  }

  setImage = () => {
    const {lesson} = this.props;
    this.setState({loaded: true, imageURL: `${config.base_url}${lesson.map.icon}`})
    this.bgImg.removeEventListener('load', this.setImage)
  }

  handleOnMouseOver(e) {
    const tooltip = e.currentTarget.lastElementChild;

    if (tooltip.getBoundingClientRect().y < 150) {
      this.setState({
        tooltipPosition: 'bottom'
      });
    }
  }

  handleOnMouseOut(e) {
    this.setState({
      tooltipPosition: 'top'
    });
  }

  render() {
    const {
      lesson,
      linkClass,
      showPopUp
    } = this.props;
    const {imageURL, loaded} = this.state;
    let status = config.lesson_not_registered.status;
    let lessonStarClassName = null;
    let background = imageURL;
    let preview = lesson.preview_image.large;
    let title = lesson.name;
    let text = lesson.short_description;
    let url = config[`lesson_${status}`].route;
    let isLessonStar = lesson.access.is_open === false;

    if (!auth.getProfile() && !lesson.access.is_open) {
      lessonStarClassName = 'lesson_type_star';
      background = null;
      preview = config.games_popup_preview_routes;
      title = i18n.lesson_quest_text;
      text = null;
    } else if (auth.getProfile() && !lesson.access.is_open && lesson.quest) {
      lessonStarClassName = 'lesson_type_star';
      background = null;
      preview = config.games_popup_preview_routes;
      status = config.lesson_quest.status;
      title = i18n.lesson_quest_text;
      text = null;
      url = `${config[`lesson_${status}`].route}${lesson.quest.id}`;
    }

    if (auth.getProfile() && lesson.access.is_open) {
      status = config.lesson_not_paid.status;
      url = config[`lesson_${status}`].route;
    }

    return (
      <div className={'lesson-wrapper' + (loaded ? linkClass : '')} data-map-position={lesson.map.position}
           onMouseOver={(e) => this.handleOnMouseOver(e)} onMouseOut={(e) => this.handleOnMouseOut(e)}>
        <button
          className={`lesson ${lessonStarClassName || ''}`}
          type="button"
          data-map-position={lesson.map.position}
          data-img-preview={preview}
          data-status={status}
          data-title={title}
          data-text={text}
          data-url={url}
          style={{backgroundImage: `url(${background})`}}
          onClick={showPopUp}
        >
          {lesson.name}
        </button>

        {!isLessonStar && <Tooltip
          title={lesson.name}
          position={this.state.tooltipPosition}
        />}
      </div>
    );
  }
}

LessonMapBtn.propTypes = {
  lesson: PropTypes.object,
  showPopUp: PropTypes.func,
  linkClass: PropTypes.string
};
