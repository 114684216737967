import React from 'react';
import {LotterySecond, withBanner} from '../../banners';

const LotterySecondBanner = ({ type }) => {
  return (
    <LotterySecond type={type} />
  );
};

export const LotterySecondRoutesBanner = withBanner(LotterySecondBanner);
