import React from 'react';
import { config } from '../../data'
import { Link } from 'react-router-dom';
import Button from '../button/button';

export const CabinetSettingsLink = () => {
  return (
    <Link
      to={config.routes.cabinet_settings}
      className="cabinet-menu__link"
    >
      <Button mod="cabinet-menu__button" color="orange" customTag="span">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18.7428 30.4H20.4571C20.9434 30.4 21.3082 30.1084 21.4176 29.6345L21.9161 27.5082C22.3173 27.3745 22.6942 27.2287 23.0346 27.0586L24.8948 28.2008C25.2839 28.4438 25.758 28.4073 26.0863 28.0792L27.29 26.8763C27.6182 26.5483 27.6668 26.0501 27.3994 25.6491L26.2687 23.8022C26.4389 23.462 26.5969 23.0853 26.7064 22.733L28.8583 22.2226C29.3204 22.1254 29.6 21.7609 29.6 21.2627V19.5859C29.6 19.1121 29.3204 18.7475 28.8583 18.6503L26.7307 18.1278C26.5969 17.7147 26.4267 17.3502 26.293 17.0464L27.4237 15.1631C27.679 14.7621 27.6547 14.3004 27.3143 13.9602L26.0863 12.7573C25.7459 12.4535 25.3325 12.3928 24.9313 12.6115L23.0346 13.7779C22.7064 13.6078 22.3295 13.462 21.9161 13.3283L21.4176 11.1655C21.3082 10.6916 20.9434 10.4 20.4571 10.4H18.7428C18.2565 10.4 17.8918 10.6916 17.7945 11.1655L17.2839 13.3041C16.8948 13.4377 16.4936 13.5835 16.1653 13.7658L14.2808 12.6115C13.8674 12.3928 13.4541 12.4414 13.1137 12.7573L11.8857 13.9602C11.5453 14.3004 11.521 14.7621 11.7763 15.1631L12.907 17.0464C12.7732 17.3502 12.603 17.7147 12.4814 18.1278L10.3538 18.6503C9.87961 18.7475 9.59998 19.1121 9.59998 19.5859V21.2627C9.59998 21.7609 9.87961 22.1254 10.3538 22.2226L12.5058 22.733C12.603 23.0853 12.7611 23.462 12.9313 23.8022L11.8006 25.6491C11.5331 26.0501 11.5817 26.5483 11.91 26.8763L13.1137 28.0792C13.4419 28.4073 13.9161 28.4438 14.3173 28.2008L16.1775 27.0586C16.5057 27.2287 16.8948 27.3745 17.2839 27.5082L17.7945 29.6345C17.8918 30.1084 18.2565 30.4 18.7428 30.4ZM19.6061 23.7415C17.758 23.7415 16.2626 22.2226 16.2626 20.3879C16.2626 18.5653 17.758 17.0586 19.6061 17.0586C21.4419 17.0586 22.9374 18.5653 22.9374 20.3879C22.9374 22.2226 21.4419 23.7415 19.6061 23.7415Z" fill="white"/>
        </svg>
      </Button>
      Настройки
    </Link>
  );
};
