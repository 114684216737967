import React, {Component} from 'react';

export class UnicornSale extends Component {
  constructor(props) {
    super(props);

    this.state = {
      skin: this.props.skin
    };
  }

  render() {
    const {skin} = this.state;

    return (
      <div className="unicorn">
        <h2 className="unicorn__title">
          Костюм<br/>
          единорога<br/>
          со скидкой!
        </h2>
        {skin.price && <div className="unicorn__count">{skin.price}</div>}
        <div className="unicorn__text">Успей купить!</div>
        <img className="unicorn__image"
             src="/img/promo/unicorn-popup.png"
             srcSet="/img/promo/unicorn-popup@2x.png 2x"
             alt=""
        />
        <button type="button" className="unicorn__button" onClick={() => {
          this.props.goToLink('edinorog')
        }}>Примерить
        </button>
      </div>
    )
  }
}
