/**
 * Соединяет классы в один. Возвращает `null`, т.к. в случае, если вернется 
 * пустая строка мы получим `<div class>...</div>`, для `null` — атрибут не 
 * присваивается.
 * 
 * @param  {...string|boolean} classNames 
 * 
 * @returns {string|null} 
 */
export const classes = (...classNames) => {
  return classNames.filter(Boolean).join(' ') || null;
}