import React from 'react';
import { render } from 'react-dom';

import {
  ErrorPage
} from '../containers';

export const page500 = () => render(
  <ErrorPage status={500} />,
  document.getElementById('root')
);
