import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {openAuthPopup} from '../../redux/actions';
import {i18n} from '../../data';

import {
  Menu,
  SocialLinks
} from '../../components';

const Footer = (props) => {

  return (
    <footer
      className={`footer footer_type_${props.modifier ? props.modifier : 'no_modifier'} ${props.isPhoneX ? 'footer_isPhoneX' : null}`}>
      <div className="footerLinks"><SocialLinks 
        isInstagramIconGradient={true}
      /></div>
      <Menu feebackToggle={props.feebackToggle}/>

      <div className="footer__btn-wrap">
        <span
          className="btn btn_size_s btn_type_emphasis"
          onClick={() => {
            props.openAuthPopup('signup');
          }}
        >
          <svg viewBox="0 0 40 40" id="cabinet--login" width="20" height="20" className="btn__icon">
            <path fill="none" d="M0 0h40v40H0z"/>
            <path fill="#FFF" d="M4 1.7v5.7l26.2-.3v26l-25-.4.1 5.6H36V1.7z"/>
            <path fill="#FFF" d="M5.2 18.1v5.5h11.4v6.5l11.3-9.3-11.3-9.4v6.8z"/>
          </svg>
          &nbsp;{i18n.enter_or_register}
        </span>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  modifier: PropTypes.string
};

export default connect(
  null,
  {openAuthPopup}
)(Footer);
