import React from 'react';
import PropTypes from 'prop-types';

export const CabinetProgress = ({child, lessons}) => {
  let finished = 0;
  let total = 0;

  if (child !== null && lessons !== null) {
    const activeLessons = [];
    const finishedLessons = [];

    lessons.forEach(item => {
      if (item.is_active === true) {
        activeLessons.push(item);

        if (item.test !== null && item.test.is_completed_successfully) {
          finishedLessons.push(item);
        }
      }
    });

    finished = finishedLessons.length;
    total = activeLessons.length;
  }

  return (
    <div className="cabinet-progress">
      <div className="cabinet-progress__line">
        <div className="cabinet-progress__position" style={{left: `${finished * 100 / total}%`}}>
          <img
            src='/img/cabinet/progress-icon.svg'
            width={44}
            height={44}
            alt=""
            className="cabinet-progress__img"
          />
          <div className="cabinet-progress__value">{finished}/{total}</div>
        </div>
        <div className="cabinet-progress__point cabinet-progress__point_start"/>
        <div className="cabinet-progress__point cabinet-progress__point_finish"/>
      </div>
    </div>
  );
};

CabinetProgress.propTypes = {
  child: PropTypes.object,
  lessons: PropTypes.array
};
