import React from 'react';
import PropTypes from 'prop-types';
import * as auth from '../../helpers/authenticationHelper';

import {
  SelectTariffLink,
  BillingLink,
  ShopLink,
  LibraryLink,
  CabinetSettingsLink,
  SupportBtn,
  LogoutBtn,
  CabinetSocialLinks
} from '../../components';
import {CabinetAchievementLink} from "../cabinet-achievement-link";

export const CabinetMenu = ({ showPopUp }) => {
  const subscription = auth.getProfile() && auth.getProfile().subscription;

  return (
    <div className="cabinet-menu">
      <nav className="cabinet-menu__main">
        {
          (typeof subscription === 'undefined') ?
          <SelectTariffLink/>
          :
          <BillingLink subscription={subscription}/>
        }

        <CabinetAchievementLink/>
        <ShopLink/>
        <LibraryLink/>
        <SupportBtn showPopUp={showPopUp}/>
        <CabinetSettingsLink/>
        <LogoutBtn/>
      </nav>
      <div className="cabinet-menu__social-links">
        <CabinetSocialLinks/>
      </div>
    </div>
  );
};

CabinetMenu.propTypes = {
  showPopUp: PropTypes.func
};
