import React, { Component } from 'react';
// import { closest, isTouch,redirect } from '../../helpers';

export class DialogAuth extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {

    return (
      <div className="auth-container">
        <div className={`auth-container__photo auth-container__photo_state_${this.props.preview}`}></div>

        <div className="auth-container__form">
          {this.props.children}
        </div>
      </div>
    )
  }
}



