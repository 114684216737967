import { config } from '../data';
import { makeAbsoluteUrl } from './index';

/**
 *
 * @type {Number}
 */
const vkScopeMail = 4194304;

/**
 * @param options
 * @param {String} options.redirect_uri
 * @param {Number} [options.scope]
 * @return {String}
 */
const getVkLink = (options = {}) => (
  [
    'https://oauth.vk.com/authorize?',
    `client_id=${config.social_auth.vk.client_id}&`,
    `redirect_uri=${makeAbsoluteUrl(options.redirect_uri)}&`,
    `scope=${options.scope || vkScopeMail}&`,
    'display=popup'
  ].join('')
)

/**
 * @param options
 * @param {String} options.redirect_uri
 * @param {String} [options.scope]
 * @return {String}
 */
const getFbLink = (options = {}) => (
  [
    'https://www.facebook.com/v2.8/dialog/oauth?',
    `client_id=${config.social_auth.fb.client_id}&`,
    `redirect_uri=${makeAbsoluteUrl(options.redirect_uri)}&`,
    `scope=${options.scope || 'email'}`
  ].join('')
)

export {
  getVkLink,
  getFbLink
}
