import { ajax } from '../../helpers';

/**
 * @typedef {Array.<Object>} Games
 */

/**
 * @returns {Promise.<Games>}
 */
export const getGames = (childId, type) => {
  let url = childId ? `/games?${(type === 'all') ? false : 'type=' + type }&child_id=${childId}` : `/games?${(type === 'all') ? false : 'type=' + type }`;

  return ajax.get(url)
    .then(response => response.data.map(el => {

      el && el.lesson
      && el.lesson.preview_image
      && el.lesson.preview_image.large
      && (el.lesson.preview_image.large = el.lesson.preview_image.large.replace('/large_', '/medium_'));
      return el;

      })
    )
    .catch(error => console.log('getGames error: ', error));
};
