import React, {Component} from 'react';
import {setPreloader} from '../../redux/actions';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';

import {
  CabinetHeader,
  CabinetLessons,
  CabinetResults,
  CabinetGames
} from '../../components';

class CabinetNotAuth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      child: null,
      content_loaded: true,
      page_title: 'Прогресс ребёнка'
    };

    this.hidePreLoader = this.hidePreLoader.bind(this);
  }

  componentDidMount() {
    this.props.setPreloader('game');
  }

  render() {
    return (
      <main className={`cabinet__main ${this.state.content_loaded ? '' : 'cabinet__preloader'}`}>
        <CabinetHeader
          page_title={this.state.page_title}
        />

        <section className="cabinet__section">
          <div className="wrapper wrapper_cabinet">
            <CabinetLessons
              child={this.state.child}
            />
          </div>
        </section>

        <section className="cabinet__section">
          <div className="wrapper wrapper_cabinet">
            <CabinetResults
              child={this.state.child}
            />
            <CabinetGames
              child={this.state.child}
            />
          </div>
        </section>
      </main>
    );
  }

  hidePreLoader() {
    this.setState({
      image_loaded: true
    }, () => {
      this.props.setPreloader('');
    })
  }
}

const mapDispatchToProps = {
  setPreloader
};

const componentWithRouter = withRouter(connect(null, mapDispatchToProps)(CabinetNotAuth))

export {componentWithRouter as CabinetNotAuth};
