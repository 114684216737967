import { ajax } from '../../helpers';
import React from 'react';
import { render } from 'react-dom';

import {
  ErrorPage
} from '../../containers';

/**
 * @typedef {Object} Stats
 */

/*
 * @returns {Promise.<Stats>}
 */
export const getStats = (childId) => {
  return ajax
    .get(`/stats/?child_id=${childId}`)
    .then(response => response.data)
    .catch(err => {
      window.devMode && console.log('getStats error: ', err)

      if (err.response.status === 404) {
        render(
          <ErrorPage status={404} />,
          document.getElementById('root')
        );
      }
    })
};
