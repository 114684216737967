import React from 'react';

export const CabinetFooter = (props) => {



  const today = new Date().getFullYear();

  return (
    <footer className="cabinet-footer cabinet__footer">
      <div className="wrapper wrapper_cabinet">
        <p>{`© ${today} «Чевостик»`}</p>
      </div>
    </footer>
  );
};
