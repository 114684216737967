import React from 'react';
import { config } from '../../data';
import { NavLink } from 'react-router-dom';

export const CabinetBanner = () => {
  return (
    <div className="cabinet-banner">
      <div className="cabinet-banner__inner">
        <div className="cabinet-banner__text">Подпишитесь на “Приключения Чевостика” прямо сейчас и получите скидку!</div>
        <NavLink
          to={`${config.routes.cabinet}/${config.routes.select_tariff}`}
          title="Выбрать тариф"
          className="cabinet-banner__btn"
          onlyActiveOnIndex={false}
        >
          Выбрать тариф
        </NavLink>
      </div>
      <img
        className="cabinet-banner__img cabinet-banner__img_left"
        src='/img/cabinet/chevostik-banner-left.svg'
        alt=""
        width={194}
        height={260}
        role="presentation"
      />
      <img
        className="cabinet-banner__img cabinet-banner__img_right"
        src='/img/cabinet/chevostik-banner-right.svg'
        alt=""
        width={249}
        height={243}
        role="presentation"
      />
    </div>
  );
};
