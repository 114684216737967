export const POINTS_FOR_QUEST = {
  4: {
    pointsForPath: [
      [6.04, 30.43],
      [40.46, 21.04],
      [60.34, 43.13],
      [90.3, 37.4]
    ],
    iconsPosition: [
      [11, 29],
      [40, 21],
      [60, 43.5],
      [90, 38],
    ]
  },
  5: {
    pointsForPath: [
      [6.04, 30.43],
      [30.46, 21.04],
      [47.34, 43.13],
      [65.3, 34.4],
      [79.3, 37.4]
    ],
    iconsPosition: [
      [10, 29],
      [30, 21],
      [49, 43.5],
      [67, 34],
      [79, 37.5]
    ]
  },
  6: {
    pointsForPath: [
      [7.61, 39.48],
      [22.22, 21.78],
      [44.40, 52.19],
      [56.47, 18.75],
      [69.12, 39.90],
      [86.93, 25.63]
    ],
    iconsPosition: [
      [10, 36],
      [21, 22],
      [44, 52],
      [57, 19],
      [70, 40],
      [86.5, 26],
    ]
  },
  7: {
    pointsForPath: [
      [5.26, 30.42],
      [18.63, 51.04],
      [35.33, 29.17],
      [52.03, 37.4],
      [69.71, 17.5],
      [87.97, 39.06],
      [73.78, 65.44]
    ],
    iconsPosition: [
      [7.5, 34],
      [19, 51],
      [36, 29],
      [51, 37.5],
      [70, 18],
      [88, 39],
      [74, 65]
    ]
  },
  8: {
    pointsForPath: [
      [5.98, 46.9],
      [15.7, 20],
      [31.29, 34.38],
      [44.01, 20.21],
      [64.82, 24.58],
      [85.5, 40.1],
      [74.47, 55.73],
      [53.86, 58.54],
    ],
    iconsPosition: [
      [7.5, 43],
      [16, 20],
      [32, 34],
      [44, 20],
      [65, 25],
      [86, 40],
      [72, 56.5],
      [54, 58.5],
    ]
  },
  9: {
    pointsForPath: [
      [4.46, 33.65],
      [13.26, 57.71],
      [25.88, 40.84],
      [36.68, 19.9],
      [51.55, 41.46],
      [65.25, 20.42],
      [84.50, 17.29],
      [84.11, 44.17],
      [65.71, 56.98],
    ],
    iconsPosition: [
      [6, 37],
      [14, 58],
      [27, 39],
      [36.5, 20],
      [52, 41.5],
      [65, 21],
      [84, 16],
      [82, 47],
      [66, 57]
    ]
  },
  10: {
    pointsForPath: [
      [2.54, 22.81],
      [16.31, 36.25],
      [27.14, 20.94],
      [42.14, 45.63],
      [55.32, 24.9],
      [71.56, 14.06],
      [90.74, 39.8],
      [68.69, 56.46],
      [48.73, 66.15],
      [29.03, 63.65]
    ],
    iconsPosition: [
      [5, 25],
      [17, 36],
      [27, 21],
      [42, 46],
      [56, 25],
      [72, 14],
      [91, 40],
      [67, 57],
      [49, 67],
      [29, 64]
    ]
  },
  11: {
    pointsForPath: [
      [6.30, 45],
      [15.24, 22.18],
      [28.68, 7.6],
      [40.29, 20],
      [50.07, 27.71],
      [70.17, 9.17],
      [88.02, 29.48],
      [75.78, 47.71],
      [65.64, 65.38],
      [43.68, 56.88],
      [26.40, 69.27]
    ],
    iconsPosition: [
      [8, 41],
      [18, 18],
      [29, 7],
      [39, 19],
      [49, 28],
      [70, 9],
      [88, 29],
      [75, 50],
      [66, 65],
      [44, 57],
      [26, 69],
    ]
  },
  12: {
    pointsForPath: [
      [6.30, 45],
      [15.24, 22.18],
      [28.68, 7.6],
      [40.29, 20],
      [50.07, 27.71],
      [60.25, 12.6],
      [76.17, 9.17],
      [90.02, 29.48],
      [75.78, 47.71],
      [60.64, 59.38],
      [43.68, 56.88],
      [26.40, 69.27]
    ],
    iconsPosition: [
      [8, 41],
      [18, 18],
      [29, 7],
      [39, 19],
      [49, 28],
      [59, 14],
      [73, 8],
      [90, 31],
      [74, 50],
      [59, 59.5],
      [45, 57],
      [27, 69]
    ]
  },
};
export const QUEST_LINE_COLOR = '#FF7A00';
export const QUEST_LINE_WIDTH = 4;
