// Изначальные размеры canvas
const BASE_CONTAINER = {
  width: 2048,
  height: 1152, // 1152 TODO: refactor
};

export function scaleGame(canvas) {
  const scaleX = window.innerWidth / BASE_CONTAINER.width;
  const scaleY = window.innerHeight / BASE_CONTAINER.height;
  const scaleBothValue = Math.min(scaleX, scaleY);

  return scaleBothValue;
}
