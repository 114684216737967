import React, { Component } from "react";
import { withRouter } from "react-router";
import queryString from "query-string";
import * as auth from "../../helpers/authenticationHelper";
import { getScreenType, getWidthMap } from "../../helpers";
import {
  redirect,
  checkImagesLoad,
  removeDataLoadAtFirst,
} from "../../helpers";
import { config, i18n } from "../../data";
import * as api from "../../api";

import {
  Lessons,
  Map,
  Quest,
  Quests,
  //PreLoader,
  MapCloud,
} from "../../components";
import Popup from "../../components/popup/popup";
import Footer from "../../components/footer/footer";
import { connect } from "react-redux";
import {
  closeAuthPopup,
  openAuthPopup,
  setPreloader,
} from "../../redux/actions";
import ExternalShop from "../externalShop/shop";
import MapNav from "../../components/map-nav/map-nav";
import { bannerType, SaleRoutesBanner } from "../banners";
import Button from "../../components/button/button";
import { Input } from "../../common/input";
import { Select } from "../../common/select";
import { sendFeedback } from "../../api/feedback/sendFeedback";

let timer;

class LessonsContainer extends Component {
  constructor(props) {
    super(props);
    const { location } = props;
    let quest_id = this.findQuestId(location.pathname);

    this.state = {
      lessons: [],
      quests: [],
      promoQuests: [],
      prizes: [],
      lesson: {
        status: "",
        type: "",
        preview: "",
        title: "",
        text: "",
        url: "",
      },
      token: null,
      paymentId: null,
      selectedTariff: null,
      backUrl: "",
      goods: [],
      messageSend: false,
      userName: null,
      userNameError: null,
      userEmail: null,
      userEmailError: null,
      userTheme: "Нужна помощь",
      userMessage: null,
      userMessageError: null,
      payments: null,
      is_popup_closed: true,
      is_buywindow_closed: true,
      popup: {
        url: "/",
        section: "payment-tariff",
        btnTitle: i18n.prize_open_secrets,
      },
      loading: true,
      forceUpdate: 0,
      quest_loaded: false,
      promoSkins: [],
      questLessons: [],
      activeQuestID: quest_id,
      children: [],
      buyItem: null,
      currentQuest: null,
      unicornPopup: null,
      payment_status: null,
      quest: null,
      isQuest: false,
      pre_loader_type: "default",
      showPopup: false,
      showFeedback: false,
      image_loaded: false,
      isIphoneX: false,
      // nav
      lessonsScreenType: getScreenType(location, false),
      disabledNextMapStep: false,
      disabledPrevMapStep: true,
      //map
      translateXMap: 0,
      timerId: null,
    };

    this.showPopUp = this.showPopUp.bind(this);
    this.hidePopUp = this.hidePopUp.bind(this);
    this.hidePopUpEsc = this.hidePopUpEsc.bind(this);
    this.showLessons = this.showLessons.bind(this);
    this.showPaymentStatus = this.showPaymentStatus.bind(this);
    this.stopMoveMap = this.stopMoveMap.bind(this);
    /* getPayments().then((response)=>{
       this.setState({
         payments: response
       })
     })*/
  }

  findQuestId(string) {
    let ret = null;
    let rx = string.match(/\/quests\/(\d+)/);
    if (rx) {
      ret = rx[1];
    }
    return ret;
  }

  scrollToLesson(id) {
    let lesson = this.state.lessons.find((l) => l.id === id);
    if (lesson) {
      let link = document.querySelector(
        '.lesson-wrapper[data-map-position="' + lesson.map.position + '"]'
      );

      let SIDE_INDENT_MAP = this.mapAside();
      const [widthWindow, scaleValue] = getWidthMap();

      let newTranslateX =
        widthWindow / 2 -
        (link.getBoundingClientRect().left +
          link.getBoundingClientRect().width / 2) /
          scaleValue -
        SIDE_INDENT_MAP * 2;

      this.setState({ translateXMap: newTranslateX });
    }
  }

  checkAuthPopups() {
    const query = queryString.parse(this.props.location.search);

    if (this.props.location.search.indexOf("reset_password_token=") > 0) {
      this.props.openAuthPopup("password-create");
    }

    if (query.auth) {
      this.props.openAuthPopup(query.auth);
    }
  }

  hideBuyModalWindow = () => {
    this.setState({ token: null });
  };
  buyLesson = (id, name) => {
    window.devMode && console.log("buyLesson", id);
    this.setState({ loading: true, selectedTariff: { price: id } });

    const user = auth.getProfile();
    api
      .paymentsQuest({
        quest_id: id,
        child_id: user.currentChildId,
        quantity: 1,
      })
      .then((data) => {
        let good = this.state.goods.filter((g) => g.id === id);
        console.log("lesson shop", good[0]);
        let backUrl = `https://game.chevostik.ru/successful-payment?type=quest&questname=${name}`;

        window.devMode && console.log("lesson shop", data);
        this.setState({
          paymentId: data.payment_id,
          token: data.confirmation_token,
          backUrl: backUrl,
          loading: false,
          is_buywindow_closed: false,
        });
      })
      .catch(() => this.setState({ loading: false }));
  };

  buyResult(e) {
    window.devMode && console.log("buyResult", e);

    this.setState({ buyItem: null });
  }

  buildQuests() {
    let subscription = auth.isSubscriptionActive();
    this.setState({
      goods: this.state.quests,
    });
    let bgColor = 0;
    let promoQuests = this.state.quests.map((item) => {
      let lessonsComplete = 0;
      const color = [
        { number: "#84A9BD" },
        { number: "#E3974B" },
        { number: "#6BA96D" },
        { number: "#9A8BAA" },
        { number: "#C2947D" },
      ];

      bgColor++;
      if (bgColor === 5) {
        bgColor = 0;
      }
      for (let i = 0; i < item.lessons.length; i++) {
        if (item.lessons[i].is_completed === true) {
          lessonsComplete++;
        }
      }
      const containerStyles = {
        height: 17,
        width: 257,
        backgroundColor: "#e0e0de",
        borderRadius: 50,
        border: "2px solid white",
      };
      const percentCompleted = (100 * lessonsComplete) / item.lessons.length;
      const fillerStyles = {
        height: "100%",
        width: `${percentCompleted}%`,
        backgroundColor: "#96D04F",
        transition: "width 1s ease-in-out",
        borderRadius: "inherit",
        textAlign: "right",
      };
      const checkPayment = ["on", "soon_finished"].includes(
        (auth.getProfile() || {}).subscription.status || { recurrent_status: 2 }
      );
      const checkSub =
        (item.category === "subscription" && checkPayment) ||
        item.category === "free";
      let lessonPaid = item.is_bought;

      return (
        <>
          {item.category === "paid" ? (
            <>
              <div
                onClick={() => {
                  if (lessonPaid === false) {
                    this.buyLesson(item.id, item.name);
                  }
                  if (lessonPaid === true) {
                    window.location.replace(
                      `/quests/${item.id}?color=${bgColor}`
                    );
                  }
                }}
                className="lessons__element"
                key={item.id}
              >
                {!lessonPaid && (
                  <div className={"block__icon"}>
                    <img
                      alt={"block icon"}
                      src={process.env.PUBLIC_URL + "/icons/Block.png"}
                    />
                  </div>
                )}
                <div
                  style={{
                    backgroundColor: color[bgColor].number,
                    filter: `grayscale(${lessonPaid ? 0 : 1})`,
                  }}
                  className={"lessons__element__icon"}
                >
                  <img
                    alt={"quest icon"}
                    className="quests__image"
                    role="presentation"
                    src={`${config.base_url}${item.image}`}
                  />
                </div>
                <div className="quests__item-name">{item.name}</div>
                <div className="quests__item-text">{item.description}</div>
                {!lessonPaid && (
                  <div className={"buy__button"}>{item.price} р</div>
                )}
                {lessonPaid && (
                  <>
                    {lessonsComplete !== 0 &&
                      lessonsComplete !== item.lessons.length && (
                        <>
                          <div className="lesson__inprogress">
                            <div style={{ display: "flex" }}>
                              <div>Пройдено</div>
                              <div className={"lesson__progress__count"}>
                                {lessonsComplete}/{item.lessons.length}
                              </div>
                            </div>
                            <div style={containerStyles}>
                              <div style={fillerStyles}></div>
                            </div>
                          </div>
                        </>
                      )}
                    {lessonsComplete === 0 && (
                      <div className="lesson__progress">
                        {lessonsComplete}/{item.lessons.length}
                      </div>
                    )}
                    {lessonsComplete === item.lessons.length && (
                      <div
                        style={{ color: "#78B83B" }}
                        className="lesson__progress"
                      >
                        <div>Завершено</div>
                        <div className={"lesson__progress__count"}>
                          {lessonsComplete}/{item.lessons.length}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              <div
                onClick={() => {
                  if (checkSub === false) {
                    window.location.replace("/cabinet/select_tariff");
                  }
                  if (checkSub === true) {
                    window.location.replace(
                      `/quests/${item.id}?color=${bgColor}`
                    );
                  }
                }}
                className="lessons__element"
                key={item.id}
              >
                {!checkSub && (
                  <div className={"block__icon"}>
                    <img
                      alt={"block icon"}
                      src={process.env.PUBLIC_URL + "/icons/Block.png"}
                    />
                  </div>
                )}
                <div
                  style={{
                    backgroundColor: color[bgColor].number,
                    filter: `grayscale(${checkSub ? 0 : 1})`,
                  }}
                  className={"lessons__element__icon"}
                >
                  <img
                    alt={"quest icon"}
                    className="quests__image"
                    role="presentation"
                    src={`${config.base_url}${item.image}`}
                  />
                </div>
                <div className="quests__item-name">{item.name}</div>
                <div className="quests__item-text">{item.description}</div>
                {!checkSub && <div className={"buy__button"}>Купить</div>}
                {checkSub && (
                  <>
                    {lessonsComplete !== 0 &&
                      lessonsComplete !== item.lessons.length && (
                        <>
                          <div className="lesson__inprogress">
                            <div style={{ display: "flex" }}>
                              <div>Пройдено</div>
                              <div className={"lesson__progress__count"}>
                                {lessonsComplete}/{item.lessons.length}
                              </div>
                            </div>
                            <div style={containerStyles}>
                              <div style={fillerStyles}></div>
                            </div>
                          </div>
                        </>
                      )}
                    {lessonsComplete === 0 && (
                      <div className="lesson__progress">
                        {lessonsComplete}/{item.lessons.length}
                      </div>
                    )}
                    {lessonsComplete === item.lessons.length && (
                      <div
                        style={{ color: "#78B83B" }}
                        className="lesson__progress"
                      >
                        <div>Завершено</div>
                        <div className={"lesson__progress__count"}>
                          {lessonsComplete}/{item.lessons.length}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </>
      );
    });
    const regDate = new Date(auth.getProfile()?.created_at);
    const nowDate = new Date();
    const createdDays =
      (regDate.getTime() - nowDate.getTime()) / (24 * 3600 * 365.25 * 1000);
    if (
      ["soon_finished", "off"].includes(subscription?.status) &&
      createdDays < 3
    ) {
      promoQuests.splice(
        0,
        0,
        <SaleRoutesBanner
          name="spring-routes"
          type={bannerType.routes}
          repeat={1}
        />
      );
    }

    this.setState({ promoQuests });
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    const quest_id = this.findQuestId(location.pathname);
    const { pathname, search } = location;
    const query = queryString.parse(search);
    const { popup } = queryString.parse(search);
    const { popup: oldPopup } = queryString.parse(prevProps.location.search);
    let currentChildId = auth.getCurrentChild();

    if (
      prevProps.location.pathname !== pathname &&
      prevProps.location.pathname.indexOf("/cabinet/child/") === 0
    ) {
      this.loadLessons();
    }

    if (
      query.popup &&
      query.popup === "successReg" &&
      this.state.forceUpdate >= 0
    ) {
      this.setState({ forceUpdate: -1 });
    }

    // Прячу попап, если  нажимаю на кнопку зарегистрироваться на странице с авторизацией (т.к нахожусь на этой же странице)
    if (location.pathname === config.routes.enter) {
      this.hidePopUp();
    }
    if (prevProps.location.pathname !== pathname) {
      if (pathname.indexOf("quests") !== -1) {
        this.setState({
          questLessons: [],
          activeQuestID: null,
          quest_loaded: false,
        });

        if (currentChildId) {
          api.getQuests(currentChildId).then((result) => {
            this.setState(
              {
                isQuest: true,
                lessonsScreenType: "quest",
                activeQuestID: quest_id,
                quests: result,
              },
              () => {
                this.buildQuests();
              }
            );

            this.showQuestItem();
          });
        } else {
          redirect();
        }
      } else {
        this.setState({
          isQuest: false,
          isQuestBg: false,
          lessonsScreenType: getScreenType(location, false),
        });
      }
    }

    if (oldPopup !== popup) {
      this.setState({
        lessonsScreenType: getScreenType(location, this.state.isQuest),
      });
    }

    this.checkAuthPopups();
  }

  loadLessons() {
    let promise = this.showLessons();

    promise.then(() => {
      if (localStorage.getItem("lesson")) {
        let getlesson = localStorage.getItem("lesson");
        let lesson = JSON.parse(getlesson);

        let user = auth.getProfile();
        let userSharedLink = user && user.shared_link;

        let obj = {
          currentTarget: {
            section: "lessons",
            getAttribute: (data) =>
              ({
                "data-status": userSharedLink ? "" : "not_paid",
                "data-img-preview": lesson.preview_image.large,
                "data-title": lesson.name,
                "data-text": lesson.short_description,
                "data-url": "/subscription",
              }[data]),
          },
        };
        this.showPopUp(obj);
        window.localStorage.removeItem("lesson");
      }
    });
  }

  hidePopUpEsc(e) {
    if (e && e.keyCode === 27 && typeof this.hasOwnProperty === "function") {
      this.hidePopUp();
    }
  }

  componentDidMount() {
    this.props.setPreloader("default");

    const {
      location: { pathname, search },
    } = this.props;
    const query = queryString.parse(search);

    let currentChildId = auth.getCurrentChild();
    this.loadLessons();
    setInterval(() => {
      if (auth.isLoggedIn() && !this.state.lessons) {
        this.loadLessons();
      }
    }, 500);

    if (query.status && query.id) {
      this.showPaymentStatus();
    }

    if (
      query.popup &&
      query.popup === "successReg" &&
      this.state.forceUpdate >= 0
    ) {
      this.setState({ forceUpdate: -1 });
    }

    window.addEventListener("keydown", this.hidePopUpEsc);

    if (auth.getCurrentName() && !!window.localStorage.getItem("auth")) {
      window.localStorage.removeItem("auth");
    }

    // let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    let ratio = window.devicePixelRatio || 1;
    let screen = {
      width: window.screen.width * ratio,
      height: window.screen.height * ratio,
    };

    // iPhone X Detection
    if (screen.width === 2436 && screen.height === 1125) {
      this.setState({
        isIphoneX: true,
      });
    }

    if (pathname.indexOf("/quests") !== -1) {
      if (currentChildId) {
        api.getQuests(currentChildId).then((result) => {
          this.setState(
            {
              isQuest: true,
              quests: result,
              //.sort((a, b) => {
              //  return a.id - b.id
              //})
            },
            () => {
              this.buildQuests();
            }
          );

          this.showQuestItem();
          if (typeof window.setPreloader !== "function") {
            this.props.setPreloader("");
          }
        });
      } else {
        redirect();
      }
    }

    window.addEventListener("resize", this.reScaleMap);

    this.checkAuthPopups();
  }

  componentWillUnmount() {
    window.setPreloader = null;

    window.removeEventListener("resize", this.reScaleMap);

    window.removeEventListener("keydown", this.hidePopUp);
  }

  showPopUp(event) {
    window.addEventListener("keydown", this.hidePopUp);

    this.setState({
      lesson: {
        status: event.currentTarget.getAttribute("data-status"),
        preview: event.currentTarget.getAttribute("data-img-preview"),
        section: "lessons",
        title: event.currentTarget.getAttribute("data-title"),
        text: event.currentTarget.getAttribute("data-text"),
        url: event.currentTarget.getAttribute("data-url"),
      },
      is_popup_closed: false,
    });
  }

  showPopUpFromQuest = (lesson) => {
    window.addEventListener("keydown", this.hidePopUp);

    this.setState({
      lesson,
      is_popup_closed: false,
    });
  };

  hidePopUp = () => {
    window.removeEventListener("keydown", this.hidePopUp);

    removeDataLoadAtFirst();

    //TODO: refactor
    this.setState({
      lesson: {
        status: "",
        type: "",
        preview: "",
        title: "",
        text: "",
        url: "",
      },
      is_popup_closed: true,
    });
  };

  buyLessons() {}

  showLessons() {
    const { location } = this.props;
    const { search } = location;
    const searchQuery = new URLSearchParams(search);

    let currentChildId = auth.getCurrentChild();

    return Promise.all([
      api.getLessons(currentChildId),
      checkImagesLoad(config.map_lessons_img),
    ]).then((result) => {
      /* let dataLoadAtFirst =
         localStorage.getItem('dataAtFirstLoad') &&
         location.pathname !== config.lesson_not_registered;*/
      /* let images = [];

       if (dataLoadAtFirst) {
         images = config.map_lessons_book_popup;

         if (isPhone()) {
           images = config.map_lessons_book_popup_mobile;
         }
       }*/

      //result[0].forEach(item => {
      //  if (item.map && item.map.icon) {
      //    images.push(`${config.base_url}${item.map.icon}`);
      //  }
      //});

      this.setState(
        {
          lessons: result[0],
          childName: auth.getCurrentName() ? auth.getCurrentName() : null,
          children: auth.getChildren() ? auth.getChildren() : null,
          lessonsScreenType: getScreenType(location, this.state.isQuest),
          image_loaded: true,
        },
        () => {
          if (this.props.hideLoader) {
            this.props.setPreloader("");
          }

          if (searchQuery.get("mapScrollToLesson")) {
            setTimeout(() => {
              this.scrollToLesson(
                parseInt(searchQuery.get("mapScrollToLesson"))
              );
            }, 10);
          }
        }
      );
    });
  }

  showPaymentStatus() {
    let title = i18n.paymentCompletedTitle;
    let text = null;
    let btnTitle = i18n.paymentBtnTitle;
    const query = queryString.parse(this.props.location.search);

    if (query.status === "processing") {
      title = i18n.paymentProcessingTitle;
      text = i18n.paymentProcessingText;
      btnTitle = i18n.paymentProcessingBtnTitle;
    }

    if (query.status === "failed") {
      title = i18n.paymentFailedTitle;
      text = i18n.paymentFailedText;
      btnTitle = i18n.paymentFailedBtnTitle;
    }

    this.setState({
      payment_status: {
        title: title,
        text: text,
        btnTitle: btnTitle,
        section: "payment_status",
      },
      is_popup_closed: false,
    });
  }

  mapAside() {
    return parseInt(
      getComputedStyle(document.documentElement).getPropertyValue(
        "--map-aside-space"
      )
    );
  }

  stopMoveMap() {
    window.clearInterval(timer);
  }

  handleQuestChange = (id) => {
    this.setState(
      { activeQuestID: id, quest_loaded: false },
      this.showQuestItem
    );
  };
  handleBuyQuest = (showPopup, currentQuest) => {
    this.setState({ showPopup: showPopup, currentQuest: currentQuest });
  };

  showQuestItem() {
    if (this.state.activeQuestID !== null && this.state.activeQuestID >= 0) {
      let currentChildId = auth.getCurrentChild();
      this.props.setPreloader("quest__preloader");

      if (currentChildId) {
        return Promise.all([
          api.getQuest(currentChildId, parseInt(this.state.activeQuestID)),
          checkImagesLoad(config.map_lessons_img),
        ]).then((result) => {
          this.setState({
            childName: auth.getCurrentName() ? auth.getCurrentName() : null,
            children: auth.getChildren() ? auth.getChildren() : null,
            questLessons: result[0].lessons.sort((a, b) => a.id - b.id),
            quest: result[0],
            quest_loaded: true,
            image_loaded: true,
            lessonsScreenType: "quest",
          });
        });
      } else {
        redirect();
      }
    }
  }

  render() {
    const {
      lessonsScreenType,
      translateXMap,
      disabledNextMapStep,
      disabledPrevMapStep,
      buyItem,
      showPopup,
      showFeedback,
      currentQuest,
      forceUpdate,
    } = this.state;

    const feebackToggle = () => {
      this.setState({ showFeedback: !this.state.showFeedback });
    };
    const { is_buywindow_closed, token, paymentId, backUrl } = this.state;
    const activeQuestID = parseInt(this.state.activeQuestID);

    const { location } = this.props;
    const { pathname, search } = location;
    let popup = (
      <Popup
        is_closed={this.state.is_popup_closed}
        hidePopUp={this.hidePopUp}
        location={this.props && location}
        item={this.state.lesson}
      />
    );

    if (this.state.payment_status) {
      popup = (
        <Popup
          is_closed={false}
          hidePopUp={this.hidePopUp}
          location={this.props && location}
          item={this.state.payment_status}
        />
      );
    }

    let clouds = Array.apply(null, Array(5)).map((i, c) => (
      <MapCloud key={c} index={c + 1} />
    ));

    const user = auth.getProfile();

    let opacity =
      (pathname.indexOf("/games") === 0 && pathname.length > 7) ||
      pathname.match(/\/lessons\/\d+/);
    let searchParam = new URLSearchParams(search.slice(1));
    let allPopups = searchParam.has("popup")
      ? searchParam.get("popup").split(",")
      : [];

    if (allPopups.length) {
      opacity = false;
    }
    const options = [
      { value: "Нужна помощь", title: "Нужна помощь" },
      { value: "Отзыв", title: "Отзыв" },
      { value: "Партнерство", title: "Партнерство" },
      { value: "Другое", title: "Другое" },
    ];
    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    return (
      <main
        className={`lessons-container ${this.props.screenClass || ""} ${
          auth.isLoggedIn() ? "lessons-container_state_logged" : ""
        }`}
        ref="lessonsContainer"
      >
        {showFeedback && (
          <div
            onClick={(e) => {
              console.log("123");
              if (e.target.className === "payment__wrapper__map") {
                this.setState({ showFeedback: false });
              }
            }}
            style={{ position: "absolute" }}
            className={"payment__wrapper__map"}
          >
            <div
              style={{ backgroundColor: "#E7E8CE" }}
              className="payment__modal__map"
            >
              <div className="quest_wrapper">
                <div
                  onClick={() => this.setState({ showFeedback: false })}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    right: "-15px",
                    top: "-15px",
                    zIndex: 99999,
                    backgroundColor: "white",
                    border: "2px solid black",
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                  }}
                >
                  <h2>X</h2>
                </div>
                {this.state.messageSend && (
                  <div>
                    <h2>Спасибо за обратную связь :)</h2>
                    <div>
                      <Button
                        onClick={() => {
                          this.setState({
                            showFeedback: false,
                            messageSend: false,
                          });
                        }}
                        color={"orange"}
                      >
                        Закрыть
                      </Button>
                    </div>
                  </div>
                )}
                {!this.state.messageSend && (
                  <>
                    <Input
                      className={"sign-up__name"}
                      placeholder_text={"Ваше имя"}
                      onChange={(e) => {
                        this.setState({ userName: e[0] });
                      }}
                    />
                    {this.state.userNameError && (
                      <span style={{ color: "red", marginLeft: "25px" }}>
                        {this.state.userNameError}
                      </span>
                    )}
                    <Input
                      className={"sign-up__name"}
                      placeholder_text={"Ваш E-mail"}
                      onChange={(e) => {
                        this.setState({ userEmail: e[0] });
                      }}
                    />
                    {this.state.userEmailError && (
                      <span style={{ color: "red", marginLeft: "25px" }}>
                        {this.state.userEmailError}
                      </span>
                    )}
                    <Select
                      onChange={(e) => {
                        console.log(e[0]);
                        this.setState({ userTheme: e[0] });
                      }}
                      className={"sign-up__name"}
                      options={options}
                    />
                    <Input
                      className={"sign-up__name"}
                      placeholder_text={"Ваше сообщение"}
                      onChange={(e) => {
                        this.setState({ userMessage: e[0] });
                      }}
                    />
                    {this.state.userMessageError && (
                      <div
                        style={{
                          color: "red",
                          marginLeft: "25px",
                          marginBottom: "25px",
                        }}
                      >
                        {this.state.userMessageError}
                      </div>
                    )}
                    <Button
                      onClick={() => {
                        const feedback = {
                          name: this.state.userName,
                          email: this.state.userEmail,
                          subject: this.state.userTheme,
                          message: this.state.userMessage,
                        };
                        if (!this.state.userName) {
                          this.setState({ userNameError: "Введите имя" });
                        } else {
                          this.setState({ userNameError: null });
                        }

                        if (!this.state.userEmail) {
                          this.setState({
                            userEmailError: "Введите Ваш e-mail",
                          });
                        } else {
                          this.setState({ userEmailError: null });
                          if (validateEmail(this.state.userEmail)) {
                            this.setState({ userEmailError: null });
                          } else {
                            this.setState({
                              userEmailError: "Введите корректный e-mail",
                            });
                          }
                        }
                        if (!this.state.userMessage) {
                          this.setState({
                            userMessageError: "Введите Ваше сообщение",
                          });
                        } else {
                          this.setState({ userMessageError: null });
                        }
                        if (
                          this.state.userName &&
                          validateEmail(this.state.userEmail) &&
                          this.state.userMessage
                        ) {
                          console.log(feedback);
                          sendFeedback(feedback).then(() => {
                            this.setState({ messageSend: true });
                          });
                        }
                      }}
                      color={"orange"}
                    >
                      Отправить
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
        )}

        {showPopup && (
          <div
            onClick={() =>
              this.setState({ showPopup: false, currentQuest: null })
            }
            className={"payment__wrapper__map"}
          >
            <div
              //  closePopup={() => this.setState({showPopup: false, currentQuest: null})}
              className="payment__modal__map"
            >
              <div className="quest_wrapper">
                {/*  <div
                  className="quest_bg"
                  style={{backgroundImage: `url(${currentQuest.image})`}}
                />*/}
                <img alt={"quest image"} src={currentQuest.image} />
                <div className="quest__subtitle">{currentQuest.name}</div>
                <div className="questPopup__description">
                  {currentQuest.description}
                </div>
                <Button
                  onClick={() => {
                    this.buyLesson(currentQuest.id, currentQuest.name);
                    this.setState({ showPopup: false });
                  }}
                  color={"orange"}
                >
                  Купить {currentQuest.price}р
                </Button>
              </div>
            </div>
          </div>
        )}
        {token && (
          <div className="buy-modal-window">
            <Popup
              popupData={{
                token: token,
                backUrl: backUrl,
                paymentId: paymentId,
              }}
              is_closed={is_buywindow_closed}
              hidePopUp={this.hideBuyModalWindow}
              item={this.state.popup}
            />
          </div>
        )}
        {this.props.hideNav ? null : (
          <MapNav
            opacity={opacity}
            user={user}
            forceUpdate={forceUpdate}
            quests={this.state.quests}
            activeQuestID={this.state.activeQuestID}
            lessonsScreenType={lessonsScreenType}
            stopMoveMap={this.stopMoveMap}
            onQuestChange={this.handleQuestChange}
            disabledNextMapStep={disabledNextMapStep}
            disabledPrevMapStep={disabledPrevMapStep}
          />
        )}
        <Map
          lessonsScreenType={lessonsScreenType}
          newTranslateX={translateXMap}
        >
          <Lessons
            quest={this.state.quests.find(({ id }) => id === activeQuestID)}
            lessonsList={this.state.lessons}
            payments={this.state.payments}
            showPopUp={this.showPopUp}
            buyLesson={this.handleBuyQuest}
          />

          <div className="map-clouds">{clouds}</div>
        </Map>
        <div
          style={{
            position: "absolute",
            width: "100px",
            height: "100px",
            backgroundColor: "black",
            zIndex: "99999",
          }}
        ></div>
        {this.state.isQuest &&
          (activeQuestID >= 0 ? (
            <Quest
              quest_loaded={this.state.quest_loaded}
              quest={this.state.quests.find(({ id }) => id === activeQuestID)}
              lessons={this.state.questLessons}
              buyLesson={this.handleBuyQuest}
              showPopUp={this.showPopUpFromQuest}
            />
          ) : (
            <Quests quests={this.state.promoQuests} />
          ))}

        {!auth.isLoggedIn() && (
          <Footer
            feebackToggle={feebackToggle}
            isPhoneX={this.state.isIphoneX}
          />
        )}

        {this.props.location.pathname.indexOf("quests") > -1 ? (
          <ExternalShop
            buyResult={(e) => {
              this.buyResult(e);
            }}
            buyItem={buyItem}
          />
        ) : null}

        {popup}
      </main>
    );
  }
}

const componentWithRouter = withRouter(
  connect(null, {
    setPreloader,
    closeAuthPopup,
    openAuthPopup,
  })(LessonsContainer)
);

export { componentWithRouter as LessonsContainer };
