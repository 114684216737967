import { ajax } from '../../helpers';

/**
 * @typedef {Object} newToken
 * */

/**
 * @returns {Promise.<newToken>}
 */
export const postNewToken = () => {
  return ajax
    .post('/auth/renew_token')
    .then(response => response.data)
    .catch(error => error);
};
